import MapCardContainer from "components/containers/MapCardContainer";
import MapCardInfoRow from "components/infoWindows/MapCardInfoRow";
import Box from "@mui/material/Box";
import MapCardViewButton from "components/buttons/MapCardViewButton";
import colors from "config/theme/colors";
import CopyButton from "components/buttons/CopyButton";
import PropTypes from "prop-types";
import { getAssetsMarkerIcon as getAssetsMarker } from "utils/getAssetsMarkerIcon";
import { Typography } from "@mui/material";
import { getAssetsIcon } from "utils/getAssetsIcon";

const AssetsMapCard = ({ item, onClick }) => {
  const rows = [
    {
      labelColor: colors.black,
      valueColor: colors.text,
      label: "Κατηγορία",
      value: (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="baseline"
          gap={1}
        >
          <Typography fontSize={14} fontWeight={500}>
            {item?.category}
          </Typography>
          <Typography fontSize={14} fontWeight={500} color={colors.grey}>
            {item?.name}
          </Typography>
        </Box>
      ),
    },
    {
      labelColor: colors.black,
      valueColor: colors.text,
      label: "Χρήση",
      value: item?.use,
    },
    {
      labelColor: colors.black,
      valueColor: colors.text,
      label: "Διεύθυνση",
      value: item?.address,
    },
    {
      labelColor: colors.black,
      valueColor: colors.text,
      label: "Επιφάνεια",
      value: `${item?.surface} m²`,
    },
    {
      labelColor: colors.black,
      valueColor: colors.text,
      label: "ΚΑΕΚ",
      value: item?.kaekNotModified,
      Component: (
        <CopyButton
          textToCopy={item.kaekNotModified}
          buttonSx={{
            padding: 0,
            marginLeft: 1,
          }}
        />
      ),
    },
  ];

  return (
    <MapCardContainer>
      <Box display="flex" gap={2} p={1}>
        <Box
          width={42}
          display="flex"
          alignItems="center"
          flexDirection={"column"}
          gap={1}
        >
          {
            <img
              src={getAssetsMarker(item?.categoryId)}
              alt={item?.category}
              width={38}
              height={38}
            />
          }
          {
            <img
              src={getAssetsIcon(item?.categoryId)}
              alt={item?.category}
              width={38}
              height={38}
            />
          }
        </Box>
        <Box display="flex" flexDirection="column" gap={0.5}>
          {rows?.map((row) => (
            <MapCardInfoRow
              key={row.label}
              labelColor={row.labelColor}
              valueColor={row.valueColor}
              Icon={row.Icon}
              label={row.label}
              value={row.value}
              Component={row.Component}
            />
          ))}
        </Box>
      </Box>

      <Box display="flex" justifyContent="center" alignItems="center" mt={3}>
        <MapCardViewButton
          sx={{
            padding: "12px, 24px, 12px, 24px",
            borderRadius: "4px",
            border: `1px solid ${colors.primary}`,
            backgroundColor: colors.white,
          }}
          fontSize={14}
          fontWeight={600}
          color={colors.primary}
          title="Προβολή Ακινήτου"
          onClick={onClick}
        />
      </Box>
    </MapCardContainer>
  );
};

AssetsMapCard.propTypes = {
  item: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default AssetsMapCard;
