import { requests } from "api/HttpClient";

const { post, get, put, delete: deleteRequest } = requests;

const certificationClass = {
  getAll: ({ pageNumber = -1, pageSize = -1 }) => {
    let url = "/CertificationClass/GetCertificationClassList";
    if (pageNumber && pageSize) {
      url += `?pageNumber=${pageNumber}&pageSize=${pageSize}`;
    }
    return get(url);
  },
  getById: (id) => get(`/CertificationClass/GetCertificationClassById/${id}`),
  create: (body) =>
    post("/CertificationClass/CreateCertiCertificationClass", body),
  update: (id, body) =>
    put(`/CertificationClass/UpdateCertificationClass/${id}`, body),
  delete: (id) =>
    deleteRequest(`/CertificationClass/DeleteCertificationClass/${id}`),
};

export default certificationClass;
