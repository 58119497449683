import PublicPremiseMarker from "../assets/markerIcons/publicPremise.svg";
import SchoolMarker from "../assets/markerIcons/school.svg";
import PlotMarker from "../assets/markerIcons/plot.svg";
import CommonAreaMarker from "../assets/markerIcons/commonArea.svg";
import DefaultMarker from "../assets/markerIcons/default.svg";
import LandMarker from "../assets/markerIcons/land.svg";
import SportFacilityMarker from "../assets/markerIcons/sportsFacility.svg";

export function getAssetsMarkerIcon(categoryId) {
  switch (categoryId) {
    case 1:
      return PublicPremiseMarker;
    case 2:
      return PlotMarker;
    case 3:
      return CommonAreaMarker;
    case 4:
      return SchoolMarker;
    case 5:
      return LandMarker;
    case 6:
      return SportFacilityMarker;
    default:
      return DefaultMarker;
  }
}
