import { requests } from "api/HttpClient";

const { get, getFile } = requests;

const assetReports = {
  getAssetMaintenances: ({
    status = "",
    dateFrom = "",
    dateTo = "",
    pageNumber = -1,
    pageSize = -1,
  }) => {
    const queryParams = {
      status,
      dateFrom,
      dateTo,
      pageNumber,
      pageSize,
    };

    const queryString = Object.keys(queryParams)
      .filter((key) => queryParams[key])
      .map(
        (key) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`
      )
      .join("&");

    const url = `/AssetReport/GetAssetMaintenances?${queryString}`;

    return get(url);
  },

  getAssetMaintenanceCostsPerAsset: ({
    dateFrom = "",
    dateTo = "",
    pageNumber = -1,
    pageSize = -1,
    assetIds = [],
  }) => {
    const queryParams = {
      dateFrom,
      dateTo,
      pageNumber,
      pageSize,
      assetIds,
    };

    const queryString = Object.keys(queryParams)
      .filter((key) => queryParams[key])
      .map((key) => {
        if (queryParams[key] instanceof Array) {
          return queryParams[key]
            .map((id) => `${encodeURIComponent(key)}=${encodeURIComponent(id)}`)
            .join("&");
        }
        return `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`;
      })
      .join("&");

    const url = `/AssetReport/GetMaintenanceCostsPerAsset?${queryString}`;

    return get(url);
  },

  getAssetMaintenancesCostsPerType: ({
    dateFrom = "",
    dateTo = "",
    pageNumber = -1,
    pageSize = -1,
  }) => {
    const queryParams = {
      dateFrom,
      dateTo,
      pageNumber,
      pageSize,
    };

    const queryString = Object.keys(queryParams)
      .filter((key) => queryParams[key])
      .map(
        (key) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`
      )
      .join("&");

    const url = `/AssetReport/GetMaintenancesCostsPerType?${queryString}`;

    return get(url);
  },

  exportAssetMaintenances: ({
    status = "",
    exportType = "",
    dateFrom = "",
    dateTo = "",
  }) => {
    const queryParams = {
      exportType,
      status,
      dateFrom,
      dateTo,
    };

    const queryString = Object.keys(queryParams)
      .filter((key) => queryParams[key])
      .map(
        (key) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`
      )
      .join("&");

    const url = `/AssetReport/ExportAssetMaintenancesToFile?${queryString}`;

    return getFile(url);
  },

  exportAssetMaintenanceCostsPerAsset: ({
    exportType = "",
    assetIds = [],
    dateFrom = "",
    dateTo = "",
  }) => {
    const queryParams = {
      exportType,
      dateFrom,
      dateTo,
      assetIds,
    };
    const queryString = Object.keys(queryParams)
      .filter((key) => queryParams[key])
      .map((key) => {
        if (queryParams[key] instanceof Array) {
          return queryParams[key]
            .map((id) => `${encodeURIComponent(key)}=${encodeURIComponent(id)}`)
            .join("&");
        }
        return `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`;
      })
      .join("&");

    const url = `/AssetReport/ExportMaintenanceCostsPerAssetToFile?${queryString}`;

    return getFile(url);
  },

  exportAssetMaintenancesCostsPerType: ({
    exportType = "",
    dateFrom = "",
    dateTo = "",
  }) => {
    const queryParams = {
      exportType,
      dateFrom,
      dateTo,
    };

    const queryString = Object.keys(queryParams)
      .filter((key) => queryParams[key])
      .map(
        (key) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`
      )
      .join("&");

    const url = `/AssetReport/ExportMaintenancesCostsPerTypeToFile?${queryString}`;

    return getFile(url);
  },
};

export default assetReports;
