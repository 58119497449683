import { requests } from "api/HttpClient";

const { post, get, put, delete: deleteRequest } = requests;

const config = {
  headers: {
    "Content-Type": "multipart/form-data",
  },
};

const maintenance = {
  getAll: ({
    pageNumber = -1,
    pageSize = -1,
    assetId,
    equipmentIds,
    maintenanceTypeIds,
    contractId,
  }) => {
    let url = "/Maintenance/GetMaintenanceListBy";

    const queryParams = [];

    if (pageNumber && pageSize) {
      queryParams.push(`pageNumber=${pageNumber}`);
      queryParams.push(`pageSize=${pageSize}`);
    }

    if (maintenanceTypeIds) {
      maintenanceTypeIds.forEach((type) => {
        queryParams.push(`MaintenanceTypeIds=${encodeURIComponent(type)}`);
      });
    }

    if (assetId) {
      queryParams.push(`assetId=${assetId}`);
    }

    if (contractId) {
      queryParams.push(`contractId=${contractId}`);
    }

    if (equipmentIds) {
      equipmentIds.forEach((type) => {
        queryParams.push(`equipmentIds=${encodeURIComponent(type)}`);
      });
    }

    if (queryParams.length > 0) {
      url += `?${queryParams.join("&")}`;
    }

    return get(url);
  },
  getEquipmentMaintenancesByAssetId: ({
    pageNumber = -1,
    pageSize = -1,
    assetId,
    maintenanceTypeIds,
    equipmentTypeIds,
  }) => {
    let url = "/Maintenance/GetEquipmentMaintenancesByAssetId";

    const queryParams = [];

    if (pageNumber && pageSize) {
      queryParams.push(`pageNumber=${pageNumber}`);
      queryParams.push(`pageSize=${pageSize}`);
    }

    if (maintenanceTypeIds) {
      maintenanceTypeIds.forEach((type) => {
        queryParams.push(`MaintenanceTypeIds=${encodeURIComponent(type)}`);
      });
    }

    if (equipmentTypeIds) {
      equipmentTypeIds.forEach((type) => {
        queryParams.push(`EquipmentTypeIds=${encodeURIComponent(type)}`);
      });
    }

    if (assetId) {
      queryParams.push(`assetId=${assetId}`);
    }

    if (queryParams.length > 0) {
      url += `?${queryParams.join("&")}`;
    }

    return get(url);
  },
  getById: (id) => get(`/Maintenance/GetMaintenanceById/${id}`),
  create: (data) => post("/Maintenance/AddMaintenance", data, config),
  addMaintenanceInMultipleEquipments: (data) =>
    post("/Maintenance/AddMaintenanceInMultipleEquipments", data, config),
  update: (id, data) =>
    put(`/Maintenance/UpdateMaintenance/${id}`, data, config),
  delete: (id) => deleteRequest(`/Maintenance/DeleteMaintenance/${id}`),
};

export default maintenance;
