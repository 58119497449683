import { Box, Typography } from "@mui/material";
import colors from "config/theme/colors";
import PropTypes from "prop-types";

const TagLabel = ({ text, onClick }) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      bgcolor={colors.background}
      color={colors.background}
      border={`1px solid ${colors.primaryLight}`}
      borderRadius={"5px"}
      padding="0 10px"
      cursor="pointer"
      onClick={onClick}
    >
      <Typography fontSize={14} fontWeight={400} color={colors.primary}>
        {text}
      </Typography>
    </Box>
  );
};

TagLabel.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

export default TagLabel;
