import { Box, Tooltip, Typography } from "@mui/material";
import CustomTable from "components/tables/CustomTable";
import { useMemo, useState } from "react";
import EquipmentTypeForm from "./EquipmentTypeForm";
import colors from "config/theme/colors";
import {
  useCreateEquipmentType,
  useDeleteEquipmentType,
  useGetAllEquipmentTypes,
  useGetEquipmentTypeById,
  useUpdateEquipmentType,
} from "hooks/queries/useEquipmentType";
import SpinningCircle from "components/spinners/SpinningCircle";
import { useAlert } from "hooks/useAlert";
import EditCircleButton from "components/buttons/EditCircleButton";
import usePagination from "hooks/usePagination";

const EquipmentTypesOverview = () => {
  const { showError, showSuccess } = useAlert();
  const { page, rowsPerPage, handleChangePage, handleChangeRowsPerPage } =
    usePagination();
  const [selectedEquipmentTypeId, setSelectedEquipmentTypeId] = useState(null);
  const [isEditing, setIsEditing] = useState(false);

  const { data: selectedEquipmentType } = useGetEquipmentTypeById(
    selectedEquipmentTypeId,
    { enabled: selectedEquipmentTypeId !== null }
  );

  const {
    data: equipmentTypes,
    isLoading,
    error,
  } = useGetAllEquipmentTypes(
    {
      pageNumber: page,
      pageSize: rowsPerPage,
    },
    { select: (data) => data?.result }
  );

  const { mutate: createEquipmentType } = useCreateEquipmentType({
    config: {
      onError: (error) => {
        showError(error.message);
      },
      onSuccess: (res) => {
        setSelectedEquipmentTypeId(res.result.id);
        showSuccess("Ο τύπος εξοπλισμού δημιουργήθηκε με επιτυχία");
      },
    },
  });
  const { mutate: updateEquipmentType } = useUpdateEquipmentType({
    id: selectedEquipmentTypeId,
    config: {
      onError: (error) => {
        showError(error.message);
      },
      onSuccess: () => {
        setIsEditing(false);
        showSuccess("Ο τύπος εξοπλισμού ενημερώθηκε με επιτυχία");
      },
    },
  });
  const { mutate: deleteEquipmentType } = useDeleteEquipmentType({
    id: selectedEquipmentTypeId,
    config: {
      onError: (error) => {
        if (
          error.message?.includes(
            "Δεν μπορείτε να διαγράψετε αυτό το αντικείμενο!"
          )
        ) {
          showError(
            "Δεν μπορείτε να διαγράψετε αυτό τον τύπο εξοπλισμού, καθώς χρησιμοποιείται σε κάποια ομάδα εξοπλισμού",
            6000
          );
          return;
        }
        showError(error.message);
      },
      onSuccess: () => {
        setSelectedEquipmentTypeId(null);
        setIsEditing(false);
        showSuccess("Ο τύπος εξοπλισμού διαγράφηκε με επιτυχία");
      },
    },
  });

  const tableHeaders = useMemo(
    () => [
      {
        id: "name",
        label: "Όνομα",
        align: "left",
        minWidth: 200,
      },
      {
        id: "button",
        label: "",
        align: "right",
      },
    ],
    []
  );

  if (isLoading) {
    return <SpinningCircle />;
  }

  if (error) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          height: "100%",
        }}
      >
        <p>Error: {error.message}</p>
      </Box>
    );
  }

  const equipmentTypeList = equipmentTypes?.items?.map((item) => ({
    ...item,
    button: (
      <Box display="flex" justifyContent="flex-end">
        <Tooltip title="Επεξεργασία" placement="left">
          <span>
            <EditCircleButton
              onClick={(e) => {
                setIsEditing(true);
                setSelectedEquipmentTypeId(item.id);
                e.stopPropagation();
              }}
            />
          </span>
        </Tooltip>
      </Box>
    ),
  }));

  const count = equipmentTypes?.totalCount;

  const handleItemClick = (item) => {
    setIsEditing(false);
    if (item && item.id === selectedEquipmentTypeId) {
      setSelectedEquipmentTypeId(null);
      return;
    }
    setSelectedEquipmentTypeId(item.id);
  };

  const handleCreateClick = (data) => {
    const payload = {
      name: data.name,
      description: data.description,
      assetCategoryIds: data.assetCategoryIds?.map((item) => item.id),
      certificationTypeId: data.certificationTypeId?.id,
    };
    createEquipmentType(payload);
  };

  const handleUpdateClick = (data) => {
    const payload = {
      name: data.name,
      description: data.description,
      assetCategoryIds: data.assetCategoryIds?.map((item) => item.id),
      certificationTypeId: data.certificationTypeId?.id,
    };
    updateEquipmentType(payload);
  };

  const handleDeleteClick = () => {
    deleteEquipmentType();
  };

  return (
    <Box
      display="flex"
      gap={2}
      justifyContent="space-between"
      width={"100%"}
      flexDirection={{
        xs: "column",
        sm: "row",
      }}
    >
      <Box
        mt={1}
        display="flex"
        flexDirection="column"
        justifyContent="flex-start"
        width={{
          xs: "100%",
          sm: "60%",
        }}
      >
        <Typography variant="h3" color={colors.primary}>
          {count > 0
            ? `Τύποι Εξοπλισμού (${count})`
            : "Δεν υπάρχουν τύποι εξοπλισμού"}
        </Typography>

        {count > 0 && (
          <CustomTable
            columns={tableHeaders}
            data={equipmentTypeList}
            fadeEffect
            onItemClick={handleItemClick}
            page={page - 1}
            rowsPerPage={rowsPerPage}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            totalResults={count}
          />
        )}
      </Box>

      <Box
        borderLeft={{
          xs: "none",
          sm: `2px solid ${colors.lightGrey}`,
        }}
        borderTop={{
          xs: `2px solid ${colors.lightGrey}`,
          sm: "none",
        }}
        sx={{
          borderRadius: 6,
          opacity: "0.2",
        }}
      />

      <Box
        width={{
          xs: "100%",
          sm: "38%",
        }}
      >
        {selectedEquipmentTypeId === null ? (
          <EquipmentTypeForm
            onSave={handleCreateClick}
            onCancel={() => setSelectedEquipmentTypeId(null)}
            isCreateForm
          />
        ) : (
          <EquipmentTypeForm
            selectedEquipmentType={selectedEquipmentType}
            onSave={handleUpdateClick}
            onCancel={() => setSelectedEquipmentTypeId(null)}
            onDelete={handleDeleteClick}
            isEditing={isEditing}
          />
        )}
      </Box>
    </Box>
  );
};

export default EquipmentTypesOverview;
