import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import MaintenanceHistoryViewEditForm from "./MaintenanceHistoryViewEditForm";
import { useNavigate, useParams } from "react-router";
import { useState } from "react";
import SpinningCircle from "components/spinners/SpinningCircle";
import EditButton from "components/buttons/EditButton";
import {
  useDeleteMaintenanceHistory,
  useGetMaintenanceHistoryById,
  useUpdateMaintenanceHistory,
} from "hooks/queries/useMaintenancesHistory";
import { useAlert } from "hooks/useAlert";
import ReturnButton from "components/buttons/ReturnButton";
import colors from "config/theme/colors";

const MaintenanceHistoryViewEdit = () => {
  const { historyId, maintenanceId } = useParams();
  const navigate = useNavigate();
  const { showError, showSuccess } = useAlert();
  const [isDeleted, setIsDeleted] = useState(false);
  const {
    data: selectedMaintenanceHistory,
    isLoading: isMaintenanceHistoryLoading,
    isError: isErrorMaintenanceHistory,
    error: errorMaintenanceHistory,
  } = useGetMaintenanceHistoryById(historyId, {
    select: (data) => data.result,
    enabled: !!historyId && !isDeleted,
  });
  const { mutate: updateMaintenanceHistory } = useUpdateMaintenanceHistory({
    id: selectedMaintenanceHistory?.id,
    config: {
      onError: (error) => {
        showError(error.message);
      },
      onSuccess: () => {
        showSuccess("Το ιστορικό συντήρησης ενημερώθηκε με επιτυχία!");
        navigate(-1);
      },
    },
  });
  const { mutate: deleteMaintenaceHistory } = useDeleteMaintenanceHistory({
    id: selectedMaintenanceHistory?.id,
    config: {
      onError: (error) => {
        showError(error.message);
        setIsDeleted(false);
      },
      onSuccess: () => {
        showSuccess("Το ιστορικό συντήρησης διαγράφηκε με επιτυχία!");
        navigate(-1);
      },
    },
  });
  const [isEditing, setIsEditing] = useState(false);

  if (isMaintenanceHistoryLoading) {
    return <SpinningCircle />;
  }

  if (isErrorMaintenanceHistory) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          height: "100%",
        }}
      >
        <p>Error: {errorMaintenanceHistory?.message}</p>
      </Box>
    );
  }

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleCancelClick = () => {
    setIsEditing(false);
  };

  const handleSaveClick = (data) => {
    setIsEditing(false);
    const payload = {
      ...data,
      maintenanceId: maintenanceId || "",
      contractId: selectedMaintenanceHistory?.contract?.id,
    };
    updateMaintenanceHistory(payload);
  };

  const handleDeleteClick = () => {
    setIsEditing(false);
    setIsDeleted(true);
    deleteMaintenaceHistory();
  };

  return (
    selectedMaintenanceHistory && (
      <>
        <Box
          display="flex"
          alignItems={"center"}
          justifyContent="flex-start"
          gap={1}
        >
          <Typography color={colors.primary} fontWeight={700} fontSize={24}>
            Διαχείριση Ιστορικού Συντήρησης
          </Typography>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          gap={2}
          sx={{
            height: "100%",
          }}
          mt={4}
        >
          <Box
            mb={2}
            display="flex"
            gap={2}
            justifyContent="space-between"
            alignItems={{
              xs: "flex-start",
              sm: "center",
            }}
            flexWrap={"wrap"}
          >
            <Box
              display="flex"
              gap={2}
              alignItems="center"
              sx={{
                width: {
                  xs: "100%",
                  sm: "auto",
                },
              }}
            >
              <ReturnButton />
              <Typography fontSize={20} fontWeight={600} color={colors.primary}>
                {`${selectedMaintenanceHistory.name}`}
              </Typography>
            </Box>
            <Box
              display="flex"
              gap={2}
              sx={{
                width: {
                  xs: "100%",
                  sm: "auto",
                },
              }}
            >
              <EditButton onClick={handleEditClick} disabled={isEditing} />
            </Box>
          </Box>
          <Box mt={1} flexDirection="column" justifyContent="space-between">
            <MaintenanceHistoryViewEditForm
              selectedMaintenanceHistory={selectedMaintenanceHistory}
              isEditing={isEditing}
              onSave={handleSaveClick}
              onCancel={handleCancelClick}
              onDelete={handleDeleteClick}
            />
          </Box>
        </Box>
      </>
    )
  );
};

export default MaintenanceHistoryViewEdit;
