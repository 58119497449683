function transformDotFieldsToObject(data) {
  const newData = { ...data };
  let result = {};

  result = Object.keys(newData).reduce((acc, key) => {
    if (newData[key] !== null) {
      acc[key] = newData[key];
    }
    if (typeof newData[key] === "object") {
      const transformedData = transformDotFieldsToObject(newData[key]);
      if (Object.keys(transformedData).length > 0) {
        acc[key] = transformedData;
      } else {
        delete acc[key];
      }
    }
    return acc;
  }, {});

  Object.keys(result).forEach((key) => {
    const parts = key.split(".");
    let currentPart = result;

    parts.forEach((part, index) => {
      if (!currentPart[part]) {
        currentPart[part] = {};
      }

      if (index === parts.length - 1) {
        if (typeof result[key] === "object") {
          currentPart[part] = transformDotFieldsToObject(result[key]);
        }

        if (result[key] !== null && result[key] !== "") {
          currentPart[part] = result[key];
        } else {
          delete currentPart[part];
        }
      } else {
        currentPart = currentPart[part];
      }
    });
  });

  Object.keys(result).forEach((key) => {
    if (key.includes(".")) {
      delete result[key];
    }
  });

  Object.keys(result).forEach((key) => {
    if (typeof result[key] === "object") {
      if (Object.keys(result[key]).length === 0) {
        delete result[key];
      }
    }
  });

  return result;
}

export default transformDotFieldsToObject;
