import { useState } from "react";

const useSorting = (initialColumn = "", initialDirection = "asc") => {
  const [orderDirection, setOrderDirection] = useState(initialDirection);
  const [valueToOrderBy, setValueToOrderBy] = useState(initialColumn);

  const handleSortChange = (columnId) => {
    const isAscending = valueToOrderBy === columnId && orderDirection === "asc";
    setValueToOrderBy(columnId);
    setOrderDirection(isAscending ? "desc" : "asc");
  };

  return {
    orderDirection,
    valueToOrderBy,
    handleSortChange,
  };
};

export default useSorting;
