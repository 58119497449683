import { createTheme } from "@mui/material/styles";
import colors from "./colors";
import fonts from "./fonts";

const theme = createTheme({
  palette: {
    primary: { main: colors.primary },
    secondary: { main: colors.secondary },
    error: { main: colors.error },
    background: { default: colors.background },
    warning: { main: colors.warning },
    info: { main: colors.info },
    success: { main: colors.success },
  },
  typography: {
    fontFamily: fonts.join(","),
    h1: { fontWeight: 500, fontSize: 35, letterSpacing: "-0.24px" },
    h2: { fontWeight: 500, fontSize: 29, letterSpacing: "-0.24px" },
    h3: { fontWeight: 500, fontSize: 24, letterSpacing: "-0.06px" },
    h4: { fontWeight: 500, fontSize: 20, letterSpacing: "-0.06px" },
    h5: { fontWeight: 500, fontSize: 16, letterSpacing: "-0.05px" },
    h6: { fontWeight: 500, fontSize: 14, letterSpacing: "-0.05px" },
    subtitle1: { fontSize: 16 },
    subtitle2: { fontSize: 14 },
    body1: { fontSize: 16 },
    body2: { fontSize: 14 },
    button: { textTransform: "none" },
    caption: { fontSize: 12 },
    overline: { fontSize: 12 },
  },
  breakpoints: {
    values: { xs: 0, sm: 600, md: 960, lg: 1280, xl: 1920 },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          backgroundColor: colors.primary,
          color: colors.white,
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: colors.primary,
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: `
        ::-webkit-scrollbar {
          width: 6px;
          height: 6px;
        }
        ::-webkit-scrollbar-thumb {
          background-color: ${colors.primaryLight};
          border-radius: 8px;
        }
        ::-webkit-scrollbar-thumb:hover {
          background-color: ${colors.primary};
          cursor: pointer;
        }
        ::selection {
          background-color: ${colors.primary};
          color: ${colors.white};
        }
      `,
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: colors.primary,
          color: colors.white,
          fontSize: 12,
          borderRadius: 8,
          padding: "8px 12px",
        },
        arrow: {
          color: colors.primary,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-notchedOutline": {
            borderRadius: 8,
          },
          "&.Mui-readOnly": {
            color: colors.text,
            backgroundColor: colors.disabledInputBackground,
          },
        },
        multiline: {
          "&.Mui-readOnly": {
            backgroundColor: colors.disabledInputBackground,
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          "& .MuiAutocomplete-input": {
            fontSize: 14,
            fontWeight: 400,
            color: colors.text,
          },
          "& .MuiIconButton-root": {
            color: colors.text,
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          fontSize: 14,
        },
      },
    },
  },
});

export default theme;
