import { Box } from "@mui/material";
import AssetViewEditForm from "./AssetViewEditForm";
import { useNavigate, useOutletContext } from "react-router";
import { useState } from "react";
import SpinningCircle from "components/spinners/SpinningCircle";
import SubHeader from "components/headers/SubHeader";
import EditButton from "components/buttons/EditButton";
import { useDeleteAsset, useUpdateAsset } from "hooks/queries/useAssets";
import {
  useGetAllAssetCategories,
  useGetAssetControlsByCategoryId,
} from "hooks/queries/useAssetCategories";
import enums from "config/enums";
import { useAlert } from "hooks/useAlert";
import clearDotAnnotatedFields from "utils/clearDotAnnotatedFields";
import { useDeleteMultipleFiles, useUploadFiles } from "hooks/queries/useFile";

const ASSET_DETAILS_TABS = [
  {
    id: -1,
    description: "Γενικά",
  },
];

const AssetViewEdit = () => {
  const { selectedAsset, setIsDeleted } = useOutletContext();
  const { showError, showSuccess, showWarning } = useAlert();
  const [selectedTab, setSelectedTab] = useState(-1);
  const [isEditing, setIsEditing] = useState(false);
  const navigate = useNavigate();
  const {
    data: assetCategories,
    isLoading: isLoadingAssetCategories,
    isError: isErrorAssetCategories,
    error: errorAssetCategories,
  } = useGetAllAssetCategories();
  const { data: availableTabs } = useGetAssetControlsByCategoryId(
    selectedAsset?.assetCategory?.id
  );
  const { mutate: deleteAsset } = useDeleteAsset({
    id: selectedAsset?.id,
    config: {
      onError: (error) => {
        showError(error.message);
        setIsDeleted(false);
      },
      onSuccess: () => {
        setIsEditing(false);
        showSuccess("Το ακίνητο διαγράφηκε με επιτυχία");
        navigate("/assets");
      },
    },
  });
  const { mutate: updateAsset } = useUpdateAsset({
    id: selectedAsset?.id,
    config: {
      onError: (error) => {
        showError(error.message);
      },
      onSuccess: () => {
        showSuccess("Το ακίνητο ενημερώθηκε με επιτυχία");
      },
    },
  });
  const { mutate: uploadFiles } = useUploadFiles({
    fileableId: selectedAsset?.id,
    fileableType: "Asset",
  });
  const { mutate: deleteMultipleFiles } = useDeleteMultipleFiles();

  if (isLoadingAssetCategories) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          height: "100%",
        }}
      >
        <SpinningCircle />
      </Box>
    );
  }

  if (isErrorAssetCategories) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          height: "100%",
        }}
      >
        <p>Error: {errorAssetCategories.message}</p>
      </Box>
    );
  }

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleUpdateWhenDeleteOnly = (payload, deletedFiles) => {
    deleteMultipleFiles(
      deletedFiles.map((file) => file.id),
      {
        onSuccess: () => {
          updateAsset(payload);
        },
        onError: (error) => {
          showWarning(
            "Υπήρξε πρόβλημα με τη διαγραφή των αρχείων. Παρακαλώ δοκιμάστε ξανά την ενημέρωση των αρχείων"
          );
          showError(error.message);
        },
      }
    );
  };

  const handleUpdateWhenUploadOnly = (payload, newFiles) => {
    uploadFiles(
      { files: newFiles },
      {
        onSuccess: () => {
          updateAsset(payload);
        },
        onError: (error) => {
          showWarning(
            "Υπήρξε πρόβλημα με το ανέβασμα των αρχείων. Παρακαλώ δοκιμάστε ξανά την ενημέρωση των αρχείων"
          );
          showError(error.message);
        },
      }
    );
  };

  const handleUpdateWhenDeleteAndUpload = (payload, deletedFiles, newFiles) => {
    deleteMultipleFiles(
      deletedFiles.map((file) => file.id),
      {
        onSuccess: () => {
          uploadFiles(
            { files: newFiles },
            {
              onSuccess: () => {
                updateAsset(payload);
              },
              onError: (error) => {
                showWarning(
                  "Υπήρξε πρόβλημα με το ανέβασμα των αρχείων. Παρακαλώ δοκιμάστε ξανά την ενημέρωση των αρχείων"
                );
                showError(error.message);
              },
            }
          );
        },
        onError: (error) => {
          showWarning(
            "Υπήρξε πρόβλημα με τη διαγραφή των αρχείων. Παρακαλώ δοκιμάστε ξανά την ενημέρωση των αρχείων"
          );
          showError(error.message);
        },
      }
    );
  };

  const handleSaveClick = (data) => {
    setIsEditing(false);
    const formData = clearDotAnnotatedFields(data);
    const { geoLocation, ...restFormData } = formData;

    const updatedAsset = {
      ...restFormData,
      assetCategoryId: assetCategories.items.find(
        (category) =>
          category.description === formData?.assetCategory.description
      )?.id,
      assetUse: {
        use: enums.AssetUse.find((use) => use.label === formData?.assetUse?.use)
          ?.id,
        description: formData?.assetUse?.description,
      },
      complianceAudit: {
        ...formData?.complianceAudit,
        ownership:
          formData?.complianceAudit?.ownership?.id ||
          formData?.complianceAudit?.ownership,
        notes:
          formData?.complianceAudit?.notes?.id ||
          formData?.complianceAudit?.notes,
        ownershipType:
          formData?.complianceAudit?.ownershipType?.id ||
          formData?.complianceAudit?.ownershipType,
      },
      financialAudit: {
        ...formData?.financialAudit,
        economicalControlNotesOptions:
          formData?.financialAudit?.economicalControlNotesOptions?.id ||
          formData?.financialAudit?.economicalControlNotesOptions,
      },
      technicalControl: {
        ...formData?.technicalControl,
        cityPlanningCharacter:
          formData?.technicalControl?.cityPlanningCharacter?.id ||
          formData?.technicalControl?.cityPlanningCharacter,
      },
      energyControl: {
        ...formData?.energyControl,
        heatingSystemCombustibleOption:
          formData?.energyControl?.heatingSystemCombustibleOption?.id ||
          formData?.energyControl?.heatingSystemCombustibleOption,
        coolingSystemCombustibleOption:
          formData?.energyControl?.coolingSystemCombustibleOption?.id ||
          formData?.energyControl?.coolingSystemCombustibleOption,
      },
      lat: geoLocation?.lat,
      long: geoLocation?.lng,
      address: geoLocation?.formattedAddress,
      postalCode: geoLocation?.postalCode,
      city: geoLocation?.city,
    };

    Object.keys(updatedAsset).forEach((key) => {
      if (updatedAsset[key]) {
        Object.keys(updatedAsset[key]).forEach((k) => {
          if (
            updatedAsset[key][k] === null ||
            updatedAsset[key][k] === undefined ||
            updatedAsset[key][k] === ""
          ) {
            delete updatedAsset[key][k];
          }
        });
      }

      if (
        updatedAsset[key] === null ||
        updatedAsset[key] === undefined ||
        updatedAsset[key] === "" ||
        (typeof updatedAsset[key] === "object" &&
          (Object.keys(updatedAsset[key]).length === 0 ||
            Object.keys(updatedAsset[key]).every(
              (k) =>
                updatedAsset[key][k] === null ||
                updatedAsset[key][k] === undefined ||
                updatedAsset[key][k] === ""
            )))
      ) {
        delete updatedAsset[key];
      }
    });

    const originalFiles = [
      ...selectedAsset.generalFiles,
      ...selectedAsset.complianceFiles,
      ...selectedAsset.financialFiles,
    ];

    const updatedFiles = [
      ...(data.generalFiles || []),
      ...(data.complianceFiles || []),
      ...(data.financialFiles || []),
    ];

    const newFiles = updatedFiles?.filter((file) =>
      originalFiles.every((attachment) => attachment.id !== file.id)
    );

    const deletedFiles = originalFiles?.filter(
      (attachment) => !updatedFiles?.some((file) => file.id === attachment.id)
    );

    if (deletedFiles.length === 0 && newFiles.length === 0) {
      updateAsset(updatedAsset);
    } else if (deletedFiles.length > 0 && newFiles.length === 0) {
      handleUpdateWhenDeleteOnly(updatedAsset, deletedFiles);
    } else if (deletedFiles.length === 0 && newFiles.length > 0) {
      handleUpdateWhenUploadOnly(updatedAsset, newFiles);
    } else {
      handleUpdateWhenDeleteAndUpload(updatedAsset, deletedFiles, newFiles);
    }
  };

  const handleCancelClick = () => {
    setIsEditing(false);
  };

  const handleDeleteClick = () => {
    if (selectedAsset.files?.length === 0 || !selectedAsset.files) {
      setIsDeleted(true);
      deleteAsset();
    } else {
      deleteMultipleFiles(
        selectedAsset.files?.map((file) => file.id),
        {
          onSuccess: () => {
            deleteAsset();
          },
          onError: (error) => {
            showWarning("Υπήρξε πρόβλημα με τη διαγραφή των αρχείων");
            showError(error.message);
            setIsDeleted(false);
          },
        }
      );
    }
  };

  return (
    availableTabs && (
      <Box
        display="flex"
        flexDirection="column"
        gap={2}
        sx={{
          height: "100%",
        }}
      >
        <Box
          mb={2}
          display="flex"
          gap={2}
          justifyContent="space-between"
          alignItems={{
            xs: "flex-start",
            sm: "center",
          }}
          flexWrap={"wrap"}
        >
          <SubHeader
            tabs={[...ASSET_DETAILS_TABS, ...availableTabs]}
            setSelectedTab={setSelectedTab}
          />
          <Box
            display="flex"
            gap={2}
            sx={{
              width: {
                xs: "100%",
                sm: "auto",
              },
            }}
          >
            <EditButton onClick={handleEditClick} disabled={isEditing} />
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" gap={2}>
          <AssetViewEditForm
            selectedAsset={selectedAsset}
            selectedTab={selectedTab}
            isEditing={isEditing}
            onSave={handleSaveClick}
            onCancel={handleCancelClick}
            onDelete={handleDeleteClick}
          />
        </Box>
      </Box>
    )
  );
};

export default AssetViewEdit;
