import { Box, Typography } from "@mui/material";
import CustomButton from "components/buttons/CustomButton";
import CustomTable from "components/tables/CustomTable";
import { useNavigate } from "react-router";
import { useMemo } from "react";
import colors from "config/theme/colors";
import { ReactComponent as AddIcon } from "assets/icons/add.svg";
import SpinningCircle from "components/spinners/SpinningCircle";
import PropTypes from "prop-types";
import { useGetAllCertificationHistory } from "hooks/queries/useCertificationsHistory";
import { formatNumbersWithSeparator } from "utils/formatNumbersWithSeperator";
import usePagination from "hooks/usePagination";
import dayjs from "dayjs";

const CertificationHistoryOverview = ({ certificationId }) => {
  const { page, rowsPerPage, handleChangePage, handleChangeRowsPerPage } =
    usePagination();
  const navigate = useNavigate();

  const {
    data: certificationHistory,
    isLoading: isCertificationHistoryLoading,
    error: errorCertificationHistory,
  } = useGetAllCertificationHistory({
    pageNumber: page,
    pageSize: rowsPerPage,
    certificationId: certificationId,
  });

  const tableHeaders = useMemo(
    () => [
      {
        id: "id",
        label: "Κωδικός",
        isSortable: true,
      },
      {
        id: "name",
        label: "Όνομα",
        isSortable: true,
      },
      {
        id: "expireDateTime",
        label: "Ημερομηνία Λήξης",
        isSortable: true,
      },
      {
        id: "amount",
        label: "Ποσό (€)",
        isSortable: true,
      },
      {
        id: "notes",
        label: "Σημειώσεις",
        isSortable: true,
      },
    ],
    []
  );

  if (isCertificationHistoryLoading) {
    return <SpinningCircle />;
  }

  if (errorCertificationHistory) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          height: "100%",
        }}
      >
        <p>Error: {errorCertificationHistory?.message}</p>
      </Box>
    );
  }

  const certificationHistoryCount = certificationHistory?.totalCount;
  const certificationHistoryList = certificationHistory?.items?.map(
    (history) => ({
      ...history,
      id: history?.id || "-",
      name: history?.name || "-",
      expireDateTime:
        dayjs(history?.expireDateTime).format("DD/MM/YYYY") || "-",
      amount: formatNumbersWithSeparator(history?.amount) || "-",
      notes: history?.notes || "-",
    })
  );

  const handleItemClick = (item) => {
    navigate(`history/${item.id}`);
  };

  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginLeft={3}
        gap={13}
      >
        <Typography fontSize={20} fontWeight={600} color={colors.secondary}>
          Ιστορικό Πιστοποίησης
        </Typography>

        <CustomButton
          icon={<AddIcon fill={colors.primary} width={20} height={20} />}
          title="Προσθήκη"
          orientation="row"
          fontSize={13}
          sx={{
            minHeight: 30,
            maxHeight: 30,
            minWidth: 160,
            maxWidth: 160,
            borderRadius: 3,
            paddingY: 2.3,
          }}
          onClick={() => {
            navigate("history/new");
          }}
        />
      </Box>

      <Box marginLeft={3}>
        {certificationHistoryList?.length > 0 &&
        tableHeaders &&
        certificationHistoryCount > 0 ? (
          <CustomTable
            columns={tableHeaders}
            data={certificationHistoryList}
            totalResults={certificationHistoryCount}
            page={page - 1}
            rowsPerPage={rowsPerPage}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            onItemClick={handleItemClick}
            fadeEffect
          />
        ) : (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{
              height: "100%",
              marginTop: "18%",
            }}
          >
            <Typography variant="h4">Δεν υπάρχουν αποτελέσματα</Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

CertificationHistoryOverview.propTypes = {
  certificationId: PropTypes.number.isRequired,
};

export default CertificationHistoryOverview;
