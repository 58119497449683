import { Box, Typography } from "@mui/material";
import MainPageLayout from "components/main/MainPageLayout";
import TicketViewEditForm from "./TicketViewEditForm";
import { useParams } from "react-router";
import { useState } from "react";
import SpinningCircle from "components/spinners/SpinningCircle";
import EditButton from "components/buttons/EditButton";
import {
  useCompleteTicket,
  useGetTicketById,
  useUpdateTicket,
} from "hooks/queries/useTickets";
import { useAlert } from "hooks/useAlert";
import ReturnButton from "components/buttons/ReturnButton";
import colors from "config/theme/colors";
import { useDeleteMultipleFiles, useUploadFiles } from "hooks/queries/useFile";
import { useAuthStore } from "stores/AuthStore";

const TicketViewEdit = () => {
  const authStore = useAuthStore();
  const { id } = useParams();
  const { showError, showSuccess, showWarning } = useAlert();
  const [isEditing, setIsEditing] = useState(false);
  const { mutate: uploadFiles } = useUploadFiles({
    fileableId: id,
    fileableType: "Ticket",
  });
  const { mutate: deleteMultipleFiles } = useDeleteMultipleFiles();
  const {
    data: selectedTicket,
    isLoading,
    isError,
    error,
  } = useGetTicketById(id, {
    select: (data) => data.result,
    enabled: !!id,
  });
  const { mutate: updateTicket } = useUpdateTicket({
    id,
    config: {
      onError: (error) => {
        showError(error.message);
      },
      onSuccess: () => {
        showSuccess("Το αίτημα ενημερώθηκε με επιτυχία");
      },
    },
  });
  const { mutate: completeTicket } = useCompleteTicket({
    id,
    config: {
      onError: (error) => {
        showError(error.message);
      },
      onSuccess: (res) => {
        if (res?.result?.status === "Rejected") {
          showWarning("Το αίτημα απορρίφθηκε με επιτυχία");
        } else if (res?.result?.status === "Completed") {
          showSuccess("Το αίτημα ολοκληρώθηκε με επιτυχία");
        }
      },
    },
  });

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          height: "100%",
        }}
      >
        <SpinningCircle />
      </Box>
    );
  }

  if (isError) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          height: "100%",
        }}
      >
        <p>Error: {error.message}</p>
      </Box>
    );
  }

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleCancelClick = () => {
    setIsEditing(false);
  };

  const handleUpdateWhenDeleteOnly = (payload, deletedFiles) => {
    deleteMultipleFiles(
      deletedFiles.map((file) => file.id),
      {
        onSuccess: () => {
          updateTicket(payload);
        },
        onError: (error) => {
          showWarning(
            "Υπήρξε πρόβλημα με τη διαγραφή των αρχείων. Παρακαλώ δοκιμάστε ξανά την ενημέρωση των αρχείων"
          );
          showError(error.message);
        },
      }
    );
  };

  const handleUpdateWhenUploadOnly = (payload, newFiles) => {
    uploadFiles(
      { files: newFiles },
      {
        onSuccess: () => {
          updateTicket(payload);
        },
        onError: (error) => {
          showWarning(
            "Υπήρξε πρόβλημα με το ανέβασμα των αρχείων. Παρακαλώ δοκιμάστε ξανά την ενημέρωση των αρχείων"
          );
          showError(error.message);
        },
      }
    );
  };

  const handleUpdateWhenDeleteAndUpload = (payload, deletedFiles, newFiles) => {
    deleteMultipleFiles(
      deletedFiles.map((file) => file.id),
      {
        onSuccess: () => {
          uploadFiles(
            { files: newFiles },
            {
              onSuccess: () => {
                updateTicket(payload);
              },
              onError: (error) => {
                showWarning(
                  "Υπήρξε πρόβλημα με το ανέβασμα των αρχείων. Παρακαλώ δοκιμάστε ξανά την ενημέρωση των αρχείων"
                );
                showError(error.message);
              },
            }
          );
        },
        onError: (error) => {
          showWarning(
            "Υπήρξε πρόβλημα με τη διαγραφή των αρχείων. Παρακαλώ δοκιμάστε ξανά την ενημέρωση των αρχείων"
          );
          showError(error.message);
        },
      }
    );
  };

  const handleSaveClick = (data) => {
    setIsEditing(false);

    const payload = {
      name: data.name,
      description: data.description,
      assetId: selectedTicket.assetId,
      equipmentId: selectedTicket.equipmentId,
    };

    const newFiles = data.files?.filter((file) =>
      selectedTicket.files.every((attachment) => attachment.id !== file.id)
    );

    const deletedFiles = selectedTicket.files?.filter(
      (attachment) => !data.files?.some((file) => file.id === attachment.id)
    );

    if (deletedFiles.length === 0 && newFiles.length === 0) {
      updateTicket(payload);
    } else if (deletedFiles.length > 0 && newFiles.length === 0) {
      handleUpdateWhenDeleteOnly(payload, deletedFiles);
    } else if (deletedFiles.length === 0 && newFiles.length > 0) {
      handleUpdateWhenUploadOnly(payload, newFiles);
    } else {
      handleUpdateWhenDeleteAndUpload(payload, deletedFiles, newFiles);
    }
  };

  const handleRejectClick = () => {
    setIsEditing(false);

    completeTicket({
      status: "Rejected",
    });
  };

  const handleCompleteClick = () => {
    setIsEditing(false);

    completeTicket({
      status: "Completed",
    });
  };

  return (
    selectedTicket && (
      <MainPageLayout pageTitle={"Διαχείριση Αιτήματος"}>
        <Box
          display="flex"
          flexDirection="column"
          gap={2}
          sx={{
            height: "100%",
          }}
        >
          <Box
            mb={2}
            display="flex"
            gap={2}
            justifyContent="space-between"
            alignItems={{
              xs: "flex-start",
              sm: "center",
            }}
            flexWrap={"wrap"}
          >
            <Box
              display="flex"
              gap={2}
              alignItems="center"
              sx={{
                width: {
                  xs: "100%",
                  sm: "auto",
                },
              }}
            >
              <ReturnButton />
              <Typography fontSize={20} fontWeight={600} color={colors.primary}>
                {`${selectedTicket.name}`}
              </Typography>
            </Box>
            {selectedTicket?.status === "Pending" &&
              (authStore.isUser ||
                authStore.isAdmin ||
                authStore.isSuperAdmin) && (
                <Box
                  display="flex"
                  gap={2}
                  sx={{
                    width: {
                      xs: "100%",
                      sm: "auto",
                    },
                  }}
                >
                  <EditButton onClick={handleEditClick} disabled={isEditing} />
                </Box>
              )}
          </Box>
          <Box
            display="flex"
            gap={2}
            justifyContent="space-between"
            sx={{
              width: "100%",
            }}
          >
            <Box mt={1} flexDirection="column" justifyContent="space-between">
              <TicketViewEditForm
                selectedTicket={selectedTicket}
                isEditing={isEditing}
                onSave={handleSaveClick}
                onCancel={handleCancelClick}
                onReject={handleRejectClick}
                onComplete={handleCompleteClick}
              />
            </Box>
            <Box
              sx={{
                borderLeft: "2px solid",
                borderColor: colors.lightGrey,
                borderRadius: 6,
                opacity: "0.2",
                marginLeft: 2,
              }}
            />
          </Box>
        </Box>
      </MainPageLayout>
    )
  );
};

export default TicketViewEdit;
