import { Box, Typography } from "@mui/material";
import MainPageLayout from "components/main/MainPageLayout";
import ProfileViewEditForm from "./ProfileViewEditForm";
import { useState } from "react";
import SpinningCircle from "components/spinners/SpinningCircle";
import EditButton from "components/buttons/EditButton";
import { useUserProfile, useUpdateUserProfile } from "hooks/queries/useAuth";
import { useAlert } from "hooks/useAlert";
import colors from "config/theme/colors";
import ChangePasswordForm from "pages/auth/ChangePasswordForm";

const ProfileViewEdit = () => {
  const { showError, showSuccess } = useAlert();
  const { data: userProfile, isLoading, isError, error } = useUserProfile();
  const { mutate: updateUserProfile } = useUpdateUserProfile();
  const [isEditing, setIsEditing] = useState(false);
  const [isChangingPassword, setIsChangingPassword] = useState(false);

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          height: "100%",
        }}
      >
        <SpinningCircle />
      </Box>
    );
  }

  if (isError) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          height: "100%",
        }}
      >
        <p>Error: {error.message}</p>
      </Box>
    );
  }

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleChangePasswordEditClick = () => {
    setIsChangingPassword(true);
  };

  const handleCancelChangePasswordClick = () => {
    setIsChangingPassword(false);
  };

  const handleSaveClick = (data) => {
    updateUserProfile(data.phoneNumber, {
      onSuccess: () => {
        setIsEditing(false);
        showSuccess("Το προφίλ ενημερώθηκε με επιτυχία");
      },
      onError: (error) => {
        showError(error.message);
      },
    });
  };

  const handleCancelClick = () => {
    setIsEditing(false);
  };

  return (
    userProfile && (
      <MainPageLayout
        pageTitle={"Διαχείριση Προφίλ"}
        pageSubTitle={"Επεξεργασία Προφίλ"}
      >
        <Box
          display="flex"
          flexDirection="column"
          gap={2}
          sx={{
            height: "100%",
          }}
        >
          <Box
            mb={2}
            display="flex"
            gap={2}
            justifyContent="space-between"
            alignItems={{
              xs: "flex-start",
              sm: "center",
            }}
            flexWrap={"wrap"}
          >
            <Box
              display="flex"
              gap={2}
              alignItems="center"
              sx={{
                width: {
                  xs: "100%",
                  sm: "auto",
                },
              }}
            >
              <Typography fontSize={20} fontWeight={600} color={colors.primary}>
                {`${userProfile.userDto.name} ${userProfile.userDto.surname}`}
              </Typography>
            </Box>
            <Box
              display="flex"
              gap={2}
              sx={{
                width: {
                  xs: "100%",
                  sm: "auto",
                },
              }}
            >
              <EditButton onClick={handleEditClick} disabled={isEditing} />
            </Box>
          </Box>

          <Box display="flex" flexDirection="column" gap={2}>
            <ProfileViewEditForm
              userProfile={userProfile.userDto}
              isEditing={isEditing}
              onSave={handleSaveClick}
              onCancel={handleCancelClick}
            />

            <Box
              mb={2}
              display="flex"
              gap={2}
              mt={6}
              justifyContent="space-between"
              alignItems={{
                xs: "flex-start",
                sm: "center",
              }}
              flexWrap={"wrap"}
            >
              <Box
                display="flex"
                gap={2}
                alignItems="center"
                sx={{
                  width: {
                    xs: "100%",
                    sm: "auto",
                  },
                }}
              >
                <Typography
                  fontSize={20}
                  fontWeight={600}
                  color={colors.primary}
                >
                  Αλλαγή Κωδικού Πρόσβασης
                </Typography>
              </Box>
              <Box
                display="flex"
                gap={2}
                sx={{
                  width: {
                    xs: "100%",
                    sm: "auto",
                  },
                }}
              >
                <EditButton
                  onClick={handleChangePasswordEditClick}
                  disabled={isChangingPassword}
                />
              </Box>
            </Box>

            <Box>
              <ChangePasswordForm
                isChangingPassword={isChangingPassword}
                setIsChangingPassword={setIsChangingPassword}
                onCancel={handleCancelChangePasswordClick}
              />
            </Box>
          </Box>
        </Box>
      </MainPageLayout>
    )
  );
};

export default ProfileViewEdit;
