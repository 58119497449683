import { Box, Typography } from "@mui/material";
import colors from "config/theme/colors";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";

const MainHeader = ({ tabs = [] }) => {
  const navigate = useNavigate();
  const [selected, setSelected] = useState("");
  const location = useLocation();

  useEffect(() => {
    const [defaultTab, ...remainingTabs] = tabs;
    const currentTab = remainingTabs.find((tab) =>
      location.pathname.includes(tab.to)
    );
    if (currentTab) setSelected(currentTab.id);
    else setSelected(defaultTab.id);
  }, [location, tabs]);

  const handleTabClick = (tab) => {
    setSelected(tab.id);
    navigate(tab.to);
  };

  return (
    <Box
      component="header"
      sx={{
        width: "100%",
        height: 45,
        display: "flex",
        justifyContent: "start",
        alignItems: "center",
        gap: 2,
        paddingY: 2,
        backgroundColor: colors.primary,
        paddingLeft: "42px",
        paddingRight: "42px",
        overflowX: "auto",
        overflowY: "hidden",
        "&::-webkit-scrollbar": {
          height: 4,
        },
        "&::-webkit-scrollbar-track": {
          backgroundColor: colors.grey,
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: colors.grey,
        },
      }}
    >
      {tabs.map((tab) => {
        return (
          <Typography
            key={tab.label}
            fontSize={14}
            fontWeight={600}
            color={colors.white}
            backgroundColor={
              selected === tab.id ? colors.greyWithOpacity : "transparent"
            }
            sx={{
              textDecoration: "none",
              cursor: "pointer",
              padding: "5px 10px",
              "&:hover": {
                backgroundColor: colors.greyWithOpacity,
              },
            }}
            onClick={() => handleTabClick(tab)}
          >
            {tab.label}
          </Typography>
        );
      })}
    </Box>
  );
};

MainHeader.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
      to: PropTypes.string.isRequired,
    })
  ),
};

export default MainHeader;
