import { TableCell, TableRow, Typography } from "@mui/material";
import colors from "config/theme/colors";
import PropTypes from "prop-types";
import React from "react";

const CustomTableItem = ({
  item,
  onClick,
  headers,
  isSelected = false,
  disableClick = false,
  textWeight,
}) => {
  return (
    <TableRow
      className={`${onClick && !disableClick ? "cursor-pointer" : ""}`}
      onClick={onClick && !disableClick ? () => onClick(item) : null}
      sx={{
        cursor: onClick && !disableClick ? "pointer" : "default",
        "&:hover": {
          boxShadow: !disableClick ? "0px 2px 8px rgba(0, 0, 0, 0.1)" : "none",
          border: "none",
          borderRadius: "10px",
        },
        "& > *": {
          borderBottom: "1px solid",
          borderTop: "1px solid",
          borderColor: colors.greyBackground,
        },
        "& > :first-of-type": {
          borderLeft: "1px solid",
          borderTopLeftRadius: "10px",
          borderBottomLeftRadius: "10px",
          borderColor: colors.greyBackground,
        },
        "& > :last-child": {
          borderRight: "1px solid",
          borderTopRightRadius: "10px",
          borderBottomRightRadius: "10px",
          borderColor: colors.greyBackground,
        },
        "&:hover > *": {
          borderColor: !disableClick
            ? item?.colorCode || colors.primary
            : colors.greyBackground,
        },
        ...(isSelected && {
          "& > *": {
            borderBottom: "1px solid",
            borderTop: "1px solid",
            borderColor: item?.colorCode || colors.primary,
          },
          "& > :first-of-type": {
            borderLeft: "1px solid",
            borderTopLeftRadius: "10px",
            borderBottomLeftRadius: "10px",
            borderColor: item?.colorCode || colors.primary,
          },
          "& > :last-child": {
            borderRight: "1px solid",
            borderTopRightRadius: "10px",
            borderBottomRightRadius: "10px",
            borderColor: item?.colorCode || colors.primary,
          },
        }),
      }}
    >
      {headers.map((header) => {
        const value = item[header.id];

        if (React.isValidElement(value)) {
          return <TableCell key={header.id}>{value}</TableCell>;
        } else if (typeof value === "string" || typeof value === "number") {
          return (
            <TableCell key={header.id}>
              <Typography fontSize={14} fontWeight={textWeight || 700}>
                {value}
              </Typography>
            </TableCell>
          );
        }

        return null;
      })}
    </TableRow>
  );
};

CustomTableItem.propTypes = {
  item: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  headers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      isSortable: PropTypes.bool,
    }).isRequired
  ).isRequired,
  isSelected: PropTypes.bool,
  disableClick: PropTypes.bool,
  textWeight: PropTypes.number,
};

export default CustomTableItem;
