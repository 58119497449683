import { Box, Typography } from "@mui/material";
import MainPageLayout from "components/main/MainPageLayout";
import CountCard from "components/cards/CountCard";
import { ReactComponent as AddIcon } from "assets/icons/add.svg";
import colors from "config/theme/colors";
import CustomButton from "components/buttons/CustomButton";
import SearchBar from "components/inputs/SearchBar";
import CustomTable from "components/tables/CustomTable";
import { useNavigate } from "react-router";
import {
  useGetAllAssets,
  useGetAssetCategoryCount,
} from "hooks/queries/useAssets";
import SpinningCircle from "components/spinners/SpinningCircle";
import { useGetAllAssetCategories } from "hooks/queries/useAssetCategories";
import { useCallback, useMemo, useState } from "react";
import CopyButton from "components/buttons/CopyButton";
import TagLabel from "components/labels/TagLabel";
import LayoutSwitcher from "pages/assets/Overview/LayoutSwitcher";
import AssetsMap from "./Map/AssetsMap";
import AssetsMapCard from "./Map/AssetsMapCard";
import { COORDINATES_ATHENS_CENTER } from "config/constants";
import { getAssetsMarkerIcon } from "utils/getAssetsMarkerIcon";
import { formatNumbersWithSeparator } from "utils/formatNumbersWithSeperator";
import useSearchInput from "hooks/useSearchInput";
import { getAssetsIcon } from "utils/getAssetsIcon";
import FilterButton from "components/buttons/FilterButton";
import enums from "config/enums";
import useSorting from "hooks/useSorting";
import usePagination from "hooks/usePagination";
import { useAuthStore } from "stores/AuthStore";

const colorPalette = [
  "#FF7A00", // Vibrant Orange
  "#313131", // Dark Gray
  "#6A41AD", // Deep Purple
  "#279500", // Rich Green
  "#0077CC", // Bright Blue
  "#FFC400", // Bright Yellow
];

const AssetsOverview = () => {
  const authStore = useAuthStore();
  const navigate = useNavigate();
  const [selectedMarker, setSelectedMarker] = useState();
  const [selectedFilters, setSelectedFilters] = useState({});
  const [selectedAssetUses, setSelectedAssetUses] = useState([]);
  const [selectedAssetCategories, setSelectedAssetCategories] = useState([]);
  const [layout, setLayout] = useState("listView");
  const {
    page,
    setPage,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
  } = usePagination();
  const { orderDirection, valueToOrderBy, handleSortChange } = useSorting();
  const { searchInput, handleSearch } = useSearchInput();
  const {
    data: assetCategories,
    isLoading: isLoadingAssetCategories,
    error: errorAssetCategories,
  } = useGetAllAssetCategories();
  const {
    data: assetCategoriesCount,
    isLoading: isLoadingAssetCategoriesCount,
    isError: isErrorAssetCategoriesCount,
    error: errorAssetCategoriesCount,
  } = useGetAssetCategoryCount();
  const {
    data: paginatedAssets,
    isLoading: paginatedAssetsLoading,
    error: paginatedAssetsError,
    refetch: refetchPaginatedAssets,
  } = useGetAllAssets(
    {
      pageNumber: page,
      pageSize: rowsPerPage,
      searchParameter: searchInput,
      assetUse: selectedAssetUses,
      assetCategoryIds: selectedAssetCategories,
      orderBy: valueToOrderBy,
      sortingOrder: orderDirection === "asc" ? "Ascending" : "Descending",
    },
    { enabled: layout === "listView" }
  );
  const {
    data: allAssets,
    isLoading: assetsLoading,
    error: assetsError,
    refetch: refetchAllAssets,
  } = useGetAllAssets(
    {
      searchParameter: searchInput,
      assetUse: selectedAssetUses,
      assetCategoryIds: selectedAssetCategories,
    },
    {
      enabled: layout === "mapView",
    }
  );

  const filterGroups = [
    {
      title: "Χρήση Ακινήτου",
      filters: enums.AssetUse?.map((assetUse) => ({
        id: assetUse?.id,
        label: assetUse?.label,
        type: "checkbox",
      })),
    },
    {
      title: "Κατηγορία Ακινήτου",
      filters: assetCategories?.items?.map((assetCategory) => ({
        id: assetCategory?.id,
        label: assetCategory?.description,
        type: "checkbox",
      })),
    },
  ];

  const handleSubmitFilters = useCallback(
    (filters) => {
      const assetUses = filters["Χρήση Ακινήτου"] || [];
      const assetCategories = filters["Κατηγορία Ακινήτου"] || [];
      setSelectedFilters(filters);
      setSelectedAssetUses(assetUses);
      setSelectedAssetCategories(assetCategories);
      if (filters) {
        setPage(1);
      }

      if (assetUses.length === 0 && assetCategories.length === 0) {
        if (layout === "listView") {
          refetchPaginatedAssets();
        } else {
          refetchAllAssets();
        }
      }
    },
    [
      setSelectedAssetUses,
      setSelectedAssetCategories,
      setSelectedFilters,
      layout,
      refetchPaginatedAssets,
      refetchAllAssets,
      setPage,
    ]
  );

  const tableHeaders = useMemo(
    () => [
      {
        id: "color",
        label: "",
        isSortable: false,
      },
      {
        id: "name",
        label: "Όνομα",
        isSortable: true,
      },
      {
        id: "assetCategory",
        label: "Κατηγορία",
        isSortable: true,
      },
      {
        id: "use",
        label: "Χρήση",
        isSortable: true,
      },
      {
        id: "address",
        label: "Διεύθυνση",
        isSortable: false,
      },
      {
        id: "surface",
        label: "Επιφάνεια (m²)",
        isSortable: false,
      },
      {
        id: "floor",
        label: "Όροφοι",
        isSortable: true,
      },
      {
        id: "kaek",
        label: "ΚΑΕΚ",
        isSortable: false,
      },
    ],
    []
  );

  const handleLayoutChange = (newLayout) => {
    setLayout(newLayout);
  };

  if (
    paginatedAssetsLoading ||
    isLoadingAssetCategories ||
    assetsLoading ||
    isLoadingAssetCategoriesCount
  ) {
    return <SpinningCircle />;
  }

  if (
    paginatedAssetsError ||
    errorAssetCategories ||
    assetsError ||
    isErrorAssetCategoriesCount
  ) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          height: "100%",
        }}
      >
        <p>
          Error:{" "}
          {paginatedAssetsError.message ||
            errorAssetCategories.message ||
            assetsError.message ||
            errorAssetCategoriesCount.message}
        </p>
      </Box>
    );
  }

  const getAssets = (assets) => {
    return assets?.map((asset) => {
      const categoryIndex = assetCategories.items.findIndex(
        (item) => item.id === asset.assetCategory.id
      );
      const categoryColor = colorPalette[categoryIndex];
      const categoryIcon = getAssetsMarkerIcon(asset.assetCategory.id);
      const onMarkerClick = () => setSelectedMarker(asset);
      const color = () => (
        <Box
          display="flex"
          justifyContent="start"
          alignItems="start"
          height={"100%"}
        >
          <Box
            width={8}
            height={8}
            borderRadius="50%"
            bgcolor={categoryColor}
          />
        </Box>
      );
      const kaek = () => (
        <Box
          px={2}
          borderRadius={2}
          bgcolor={colors.greyBackground}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography fontSize={12} fontWeight={700} color={colors.grey}>
            {asset.kaek}
          </Typography>
          <CopyButton textToCopy={asset.kaek} />
        </Box>
      );

      return {
        id: asset.id,
        colorCode: categoryColor,
        lat: asset.lat,
        lng: asset.long,
        color: color(),
        name: asset.name,
        assetCategory: asset.assetCategory.description,
        categoryId: asset.assetCategory.id,
        use: asset.assetUse.description,
        address: `${asset.city}, ${asset.address} ${asset.postalCode}`,
        surface: formatNumbersWithSeparator(asset.areaSize || "-"),
        floor: formatNumbersWithSeparator(asset.floorCount || "-"),
        kaek: kaek(),
        onMarkerClick,
        icon: categoryIcon,
        kaekNotModified: asset.kaek,
      };
    });
  };

  const assetsCount =
    layout === "listView" ? paginatedAssets?.totalCount : allAssets?.totalCount;

  const assetList = getAssets(
    layout === "listView" ? paginatedAssets?.items : allAssets?.items
  );

  const handleItemClick = (item) => {
    if (authStore.isUser) {
      navigate(`/assets/${item.id}`);
    } else {
      navigate(`${item.id}`);
    }
  };

  const navigateToCreateBasedOnRole = () => {
    if (authStore.isUser) {
      navigate("/assets/new");
    } else {
      navigate("new");
    }
  };

  const infoWindowRenderer = (item) => (
    <AssetsMapCard onClick={() => handleItemClick(item)} item={item} />
  );

  const renderLayoutSwitcher = () => {
    if (assetsCount === 0) {
      return (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{
            height: "100%",
          }}
        >
          <Typography variant="h4">Δεν υπάρχουν αποτελέσματα</Typography>
        </Box>
      );
    } else if (layout === "listView") {
      return (
        <CustomTable
          columns={tableHeaders}
          data={assetList}
          fadeEffect
          onItemClick={handleItemClick}
          page={page - 1}
          rowsPerPage={rowsPerPage}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          totalResults={assetsCount}
          orderDirection={orderDirection}
          valueToOrderBy={valueToOrderBy}
          onSortChange={handleSortChange}
        />
      );
    } else {
      return (
        <AssetsMap
          className="w-full h-[60vh] mt-5"
          center={COORDINATES_ATHENS_CENTER}
          mapMarkers={assetList}
          selectedMarker={selectedMarker}
          initialZoom={15}
          setSelectedMarker={setSelectedMarker}
          infoWindowRenderer={infoWindowRenderer}
          enableClustering
        />
      );
    }
  };

  return (
    <MainPageLayout pageTitle="Ακίνητα">
      <Box
        display="flex"
        flexDirection={{
          xs: "column",
          sm: "row",
        }}
        justifyContent={{
          xs: "space-between",
        }}
        flexGrow={1}
        flexWrap={{
          xs: "wrap",
          md: "nowrap",
        }}
        width={"100%"}
        gap={2}
      >
        <Box
          display="flex"
          gap={2}
          flexWrap={{
            xs: "wrap",
            sm: "nowrap",
          }}
          sx={{
            overflowX: "auto",
          }}
        >
          {assetCategories?.items?.map((assetCategory) => {
            const categoryData = assetCategoriesCount?.find(
              (item) => item.name === assetCategory.description
            );
            const count = categoryData?.count || 0;
            return (
              <CountCard
                key={assetCategory.id}
                title={assetCategory.description}
                backgroundColor={
                  colorPalette[assetCategories.items.indexOf(assetCategory)]
                }
                count={count}
                icon={getAssetsIcon(assetCategory.id, true)}
                boxSx={{
                  minWidth: { xs: "100%", sm: "250px" },
                }}
              />
            );
          })}
        </Box>
        <CustomButton
          icon={<AddIcon fill={colors.primary} width={30} height={30} />}
          title="Προσθήκη"
          sx={{
            minWidth: 120,
          }}
          onClick={navigateToCreateBasedOnRole}
        />
      </Box>
      <Box
        mt={4}
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent={{
          xs: "space-between",
          lg: "flex-start",
        }}
        flexGrow={1}
        gap={2}
        height={40}
      >
        <SearchBar
          placeholder="Αναζήτηση σε Όνομα, Κατηγορία, Χρήση, Διεύθυνση..."
          handleSearch={handleSearch}
          searchKey={searchInput}
        />
      </Box>
      <Box
        mt={4}
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        flexGrow={1}
        width={"100%"}
      >
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="start"
          alignItems="center"
          gap={4}
          flexWrap="wrap"
        >
          <Typography variant="h3" color="primary">
            Ακίνητα ({assetsCount})
          </Typography>
          <Box
            display="flex"
            flexDirection="row"
            gap={1}
            flexWrap="wrap"
            flexGrow={1}
          >
            {assetCategories?.items.map((assetCategory) => {
              const count = assetList?.filter(
                (assetItem) => assetItem.categoryId === assetCategory.id
              ).length;
              return count > 0 ? (
                <TagLabel
                  key={assetCategory.id}
                  text={`${count} ${assetCategory.description}`}
                />
              ) : null;
            })}
          </Box>
          <Box display="flex" flexGrow={1} justifyContent="flex-end">
            <FilterButton
              selectedFilters={selectedFilters}
              filterGroups={filterGroups}
              onApplyFilters={handleSubmitFilters}
            />
            <LayoutSwitcher
              activeLayout={layout}
              onLayoutChange={handleLayoutChange}
            />
          </Box>
        </Box>

        {renderLayoutSwitcher()}
      </Box>
    </MainPageLayout>
  );
};

export default AssetsOverview;
