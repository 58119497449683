import BuildingIcon from "../assets/icons/building.svg";
import LandIcon from "../assets/icons/land.svg";
import { ReactComponent as BuildingIconWhiteSvg } from "../assets/icons/buildindIconWhite.svg";
import { ReactComponent as LandIconWhiteSvg } from "../assets/icons/landIconWhite.svg";

export function getAssetsIcon(categoryId, displayAsSvg = false) {
  if (displayAsSvg) {
    switch (categoryId) {
      case 1:
        return <BuildingIconWhiteSvg width={35} height={35} />;
      case 2:
        return <LandIconWhiteSvg width={35} height={35} />;
      case 3:
        return <LandIconWhiteSvg width={35} height={35} />;
      case 4:
        return <BuildingIconWhiteSvg width={35} height={35} />;
      case 5:
        return <LandIconWhiteSvg width={35} height={35} />;
      case 6:
        return <LandIconWhiteSvg width={35} height={35} />;
      default:
        return <BuildingIconWhiteSvg width={35} height={35} />;
    }
  } else {
    switch (categoryId) {
      case 1:
        return BuildingIcon;
      case 2:
        return LandIcon;
      case 3:
        return LandIcon;
      case 4:
        return BuildingIcon;
      case 5:
        return LandIcon;
      case 6:
        return LandIcon;
      default:
        return BuildingIcon;
    }
  }
}
