import colors from "config/theme/colors";
import PropTypes from "prop-types";
import MainHeader from "components/headers/MainHeader";
import PowerSettingsNewOutlinedIcon from "@mui/icons-material/PowerSettingsNewOutlined";
import { ReactComponent as BellIcon } from "assets/icons/bell.svg";
import {
  Box,
  IconButton,
  Typography,
  Tooltip,
  Badge,
  keyframes,
} from "@mui/material";
import { observer } from "mobx-react-lite";
import { useAuthStore } from "stores/AuthStore";
import useNotifications from "hooks/useNotifications";
import { useState } from "react";
import NotificationsPopover from "components/modals/NotificationsPopover";

const ring = keyframes`
  0% { transform: rotate(0deg); }
  25% { transform: rotate(15deg); }
  50% { transform: rotate(-10deg); }
  75% { transform: rotate(5deg); }
  100% { transform: rotate(0deg); }
`;

const Topbar = ({ title = "", subTitle = "", headerTabs = [] }) => {
  const authStore = useAuthStore();
  const {
    counts: { newTasks: newTasksCount, newTickets: newTicketsCount },
  } = useNotifications();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleBellClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const isPopoverOpen = Boolean(anchorEl);

  const notificationsCount =
    authStore.isSuperAdmin || authStore.isAdmin
      ? newTasksCount + newTicketsCount
      : authStore.isUser
        ? newTasksCount
        : newTicketsCount;

  const validNotificationsCount = isNaN(notificationsCount)
    ? 0
    : notificationsCount;

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <Box
        display="flex"
        flexDirection={{
          xs: "column",
          md: "row",
        }}
        justifyContent="space-between"
        alignItems={{
          xs: "flex-start",
          md: "center",
        }}
        width="100%"
        paddingBottom={1}
        paddingX="42px"
        paddingTop="22px"
        height={{
          xs: "auto",
          md: 66,
        }}
        gap={2}
        borderBottom={1}
        borderColor={colors.greyWithOpacity}
      >
        <Box
          display="flex"
          alignItems={{
            xs: "flex-start",
            sm: "center",
          }}
          justifyContent="flex-start"
          flexDirection={{
            xs: "column",
            sm: "row",
          }}
          gap={1}
        >
          <Typography color={colors.primary} fontWeight={700} fontSize={24}>
            {title}
          </Typography>
          <Typography color={colors.text} fontWeight={500} fontSize={18}>
            {subTitle}
          </Typography>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent={"space-between"}
          gap={1}
          bgcolor={colors.white}
          borderRadius={4}
          flexWrap={{
            xs: "wrap",
            sm: "nowrap",
          }}
        >
          {(authStore.isTechnicalIssues ||
            authStore.isSuperAdmin ||
            authStore.isUser ||
            authStore.isAdmin) && (
            <IconButton
              sx={{
                height: 38,
                border: 1,
                borderRadius: 2,
                borderColor: colors.lightGrey,
                "&:hover svg": {
                  animation: `${ring} 0.5s`,
                },
              }}
            >
              <Tooltip title="Ειδοποιήσεις" placement="bottom">
                <Badge
                  badgeContent={validNotificationsCount}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  sx={{
                    "& .MuiBadge-badge": {
                      backgroundColor: colors.primary,
                      color: colors.white,
                      top: -8,
                      left: -5,
                    },
                  }}
                >
                  <BellIcon
                    onClick={handleBellClick}
                    width={20}
                    height={20}
                    fill={colors.grey}
                  />
                </Badge>
              </Tooltip>
            </IconButton>
          )}

          <IconButton
            sx={{
              height: 38,
              border: 1,
              borderRadius: 2,
              borderColor: colors.lightGrey,
            }}
          >
            <Tooltip title="Αποσύνδεση" placement="bottom">
              <PowerSettingsNewOutlinedIcon
                onClick={() => authStore.logout()}
                width={20}
                height={20}
                sx={{ fill: colors.grey }}
              />
            </Tooltip>
          </IconButton>
        </Box>
      </Box>
      {headerTabs.length > 0 && <MainHeader tabs={headerTabs} />}
      <NotificationsPopover
        anchorEl={anchorEl}
        open={isPopoverOpen}
        onClose={handlePopoverClose}
      />
    </Box>
  );
};

Topbar.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  headerTabs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
      to: PropTypes.string.isRequired,
    })
  ),
};

export default observer(Topbar);
