import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Agent } from "api/agent";

export const useGetAllEquipmentClasses = (
  { pageNumber = -1, pageSize = -1, equipmentGroupId = null } = {
    pageNumber: -1,
    pageSize: -1,
    equipmentGroupId: null,
  },
  options = {}
) => {
  return useQuery({
    queryKey: [
      "getAllEquipmentClasses",
      pageNumber,
      pageSize,
      equipmentGroupId,
    ],
    queryFn: () =>
      Agent.equipmentClass.getAll({
        pageNumber: pageNumber,
        pageSize: pageSize,
        equipmentGroupId: equipmentGroupId,
      }),
    staleTime: 1000 * 60 * 60,
    ...options,
  });
};

export const useGetEquipmentClassById = (id, options = {}) => {
  return useQuery({
    queryKey: ["getEquipmentClassById", +id],
    queryFn: () => Agent.equipmentClass.getById(id),
    staleTime: 1000 * 60 * 10,
    ...options,
  });
};

export const useCreateEquipmentClass = ({ config = {} }) => {
  const queryClient = useQueryClient();
  const { onSuccess, ...restConfig } = config || {};

  return useMutation({
    mutationKey: ["createEquipmentClass"],
    mutationFn: (body) => Agent.equipmentClass.create(body),
    onSuccess: (...args) => {
      queryClient.invalidateQueries({ queryKey: ["getAllEquipmentClasses"] });
      onSuccess?.(...args);
    },
    ...restConfig,
  });
};

export const useUpdateEquipmentClass = ({ id, config = {} }) => {
  const queryClient = useQueryClient();
  const { onSuccess, ...restConfig } = config || {};

  return useMutation({
    mutationKey: ["updateEquipmentClass"],
    mutationFn: (body) => Agent.equipmentClass.update(id, body),
    onSuccess: (...args) => {
      queryClient.invalidateQueries({ queryKey: ["getAllEquipmentClasses"] });
      queryClient.invalidateQueries({
        queryKey: ["getEquipmentClassById", +id],
      });
      onSuccess?.(...args);
    },
    ...restConfig,
  });
};

export const useDeleteEquipmentClass = ({ id, config = {} }) => {
  const queryClient = useQueryClient();
  const { onSuccess, ...restConfig } = config || {};

  return useMutation({
    mutationKey: ["deleteEquipmentClass"],
    mutationFn: () => Agent.equipmentClass.delete(id),
    onSuccess: (...args) => {
      queryClient.invalidateQueries({ queryKey: ["getAllEquipmentClasses"] });
      onSuccess?.(...args);
    },
    ...restConfig,
  });
};
