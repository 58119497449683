import React, { useState } from "react";
import {
  Typography,
  IconButton,
  Box,
  Dialog,
  DialogContent,
} from "@mui/material";
import { Bar } from "react-chartjs-2";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { chartsPalette } from "config/theme/chartsColorScheme";
import colors from "config/theme/colors";

const customLegendPaddingPlugin = {
  id: "increase-legend-spacing",
  beforeInit(chart) {
    const originalFit = chart.legend.fit;
    chart.legend.fit = function fit() {
      originalFit.bind(chart.legend)();
      this.height += 20;
    };
  },
};

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels,
  customLegendPaddingPlugin
);

const CustomBarChart = ({
  title,
  data,
  stacked = false,
  yAxisTitle,
  tooltipIdentifier = "",
  extraLabelText = "",
  showDataLabels = false,
}) => {
  const [isFullscreen, setIsFullscreen] = useState(false);

  const toggleFullscreen = () => {
    setIsFullscreen(!isFullscreen);
  };

  const updatedData = {
    ...data,
    datasets: data.datasets.map((dataset, index) => {
      const color = chartsPalette[index % chartsPalette.length];
      return {
        ...dataset,
        backgroundColor: color,
      };
    }),
  };

  return (
    <Box
      sx={{
        position: "relative",
        margin: "0 auto",
        boxShadow: "0 12.01px 32.77px rgba(0, 0, 0, 0.04)",
        padding: 2,
        backgroundColor: colors.white,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          variant="h5"
          fontSize={14}
          fontWeight={600}
          sx={{ marginBottom: "20px" }}
        >
          {title}
        </Typography>
        <IconButton onClick={toggleFullscreen}>
          <FullscreenIcon />
        </IconButton>
      </Box>
      <Bar
        data={updatedData}
        options={{
          responsive: true,
          maintainAspectRatio: true,
          scales: {
            x: {
              stacked: stacked,
            },
            y: {
              stacked: stacked,
              title: {
                display: !!yAxisTitle,
                text: yAxisTitle,
              },
            },
          },
          plugins: {
            tooltip: {
              callbacks: {
                label: function (context) {
                  const value = context.parsed.y;
                  return (
                    context.dataset.label + ": " + value + tooltipIdentifier
                  );
                },
              },
            },
            legend: {
              display: true,
              position: "top",
              align: "start",
              labels: {
                usePointStyle: true,
                pointStyle: "rectRounded",
              },
            },
            datalabels: {
              display: showDataLabels,
              color: colors.white,
              align: "end",
              anchor: "start",
              offset: -2,
              clip: true,
              clamp: true,
              font: {
                weight: "700",
                size: 12,
              },
              formatter: function (value) {
                return extraLabelText ? value + " " + extraLabelText : value;
              },
            },
          },
        }}
      />
      <Dialog
        open={isFullscreen}
        onClose={toggleFullscreen}
        maxWidth="xl"
        fullWidth
      >
        <DialogContent
          sx={{
            padding: 0,
            height: "80vh",
            maxHeight: "80vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              padding: 2,
            }}
          >
            <Typography variant="h5">{title}</Typography>
            <IconButton onClick={toggleFullscreen}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box
            sx={{
              width: "100%",
              height: "100%",
              padding: 2,
              boxSizing: "border-box",
            }}
          >
            <Bar
              data={updatedData}
              options={{
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                  x: {
                    stacked: stacked,
                  },
                  y: {
                    stacked: stacked,
                    title: {
                      display: !!yAxisTitle,
                      text: yAxisTitle,
                    },
                  },
                },
                plugins: {
                  tooltip: {
                    callbacks: {
                      label: function (context) {
                        const value = context.parsed.y;
                        return (
                          context.dataset.label +
                          ": " +
                          value +
                          tooltipIdentifier
                        );
                      },
                    },
                  },
                  legend: {
                    display: true,
                    position: "top",
                    align: "start",
                    labels: {
                      usePointStyle: true,
                      pointStyle: "rectRounded",
                    },
                  },
                  datalabels: {
                    display: showDataLabels,
                    color: colors.white,
                    align: "end",
                    anchor: "start",
                    offset: -2,
                    clip: true,
                    clamp: true,
                    font: {
                      weight: "700",
                      size: 12,
                    },
                    formatter: function (value) {
                      return extraLabelText
                        ? value + " " + extraLabelText
                        : value;
                    },
                  },
                },
              }}
            />
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

CustomBarChart.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  stacked: PropTypes.bool,
  yAxisTitle: PropTypes.string,
  tooltipIdentifier: PropTypes.string,
  extraLabelText: PropTypes.string,
  showDataLabels: PropTypes.bool,
};

export default CustomBarChart;
