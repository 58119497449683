import ExportButtons from "components/buttons/ExportButtons";
import {
  useExportAssetMaintenanceCostsPerAsset,
  useExportAssetMaintenanceCostsPerType,
  useExportAssetMaintenances,
} from "hooks/queries/useAssetReports";

import { useAlert } from "hooks/useAlert";
import PropTypes from "prop-types";
import { useCallback, useEffect, useState } from "react";

const exportButtonsToDisplay = ["pdf", "excel", "csv"];
const fileExtensions = { pdf: "pdf", excel: "xlsx", csv: "csv" };

const AssetReportsExportButtons = ({ filters, reportType }) => {
  const { showError } = useAlert();
  const [selectedExportType, setSelectedExportType] = useState(null);

  const getFetchFunctionByReportType = (reportType) => {
    switch (reportType) {
      case "ExpiringMaintenances":
      case "CompletedMaintenances":
        return useExportAssetMaintenances;
      case "MaintenanceCostsPerAsset":
        return useExportAssetMaintenanceCostsPerAsset;
      case "MaintenanceCostsPerType":
        return useExportAssetMaintenanceCostsPerType;
      default:
        return null;
    }
  };

  const fetchFunction = useCallback(getFetchFunctionByReportType(reportType), [
    reportType,
  ]);

  const { data, isLoading, isError, refetch } = fetchFunction({
    exportType: selectedExportType,
    ...filters,
  });

  const handleExport = useCallback((type) => {
    setSelectedExportType(type);
  }, []);

  const handleDownload = useCallback(() => {
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement("a");
    link.href = url;
    link.download = `report.${fileExtensions[selectedExportType]}`;
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
    setSelectedExportType(null);
  }, [data, selectedExportType]);

  useEffect(() => {
    if (selectedExportType) {
      refetch();
    }
  }, [selectedExportType]);

  useEffect(() => {
    if (data) {
      handleDownload();
    }

    if (isError) {
      showError("Παρουσιάστηκε πρόβλημα κατά την εξαγωγή του αρχείου.");
    }
  }, [data, handleDownload, isError, showError]);

  return (
    <ExportButtons
      exportButtons={exportButtonsToDisplay}
      onPdfExport={() => handleExport("pdf")}
      onExcelExport={() => handleExport("excel")}
      onCsvExport={() => handleExport("csv")}
      isLoading={isLoading}
    />
  );
};

AssetReportsExportButtons.propTypes = {
  filters: PropTypes.object.isRequired,
  reportType: PropTypes.string.isRequired,
};

export default AssetReportsExportButtons;
