import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Agent } from "api/agent";

export const useGetAllAssets = (
  {
    pageNumber = -1,
    pageSize = -1,
    searchParameter = "",
    assetUse = [],
    assetCategoryIds = [],
    orderBy = "",
    sortingOrder = "",
  } = {
    pageNumber: -1,
    pageSize: -1,
    searchParameter: "",
    assetUse: [],
    assetCategoryIds: [],
    orderBy: "",
    sortingOrder: "",
  },
  options = {}
) => {
  return useQuery({
    queryKey: [
      "getAllAssets",
      pageNumber,
      pageSize,
      searchParameter,
      assetUse,
      assetCategoryIds,
      orderBy,
      sortingOrder,
    ],
    queryFn: () =>
      Agent.assets.getAllAssets({
        pageNumber: pageNumber,
        pageSize: pageSize,
        searchParameter: searchParameter,
        assetUse,
        assetCategoryIds,
        orderBy,
        sortingOrder,
      }),
    staleTime: 1000 * 60 * 60,
    ...options,
  });
};

export const useGetAssetById = (assetId, options = {}) => {
  return useQuery({
    queryKey: ["getAssetById", assetId],
    queryFn: () => Agent.assets.getAssetById(assetId),
    staleTime: 1000 * 60 * 10,
    ...options,
  });
};

export const useGetAssetCategoryCount = (options = {}) => {
  return useQuery({
    queryKey: ["getAssetCategoryCount"],
    queryFn: () => Agent.assets.getAssetCategoryCount(),
    staleTime: 1000 * 60 * 10,
    ...options,
  });
};

export const useCreateAsset = ({ config = {} }) => {
  const queryClient = useQueryClient();
  const { onSuccess, ...restConfig } = config || {};

  return useMutation({
    mutationKey: ["createAsset"],
    mutationFn: (body) => Agent.assets.createAsset(body),
    onSuccess: (...args) => {
      queryClient.invalidateQueries(["getAllAssets"]);
      queryClient.invalidateQueries(["getAssetCategoryCount"]);
      onSuccess?.(...args);
    },
    ...restConfig,
  });
};

export const useUpdateAsset = ({ id, config = {} }) => {
  const queryClient = useQueryClient();
  const { onSuccess, ...restConfig } = config || {};

  return useMutation({
    mutationKey: ["updateAsset"],
    mutationFn: (body) => Agent.assets.updateAsset(id, body),
    onSuccess: (...args) => {
      queryClient.invalidateQueries(["getAllAssets"]);
      queryClient.invalidateQueries(["getAssetById", id]);
      onSuccess?.(...args);
    },
    ...restConfig,
  });
};

export const useDeleteAsset = ({ id, config = {} }) => {
  const queryClient = useQueryClient();
  const { onSuccess, ...restConfig } = config || {};

  return useMutation({
    mutationKey: ["deleteAsset"],
    mutationFn: () => Agent.assets.deleteAsset(id),
    onSuccess: (...args) => {
      queryClient.invalidateQueries(["getAllAssets"]);
      queryClient.invalidateQueries(["getAssetCategoryCount"]);
      onSuccess?.(...args);
    },
    ...restConfig,
  });
};
