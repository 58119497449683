import PropTypes from "prop-types";
import {
  Box,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import colors from "config/theme/colors";
import { InfoOutlined, Visibility, VisibilityOff } from "@mui/icons-material";
import Tooltip from "@mui/material/Tooltip";
import { Controller } from "react-hook-form";
import { useState } from "react";
import { addDotsSeperator } from "utils/addDotsSeperator";
import { removeNonNumericValues } from "utils/removeNonNumericValues";

const CustomTextField = ({
  label,
  name,
  type,
  disabled = false,
  required = false,
  isLink = false,
  onClick,
  control,
  editable = true,
  sensitive = false,
  placeholder,
  sx,
  isDecimal = false,
  description,
  onChange,
  defaultValue,
}) => {
  const [showValue, setShowValue] = useState(false);

  const handleNumberChange = (event, onChange) => {
    let newValue = event.target.value;

    if (isDecimal) {
      newValue = newValue
        .replace(/[^0-9.,]/g, "")
        .replace(/\./g, "")
        .replace(/,/g, ".");

      const parts = newValue.split(".");
      if (parts.length > 2) {
        newValue = `${parts[0]}.${parts.slice(1).join("")}`;
      }

      if (parts[1] && parts[1].length > 2) {
        newValue = `${parts[0]}.${parts[1].substring(0, 2)}`;
      }

      newValue = addDotsSeperator(newValue.replace(/\./g, ","));
    } else {
      newValue = removeNonNumericValues(newValue);
      newValue = addDotsSeperator(newValue);
    }

    onChange(newValue);
  };

  const toggleValueVisibility = () => {
    setShowValue(!showValue);
  };

  const ensureAbsoluteURL = (url) => {
    if (url && !url.startsWith("http://") && !url.startsWith("https://")) {
      return `https://${url}`;
    }
    return url;
  };

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Box display="flex" flexDirection="column">
        <Typography
          fontSize={12}
          fontWeight={700}
          display="flex"
          alignItems="center"
          gap={1}
        >
          {label} {required && "*"}
          {!editable && (
            <Tooltip title="Μη επεξεργάσιμο πεδίο" placement="right">
              <InfoOutlined
                fontSize="small"
                sx={{
                  color: colors.primary,
                  cursor: "pointer",
                  maxHeight: "0.9em",
                }}
              />
            </Tooltip>
          )}
        </Typography>
        {description && (
          <Typography fontSize={12} color={colors.grey}>
            {description}
          </Typography>
        )}
      </Box>
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState: { error } }) =>
          type === "number" ? (
            <TextField
              placeholder={
                field.placeholder || placeholder || "Εισάγετε αριθμό..."
              }
              fullWidth
              value={field.value || defaultValue || ""}
              variant="outlined"
              error={!!error}
              helperText={error ? error.message : null}
              InputLabelProps={{ shrink: true }}
              onChange={(e) => {
                handleNumberChange(e, field.onChange);
                if (onChange) {
                  onChange(e);
                }
              }}
              InputProps={{
                readOnly: disabled || !editable,
                style: {
                  fontSize: 14,
                  fontWeight: 400,
                  color: disabled && isLink ? colors.primary : colors.text,
                  textDecoration: disabled && isLink ? "underline" : "none",
                  cursor: disabled && isLink ? "pointer" : "text",
                  ...sx,
                },
              }}
            />
          ) : (
            <TextField
              {...field}
              placeholder={
                field.placeholder || placeholder || "Εισάγετε τιμή..."
              }
              type={sensitive && !showValue ? "password" : type}
              required={required}
              fullWidth
              value={field.value || defaultValue || ""}
              variant="outlined"
              onChange={(e) => {
                field.onChange(e);
                if (onChange) {
                  onChange(e);
                }
              }}
              error={!!error}
              helperText={error ? error.message : null}
              multiline={type === "textarea"}
              rows={type === "textarea" ? 4 : 1}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                readOnly: disabled || !editable,
                endAdornment: sensitive && (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={toggleValueVisibility}
                      edge="end"
                    >
                      {showValue ? (
                        <Visibility sx={{ color: colors.grey }} />
                      ) : (
                        <VisibilityOff sx={{ color: colors.grey }} />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
                style: {
                  fontSize: 14,
                  fontWeight: 400,
                  color: disabled && isLink ? colors.primary : colors.text,
                  textDecoration: disabled && isLink ? "underline" : "none",
                  cursor:
                    disabled &&
                    isLink &&
                    field.value &&
                    field.value !== placeholder
                      ? "pointer"
                      : "text",
                  ...sx,
                },
              }}
              inputProps={{
                style: {
                  cursor:
                    disabled &&
                    isLink &&
                    field.value &&
                    field.value !== placeholder
                      ? "pointer"
                      : "text",
                },
                onClick: () => {
                  if (
                    disabled &&
                    isLink &&
                    field.value &&
                    field.value !== placeholder
                  ) {
                    const url = ensureAbsoluteURL(field.value);
                    if (!onClick) {
                      window.open(url, "_blank");
                    }
                    if (onClick) {
                      onClick();
                    }
                  }
                },
              }}
            />
          )
        }
      />
    </Box>
  );
};

CustomTextField.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.oneOf(["text", "textarea", "number", "email"]).isRequired,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  control: PropTypes.object,
  editable: PropTypes.bool,
  sensitive: PropTypes.bool,
  sx: PropTypes.object,
  placeholder: PropTypes.string,
  isLink: PropTypes.bool,
  onClick: PropTypes.func,
  isDecimal: PropTypes.bool,
  description: PropTypes.string,
  onChange: PropTypes.func,
  defaultValue: PropTypes.any,
};

export default CustomTextField;
