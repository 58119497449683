import CustomTextField from "components/inputs/CustomTextField";
import SaveButton from "components/buttons/SaveButton";
import colors from "../../config/theme/colors";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { Box, Grid, Typography } from "@mui/material";
import { useForgotPassword } from "hooks/queries/useAuth";
import ReturnButton from "components/buttons/ReturnButton";
import PromoPage from "../special/PromoPage";
import { useIsMutating } from "@tanstack/react-query";
import { useState } from "react";

const ForgotPasswordPage = () => {
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const { mutate: forgotPassword } = useForgotPassword();
  const isOnForgotPasswordLoading =
    useIsMutating({ mutationKey: ["forgotPassword"] }) > 0;

  const onForgotPassword = (data) => {
    forgotPassword(data.email, {
      onError: (error) => {
        setErrorMessage(error.message);
        setSuccessMessage("");
      },
      onSuccess: () => {
        setSuccessMessage("Παρακαλούμε ελέγξτε το email σας!");
        setErrorMessage("");
      },
    });
  };

  const handleInputChange = () => {
    setErrorMessage("");
    setSuccessMessage("");
  };

  const schema = yup.object().shape({
    email: yup
      .string()
      .email("Πληκτρολογήστε ένα πραγματικό email")
      .trim("Το email δεν μπορεί να περιέχει κενά")
      .strict(true)
      .required("Το πεδίο ειναι υποχρεωτικό"),
  });

  const { control, handleSubmit, getValues } = useForm({
    resolver: yupResolver(schema),
  });

  return (
    <form onSubmit={handleSubmit(() => onForgotPassword(getValues()))}>
      <Box
        display="flex"
        flexDirection="column"
        gap={2}
        sx={{
          backgroundColor: colors.white,
        }}
      >
        <Grid
          container
          sx={{
            minHeight: "100vh",
          }}
        >
          <Grid
            item
            xs={12}
            md={6}
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            paddingTop={{
              xs: 5,
              md: 0,
            }}
            paddingX={{
              xs: 6,
              sm: 6,
              md: 10,
              lg: 15,
              xl: 20,
            }}
          >
            <Box display={"flex"} alignItems={"center"} gap={2}>
              <ReturnButton />
              <h1 style={{ fontSize: "48px" }}>
                <b>Επαναφορά Κωδικού</b>
              </h1>
            </Box>
            <p>
              Παρακαλούμε εισάγετε το email σας παρακάτω. Σύντομα, θα λάβετε ένα
              email με οδηγίες για την επαναφορά του κωδικού σας.
            </p>
            <div>
              <div className="mt-10">
                <Grid item xs={12} md={12}>
                  <CustomTextField
                    label="Email"
                    name="email"
                    type="email"
                    control={control}
                    disabled={false}
                    placeholder="Πληκτρολογήστε το email σας"
                    onChange={handleInputChange}
                  />
                </Grid>
              </div>

              <Typography color={colors.error} fontSize={14} mt={2}>
                {errorMessage}
              </Typography>

              <Typography color={colors.success} fontSize={14} mt={2}>
                {successMessage}
              </Typography>

              <Box
                display="flex"
                justifyContent="flex-center"
                alignItems="center"
                gap={2}
                sx={{ paddingTop: "50px", width: "100%" }}
              >
                <SaveButton
                  text={"Επαναφορά Κωδικού"}
                  width={"100%"}
                  isLoading={isOnForgotPasswordLoading}
                />
              </Box>
            </div>
          </Grid>

          <PromoPage />
        </Grid>
      </Box>
    </form>
  );
};

export default ForgotPasswordPage;
