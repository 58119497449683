import MainPageLayout from "components/main/MainPageLayout";
import { useNavigate } from "react-router";
import { useCreateCompany } from "hooks/queries/useCompanies";
import { useAlert } from "hooks/useAlert";
import CompanyCreateForm from "./CompanyCreateForm";
import enums from "config/enums";

const CompanyCreate = () => {
  const { showError, showSuccess } = useAlert();
  const { mutate: createCompany } = useCreateCompany({
    config: {
      onError: (error) => {
        showError(error.message);
      },
      onSuccess: () => {
        showSuccess("O οργανισμός δημιουργήθηκε με επιτυχία");
        navigate("/companies");
      },
    },
  });
  const navigate = useNavigate();

  const handleCreateClick = (data) => {
    const payload = {
      name: data.name,
      description: data.description,
      vat: data.vat,
      contactPerson: data.contactPerson,
      contactPhone: data.contactPhone,
      address: data.address,
      email: data.email,
      type: enums.CompanyTypes?.find((type) => type.label === data.type)?.id,
    };
    createCompany(payload);
  };

  return (
    <MainPageLayout
      pageTitle="Νέος Οργανισμός"
      pageSubTitle="Δημιουργία νέου οργανισμού"
    >
      <CompanyCreateForm onCreate={handleCreateClick} />
    </MainPageLayout>
  );
};

export default CompanyCreate;
