import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Agent } from "api/agent";

export const useGetAllCertificationHistory = (
  { pageNumber = -1, pageSize = -1, certificationId } = {
    pageNumber: -1,
    pageSize: -1,
    certificationId: null,
  },
  options = {}
) => {
  return useQuery({
    queryKey: [
      "getAllCertificationHistory",
      pageNumber,
      pageSize,
      certificationId,
    ],
    queryFn: () =>
      Agent.certificationHistory.getAll({
        pageNumber,
        pageSize,
        certificationId,
      }),
    staleTime: 1000 * 60 * 60,
    ...options,
  });
};

export const useGetCertificationHistoryById = (
  certificationHistoryId,
  options = {}
) => {
  return useQuery({
    queryKey: ["getCertificationHistoryById", +certificationHistoryId],
    queryFn: () => Agent.certificationHistory.getById(certificationHistoryId),
    staleTime: 1000 * 60 * 10,
    ...options,
  });
};

export const useCreateCertificationHistory = ({ config = {} }) => {
  const queryClient = useQueryClient();
  const { onSuccess, ...restConfig } = config;

  return useMutation({
    mutationKey: ["createCertificationHistory"],
    mutationFn: (body) => Agent.certificationHistory.create(body),
    onSuccess: (...args) => {
      queryClient.invalidateQueries(["getAllCertificationHistory"]);
      queryClient.invalidateQueries(["getAllCertifications"]);
      onSuccess?.(...args);
    },
    ...restConfig,
  });
};

export const useUpdateCertificationHistory = ({ id, config = {} }) => {
  const queryClient = useQueryClient();
  const { onSuccess, ...restConfig } = config;
  return useMutation({
    mutationKey: ["updateCertificationHistory"],
    mutationFn: (body) => Agent.certificationHistory.update(id, body),
    onSuccess: (...args) => {
      queryClient.invalidateQueries(["getAllCertificationHistory"]);
      queryClient.invalidateQueries(["getCertificationHistoryById", +id]);
      queryClient.invalidateQueries(["getAllCertifications"]);
      onSuccess?.(...args);
    },
    ...restConfig,
  });
};

export const useDeleteCertificationHistory = ({ id, config = {} }) => {
  const queryClient = useQueryClient();
  const { onSuccess, ...restConfig } = config;

  return useMutation({
    mutationKey: ["deleteCertificationHistory"],
    mutationFn: () => Agent.certificationHistory.delete(id),
    onSuccess: (...args) => {
      queryClient.invalidateQueries(["getAllCertificationHistory"]);
      queryClient.invalidateQueries(["getCertificationHistoryById", +id]);
      queryClient.invalidateQueries(["getAllCertifications"]);
      onSuccess?.(...args);
    },
    ...restConfig,
  });
};
