import React from "react";
import MainPageLayout from "components/main/MainPageLayout";
import DashboardCharts from "./DashboardCharts";
import DashboardBoxStats from "./DashboardBoxStats";

const Dashboard = () => {
  return (
    <MainPageLayout
      pageTitle="Κεντρική"
      pageSubTitle="Στατιστικά Στοιχεία Τρέχοντος Έτους"
    >
      <DashboardBoxStats />
      <DashboardCharts />
    </MainPageLayout>
  );
};

export default Dashboard;
