import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import CancelButton from "components/buttons/CancelButton";
import CustomTextField from "components/inputs/CustomTextField";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import PropTypes from "prop-types";
import SaveButton from "components/buttons/SaveButton";
import DateField from "components/inputs/DateField";
import { useIsMutating } from "@tanstack/react-query";
import { yupResolver } from "@hookform/resolvers/yup";
import Typography from "@mui/material/Typography";
import CustomButton from "components/buttons/CustomButton";
import colors from "config/theme/colors";
import { useEffect, useMemo } from "react";

const TasksViewEditForm = ({
  selectedTask,
  isEditing,
  onSave,
  onAction,
  onComplete,
  onCancel,
}) => {
  const schema = yup.object({
    notes: yup.string().notRequired(),
  });

  const defaultValues = useMemo(() => {
    return {
      name: selectedTask?.name || "-",
      asset:
        selectedTask?.maintenance?.asset?.name ||
        selectedTask?.maintenance?.equipment?.asset?.name ||
        selectedTask?.rent?.asset?.name ||
        "-",
      equipment:
        selectedTask?.rent?.equipment?.name ||
        selectedTask?.maintenance?.equipment?.name ||
        "-",
      createdAt: selectedTask?.entityCreatedAt || "-",
      expiresAt: selectedTask?.expiresAt || "-",
      notes: selectedTask?.notes || "-",
      status: selectedTask?.status || "-",
    };
  }, [selectedTask]);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isDirty },
    getValues,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  });

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  const isUpdateLoading = useIsMutating({ mutationKey: ["updateTask"] }) > 0;
  const isForceCompleteLoading =
    useIsMutating({ mutationKey: ["forceCompleteTask"] }) > 0;

  const handleFormSubmit = (data) => {
    onSave(data);
  };

  const handleAction = () => {
    onAction();
  };

  const handleComplete = () => {
    onComplete();
  };

  return (
    <form onSubmit={handleSubmit(() => handleFormSubmit(getValues()))}>
      <Box display="flex" flexDirection="column" gap={4}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <CustomTextField
              label="Όνομα"
              name="name"
              type="text"
              control={control}
              disabled={true}
              editable={false}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <CustomTextField
              label="Ακίνητο"
              name="asset"
              type="text"
              control={control}
              disabled={true}
              editable={false}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <CustomTextField
              label="Εξοπλισμός"
              name="equipment"
              type="text"
              control={control}
              disabled={true}
              editable={false}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <DateField
              label="Δημιουργία"
              name="createdAt"
              control={control}
              disabled={true}
              editable={false}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <DateField
              label="Ολοκλήρωση"
              name="expiresAt"
              control={control}
              disabled={true}
              editable={false}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <CustomTextField
              label="Σημειώσεις"
              name="notes"
              type="textarea"
              control={control}
              disabled={!isEditing}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <CustomTextField
              label="Κατάσταση"
              name="status"
              type="text"
              control={control}
              disabled={true}
              editable={false}
            />
          </Grid>
        </Grid>

        {Object.keys(errors).length > 0 && (
          <Typography color="error" fontSize={14}>
            Παρακαλώ ελέγξτε ότι όλα τα πεδία έχουν συμπληρωθεί σωστά
          </Typography>
        )}

        <Box display="flex" alignItems="center" gap={2}>
          <CancelButton
            disabled={!isEditing}
            onClick={() => {
              reset();
              onCancel();
            }}
          />
          {selectedTask.status !== "Ολοκληρωμένο" && (
            <Box display="flex" flexGrow={1} justifyContent="flex-end" gap={2}>
              <CustomButton
                onClick={handleAction}
                title="Ενέργεια και ολοκλήρωση"
                fontSize={12}
                fontWeight={700}
                sx={{
                  "&:hover": {
                    backgroundColor: colors.success,
                    opacity: 0.9,
                  },
                  "&:disabled": {
                    opacity: 0.5,
                    backgroundColor: colors.success,
                  },
                  padding: "12px 22px",
                  borderRadius: 2,
                  backgroundColor: colors.success,
                }}
              />
              <CustomButton
                onClick={handleComplete}
                title="Απλή ολοκλήρωση"
                fontSize={12}
                fontWeight={700}
                isLoading={isForceCompleteLoading}
                sx={{
                  "&:hover": {
                    backgroundColor: colors.info,
                    opacity: 0.9,
                  },
                  "&:disabled": {
                    opacity: 0.5,
                    backgroundColor: colors.info,
                  },
                  padding: "12px 22px",
                  borderRadius: 2,
                  backgroundColor: colors.info,
                }}
              />
              <SaveButton
                disabled={!isEditing || !isDirty}
                isLoading={isUpdateLoading}
              />
            </Box>
          )}
        </Box>
      </Box>
    </form>
  );
};

TasksViewEditForm.propTypes = {
  selectedTask: PropTypes.object.isRequired,
  onComplete: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  isEditing: PropTypes.bool.isRequired,
  onAction: PropTypes.func.isRequired,
};

export default TasksViewEditForm;
