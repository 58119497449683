import { authStore } from "stores/AuthStore";
import { useGetTaskCount } from "./queries/useTasks";
import { useGetTicketStatusCount } from "./queries/useTickets";

const useNotifications = () => {
  const {
    data: taskStatusCount,
    isLoading: taskStatusCountLoading,
    error: taskStatusCountError,
  } = useGetTaskCount({
    refetchInterval: 1000 * 60 * 10, // 10 minutes
    select: (data) => {
      return data?.result?.find((item) => item.name === "Νέο")?.count || 0;
    },
    enabled:
      authStore.isTechnicalIssues ||
      authStore.isSuperAdmin ||
      authStore.isUser ||
      authStore.isAdmin,
  });

  const {
    data: ticketsStatusCount,
    isLoading: ticketsStatusCountLoading,
    error: ticketsStatusCountError,
  } = useGetTicketStatusCount({
    refetchInterval: 1000 * 60 * 10, // 10 minutes
    select: (data) =>
      data?.result?.find((item) => item.name === "Pending")?.count || 0,
    enabled:
      authStore.isTechnicalIssues ||
      authStore.isSuperAdmin ||
      authStore.isUser ||
      authStore.isAdmin,
  });

  return {
    counts: {
      newTasks: taskStatusCount,
      newTickets: ticketsStatusCount,
    },
    isLoading: taskStatusCountLoading || ticketsStatusCountLoading,
    error: taskStatusCountError || ticketsStatusCountError,
    enabled:
      authStore.isTechnicalIssues ||
      authStore.isSuperAdmin ||
      authStore.isUser ||
      authStore.isAdmin,
  };
};

export default useNotifications;
