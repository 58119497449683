import { useQuery } from "@tanstack/react-query";
import { Agent } from "api/agent";

const baseQueryConfig = {
  staleTime: Infinity,
  enabled: false,
  retry: false,
  refetchOnReconnect: false,
  refetchOnWindowFocus: false,
  refetchOnMount: false,
};
export const useGetReportContracts = (
  {
    dateOption = "",
    dateFrom = "",
    dateTo = "",
    pageNumber = -1,
    pageSize = -1,
  },
  options = {}
) => {
  return useQuery({
    queryKey: [
      "getContracts",
      dateOption,
      dateFrom,
      dateTo,
      pageNumber,
      pageSize,
    ],
    queryFn: () =>
      Agent.contractReports.getContracts({
        dateOption: dateOption,
        dateFrom: dateFrom,
        dateTo: dateTo,
        pageNumber: pageNumber,
        pageSize: pageSize,
      }),
    staleTime: 1000 * 60 * 60,
    ...options,
  });
};

export const useGetReportContractsForSpecificOrganization = (
  { pageNumber = -1, pageSize = -1, externalCompanyId = [] },
  options = {}
) => {
  return useQuery({
    queryKey: [
      "getContractsSpecificOrganization",
      pageNumber,
      pageSize,
      externalCompanyId?.join(","),
    ],
    queryFn: () =>
      Agent.contractReports.getContractsForSpecificOrganization({
        pageNumber: pageNumber,
        pageSize: pageSize,
        externalCompanyId: externalCompanyId,
      }),
    staleTime: 1000 * 60 * 60,
    ...options,
  });
};

export const useExportContracts = ({
  dateOption = "",
  exportType = "",
  dateFrom = "",
  dateTo = "",
}) => {
  return useQuery({
    queryKey: ["exportContracts", exportType, dateFrom, dateTo, dateOption],
    queryFn: () =>
      Agent.contractReports.exportContracts({
        dateOption: dateOption,
        exportType: exportType,
        dateFrom: dateFrom,
        dateTo: dateTo,
      }),
    ...baseQueryConfig,
  });
};

export const useExportContractsForSpecificOrganization = ({
  exportType = "",
  externalCompanyId = [],
}) => {
  return useQuery({
    queryKey: [
      "exportSpecificOrganizationContracts",
      exportType,
      externalCompanyId?.join(","),
    ],
    queryFn: () =>
      Agent.contractReports.exportContractsForSpecificOrganization({
        exportType: exportType,
        externalCompanyId: externalCompanyId,
      }),
    ...baseQueryConfig,
  });
};
