import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Agent } from "api/agent";

export const useGetAllTickets = (
  {
    pageNumber = -1,
    pageSize = -1,
    status,
    searchParameter,
    orderBy,
    sortingOrder,
  } = {
    pageNumber: -1,
    pageSize: -1,
    status: [],
    searchParameter: "",
    orderBy: "",
    sortingOrder: "",
  },
  options = {}
) => {
  return useQuery({
    queryKey: [
      "getAllTickets",
      pageNumber,
      pageSize,
      status,
      searchParameter,
      orderBy,
      sortingOrder,
    ],
    queryFn: () =>
      Agent.tickets.getAll({
        pageNumber,
        pageSize,
        status,
        searchParameter,
        orderBy,
        sortingOrder,
      }),
    staleTime: 1000 * 60 * 60,
    ...options,
  });
};

export const useGetTicketById = (ticketId, options = {}) => {
  return useQuery({
    queryKey: ["getTicketById", +ticketId],
    queryFn: () => Agent.tickets.getById(ticketId),
    staleTime: 1000 * 60 * 10,
    ...options,
  });
};

export const useGetTicketStatusCount = (options = {}) => {
  return useQuery({
    queryKey: ["getTicketStatusCount"],
    queryFn: () => Agent.tickets.getTicketStatusCount(),
    staleTime: 1000 * 60 * 10,
    ...options,
  });
};

export const useCreateTicket = ({ config = {} }) => {
  const queryClient = useQueryClient();
  const { onSuccess, ...restConfig } = config;

  return useMutation({
    mutationKey: "createTicket",
    mutationFn: (body) => Agent.tickets.create(body),
    onSuccess: (...args) => {
      queryClient.invalidateQueries({ queryKey: ["getAllTickets"] });
      queryClient.invalidateQueries({ queryKey: ["getTicketStatusCount"] });
      onSuccess?.(...args);
    },
    ...restConfig,
  });
};

export const useUpdateTicket = ({ id, config = {} }) => {
  const queryClient = useQueryClient();
  const { onSuccess, ...restConfig } = config;

  return useMutation({
    mutationKey: "updateTicket",
    mutationFn: (body) => Agent.tickets.update(id, body),
    onSuccess: (...args) => {
      queryClient.invalidateQueries({ queryKey: ["getAllTickets"] });
      queryClient.invalidateQueries({ queryKey: ["getTicketById", +id] });
      queryClient.invalidateQueries({ queryKey: ["getTicketStatusCount"] });
      onSuccess?.(...args);
    },
    ...restConfig,
  });
};

export const useDeleteTicket = ({ id, config = {} }) => {
  const queryClient = useQueryClient();
  const { onSuccess, ...restConfig } = config;

  return useMutation({
    mutationKey: "deleteTicket",
    mutationFn: () => Agent.tickets.delete(id),
    onSuccess: (...args) => {
      queryClient.invalidateQueries({ queryKey: ["getAllTickets"] });
      queryClient.invalidateQueries({ queryKey: ["getTicketById", +id] });
      queryClient.invalidateQueries({ queryKey: ["getTicketStatusCount"] });
      onSuccess?.(...args);
    },
    ...restConfig,
  });
};

export const useCompleteTicket = ({ id, config = {} }) => {
  const queryClient = useQueryClient();
  const { onSuccess, ...restConfig } = config;

  return useMutation({
    mutationKey: "completeTicket",
    mutationFn: (body) => Agent.tickets.complete(id, body),
    onSuccess: (...args) => {
      queryClient.invalidateQueries({ queryKey: ["getAllTickets"] });
      queryClient.invalidateQueries({ queryKey: ["getTicketById", +id] });
      queryClient.invalidateQueries({ queryKey: ["getTicketStatusCount"] });
      onSuccess?.(...args);
    },
    ...restConfig,
  });
};
