import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useLocation, useNavigate, useParams } from "react-router";
import { useCreateMaintenanceHistory } from "hooks/queries/useMaintenancesHistory";
import { useAlert } from "hooks/useAlert";
import colors from "config/theme/colors";
import SpinningCircle from "components/spinners/SpinningCircle";
import MaintenanceHistoryCreateForm from "./MaintenanceHistoryCreateForm";
import { useGetMaintenanceById } from "hooks/queries/useMaintenances";

const MaintenanceHistoryCreate = () => {
  const location = useLocation();
  const taskId = location?.state?.taskId;
  const { showError, showSuccess } = useAlert();
  const { maintenanceId } = useParams();
  const navigate = useNavigate();
  const {
    data: maintenance,
    isLoading: isMaintenanceLoading,
    isError: isErrorMaintenance,
    error: errorMaintenance,
  } = useGetMaintenanceById(maintenanceId);

  const { mutate: createMaintenanceHistory } = useCreateMaintenanceHistory({
    config: {
      onError: (error) => {
        showError(error.message);
      },
      onSuccess: () => {
        showSuccess("Το ιστορικό συντήρησης δημιουργήθηκε με επιτυχία!");
        navigate(-1);
      },
    },
  });

  if (isMaintenanceLoading) {
    return <SpinningCircle />;
  }

  if (isErrorMaintenance) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          height: "100%",
        }}
      >
        <p>
          Error:
          {errorMaintenance.message}
        </p>
      </Box>
    );
  }

  const handleCreateClick = (data) => {
    const payload = {
      ...data,
      maintenanceId: maintenanceId || "",
      contractId: maintenance?.contract?.id || "",
      scheduleTaskId: taskId || null,
    };
    createMaintenanceHistory(payload);
  };

  return (
    <>
      <Box
        display="flex"
        alignItems={"center"}
        justifyContent="flex-start"
        gap={1}
      >
        <Typography color={colors.primary} fontWeight={700} fontSize={24}>
          Νέο Ιστορικό Συντήρησης
        </Typography>
        <Typography color={colors.text} fontWeight={500} fontSize={18}>
          Δημιουργία νέου ιστορικού συντήρησης
        </Typography>
      </Box>

      <Box mt={4.5}>
        <MaintenanceHistoryCreateForm onCreate={handleCreateClick} />
      </Box>
    </>
  );
};

export default MaintenanceHistoryCreate;
