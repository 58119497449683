import { Box, Typography } from "@mui/material";
import CustomButton from "components/buttons/CustomButton";
import SearchBar from "components/inputs/SearchBar";
import SpinningCircle from "components/spinners/SpinningCircle";
import CustomTable from "components/tables/CustomTable";
import { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router";
import { ReactComponent as AddIcon } from "assets/icons/add.svg";
import colors from "config/theme/colors";
import MainPageLayout from "components/main/MainPageLayout";
import {
  useGetAllContracts,
  useGetContractTypeCount,
} from "hooks/queries/useContracts";
import enums from "config/enums";
import dayjs from "dayjs";
import TagLabel from "components/labels/TagLabel";
import CountCard from "components/cards/CountCard";
import FolderOutlinedIcon from "@mui/icons-material/FolderOutlined";
import useSearchInput from "hooks/useSearchInput";
import FilterButton from "components/buttons/FilterButton";
import useSorting from "hooks/useSorting";
import usePagination from "hooks/usePagination";
import StatusLabel from "components/labels/StatusLabel";
import { useAuthStore } from "stores/AuthStore";

const colorPalette = ["#444444", "#1373A9D6", "#133D8F"];

const ContractsOverview = () => {
  const authStore = useAuthStore();
  const navigate = useNavigate();
  const {
    page,
    setPage,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
  } = usePagination();
  const [selectedFilters, setSelectedFilters] = useState({});
  const [selectedFacilityTypes, setSelectedFacilityTypes] = useState([]);
  const [selectedServiceTypes, setSelectedServiceTypes] = useState([]);
  const [selectedStatuses, setSelectedStatuses] = useState([]);
  const { orderDirection, valueToOrderBy, handleSortChange } = useSorting();
  const { searchInput, handleSearch } = useSearchInput();
  const {
    data: contracts,
    isLoading: isContractsLoading,
    error: errorContracts,
    refetch: refetchContracts,
  } = useGetAllContracts({
    pageNumber: page,
    pageSize: rowsPerPage,
    searchParameter: searchInput,
    facilityType: selectedFacilityTypes,
    serviceType: selectedServiceTypes,
    status: selectedStatuses,
    orderBy: valueToOrderBy,
    sortingOrder: orderDirection === "asc" ? "Ascending" : "Descending",
  });
  const {
    data: contractTypesCount,
    isLoading: isLoadingContractTypesCount,
    isError: isErrorContractTypesCount,
    error: errorContractTypesCount,
  } = useGetContractTypeCount();

  const filterGroups = [
    {
      title: "Τύπος Εγκατάστασης",
      filters: enums.FacilityTypes.map((type) => ({
        id: type.id,
        label: type.label,
        type: "checkbox",
      })),
    },
    {
      title: "Τύπος Σύμβασης",
      filters: enums.ContractTypes.map((type) => ({
        id: type.id,
        label: type.label,
        type: "checkbox",
      })),
    },
    {
      title: "Κατάσταση Σύμβασης",
      filters: enums.ContractStatuses.map((type) => ({
        id: type.id,
        label: type.label,
        type: "checkbox",
      })),
    },
  ];

  const handleSubmitFilters = useCallback(
    (filters) => {
      const facilityTypes = filters["Τύπος Εγκατάστασης"] || [];
      const contractTypes = filters["Τύπος Σύμβασης"] || [];
      const contractStatuses = filters["Κατάσταση Σύμβασης"] || [];
      setSelectedFilters(filters);
      setSelectedFacilityTypes(facilityTypes);
      setSelectedServiceTypes(contractTypes);
      setSelectedStatuses(contractStatuses);
      if (filters) {
        setPage(1);
      }

      if (
        facilityTypes.length === 0 &&
        contractTypes.length === 0 &&
        contractStatuses.length === 0
      ) {
        refetchContracts();
      }
    },
    [
      setSelectedFilters,
      setSelectedFacilityTypes,
      setSelectedServiceTypes,
      setSelectedStatuses,
      refetchContracts,
      setPage,
    ]
  );

  const tableHeaders = useMemo(
    () => [
      {
        id: "name",
        label: "Σύμβαση",
        isSortable: true,
      },
      {
        id: "number",
        label: "Αριθμός",
        isSortable: true,
      },
      {
        id: "externalCompanyContactPerson",
        label: "Συμβαλλόμενος",
        isSortable: true,
      },
      {
        id: "validFrom",
        label: "Ημ/νία ΄Eναρξης",
        isSortable: true,
      },
      {
        id: "validTo",
        label: "Ημ/νία Λήξης",
        isSortable: true,
      },
      {
        id: "type",
        label: "Τύπος Σύμβασης",
        isSortable: false,
      },
      {
        id: "status",
        label: "Κατάσταση",
        isSortable: false,
      },
    ],
    []
  );

  if (isContractsLoading || isLoadingContractTypesCount) {
    return <SpinningCircle />;
  }

  if (errorContracts || isErrorContractTypesCount) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          height: "100%",
        }}
      >
        <p>
          Error: {errorContracts.message || errorContractTypesCount.message}
        </p>
      </Box>
    );
  }

  const contractsCount = contracts?.totalCount;
  const contractsList = contracts?.items?.map((contract) => ({
    id: contract.id,
    name: contract.name || "-",
    externalCompanyContactPerson: contract?.externalCompany?.name || "-",
    number: contract.number || "-",
    validFrom: dayjs(contract.validFrom).format("DD/MM/YYYY") || "-",
    validTo: dayjs(contract.validTo).format("DD/MM/YYYY") || "-",
    type: enums.ContractTypes.find((type) => type.id === contract.type)?.label,
    status: contract.status ? (
      <StatusLabel
        statusValue={contract.status}
        statusText={
          enums.ContractStatuses.find((status) => status.id === contract.status)
            ?.label
        }
      />
    ) : (
      "-"
    ),
  }));

  const handleItemClick = (item) => {
    navigate(`${item.id}`);
  };

  return (
    <MainPageLayout pageTitle="Συμβάσεις">
      <Box
        display="flex"
        flexDirection={{
          xs: "column",
          sm: "row",
        }}
        justifyContent={{
          xs: "space-between",
        }}
        flexGrow={1}
        flexWrap={{
          xs: "wrap",
          md: "nowrap",
        }}
        width={"100%"}
        gap={2}
      >
        <Box
          display="flex"
          gap={2}
          flexWrap={{
            xs: "wrap",
            sm: "nowrap",
          }}
          sx={{
            overflowX: "auto",
          }}
        >
          {enums.ContractTypes?.map((contractType) => {
            const categoryData = contractTypesCount?.find(
              (item) => item.name === contractType.id
            );
            const count = categoryData?.count || 0;
            return (
              <CountCard
                key={contractType.id}
                title={contractType.label}
                backgroundColor={
                  colorPalette[enums.ContractTypes?.indexOf(contractType)]
                }
                icon={<FolderOutlinedIcon fontSize="large" />}
                count={count}
                boxSx={{
                  minWidth: { xs: "100%", sm: "250px" },
                }}
              />
            );
          })}
        </Box>
        {!authStore.isUser && (
          <CustomButton
            icon={<AddIcon fill={colors.primary} width={30} height={30} />}
            title="Προσθήκη"
            sx={{
              minWidth: 120,
            }}
            onClick={() => {
              navigate("new");
            }}
          />
        )}
      </Box>
      <Box
        mt={4}
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent={{
          xs: "space-between",
          lg: "flex-start",
        }}
        flexGrow={1}
        gap={2}
        height={40}
      >
        <SearchBar
          placeholder="Αναζήτηση σε Σύμβαση, Αριθμό, Συμβαλλόμενος, Τύπο Σύμβασης..."
          handleSearch={handleSearch}
          searchKey={searchInput}
        />
      </Box>
      <Box
        mt={4}
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        flexGrow={1}
        width={"100%"}
      >
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="start"
          gap={4}
          flexWrap={"wrap"}
          alignItems={"center"}
        >
          <Typography variant="h3" color="primary">
            Συμβάσεις ({contractsCount})
          </Typography>
          <Box display="flex" flexDirection="row" gap={1} flexWrap={"wrap"}>
            {enums.ContractTypes?.map((contractType) => {
              const count = contractsList?.filter(
                (contractItem) => contractItem.type === contractType.label
              ).length;
              return count > 0 ? (
                <TagLabel
                  key={contractType.id}
                  text={`${count} ${contractType.label}`}
                  color={
                    colorPalette[enums.ContractTypes?.indexOf(contractType)]
                  }
                />
              ) : null;
            })}
          </Box>
          <Box display="flex" flexGrow={1} justifyContent="flex-end">
            <FilterButton
              selectedFilters={selectedFilters}
              filterGroups={filterGroups}
              onApplyFilters={handleSubmitFilters}
            />
          </Box>
        </Box>

        {contractsList.length > 0 && tableHeaders && contractsCount > 0 ? (
          <CustomTable
            columns={tableHeaders}
            data={contractsList}
            fadeEffect
            onItemClick={handleItemClick}
            page={page - 1}
            rowsPerPage={rowsPerPage}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            totalResults={contractsCount}
            orderDirection={orderDirection}
            valueToOrderBy={valueToOrderBy}
            onSortChange={handleSortChange}
          />
        ) : (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{
              height: "100%",
            }}
          >
            <Typography variant="h4">Δεν υπάρχουν αποτελέσματα</Typography>
          </Box>
        )}
      </Box>
    </MainPageLayout>
  );
};

export default ContractsOverview;
