import { requests } from "api/HttpClient";

const { get, getFile } = requests;

const contractReports = {
  getContracts: ({
    dateOption = "",
    dateFrom = "",
    dateTo = "",
    pageNumber = -1,
    pageSize = -1,
  }) => {
    const queryParams = {
      dateOption,
      dateFrom,
      dateTo,
      pageNumber,
      pageSize,
    };

    const queryString = Object.keys(queryParams)
      .filter((key) => queryParams[key])
      .map(
        (key) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`
      )
      .join("&");

    const url = `/ContactReport/GetContracts?${queryString}`;

    return get(url);
  },

  getContractsForSpecificOrganization: ({
    pageNumber = -1,
    pageSize = -1,
    externalCompanyId = [],
  }) => {
    const queryParams = {
      pageNumber,
      pageSize,
      externalCompanyId,
    };

    const queryString = Object.keys(queryParams)
      .filter((key) => queryParams[key])
      .map((key) => {
        if (queryParams[key] instanceof Array) {
          return queryParams[key]
            .map((id) => `${encodeURIComponent(key)}=${encodeURIComponent(id)}`)
            .join("&");
        }
        return `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`;
      })
      .join("&");

    const url = `/ContactReport/GetContractsSpecificOrganization?${queryString}`;

    return get(url);
  },

  exportContracts: ({
    dateOption = "",
    exportType = "",
    dateFrom = "",
    dateTo = "",
  }) => {
    const queryParams = {
      exportType,
      dateOption,
      dateFrom,
      dateTo,
    };

    const queryString = Object.keys(queryParams)
      .filter((key) => queryParams[key])
      .map(
        (key) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`
      )
      .join("&");

    const url = `/ContactReport/ExportContractsToFile?${queryString}`;

    return getFile(url);
  },

  exportContractsForSpecificOrganization: ({
    exportType = "",
    externalCompanyId = [],
  }) => {
    const queryParams = {
      exportType,
      externalCompanyId,
    };
    const queryString = Object.keys(queryParams)
      .filter((key) => queryParams[key])
      .map((key) => {
        if (queryParams[key] instanceof Array) {
          return queryParams[key]
            .map((id) => `${encodeURIComponent(key)}=${encodeURIComponent(id)}`)
            .join("&");
        }
        return `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`;
      })
      .join("&");

    const url = `/ContactReport/ExportSpecificOrganisationContractsToFile?${queryString}`;

    return getFile(url);
  },
};

export default contractReports;
