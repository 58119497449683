import { requests } from "api/HttpClient";

const { get } = requests;

const assetCategories = {
  getAllAssetCategories: ({ pageNumber = -1, pageSize = -1 }) => {
    let url = "/AssetCategory/GetAssetCategoryList";
    if (pageNumber && pageSize) {
      url += `?pageNumber=${pageNumber}&pageSize=${pageSize}`;
    }
    return get(url);
  },
  getAssetControlsByCategoryId: (id) =>
    get(`/AssetCategory/GetControlsByAssetCategoryId/${id}`),
};

export default assetCategories;
