import { useQuery } from "@tanstack/react-query";
import { Agent } from "api/agent";

export const useGetAllAssetCategories = (
  pageNumber = -1,
  pageSize = -1,
  options = {}
) => {
  return useQuery({
    queryKey: ["getAllAssetCategories", pageNumber, pageSize],
    queryFn: () =>
      Agent.assetCategories.getAllAssetCategories({
        pageNumber: pageNumber,
        pageSize: pageSize,
      }),
    staleTime: 1000 * 60 * 60,
    ...options,
  });
};

export const useGetAssetControlsByCategoryId = (
  assetCategoryId,
  options = {}
) => {
  return useQuery({
    queryKey: ["getAssetControlsByCategoryId", assetCategoryId],
    queryFn: () =>
      Agent.assetCategories.getAssetControlsByCategoryId(assetCategoryId),
    enabled: !!assetCategoryId,
    staleTime: 1000 * 60 * 10,
    ...options,
  });
};
