import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Box, Button, styled, Typography } from "@mui/material";
import colors from "config/theme/colors";
import { useDropzone } from "react-dropzone";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import { Controller } from "react-hook-form";
import FilePreviewCarousel from "components/carousels/FilePreviewCarousel";
import { useDownloadFile } from "hooks/queries/useFile";

const MAX_FILES_NUMBER = 6;

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const AlfrescoFileUploader = ({
  name,
  label,
  value,
  multiple = false,
  disabled = false,
  required = false,
  validTypes,
  maxSize,
  control,
  editable = true,
  filesCount,
  description,
}) => {
  const [filePreviews, setFilePreviews] = useState([]);
  const [errors, setErrors] = useState([]);
  const validTypesString = validTypes.map((type) => type.split("/")[1]);
  const acceptTypes = validTypes.reduce((acc, type) => {
    const item = `.${type.split("/")[1]}`;
    acc[type] = acc[type] || [];
    acc[type].push(item);
    return acc;
  }, {});

  const { mutate: downloadFile } = useDownloadFile();

  useEffect(() => {
    if (value?.length > 0 && filePreviews?.length === 0) {
      setFilePreviews(value);
    }
  }, [value]);

  const onDrop = useCallback(
    (acceptedFiles, rejectedFiles, onChange) => {
      const totalFiles = acceptedFiles.length + filePreviews.length;

      if (totalFiles > MAX_FILES_NUMBER) {
        setErrors([
          {
            fileName: "Συνολικά αρχεία",
            errors: [
              `Ξεπεράσατε το μέγιστο αριθμό αρχείων (${MAX_FILES_NUMBER})`,
            ],
          },
        ]);
        return;
      }

      const previews = acceptedFiles.map((file) => ({
        name: file.name,
        preview: URL.createObjectURL(file),
        type: file.type,
        title: file.name,
      }));

      setFilePreviews((prev) => [...prev, ...previews]);

      onChange([...filePreviews, ...previews]);

      const newErrors = rejectedFiles.map(({ file }) => {
        const fileErrors = [];
        if (file.size > maxSize) {
          fileErrors.push(
            `Το αρχείο είναι μεγαλύτερο από ${maxSize / 1000000}MB`
          );
        }
        if (
          !validTypes.includes(file.type) &&
          !validTypes.includes(file.mimeType)
        ) {
          fileErrors.push("Μη επιτρεπτός τύπος αρχείου");
        }
        return { fileName: file.name, errors: fileErrors };
      });

      setErrors(newErrors);
    },
    [maxSize, validTypes, filePreviews]
  );

  const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
    onDrop,
    multiple,
    accept: acceptTypes,
    maxSize,
    disabled: disabled || !editable,
    maxFiles: MAX_FILES_NUMBER,
    useFsAccessApi: false,
    noClick: true,
    noKeyboard: true,
    noDrag: true,
  });

  const handleChange = (e, onChange) => {
    const files = Array.from(e.target.files);
    const acceptedFiles = [];
    const rejectedFiles = [];

    files.forEach((file) => {
      if (
        file.size > maxSize ||
        (!validTypes.includes(file.type) && !validTypes.includes(file.mimeType))
      ) {
        rejectedFiles.push({ file, errors: [] });
      } else {
        acceptedFiles.push(file);
      }
    });

    onDrop(acceptedFiles, rejectedFiles, onChange);
  };

  const handleDelete = (file, onChange) => {
    setFilePreviews((prev) => prev.filter((f) => f.name !== file.name));
    onChange?.(filePreviews.filter((f) => f.name !== file.name));
    setErrors([]);
  };

  const handleDownload = (file) => {
    if (file.preview) {
      const link = document.createElement("a");
      link.href = file.preview;
      link.download = file.title || file.name || "downloaded-file";
      document.body.appendChild(link);
      link.click();
      link.remove();
      return;
    }
    downloadFile(file.id, {
      onSuccess: (data) => {
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement("a");
        link.href = url;
        link.download = file.title;
        document.body.appendChild(link);
        link.click();
        link.remove();
      },
    });
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => {
        const currentFilesPreviews = value;
        return (
          <Box width="100%">
            <Box display={"flex"} gap={0.5} justifyContent={"flex-start"}>
              <Typography fontSize={12} fontWeight={700}>
                {label} {required && "*"} {filesCount && `(${filesCount})`}
              </Typography>
              {description && (
                <Typography
                  fontSize={12}
                  fontWeight={"light"}
                  fontStyle={"italic"}
                >
                  - {description}
                </Typography>
              )}
            </Box>
            <Typography
              fontSize={11}
              color={colors.grey}
              sx={{ marginBottom: 1 }}
            >
              Επιτρεπόμενοι τύποι αρχείων: {validTypesString.join(", ")}
            </Typography>

            <Box
              display="flex"
              flexDirection={{ xs: "column", sm: "row" }}
              alignItems="center"
              justifyContent="center"
              gap={2}
              height={"fit-content"}
              width={"100%"}
              sx={{
                border: `1px solid ${colors.lightGrey}`,
                borderRadius: 2,
                backgroundColor: isDragActive
                  ? colors.lightGrey
                  : "transparent",
                paddingX: 2,
                paddingY: 2,
              }}
            >
              {currentFilesPreviews?.length > 0 && (
                <Box
                  width={{ xs: "100%", sm: "70%" }}
                  maxHeight="200px"
                  overflow={"hidden"}
                >
                  <FilePreviewCarousel
                    filePreviews={currentFilesPreviews}
                    onDelete={
                      disabled || !editable
                        ? null
                        : (file) => handleDelete(file, onChange)
                    }
                    onDownload={handleDownload}
                  />
                </Box>
              )}
              <Button
                {...getRootProps()}
                variant="outlined"
                component="label"
                disabled={disabled || !editable}
                startIcon={<FileUploadOutlinedIcon />}
                onClick={(e) => {
                  e.preventDefault();
                  open();
                  e.stopPropagation();
                }}
                sx={{
                  flexShrink: 0,
                  backgroundColor: "transparent",
                  color: colors.primary,
                  border: `1px solid ${colors.primary}`,
                  fontWeight: 700,
                  "&.Mui-disabled": {
                    color: colors.primary,
                    borderColor: colors.primary,
                    opacity: 0.5,
                  },
                }}
              >
                Επιλογή αρχείων
                <VisuallyHiddenInput
                  {...getInputProps()}
                  name={name}
                  required={required}
                  onChange={(e) => {
                    handleChange(e, onChange);
                  }}
                />
              </Button>
            </Box>

            {errors.map(({ fileName, errors }) => (
              <Box marginTop={1} key={fileName}>
                {errors.map((error) => (
                  <Typography key={error} fontSize={12} color={colors.error}>
                    {fileName}: {error}
                  </Typography>
                ))}
              </Box>
            ))}
          </Box>
        );
      }}
    />
  );
};

AlfrescoFileUploader.propTypes = {
  name: PropTypes.string.isRequired,
  multiple: PropTypes.bool,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  label: PropTypes.string.isRequired,
  validTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
  maxSize: PropTypes.number,
  control: PropTypes.object,
  editable: PropTypes.bool,
  filesCount: PropTypes.number,
  description: PropTypes.string,
  value: PropTypes.array,
};

export default AlfrescoFileUploader;
