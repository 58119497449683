import PropTypes from "prop-types";
import { InputAdornment, TextField } from "@mui/material";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import { useEffect, useRef, useState } from "react";
import CustomButton from "components/buttons/CustomButton";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import colors from "config/theme/colors";

const SearchBar = ({ placeholder, handleSearch, searchKey, sx = {} }) => {
  const [searchInput, setSearchInput] = useState(searchKey);
  const inputRef = useRef(null);

  useEffect(() => {
    inputRef.current.focus();
  }, []);

  return (
    <>
      <TextField
        inputRef={inputRef}
        placeholder={placeholder || "Αναζήτηση..."}
        variant="outlined"
        size="small"
        value={searchInput}
        onChange={(e) => setSearchInput(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === "Enter") handleSearch(searchInput);
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <FilterListOutlinedIcon />
            </InputAdornment>
          ),
          endAdornment: searchInput && (
            <InputAdornment title="Clear" position="end">
              <ClearOutlinedIcon
                sx={{
                  cursor: "pointer",
                  "&:hover": {
                    borderRadius: "50%",
                    bgcolor: colors.lightGrey,
                  },
                }}
                onClick={() => setSearchInput("")}
              />
            </InputAdornment>
          ),
        }}
        sx={{
          width: "100%",
          "&:hover": {
            borderColor: "primary",
          },
          ...sx,
        }}
      />
      <CustomButton
        title="Αναζήτηση"
        fontSize={14}
        fontWeight={600}
        sx={{
          minWidth: 140,
          height: "100%",
          p: 0,
          borderRadius: 2,
        }}
        onClick={() => handleSearch(searchInput)}
      />
    </>
  );
};

SearchBar.propTypes = {
  placeholder: PropTypes.string,
  sx: PropTypes.object,
  handleSearch: PropTypes.func.isRequired,
  searchKey: PropTypes.string,
};

export default SearchBar;
