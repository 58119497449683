import { requests } from "api/HttpClient";

const { get, getFile } = requests;

const ticketReports = {
  getTickets: ({
    status = "",
    dateFrom = "",
    dateTo = "",
    pageNumber = -1,
    pageSize = -1,
  }) => {
    const queryParams = {
      status,
      dateFrom,
      dateTo,
      pageNumber,
      pageSize,
    };

    const queryString = Object.keys(queryParams)
      .filter((key) => queryParams[key])
      .map(
        (key) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`
      )
      .join("&");

    const url = `/TicketReport/GetTickets?${queryString}`;

    return get(url);
  },

  getTicketsPerAsset: ({
    dateFrom = "",
    dateTo = "",
    pageNumber = -1,
    pageSize = -1,
  }) => {
    const queryParams = {
      dateFrom,
      dateTo,
      pageNumber,
      pageSize,
    };

    const queryString = Object.keys(queryParams)
      .filter((key) => queryParams[key])
      .map(
        (key) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`
      )
      .join("&");

    const url = `/TicketReport/GetTicketsPerAsset?${queryString}`;

    return get(url);
  },

  exportTickets: ({
    status = "",
    exportType = "",
    dateFrom = "",
    dateTo = "",
  }) => {
    const queryParams = {
      status,
      exportType,
      dateFrom,
      dateTo,
    };

    const queryString = Object.keys(queryParams)
      .filter((key) => queryParams[key])
      .map(
        (key) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`
      )
      .join("&");

    const url = `/TicketReport/ExportTicketsToFile?${queryString}`;

    return getFile(url);
  },

  exportTicketsPerAsset: ({ exportType = "", dateFrom = "", dateTo = "" }) => {
    const queryParams = {
      exportType,
      dateFrom,
      dateTo,
    };

    const queryString = Object.keys(queryParams)
      .filter((key) => queryParams[key])
      .map(
        (key) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`
      )
      .join("&");

    const url = `/TicketReport/ExportTicketsPerAssetToFile?${queryString}`;

    return getFile(url);
  },
};

export default ticketReports;
