import { Box, Typography } from "@mui/material";
import colors from "config/theme/colors";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useLocation } from "react-router";

const SubHeader = ({ tabs = [], setSelectedTab }) => {
  const [selected, setSelected] = useState();
  const location = useLocation();

  const handleTabClick = (tab) => {
    if (tab !== null && tab !== undefined) {
      setSelected(tab.id);
      setSelectedTab(tab.id);
    }
  };

  useEffect(() => {
    setSelected(tabs[0].id);
  }, []);

  useEffect(() => {
    const currentTab =
      tabs.find((tab) => location.pathname.includes(tab.to))?.id ?? null;
    if (currentTab !== null) setSelected(currentTab);
  }, [location.pathname, tabs]);

  return (
    <Box
      component="header"
      sx={{
        width: "fit-content",
        height: 36,
        display: "flex",
        justifyContent: "start",
        alignItems: "center",
        gap: 2,
        backgroundColor: "transparent",
        border: `0.5px solid ${colors.greyBackground}`,
        borderRadius: 2,
        overflowX: "auto",
        overflowY: "hidden",
        "&::-webkit-scrollbar": {
          height: 2,
        },
        "&::-webkit-scrollbar-track": {
          backgroundColor: colors.grey,
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: colors.grey,
        },
      }}
    >
      {tabs.map((tab) => {
        return (
          <Typography
            key={tab.description}
            fontSize={12}
            fontWeight={700}
            color={selected === tab.id ? colors.white : colors.black}
            backgroundColor={selected === tab.id ? colors.black : "transparent"}
            borderRadius={2}
            sx={{
              textDecoration: "none",
              cursor: "pointer",
              padding: "8px 10px",
              "&:hover": {
                backgroundColor: colors.black,
                color: colors.white,
              },
            }}
            onClick={() => handleTabClick(tab)}
          >
            <span
              style={{
                textWrap: "nowrap",
              }}
            >
              {tab.description}
            </span>
          </Typography>
        );
      })}
    </Box>
  );
};

SubHeader.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      description: PropTypes.string.isRequired,
    })
  ),
  setSelectedTab: PropTypes.func,
};

export default SubHeader;
