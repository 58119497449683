const equipmentResponseFields = [
  {
    name: "name",
    label: "Όνομα",
    type: "text",
    required: true,
  },
  {
    name: "description",
    label: "Περιγραφή",
    type: "text",
    required: false,
  },
  {
    name: "floor",
    label: "Όροφος",
    type: "number",
    required: false,
  },
  {
    name: "supplyType",
    label: "Τύπος Προμήθειας",
    type: "select",
    required: true,
    options: ["Αγορά", "Ενοικίαση"],
  },
  {
    name: "equipmentTypeId",
    label: "Τύπος",
    type: "select",
    required: true,
  },
  {
    name: "equipmentGroupId",
    label: "Ομάδα",
    type: "select",
    required: true,
  },
  {
    name: "equipmentClassId",
    label: "Κλάση",
    type: "select",
    required: true,
  },
  {
    label: "Αρχεία",
    name: "files",
    type: "file",
    multiple: true,
    validTypes: ["image/jpeg", "image/png", "application/pdf"],
    maxSize: 5000000,
  },
];

export default equipmentResponseFields;
