const rentRequestFields = {
  rent: [
    {
      label: "Τίτλος",
      name: "title",
      type: "text",
      required: false,
    },
    {
      label: "Ποσό Ενοικίου (€)",
      name: "amount",
      type: "number",
      required: false,
    },
    {
      label: "Τύπος Ενοικίασης",
      name: "rentType",
      type: "select",
      required: true,
      options: [],
    },
    {
      label: "Συχνότητα (μέρες)",
      name: "frequency",
      type: "number",
      required: false,
    },
    {
      label: "Συμβόλαιο",
      name: "contractId",
      type: "select",
      required: true,
      options: [],
    },
    {
      label: "Αρχεία",
      name: "files",
      type: "file",
      multiple: true,
      validTypes: ["image/jpeg", "image/png", "application/pdf"],
      maxSize: 5000000,
    },
  ],
  rentHistory: [
    {
      label: "Hμερομηνία Πληρωμής",
      name: "paymentDate",
      type: "date",
      required: true,
    },
  ],
};

export default rentRequestFields;
