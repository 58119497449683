import { requests } from "api/HttpClient";

const { get, put } = requests;

const config = {
  headers: {
    "Content-Type": "multipart/form-data",
  },
};

const tasks = {
  getAllTasks: ({
    pageNumber = -1,
    pageSize = -1,
    searchParameter = "",
    maintenanceId = null,
    rentId = null,
    scheduledTaskStatus = [],
    orderBy = "",
    sortingOrder = "",
  }) => {
    let url = "/ScheduledTask/GetScheduledTaskList";
    if (pageNumber && pageSize && searchParameter) {
      url += `?searchParameter=${searchParameter}&pageNumber=${pageNumber}&pageSize=${pageSize}`;
    }
    if (pageNumber && pageSize && !searchParameter) {
      url += `?pageNumber=${pageNumber}&pageSize=${pageSize}`;
    }

    if (maintenanceId) {
      url += `&maintenanceId=${maintenanceId}`;
    }

    if (rentId) {
      url += `&rentId=${rentId}`;
    }

    if (scheduledTaskStatus) {
      scheduledTaskStatus.forEach((status) => {
        url += `&scheduledTaskStatus=${status}`;
      });
    }

    if (orderBy) {
      url += `&orderBy=${orderBy}`;
    }

    if (sortingOrder) {
      url += `&sortingOrder=${sortingOrder}`;
    }

    return get(url);
  },
  getTaskById: (id) => get(`/ScheduledTask/GetScheduledTaskById/${id}`),
  getTaskCount: () => get("ScheduledTask/Count"),
  updateTask: (id, body) =>
    put(`/ScheduledTask/UpdateScheduledTask/${id}`, body, config),
  forceCompleteTask: (id) =>
    put(`/ScheduledTask/ForceCompleteScheduledTask/${id}`),
};

export default tasks;
