import { makeAutoObservable } from "mobx";
import { createContext, useContext } from "react";
import PropTypes from "prop-types";
import { jwtDecode } from "jwt-decode";
import enums from "config/enums";
import { Agent } from "api/agent";

class AuthStore {
  token = null;
  refreshToken = null;
  isUserAuthenticated = false;
  rememberMe = false;

  isSuperAdmin = false;
  isAdmin = false;
  isManager = false;
  isUser = false;
  isTechnicalIssues = false;

  constructor() {
    makeAutoObservable(this);
    this.initialize();
    this.updateRoles();
  }

  initialize() {
    const storedToken =
      JSON.parse(localStorage.getItem("token"))?.token ||
      JSON.parse(sessionStorage.getItem("token"))?.token;
    const storedRefreshToken =
      JSON.parse(localStorage.getItem("token"))?.refreshToken ||
      JSON.parse(sessionStorage.getItem("token"))?.refreshToken;

    if (storedToken) {
      this.token = storedToken;
      this.refreshToken = storedRefreshToken;
      this.isUserAuthenticated = true;
    }
  }

  setToken = (value) => {
    this.token = value;
  };

  setRefreshToken = (value) => {
    this.refreshToken = value;
  };

  setIsUserAuthenticated = (value) => {
    this.isUserAuthenticated = value;
  };

  setRememberMe = (value) => {
    this.rememberMe = value;
  };

  storeToken = (data) => {
    if (this.rememberMe) {
      localStorage.setItem("token", JSON.stringify(data?.result));
    } else {
      sessionStorage.setItem("token", JSON.stringify(data?.result));
    }
    this.setToken(data?.result?.token);
    this.setRefreshToken(data?.result?.refreshToken);
    this.setIsUserAuthenticated(true);
    this.updateRoles();
  };

  updateRoles = () => {
    if (this.token) {
      const decoded = jwtDecode(this.token);
      this.isSuperAdmin = decoded.role === enums.Roles.SuperAdmin;
      this.isAdmin = decoded.role === enums.Roles.Admin;
      this.isManager = decoded.role === enums.Roles.Manager;
      this.isUser = decoded.role === enums.Roles.User;
      this.isTechnicalIssues = decoded.role === enums.Roles.TechnicalIssues;
    } else {
      this.isSuperAdmin = false;
      this.isAdmin = false;
      this.isManager = false;
      this.isUser = false;
      this.isTechnicalIssues = false;
    }
  };

  logout = () => {
    if (this.token) {
      Agent.auth.logout(this.token, this.refreshToken).finally(() => {
        localStorage.removeItem("token");
        sessionStorage.removeItem("token");
        this.setToken(null);
        this.setIsUserAuthenticated(false);
      });
    } else {
      this.setIsUserAuthenticated(false);
    }
  };
}

export const authStore = new AuthStore();
export const AuthStoreContext = createContext(authStore);
export const AuthStoreProvider = ({ children, store }) => (
  <AuthStoreContext.Provider value={store}>
    {children}
  </AuthStoreContext.Provider>
);

AuthStoreProvider.propTypes = {
  store: PropTypes.object,
  children: PropTypes.node,
};

export const useAuthStore = () => useContext(AuthStoreContext);
