import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useLocation, useNavigate, useParams } from "react-router";
import { useCreateRentHistory } from "hooks/queries/useRentHistory";
import { useAlert } from "hooks/useAlert";
import colors from "config/theme/colors";
import RentHistoryCreateForm from "./RentHistoryCreateForm";

const RentHistoryCreate = () => {
  const location = useLocation();
  const taskId = location?.state?.taskId;
  const rentId = location?.state?.rentId;
  const { showError, showSuccess } = useAlert();
  const navigate = useNavigate();

  const { mutate: createRentHistory } = useCreateRentHistory({
    config: {
      onError: (error) => {
        showError(error.message);
      },
      onSuccess: () => {
        showSuccess("Το ιστορικό ενοικίασης δημιουργήθηκε με επιτυχία!");
        navigate(-1);
      },
    },
  });

  const handleCreateClick = (data) => {
    const payload = {
      ...data,
      rentId: rentId || null,
      scheduleTaskId: taskId || null,
    };
    createRentHistory(payload);
  };

  return (
    <>
      <Box
        display="flex"
        alignItems={"center"}
        justifyContent="flex-start"
        gap={1}
      >
        <Typography color={colors.primary} fontWeight={700} fontSize={24}>
          Νέο Ιστορικό Ενοικίασης
        </Typography>
        <Typography color={colors.text} fontWeight={500} fontSize={18}>
          Δημιουργία νέου ιστορικού ενοικίασης
        </Typography>
      </Box>

      <Box mt={4.5}>
        <RentHistoryCreateForm onCreate={handleCreateClick} />
      </Box>
    </>
  );
};

export default RentHistoryCreate;
