import { Snackbar } from "@mui/material";
import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import PropTypes from "prop-types";
import { error, success, warning } from "components/alerts/Alerts";

const AlertContext = createContext();

export const useAlert = () => useContext(AlertContext);

export const AlertProvider = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("info");
  const [duration, setDuration] = useState(3000);

  const showError = useCallback(
    (message, duration) => {
      setSeverity("error");
      setMessage(message);
      duration && setDuration(duration);
      setOpen(true);
    },
    [setMessage, setOpen]
  );

  const showSuccess = useCallback(
    (message, duration) => {
      setSeverity("success");
      setMessage(message);
      duration && setDuration(duration);
      setOpen(true);
    },
    [setMessage, setOpen]
  );

  const showWarning = useCallback(
    (message, duration) => {
      setSeverity("warning");
      setMessage(message);
      duration && setDuration(duration);
      setOpen(true);
    },
    [setMessage, setOpen]
  );

  return useMemo(() => {
    const handleClose = (event, reason) => {
      if (reason === "clickaway") {
        return;
      }

      setOpen(false);
    };

    const getCustomAlert = (onClose) => {
      switch (severity) {
        case "success":
          return success(message, onClose);
        case "error":
          return error(message, onClose);
        case "warning":
          return warning(message, onClose);
        default:
          return null;
      }
    };

    return (
      <AlertContext.Provider
        value={{
          showError,
          showSuccess,
          showWarning,
        }}
      >
        {children}
        <Snackbar
          open={open}
          autoHideDuration={duration}
          onClose={handleClose}
          sx={{
            "& .MuiSnackbarContent-root": {
              opacity: 0.5,
            },
          }}
        >
          {getCustomAlert(handleClose)}
        </Snackbar>
      </AlertContext.Provider>
    );
  }, [
    showError,
    showSuccess,
    showWarning,
    children,
    open,
    severity,
    message,
    duration,
  ]);
};

AlertProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
