import { requests } from "api/HttpClient";

const { post, get, put, delete: deleteRequest } = requests;

const config = {
  headers: {
    "Content-Type": "multipart/form-data",
  },
};

const tickets = {
  getAll: ({
    pageNumber = -1,
    pageSize = -1,
    status,
    searchParameter,
    orderBy,
    sortingOrder,
  }) => {
    let url = "/Ticket/GetTicketList";

    const queryParams = [];

    if (pageNumber && pageSize) {
      queryParams.push(`pageNumber=${pageNumber}`);
      queryParams.push(`pageSize=${pageSize}`);
    }

    if (searchParameter) {
      queryParams.push(`searchParameter=${searchParameter}`);
    }

    if (orderBy) {
      queryParams.push(`orderBy=${orderBy}`);
    }

    if (sortingOrder) {
      queryParams.push(`sortingOrder=${sortingOrder}`);
    }

    if (status) {
      status.forEach((status) => {
        queryParams.push(`ticketStatus=${encodeURIComponent(status)}`);
      });
    }

    if (queryParams.length > 0) {
      url += `?${queryParams.join("&")}`;
    }

    return get(url);
  },
  getById: (id) => {
    return get(`/Ticket/GetTicketById/${id}`);
  },
  getTicketStatusCount: () => get("/Ticket/GetTicketStatusCount"),
  create: (data) => post("/Ticket/CreateTicket", data, config),
  update: (id, data) => put(`/Ticket/UpdateTicket/${id}`, data, config),
  delete: (id) => deleteRequest(`/Ticket/DeleteTicket/${id}`),
  complete: (id, data) => put(`/Ticket/CompleteTicket/${id}`, data, config),
};

export default tickets;
