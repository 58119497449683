import { Box, Tooltip, Typography } from "@mui/material";
import colors from "config/theme/colors";
import PropTypes from "prop-types";
import Shade from "assets/icons/black_shade.svg";
import { formatNumbersWithSeparator } from "utils/formatNumbersWithSeperator";

const CountCard = ({
  title,
  description,
  icon,
  backgroundColor,
  count,
  endTextSymbol,
  info,
  boxSx,
}) => {
  return (
    <Tooltip
      title={info || ""}
      arrow
      placement="top"
      componentsProps={{
        tooltip: {
          sx: {
            bgcolor: backgroundColor ?? colors.primary,
            "& .MuiTooltip-arrow": {
              color: backgroundColor ?? colors.primary,
            },
          },
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          minWidth: "250px",
          maxWidth: "100%",
          height: "150px",
          p: 2,
          gap: 2,
          borderRadius: 4,
          bgcolor: backgroundColor,
          color: colors.white,
          backgroundImage: `url(${Shade})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          cursor: info ? "pointer" : "default",
          ...boxSx,
        }}
      >
        <Box display="flex" flexDirection="column" justifyContent="center">
          <Typography
            fontWeight={500}
            fontSize={17}
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitLineClamp: 2,
              WebkitBoxOrient: "vertical",
            }}
          >
            {title}
          </Typography>
          {description && (
            <Typography fontSize={12} fontWeight={300}>
              {description}
            </Typography>
          )}
        </Box>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box
            display="flex"
            justifyContent="flex-start"
            alignItems="baseline"
            gap={1}
          >
            <Typography fontSize={36} fontWeight={600}>
              {isNaN(count) ? count : formatNumbersWithSeparator(count)}
            </Typography>
            {endTextSymbol && (
              <Typography fontSize={36} fontWeight={300}>
                {endTextSymbol}
              </Typography>
            )}
          </Box>
          {icon}
        </Box>
      </Box>
    </Tooltip>
  );
};

CountCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  icon: PropTypes.node,
  backgroundColor: PropTypes.string.isRequired,
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  endTextSymbol: PropTypes.string,
  info: PropTypes.string,
  boxSx: PropTypes.object,
};

export default CountCard;
