import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useNavigate, useParams } from "react-router";
import { useAlert } from "hooks/useAlert";
import colors from "config/theme/colors";
import CertificationHistoryCreateForm from "./CertificationHistoryCreateForm";
import { useCreateCertificationHistory } from "hooks/queries/useCertificationsHistory";
import dayjs from "dayjs";

const CertificationHistoryCreate = () => {
  const { showError, showSuccess } = useAlert();
  const { certificationId } = useParams();
  const navigate = useNavigate();

  const { mutate: createCertificationHistory } = useCreateCertificationHistory({
    config: {
      onError: (error) => {
        showError(error.message);
      },
      onSuccess: () => {
        showSuccess("Το ιστορικό πιστοποίησης δημιουργήθηκε με επιτυχία");
        navigate(-1);
      },
    },
  });

  const handleCreateClick = (data) => {
    const payload = {
      ...data,
      certificationId: certificationId || "",
      issueDateTime: dayjs(data?.issueDateTime).format("YYYY-MM-DD") || null,
      expireDateTime: dayjs(data?.expireDateTime).format("YYYY-MM-DD") || null,
    };
    createCertificationHistory(payload);
  };

  return (
    <>
      <Box
        display="flex"
        alignItems={"center"}
        justifyContent="flex-start"
        gap={1}
      >
        <Typography color={colors.primary} fontWeight={700} fontSize={24}>
          Νέο Ιστορικό Πιστοποίησης
        </Typography>
        <Typography color={colors.text} fontWeight={500} fontSize={18}>
          Δημιουργία νέου ιστορικού πιστοποίησης
        </Typography>
      </Box>

      <Box mt={4.5}>
        <CertificationHistoryCreateForm onCreate={handleCreateClick} />
      </Box>
    </>
  );
};

export default CertificationHistoryCreate;
