import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Agent } from "api/agent";

export const useGetAllUsers = (
  {
    pageNumber = -1,
    pageSize = -1,
    searchParameter = "",
    role = [],
    status = [],
    orderBy = "",
    sortingOrder = "",
  },
  options = {}
) => {
  return useQuery({
    queryKey: [
      "getAllUsers",
      pageNumber,
      pageSize,
      searchParameter,
      role,
      status,
      orderBy,
      sortingOrder,
    ],
    queryFn: () =>
      Agent.users.getAll({
        pageNumber: pageNumber,
        pageSize: pageSize,
        searchParameter: searchParameter,
        role,
        status,
        orderBy,
        sortingOrder,
      }),
    staleTime: 1000 * 60 * 60,
    ...options,
  });
};

export const useGetUserById = (userId, options = {}) => {
  return useQuery({
    queryKey: ["getUserById", userId],
    queryFn: () => Agent.users.getById(userId),
    staleTime: 1000 * 60 * 10,
    ...options,
  });
};

export const useGetUserByEmail = (email, options = {}) => {
  return useQuery({
    queryKey: ["getUserByEmail", email],
    queryFn: () => Agent.users.getByEmail(email),
    staleTime: 1000 * 60 * 10,
    ...options,
  });
};

export const useGetUserRolesCount = (options = {}) => {
  return useQuery({
    queryKey: ["getUserRolesCount"],
    queryFn: () => Agent.users.getUserRolesCount(),
    staleTime: 1000 * 60 * 10,
    ...options,
  });
};

export const useCreateUser = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ["createUser"],
    mutationFn: (body) => Agent.users.create(body),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["getAllUsers"] });
      queryClient.invalidateQueries({ queryKey: ["getUserRolesCount"] });
    },
  });
};

export const useUpdateUser = (id) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ["updateUser"],
    mutationFn: (body) => Agent.users.update(id, body),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["getAllUsers"] });
      queryClient.invalidateQueries({ queryKey: ["getUserById", id] });
      queryClient.invalidateQueries({ queryKey: ["getUserByEmail"] });
      queryClient.invalidateQueries({ queryKey: ["getUserRolesCount"] });
    },
  });
};

export const useDeleteUser = (id) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ["deleteUser"],
    mutationFn: () => Agent.users.delete(id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["getAllUsers"] });
      queryClient.invalidateQueries({ queryKey: ["getUserById", id] });
      queryClient.invalidateQueries({ queryKey: ["getUserByEmail"] });
      queryClient.invalidateQueries({ queryKey: ["getUserRolesCount"] });
    },
  });
};
