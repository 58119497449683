import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import RentHistoryViewEditForm from "./RentHistoryViewEditForm";
import { useLocation, useNavigate, useParams } from "react-router";
import { useState } from "react";
import EditButton from "components/buttons/EditButton";
import {
  useDeleteRentHistory,
  useGetRentHistoryById,
  useUpdateRentHistory,
} from "hooks/queries/useRentHistory";
import { useAlert } from "hooks/useAlert";
import ReturnButton from "components/buttons/ReturnButton";
import colors from "config/theme/colors";
import SpinningCircle from "components/spinners/SpinningCircle";

const RentHistoryViewEdit = () => {
  const { historyId } = useParams();
  const location = useLocation();
  const rentId = location?.state?.rentId;
  const navigate = useNavigate();
  const { showError, showSuccess } = useAlert();
  const [isDeleted, setIsDeleted] = useState(false);
  const {
    data: selectedRentHistory,
    isLoading: isRentHistoryLoading,
    isError: isErrorRentHistory,
    error: errorRentHistory,
  } = useGetRentHistoryById(historyId, {
    select: (data) => data.result,
    enabled: !!historyId && !isDeleted,
  });
  const { mutate: updateRentHistory } = useUpdateRentHistory({
    id: selectedRentHistory?.id,
    config: {
      onError: (error) => {
        showError(error.message);
      },
      onSuccess: () => {
        showSuccess("Το ιστορικό ενοικίασης ενημερώθηκε με επιτυχία!");
        navigate(-1);
      },
    },
  });
  const { mutate: deleteMaintenaceHistory } = useDeleteRentHistory({
    id: selectedRentHistory?.id,
    config: {
      onError: (error) => {
        showError(error.message);
        setIsDeleted(false);
      },
      onSuccess: () => {
        showSuccess("Το ιστορικό ενοικίασης διαγράφηκε με επιτυχία!");
        navigate(-1);
      },
    },
  });
  const [isEditing, setIsEditing] = useState(false);

  if (isRentHistoryLoading) {
    return <SpinningCircle />;
  }

  if (isErrorRentHistory) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          height: "100%",
        }}
      >
        <p>Error: {errorRentHistory?.message}</p>
      </Box>
    );
  }

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleCancelClick = () => {
    setIsEditing(false);
  };

  const handleSaveClick = (data) => {
    setIsEditing(false);
    const payload = {
      ...data,
      rentId: rentId || "",
    };
    updateRentHistory(payload);
  };

  const handleDeleteClick = () => {
    setIsEditing(false);
    setIsDeleted(true);
    deleteMaintenaceHistory();
  };

  return (
    selectedRentHistory && (
      <>
        <Box
          display="flex"
          alignItems={"center"}
          justifyContent="flex-start"
          gap={1}
        >
          <Typography color={colors.primary} fontWeight={700} fontSize={24}>
            Διαχείριση Ιστορικού Ενοικίασης
          </Typography>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          gap={2}
          sx={{
            height: "100%",
          }}
          mt={4}
        >
          <Box
            mb={2}
            display="flex"
            gap={2}
            justifyContent="space-between"
            alignItems={{
              xs: "flex-start",
              sm: "center",
            }}
            flexWrap={"wrap"}
          >
            <Box
              display="flex"
              gap={2}
              alignItems="center"
              sx={{
                width: {
                  xs: "100%",
                  sm: "auto",
                },
              }}
            >
              <ReturnButton />
            </Box>
            <Box
              display="flex"
              gap={2}
              sx={{
                width: {
                  xs: "100%",
                  sm: "auto",
                },
              }}
            >
              <EditButton onClick={handleEditClick} disabled={isEditing} />
            </Box>
          </Box>
          <Box mt={1} flexDirection="column" justifyContent="space-between">
            <RentHistoryViewEditForm
              selectedRentHistory={selectedRentHistory}
              isEditing={isEditing}
              onSave={handleSaveClick}
              onCancel={handleCancelClick}
              onDelete={handleDeleteClick}
            />
          </Box>
        </Box>
      </>
    )
  );
};

export default RentHistoryViewEdit;
