import {
  GoogleMap,
  InfoWindowF,
  OverlayView,
  OverlayViewF,
  MarkerClusterer,
  MarkerF,
} from "@react-google-maps/api";
import { cloneElement, useCallback, useEffect, useState } from "react";
import useMapLoader from "hooks/useMapLoader";
import Box from "@mui/material/Box";
import CustomButton from "components/buttons/CustomButton";
import PropTypes from "prop-types";

const defaultMapCenter = {
  lat: 40.73061,
  lng: -73.935242,
};

const CUSTOM_STYLES = [
  {
    featureType: "poi",
    stylers: [{ visibility: "off" }],
  },
  {
    featureType: "transit",
    stylers: [{ visibility: "off" }],
  },
  {
    featureType: "landscape",
    elementType: "geometry",
    stylers: [{ visibility: "on" }],
  },
  {
    featureType: "administrative",
    stylers: [{ visibility: "on" }],
  },
];

const singleMarkerZoom = 16;

const GenericMap = ({
  markers = [],
  center,
  className,
  containerStyle = {
    width: "100%",
    height: "100%",
  },
  infoWindowRenderer,
  initialZoom = 10,
  selectedMarker,
  setSelectedMarker,
  enableClustering = false,
}) => {
  const mapCenter = center || defaultMapCenter;
  const { isLoaded, loadError } = useMapLoader();
  const [map, setMap] = useState();

  const markerClustererOptions = {
    imagePath:
      "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
    maxZoom: 15,
  };

  const onMapClick = useCallback(() => {
    setSelectedMarker(null);
  }, [setSelectedMarker]);

  const onResetBtnClick = () => {
    setSelectedMarker(null);
    onLoadMap(map);
  };

  const renderMarkers = (items, clusterer) => {
    return items.map((item, i) => {
      return item.icon ? (
        <MarkerF
          key={item.lat + item.lng + i + item.name}
          position={{ lat: item.lat, lng: item.lng }}
          onClick={() => setSelectedMarker(item)}
          icon={item.icon}
          clusterer={clusterer}
        />
      ) : (
        <OverlayViewF
          key={item.lat + item.lng + i + item.name}
          mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
          position={{ lat: item.lat, lng: item.lng }}
          getPixelPositionOffset={(width, height) => ({
            x: -(width / 2),
            y: -height / 2,
          })}
        >
          {item.markerRenderer &&
            cloneElement(item?.markerRenderer, {
              onClick: (event) => {
                event.stopPropagation();
                item?.onMarkerClick();
              },
            })}
        </OverlayViewF>
      );
    });
  };

  const onLoadMap = useCallback(
    (mapInstance) => {
      mapInstance.setOptions({
        draggableCursor: "pointer",
        styles: CUSTOM_STYLES,
        mapTypeControlOptions: {
          mapTypeIds: [],
        },
      });

      setMap(mapInstance);

      const bounds = new window.google.maps.LatLngBounds();
      if (markers && markers?.length > 0) {
        markers?.forEach((marker) => {
          bounds.extend({
            lat: +marker?.lat,
            lng: +marker?.lng,
          });
        });
        mapInstance.fitBounds(bounds);
        if (markers.length === 1) {
          mapInstance.setZoom(singleMarkerZoom);
        }
      }
    },
    [markers]
  );

  const onUnmount = useCallback(() => {
    setMap(null);
  }, []);

  useEffect(() => {
    if (map) {
      const bounds = new window.google.maps.LatLngBounds();
      markers.forEach((marker) => {
        bounds.extend({
          lat: marker.lat,
          lng: marker.lng,
        });
      });
      markers.length > 0 ? map.fitBounds(bounds) : map.setCenter(mapCenter);
    }
  }, [map, markers, mapCenter]);

  if (map && selectedMarker) {
    map.setZoom(Math.max(map.getZoom(), singleMarkerZoom));
    map.panTo({
      lat: selectedMarker.lat,
      lng: selectedMarker.lng,
    });
  }

  if (loadError) {
    return (
      <span>
        Παρουσιάστηκε σφάλμα κατά τη φόρτωση του χάρτη. Παρακαλώ δοκιμάστε ξανά.
      </span>
    );
  }

  if (!isLoaded) {
    return null;
  }

  return (
    <>
      {markers?.length > 0 && (
        <section className={`${className} relative`}>
          <Box className="absolute top-0 left-0 z-10 h-8 mt-2 ml-2 border-0 shadow rounded-3xl">
            <CustomButton
              title="Επαναφορά"
              fontSize={14}
              fontWeight={600}
              sx={{
                minWidth: 140,
                height: "100%",
                p: 0,
                borderRadius: 2,
              }}
              onClick={onResetBtnClick}
            />
          </Box>
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={mapCenter}
            zoom={initialZoom}
            mapTypeId="terrain"
            onLoad={onLoadMap}
            onUnmount={onUnmount}
            onClick={onMapClick}
          >
            {enableClustering && !selectedMarker ? (
              <MarkerClusterer options={markerClustererOptions}>
                {(clusterer) => renderMarkers(markers, clusterer)}
              </MarkerClusterer>
            ) : (
              renderMarkers(markers)
            )}

            {selectedMarker && (
              <InfoWindowF
                position={{
                  lat: selectedMarker.lat,
                  lng: selectedMarker.lng,
                }}
                onCloseClick={() => setSelectedMarker(null)}
              >
                {infoWindowRenderer(selectedMarker)}
              </InfoWindowF>
            )}
          </GoogleMap>
        </section>
      )}
    </>
  );
};

GenericMap.propTypes = {
  markers: PropTypes.array,
  center: PropTypes.object,
  className: PropTypes.string,
  containerStyle: PropTypes.object,
  infoWindowRenderer: PropTypes.func,
  initialZoom: PropTypes.number,
  selectedMarker: PropTypes.object,
  setSelectedMarker: PropTypes.func,
  enableClustering: PropTypes.bool,
};

export default GenericMap;
