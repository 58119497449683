import { Button, Typography } from "@mui/material";
import colors from "config/theme/colors";
import PropTypes from "prop-types";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";

const EditButton = ({ onClick, text = "Επεξεργασία", disabled = false }) => {
  return (
    <Button
      onClick={onClick}
      endIcon={<BorderColorOutlinedIcon />}
      disabled={disabled}
      sx={{
        backgroundColor: colors.white,
        color: colors.primary,
        border: `1px solid ${colors.primary}`,
        borderColor: disabled ? colors.grey : colors.primary,
        borderRadius: "0",
        padding: "8px 14px",
        width: "100%",
        "&:hover": {
          backgroundColor: colors.primary,
          color: colors.white,
        },
        cursor: "pointer",
      }}
    >
      <Typography fontSize={12} fontWeight={400}>
        {text}
      </Typography>
    </Button>
  );
};

EditButton.propTypes = {
  onClick: PropTypes.func,
  text: PropTypes.string,
};

export default EditButton;
