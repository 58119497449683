import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import CancelButton from "components/buttons/CancelButton";
import SaveButton from "components/buttons/SaveButton";
import PropTypes from "prop-types";
import colors from "config/theme/colors";
import { ReactComponent as CloseIcon } from "assets/icons/close.svg";
import Tooltip from "@mui/material/Tooltip";
import { ReactComponent as FiltersIcon } from "assets/icons/filters.svg";
import CheckIcon from "@mui/icons-material/Check";

const FilterOptionsModal = ({
  isOpen,
  onCloseModal,
  filterGroups,
  onChangeFilter,
  onSelectAll,
  onDeselectAll,
  onApplyFilters,
}) => {
  return (
    <Modal
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      open={isOpen}
      onClose={onCloseModal}
    >
      <Box
        sx={{
          backgroundColor: colors.white,
          borderRadius: "12px",
          width: { xs: "90%", sm: "80%", md: "60%" },
          maxHeight: "90vh",
        }}
        boxShadow={"0px 4px 4px #2948981A"}
        gap={2}
      >
        <Box
          sx={{
            backgroundColor: colors.lightGreyBg,
          }}
          padding={2}
          display="flex"
          borderRadius={"12px 12px 0 0"}
        >
          <FiltersIcon
            fill={colors.grey}
            stroke={colors.grey}
            width={24}
            height={24}
          />

          <Box
            sx={{
              marginLeft: 1.5,
            }}
          >
            <Typography
              color={colors.filterTitle}
              fontSize="16px"
              fontWeight={600}
            >
              Φίλτρα
            </Typography>
          </Box>

          <Box display="flex" flexGrow={1} justifyContent="flex-end">
            <Tooltip onClick={onCloseModal} style={{ cursor: "pointer" }}>
              <CloseIcon stroke={colors.filterCloseIcon} />
            </Tooltip>
          </Box>
        </Box>

        <Box
          sx={{
            padding: 2,
            marginTop: 1.5,
            overflowY: "auto",
            maxHeight: "70vh",
          }}
        >
          {filterGroups?.map((group, groupIndex) => (
            <Box
              key={group.title}
              sx={{
                position: "relative",
                border: `1px solid ${colors.filterGroupsParentBox}`,
                borderRadius: "8px",
                marginTop: groupIndex > 0 ? "3em" : "0",
                padding: 2,
              }}
            >
              <Box
                sx={{
                  position: "absolute",
                  top: "-1em",
                  backgroundColor: colors.white,
                  paddingX: 1,
                  zIndex: 1,
                }}
              >
                <Typography
                  fontWeight={700}
                  fontSize="20px"
                  color={colors.primary}
                >
                  {group.title}
                </Typography>
              </Box>

              <Box
                gap={1}
                display="flex"
                alignItems="center"
                justifyContent={"flex-end"}
                flexWrap={"wrap"}
              >
                <Typography
                  sx={{ cursor: "pointer", textDecoration: "underline" }}
                  color={colors.primary}
                  onClick={() => onSelectAll(groupIndex)}
                >
                  Επιλογή Όλων
                </Typography>
                <Divider
                  color={colors.primary}
                  orientation="vertical"
                  flexItem
                />
                <Typography
                  sx={{ cursor: "pointer", textDecoration: "underline" }}
                  color={colors.primary}
                  onClick={() => onDeselectAll(groupIndex)}
                >
                  Αποεπιλογή Όλων
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  gap: 2,
                  paddingTop: "1em",
                }}
              >
                {group.filters?.map((filter, filterIndex) => (
                  <Box
                    key={filter.id}
                    sx={{
                      border: `1px solid ${filter.checked ? colors.filterChecked : colors.filterCheckBoxBorder}`,
                      borderRadius: "8px",
                      padding: "0px 4px",
                      display: "flex",
                      alignItems: "center",
                      minWidth: "120px",
                    }}
                  >
                    <FormControlLabel
                      label={filter.label}
                      control={<Box />}
                      sx={{
                        ".MuiFormControlLabel-label": {
                          color: filter.checked
                            ? colors.black
                            : colors.filterModalLabel,
                          fontWeight: filter.checked ? 500 : 400,
                          fontSize: "14px",
                          margin: 0,
                          marginLeft: 2,
                        },
                      }}
                    />
                    <Checkbox
                      checked={filter.checked}
                      onChange={() => onChangeFilter(groupIndex, filterIndex)}
                      icon={
                        <Box
                          sx={{
                            width: 24,
                            height: 24,
                            borderRadius: "50%",
                            border: `1px solid ${colors.filterCheckBoxBorder}`,
                          }}
                        />
                      }
                      checkedIcon={
                        <Box
                          sx={{
                            width: 24,
                            height: 24,
                            borderRadius: "50%",
                            backgroundColor: colors.filterCheckboxCheckedIcon,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <CheckIcon
                            sx={{ color: colors.white, fontSize: 16 }}
                          />
                        </Box>
                      }
                    />
                  </Box>
                ))}
              </Box>
            </Box>
          ))}
        </Box>

        <Box
          display="flex"
          gap={2}
          justifyContent="space-between"
          alignItems="center"
          padding={2}
        >
          <CancelButton onClick={onCloseModal} />
          <SaveButton text="Εμφάνιση Αποτελεσμάτων" onClick={onApplyFilters} />
        </Box>
      </Box>
    </Modal>
  );
};

FilterOptionsModal.propTypes = {
  isOpen: PropTypes.bool,
  onCloseModal: PropTypes.func,
  filterGroups: PropTypes.array,
  onChangeFilter: PropTypes.func,
  onSelectAll: PropTypes.func,
  onDeselectAll: PropTypes.func,
  onApplyFilters: PropTypes.func,
};

export default FilterOptionsModal;
