import { Box, Typography } from "@mui/material";
import { ReactComponent as AddIcon } from "assets/icons/add.svg";
import CustomTable from "components/tables/CustomTable";
import { useNavigate, useParams } from "react-router";
import SpinningCircle from "components/spinners/SpinningCircle";
import { useMemo, useState, useCallback } from "react";
import CustomButton from "components/buttons/CustomButton";
import colors from "config/theme/colors";
import { useGetAllCertifications } from "hooks/queries/useCertifications";
import PropTypes from "prop-types";
import FilterButton from "components/buttons/FilterButton";
import { useGetAllCertificationTypes } from "hooks/queries/useCertificationType";
import usePagination from "hooks/usePagination";
import dayjs from "dayjs";
import enums from "config/enums";
import StatusLabel from "components/labels/StatusLabel";

const CertificationOverview = ({ facilityType = null }) => {
  const { id: assetId, equipmentId } = useParams();
  const navigate = useNavigate();
  const {
    page,
    setPage,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
  } = usePagination();
  const [selectedFilters, setSelectedFilters] = useState({});
  const [selectedCertificationTypes, setSelectedCertificationTypes] = useState(
    []
  );
  const {
    data: certifications,
    isLoading,
    error,
    refetch: refetchCertifications,
  } = useGetAllCertifications({
    pageNumber: page,
    pageSize: rowsPerPage,
    assetId: facilityType === "asset" ? assetId : null,
    equipmentId: facilityType === "equipment" ? equipmentId : null,
    certificationTypeIds: selectedCertificationTypes,
  });
  const {
    data: certificationTypes,
    isLoading: isCertificationTypesLoading,
    error: certificationTypesError,
  } = useGetAllCertificationTypes({
    assetId: facilityType === "asset" ? assetId : null,
    equipmentId: facilityType === "equipment" ? equipmentId : null,
  });

  const tableHeaders = useMemo(
    () => [
      {
        id: "name",
        label: "Τίτλος",
        isSortable: false,
      },
      {
        id: "type",
        label: "Τύπος Πιστοποίησης",
        isSortable: false,
      },
      {
        id: "lastDate",
        label: "Hμ/νία Τελευταίας Πιστοποίησης",
        isSortable: false,
      },
      {
        id: "nextDate",
        label: "Ημ/νία Επόμενης Πιστοποίησης",
        isSortable: false,
      },
      {
        id: "frequency",
        label: "Συχνότητα",
        isSortable: false,
      },
      {
        id: "status",
        label: "Κατάσταση",
        isSortable: false,
      },
    ],
    []
  );

  const filterGroups = [
    {
      title: "Τύπος Πιστοποίησης",
      filters: certificationTypes?.items?.map((certificationType) => ({
        id: certificationType.id,
        label: certificationType.name,
        type: "checkbox",
      })),
    },
  ];

  const handleSubmitFilters = useCallback(
    (filters) => {
      const certificationTypes = filters["Τύπος Πιστοποίησης"] || [];
      setSelectedFilters(filters);
      setSelectedCertificationTypes(certificationTypes);
      if (filters) {
        setPage(1);
      }

      if (certificationTypes.length === 0) {
        refetchCertifications();
      }
    },
    [
      setSelectedFilters,
      setSelectedCertificationTypes,
      refetchCertifications,
      setPage,
    ]
  );

  if (isLoading || isCertificationTypesLoading) {
    return <SpinningCircle />;
  }

  if (error || certificationTypesError) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          height: "100%",
        }}
      >
        <p>Error: {error.message || certificationTypesError.message}</p>
      </Box>
    );
  }

  const certificationCount = certifications?.totalCount;
  const certificationList = certifications?.items.map((certificationΙtem) => {
    return {
      id: certificationΙtem.id,
      type: certificationΙtem.certificationType?.name || "-",
      name: certificationΙtem.name || "-",
      frequency: certificationΙtem.frequency
        ? `Κάθε ${certificationΙtem.frequency} ημέρες`
        : "-",
      nextDate: certificationΙtem.nextDate
        ? dayjs(certificationΙtem.nextDate).format("DD/MM/YYYY")
        : "-",
      lastDate: certificationΙtem.lastDate
        ? dayjs(certificationΙtem.lastDate).format("DD/MM/YYYY")
        : "-",
      status: certificationΙtem.status ? (
        <StatusLabel
          statusValue={certificationΙtem.status}
          statusText={enums.CertificationStatus[certificationΙtem.status]}
        />
      ) : (
        "-"
      ),
    };
  });

  const handleItemClick = (item) => {
    navigate(`${item.id}`);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={4}
      sx={{
        height: "100%",
      }}
    >
      <Box
        display="flex"
        flexDirection={{
          xs: "column",
          sm: "row",
        }}
        justifyContent={{
          xs: "space-between",
        }}
        flexGrow={1}
        flexWrap={{
          xs: "wrap",
          md: "nowrap",
        }}
        width={"100%"}
        gap={2}
      >
        <Box
          display="flex"
          gap={2}
          flexWrap={{
            xs: "wrap",
            sm: "nowrap",
          }}
          sx={{
            overflowX: "auto",
          }}
        />
        <CustomButton
          icon={<AddIcon fill={colors.primary} width={20} height={20} />}
          title="Προσθήκη"
          orientation="row"
          fontSize={13}
          fontWeight={700}
          sx={{
            minHeight: "33px",
            maxHeight: "33px",
            borderRadius: 3,
            paddingY: 2.3,
          }}
          onClick={() => {
            navigate("new");
          }}
        />
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        flexGrow={1}
        gap={2}
        width={"100%"}
      >
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          gap={4}
          flexWrap={"wrap"}
        >
          <Typography variant="h3" color="primary">
            Πιστοποιήσεις ({certificationCount})
          </Typography>
          <FilterButton
            filterGroups={filterGroups}
            onApplyFilters={handleSubmitFilters}
            selectedFilters={selectedFilters}
          />
        </Box>

        {certificationList.length > 0 &&
        tableHeaders &&
        certificationCount > 0 ? (
          <CustomTable
            columns={tableHeaders}
            data={certificationList}
            fadeEffect
            onItemClick={handleItemClick}
            page={page - 1}
            rowsPerPage={rowsPerPage}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            totalResults={certificationCount}
          />
        ) : (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{
              height: "100%",
            }}
          >
            <Typography variant="h4">Δεν υπάρχουν αποτελέσματα</Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

CertificationOverview.propTypes = {
  facilityType: PropTypes.oneOf(["equipment", "asset"]).isRequired,
};

export default CertificationOverview;
