import { requests } from "api/HttpClient";

const { post, get } = requests;

const authSetup = {
  getAllRoles: () => get("/AuthSetup/GetAllRoles"),
  getUserRoles: (userId) => get(`/AuthSetup/GetUserRoles?userId=${userId}`),
  getAllClaims: () => get("/AuthSetup/GetAllClaims"),
  getUserClaims: (userId) => get(`/AuthSetup/GetUserClaims?userId=${userId}`),
  AddClaimToUser: (body) => post("/AuthSetup/AddClaimToUser", body),
  RemoveClaimFromUser: (body) => post("/AuthSetup/RemoveClaimFromUser", body),
  AddRoleToUser: (body) => post("/AuthSetup/AddRoleToUser", body),
  RemoveRoleFromUser: (body) => post("/AuthSetup/RemoveRoleFromUser", body),
};

export default authSetup;
