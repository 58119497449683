import { Box, Button, Popover, Typography } from "@mui/material";
import useNotifications from "hooks/useNotifications";
import React from "react";
import { useNavigate } from "react-router";
import PropTypes from "prop-types";
import colors from "config/theme/colors";
import { useAuthStore } from "stores/AuthStore";

const NotificationsPopover = ({ anchorEl, open, onClose }) => {
  const navigate = useNavigate();
  const authStore = useAuthStore();
  const {
    counts: { newTasks: newTasksCount, newTickets: newTicketsCount },
  } = useNotifications();

  const handleClose = () => {
    onClose();
  };

  const isTechnicalOptionEnabled =
    authStore.isTechnicalIssues || authStore.isSuperAdmin || authStore.isAdmin;

  const isTaskOptionEnabled =
    authStore.isUser || authStore.isSuperAdmin || authStore.isAdmin;

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      sx={{
        "& .MuiPopover-paper": {
          borderRadius: 4,
          marginTop: 2,
        },
      }}
    >
      <Box
        p={2}
        gap={2}
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
        width={200}
      >
        {newTasksCount === 0 && newTicketsCount === 0 && (
          <Typography color={colors.text} fontSize={16}>
            Δεν υπάρχουν νέες ειδοποιήσεις
          </Typography>
        )}
        {isTaskOptionEnabled && newTasksCount > 0 && (
          <Button
            fullWidth
            onClick={() => {
              onClose();
              navigate("/tasks");
            }}
            sx={{
              "&:hover": {
                backgroundColor: colors.lightGrey,
              },
            }}
          >
            Νέες εργασίες: {newTasksCount}
          </Button>
        )}
        {isTechnicalOptionEnabled && newTicketsCount > 0 && (
          <Button
            fullWidth
            onClick={() => {
              onClose();
              const navUrl = isTechnicalOptionEnabled
                ? "/technical-issues"
                : "/tickets";
              navigate(navUrl);
            }}
            sx={{
              "&:hover": {
                backgroundColor: colors.lightGrey,
              },
            }}
          >
            Νέα τεχνικά αιτήματα: {newTicketsCount}
          </Button>
        )}
      </Box>
    </Popover>
  );
};

NotificationsPopover.propTypes = {
  anchorEl: PropTypes.any,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default NotificationsPopover;
