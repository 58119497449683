import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import { ReactComponent as PdfIcon } from "assets/icons/pdf.svg";
import { ReactComponent as ExcelIcon } from "assets/icons/excel.svg";
import { ReactComponent as CsvIcon } from "assets/icons/csv.svg";
import PropTypes from "prop-types";
import SpinningCircle from "components/spinners/SpinningCircle";

const buttonConfigMap = {
  pdf: { title: "Εξαγωγή σε PDF", icon: <PdfIcon width={32} height={32} /> },
  excel: {
    title: "Εξαγωγή σε Excel",
    icon: <ExcelIcon width={32} height={32} />,
  },
  csv: { title: "Εξαγωγή σε CSV", icon: <CsvIcon width={32} height={32} /> },
};

const ExportButtons = ({
  exportButtons,
  onPdfExport,
  onExcelExport,
  onCsvExport,
  isLoading,
}) => {
  const buttonClickHandlers = {
    pdf: onPdfExport,
    excel: onExcelExport,
    csv: onCsvExport,
  };

  if (isLoading) return <SpinningCircle />;

  return (
    <Box
      sx={{
        display: "flex",
        gap: 3,
      }}
    >
      {exportButtons?.map((buttonKey) => {
        const config = buttonConfigMap[buttonKey];
        if (!config) return null;

        return (
          <Tooltip key={buttonKey} title={config?.title}>
            <IconButton onClick={buttonClickHandlers[buttonKey]}>
              {config?.icon}
            </IconButton>
          </Tooltip>
        );
      })}
    </Box>
  );
};

ExportButtons.propTypes = {
  exportButtons: PropTypes.arrayOf(PropTypes.oneOf(["pdf", "excel", "csv"])),
  onPdfExport: PropTypes.func,
  onExcelExport: PropTypes.func,
  onCsvExport: PropTypes.func,
  isLoading: PropTypes.bool,
};

export default ExportButtons;
