import { Box, Typography } from "@mui/material";
import CustomButton from "components/buttons/CustomButton";
import SearchBar from "components/inputs/SearchBar";
import SpinningCircle from "components/spinners/SpinningCircle";
import CustomTable from "components/tables/CustomTable";
import { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router";
import { ReactComponent as AddIcon } from "assets/icons/add.svg";
import colors from "config/theme/colors";
import MainPageLayout from "components/main/MainPageLayout";
import { useGetAllUsers, useGetUserRolesCount } from "hooks/queries/useUsers";
import { useGetAllRoles } from "hooks/queries/useAuthSetup";
import { ReactComponent as UserIcon } from "assets/icons/user.svg";
import { ReactComponent as PeopleIcon } from "assets/icons/people.svg";
import TagLabel from "components/labels/TagLabel";
import CountCard from "components/cards/CountCard";
import useSearchInput from "hooks/useSearchInput";
import FilterButton from "components/buttons/FilterButton";
import enums from "config/enums";
import useSorting from "hooks/useSorting";
import usePagination from "hooks/usePagination";

const colorPalette = ["#444444", "#697CFC", "#5F55CE", "#FFC700", "#FF6C00"];

const UsersOverview = () => {
  const navigate = useNavigate();
  const {
    page,
    setPage,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
  } = usePagination();
  const [selectedFilters, setSelectedFilters] = useState({});
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [selectedStatuses, setSelectedStatuses] = useState([]);
  const { orderDirection, valueToOrderBy, handleSortChange } = useSorting();
  const { searchInput, handleSearch } = useSearchInput();
  const {
    data: availableRoles,
    isLoading: isLoadingRoles,
    error: errorRoles,
  } = useGetAllRoles({
    select: (data) => data?.result,
  });
  const {
    data: userRolesCount,
    isLoading: isLoadingUserRolesCount,
    isError: isErrorUserRolesCount,
    error: errorUserRolesCount,
  } = useGetUserRolesCount();
  const {
    data: users,
    isLoading,
    error,
    refetch: refetchUsers,
  } = useGetAllUsers({
    pageNumber: page,
    pageSize: rowsPerPage,
    searchParameter: searchInput,
    role: selectedRoles,
    status: selectedStatuses,
    orderBy: valueToOrderBy,
    sortingOrder: orderDirection === "asc" ? "Ascending" : "Descending",
  });

  const filterGroups = [
    {
      title: "Ρόλος Χρήστη",
      filters: availableRoles?.map((role) => ({
        id: role.name,
        label: role.name,
        type: "checkbox",
      })),
    },
    {
      title: "Κατάσταση Χρήστη",
      filters: enums.UserStatuses?.map((status) => ({
        id: status.id,
        label: status.label,
        type: "checkbox",
      })),
    },
  ];

  const handleSubmitFilters = useCallback(
    (filters) => {
      const userRoles = filters["Ρόλος Χρήστη"] || [];
      const userStatuses = filters["Κατάσταση Χρήστη"] || [];
      setSelectedFilters(filters);
      setSelectedRoles(userRoles);
      setSelectedStatuses(userStatuses);
      if (filters) {
        setPage(1);
      }

      if (userRoles.length === 0 && userStatuses.length === 0) {
        refetchUsers();
      }
    },
    [
      setSelectedFilters,
      setSelectedRoles,
      setSelectedStatuses,
      refetchUsers,
      setPage,
    ]
  );

  const tableHeaders = useMemo(
    () => [
      {
        id: "icon",
        label: "",
        isSortable: false,
      },
      {
        id: "name",
        label: "Όνομα",
        isSortable: true,
      },
      {
        id: "surname",
        label: "Επώνυμο",
        isSortable: true,
      },
      {
        id: "phoneNumber",
        label: "Τηλέφωνο",
        isSortable: false,
      },
      {
        id: "email",
        label: "Email",
        isSortable: false,
      },
      {
        id: "role",
        label: "Ρόλος",
        isSortable: false,
      },
      {
        id: "status",
        label: "Κατάσταση",
        isSortable: false,
      },
    ],
    []
  );

  if (isLoading || isLoadingRoles || isLoadingUserRolesCount) {
    return <SpinningCircle />;
  }

  if (error || errorRoles || isErrorUserRolesCount) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          height: "100%",
        }}
      >
        <p>Error: {error.message || errorUserRolesCount.message}</p>
      </Box>
    );
  }

  const usersCount = users?.totalCount;
  const userList = users?.items?.map((user) => ({
    id: user.id,
    icon: (
      <UserIcon
        fill={colors.white}
        stroke={colors.text}
        width={30}
        height={30}
      />
    ),
    name: user.name,
    surname: user.surname,
    phoneNumber: user.phoneNumber || "-",
    email: user.email,
    role: availableRoles?.find((role) => role.name === user.role)?.name,
    status: (
      <Box
        sx={{
          backgroundColor:
            user.status === "Active" ? colors.successBg : colors.errorBg,
          borderRadius: 2,
          padding: 1,
          textAlign: "center",
        }}
      >
        <Typography
          color={user.status === "Active" ? colors.success : colors.error}
          fontWeight={500}
          fontSize={14}
        >
          {user.status === "Active" ? "Ενεργός" : "Ανενεργός"}
        </Typography>
      </Box>
    ),
  }));

  const handleItemClick = (item) => {
    navigate(`${item.id}`);
  };

  return (
    <MainPageLayout pageTitle="Χρήστες">
      <Box
        display="flex"
        flexDirection={{
          xs: "column",
          sm: "row",
        }}
        justifyContent={{
          xs: "space-between",
        }}
        flexGrow={1}
        flexWrap={{
          xs: "wrap",
          md: "nowrap",
        }}
        width={"100%"}
        gap={2}
      >
        <Box
          display="flex"
          gap={2}
          flexWrap={{
            xs: "wrap",
            sm: "nowrap",
          }}
          sx={{
            overflowX: "auto",
          }}
        >
          {availableRoles?.map((userRole) => {
            const categoryData = userRolesCount?.find(
              (item) => item.name === userRole.name
            );
            const count = categoryData?.count || 0;
            return (
              <CountCard
                key={userRole.id}
                title={userRole.name}
                backgroundColor={
                  colorPalette[availableRoles?.indexOf(userRole)]
                }
                icon={
                  <PeopleIcon
                    width={30}
                    height={30}
                    fill="none"
                    stroke={colors.white}
                  />
                }
                count={count}
                boxSx={{
                  minWidth: { xs: "100%", sm: "250px" },
                }}
              />
            );
          })}
        </Box>
        <CustomButton
          icon={<AddIcon fill={colors.primary} width={30} height={30} />}
          title="Προσθήκη"
          sx={{
            minWidth: 120,
          }}
          onClick={() => {
            navigate("new");
          }}
        />
      </Box>
      <Box
        mt={4}
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent={{
          xs: "space-between",
          lg: "flex-start",
        }}
        flexGrow={1}
        gap={2}
        height={40}
      >
        <SearchBar
          placeholder="Αναζήτηση σε Όνομα, Τηλέφωνο, Email, Ρόλο..."
          handleSearch={handleSearch}
          searchKey={searchInput}
        />
      </Box>
      <Box
        mt={4}
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        flexGrow={1}
        width={"100%"}
      >
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="start"
          gap={4}
          flexWrap={"wrap"}
          alignItems={"center"}
        >
          <Typography variant="h3" color="primary">
            Χρήστες ({usersCount})
          </Typography>
          {availableRoles?.length > 0 && (
            <Box display="flex" flexDirection="row" gap={1} flexWrap={"wrap"}>
              {availableRoles?.map((userRole) => {
                const count = userList?.filter(
                  (userItem) => userItem.role === userRole.name
                ).length;
                return count > 0 ? (
                  <TagLabel
                    key={userRole.id}
                    text={`${count} ${userRole.name}`}
                    color={colorPalette[availableRoles?.indexOf(userRole)]}
                  />
                ) : null;
              })}
            </Box>
          )}
          <Box display="flex" flexGrow={1} justifyContent="flex-end">
            <FilterButton
              selectedFilters={selectedFilters}
              filterGroups={filterGroups}
              onApplyFilters={handleSubmitFilters}
            />
          </Box>
        </Box>

        {userList.length > 0 && tableHeaders && usersCount > 0 ? (
          <CustomTable
            columns={tableHeaders}
            data={userList}
            fadeEffect
            onItemClick={handleItemClick}
            page={page - 1}
            rowsPerPage={rowsPerPage}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            totalResults={usersCount}
            orderDirection={orderDirection}
            valueToOrderBy={valueToOrderBy}
            onSortChange={handleSortChange}
          />
        ) : (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{
              height: "100%",
            }}
          >
            <p>Δεν υπάρχουν αποτελέσματα</p>
          </Box>
        )}
      </Box>
    </MainPageLayout>
  );
};

export default UsersOverview;
