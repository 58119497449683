import { Box, Tooltip, Typography } from "@mui/material";
import CustomTable from "components/tables/CustomTable";
import { useMemo, useState } from "react";
import CertificationTypeForm from "./CertificationTypeForm";
import colors from "config/theme/colors";
import SpinningCircle from "components/spinners/SpinningCircle";
import { useAlert } from "hooks/useAlert";
import EditCircleButton from "components/buttons/EditCircleButton";
import { useGetAllCertificationClasses } from "hooks/queries/useCertificationClass";
import {
  useCreateCertificationType,
  useDeleteCertificationType,
  useGetAllCertificationTypes,
  useGetCertificationTypeById,
  useUpdateCertificationType,
} from "hooks/queries/useCertificationType";
import usePagination from "hooks/usePagination";

const CertificationTypesOverview = () => {
  const { showError, showSuccess } = useAlert();
  const { page, rowsPerPage, handleChangePage, handleChangeRowsPerPage } =
    usePagination();
  const [selectedCertificationTypeId, setSelectedCertificationTypeId] =
    useState(null);
  const [isEditing, setIsEditing] = useState(false);

  const { data: selectedCertificationType } = useGetCertificationTypeById(
    selectedCertificationTypeId,
    { enabled: selectedCertificationTypeId !== null }
  );

  const {
    data: certificationClassOptions,
    isLoading: certificationClassesLoading,
    error: certificationClassesError,
  } = useGetAllCertificationClasses(
    {
      pageNumber: null,
      pageSize: null,
    },
    {
      select: (data) => data?.result?.items,
    }
  );

  const {
    data: certificationTypes,
    isLoading,
    error,
  } = useGetAllCertificationTypes(
    {
      pageNumber: page,
      pageSize: rowsPerPage,
    },
    { select: (data) => data?.result }
  );

  const { mutate: createCertificationType } = useCreateCertificationType({
    config: {
      onError: (error) => {
        showError(error.message);
      },
      onSuccess: (res) => {
        setSelectedCertificationTypeId(res.result?.id);
        showSuccess("Ο τύπος πιστοποίησης δημιουργήθηκε με επιτυχία");
      },
    },
  });
  const { mutate: updateCertificationType } = useUpdateCertificationType({
    id: selectedCertificationTypeId,
    config: {
      onError: (error) => {
        showError(error.message);
      },
      onSuccess: () => {
        setIsEditing(false);
        showSuccess("Ο τύπος πιστοποίησης ενημερώθηκε με επιτυχία");
      },
    },
  });
  const { mutate: deleteCertificationType } = useDeleteCertificationType({
    id: selectedCertificationTypeId,
    config: {
      onError: (error) => {
        if (
          error.message?.includes(
            "Δεν μπορείτε να διαγράψετε αυτό το αντικείμενο!"
          )
        ) {
          showError(
            "Δεν μπορείτε να διαγράψετε αυτό τον τύπο πιστοποίησης, καθώς χρησιμοποιείται σε κάποια πιστοποίηση",
            6000
          );
          return;
        }
        showError(error.message);
      },
      onSuccess: () => {
        setSelectedCertificationTypeId(null);
        setIsEditing(false);
        showSuccess("Ο τύπος πιστοποίησης διαγράφηκε με επιτυχία");
      },
    },
  });

  const tableHeaders = useMemo(
    () => [
      {
        id: "name",
        label: "Όνομα",
        align: "left",
        minWidth: 200,
      },
      {
        id: "type",
        label: "Τύπος",
        align: "left",
        minWidth: 200,
      },
      {
        id: "certificationClass",
        label: "Kλάση Πιστοποίησης",
        align: "left",
        minWidth: 200,
      },
      {
        id: "button",
        label: "",
        align: "right",
      },
    ],
    []
  );

  if (isLoading || certificationClassesLoading) {
    return <SpinningCircle />;
  }

  if (error || certificationClassesError) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          height: "100%",
        }}
      >
        <p>Error: {error.message}</p>
      </Box>
    );
  }

  const certificationTypeList = certificationTypes?.items?.map((item) => ({
    ...item,
    certificationClass:
      certificationClassOptions.find(
        (option) => option.id === item.certificationClassId
      ).name || "",
    type: item.type === "Asset" ? "Ακίνητο" : "Εξοπλισμός",
    button: (
      <Box display="flex" justifyContent="flex-end">
        <Tooltip title="Επεξεργασία" placement="left">
          <span>
            <EditCircleButton
              onClick={(e) => {
                setIsEditing(true);
                setSelectedCertificationTypeId(item.id);
                e.stopPropagation();
              }}
            />
          </span>
        </Tooltip>
      </Box>
    ),
  }));

  const count = certificationTypes?.totalCount;

  const handleItemClick = (item) => {
    setIsEditing(false);
    if (item && item.id === selectedCertificationTypeId) {
      setSelectedCertificationTypeId(null);
      return;
    }
    setSelectedCertificationTypeId(item.id);
  };

  const handleCreateClick = (data) => {
    const payload = {
      name: data.name,
      description: data.description,
      type: data.type?.id,
      certificationClassId: data.certificationClassId?.id,
    };
    createCertificationType(payload);
  };

  const handleUpdateClick = (data) => {
    const payload = {
      name: data.name,
      description: data.description,
      type: data.type?.id,
      certificationClassId: data.certificationClassId?.id,
    };

    updateCertificationType(payload);
  };

  const handleDeleteClick = () => {
    deleteCertificationType();
  };

  return (
    <Box
      display="flex"
      gap={2}
      justifyContent="space-between"
      width={"100%"}
      flexDirection={{
        xs: "column",
        sm: "row",
      }}
    >
      <Box
        mt={1}
        display="flex"
        flexDirection="column"
        justifyContent="flex-start"
        width={{
          xs: "100%",
          sm: "60%",
        }}
      >
        <Typography variant="h3" color={colors.primary}>
          {count > 0
            ? `Τύποι Πιστοποίησης (${count})`
            : "Δεν υπάρχουν τύποι πιστοποίησης"}
        </Typography>

        {count > 0 && (
          <CustomTable
            columns={tableHeaders}
            data={certificationTypeList}
            fadeEffect
            onItemClick={handleItemClick}
            page={page - 1}
            rowsPerPage={rowsPerPage}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            totalResults={count}
          />
        )}
      </Box>

      <Box
        borderLeft={{
          xs: "none",
          sm: `2px solid ${colors.lightGrey}`,
        }}
        borderTop={{
          xs: `2px solid ${colors.lightGrey}`,
          sm: "none",
        }}
        sx={{
          borderRadius: 6,
          opacity: "0.2",
        }}
      />

      <Box
        width={{
          xs: "100%",
          sm: "38%",
        }}
      >
        {selectedCertificationTypeId === null ? (
          <CertificationTypeForm
            onSave={handleCreateClick}
            onCancel={() => setSelectedCertificationTypeId(null)}
            isCreateForm
          />
        ) : (
          <CertificationTypeForm
            selectedCertificationType={selectedCertificationType}
            onSave={handleUpdateClick}
            onCancel={() => setSelectedCertificationTypeId(null)}
            onDelete={handleDeleteClick}
            isEditing={isEditing}
          />
        )}
      </Box>
    </Box>
  );
};

export default CertificationTypesOverview;
