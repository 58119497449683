import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import CustomTable from "components/tables/CustomTable";
import { useMemo, useState } from "react";
import MaintenanceTypeForm from "./MaintenanceTypeForm";
import colors from "config/theme/colors";
import {
  useCreateMaintenanceType,
  useDeleteMaintenanceType,
  useGetAllMaintenanceTypes,
  useGetMaintenanceTypeById,
  useUpdateMaintenanceType,
} from "hooks/queries/useMaintenanceType";
import SpinningCircle from "components/spinners/SpinningCircle";
import { useAlert } from "hooks/useAlert";
import EditCircleButton from "components/buttons/EditCircleButton";
import usePagination from "hooks/usePagination";

const MaintenanceTypesOverview = () => {
  const { showError, showSuccess } = useAlert();
  const { page, rowsPerPage, handleChangePage, handleChangeRowsPerPage } =
    usePagination();
  const [selectedMaintenanceTypeId, setSelectedMaintenanceTypeId] =
    useState(null);
  const [isEditing, setIsEditing] = useState(false);

  const { data: selectedMaintenanceType } = useGetMaintenanceTypeById(
    selectedMaintenanceTypeId,
    { enabled: selectedMaintenanceTypeId !== null }
  );

  const {
    data: maintenanceTypes,
    isLoading,
    error,
  } = useGetAllMaintenanceTypes({
    pageNumber: page,
    pageSize: rowsPerPage,
  });

  const { mutate: createMaintenanceType } = useCreateMaintenanceType({
    config: {
      onError: (error) => {
        showError(error.message);
      },
      onSuccess: (res) => {
        setSelectedMaintenanceTypeId(res.result?.id);
        showSuccess("Ο τύπος συντήρησης δημιουργήθηκε με επιτυχία");
      },
    },
  });
  const { mutate: updateMaintenanceType } = useUpdateMaintenanceType({
    id: selectedMaintenanceTypeId,
    config: {
      onError: (error) => {
        showError(error.message);
      },
      onSuccess: () => {
        setIsEditing(false);
        showSuccess("Ο τύπος συντήρησης ενημερώθηκε με επιτυχία");
      },
    },
  });
  const { mutate: deleteMaintenanceType } = useDeleteMaintenanceType({
    id: selectedMaintenanceTypeId,
    config: {
      onError: (error) => {
        if (
          error.message?.includes(
            "Δεν μπορείτε να διαγράψετε αυτό το αντικείμενο!"
          )
        ) {
          showError(
            "Δεν μπορείτε να διαγράψετε αυτόν τον τύπο συντήρησης, καθώς χρησιμοποιείται σε κάποια συντήρηση",
            6000
          );
          return;
        }
        showError(error.message);
      },
      onSuccess: () => {
        setSelectedMaintenanceTypeId(null);
        setIsEditing(false);
        showSuccess("Ο τύπος συντήρησης διαγράφηκε με επιτυχία");
      },
    },
  });

  const tableHeaders = useMemo(
    () => [
      {
        id: "name",
        label: "Όνομα",
        align: "left",
        minWidth: 200,
      },
      {
        id: "type",
        label: "Τύπος",
        align: "left",
        minWidth: 200,
      },
      {
        id: "button",
        label: "",
        align: "right",
      },
    ],
    []
  );

  if (isLoading) {
    return <SpinningCircle />;
  }

  if (error) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          height: "100%",
        }}
      >
        <p>Error: {error.message}</p>
      </Box>
    );
  }

  const maintenanceTypeList = maintenanceTypes?.items?.map((item) => ({
    ...item,
    type: item.type === "Asset" ? "Ακίνητο" : "Εξοπλισμός",
    button: (
      <Box display="flex" justifyContent="flex-end">
        <Tooltip title="Επεξεργασία" placement="left">
          <span>
            <EditCircleButton
              onClick={(e) => {
                setIsEditing(true);
                setSelectedMaintenanceTypeId(item.id);
                e.stopPropagation();
              }}
            />
          </span>
        </Tooltip>
      </Box>
    ),
  }));

  const count = maintenanceTypes?.totalCount;

  const handleItemClick = (item) => {
    setIsEditing(false);
    if (item && item.id === selectedMaintenanceTypeId) {
      setSelectedMaintenanceTypeId(null);
      return;
    }
    setSelectedMaintenanceTypeId(item.id);
  };

  const handleCreateClick = (data) => {
    const payload = {
      name: data.name,
      description: data.description,
      type: data.type.id,
      typeCategoriesIds: data.typeCategoriesIds?.map((category) => category.id),
    };
    createMaintenanceType(payload);
  };

  const handleUpdateClick = (data) => {
    const payload = {
      name: data.name,
      description: data.description,
      type: data.type.id,
      typeCategoriesIds: data.typeCategoriesIds?.map((category) => category.id),
    };
    updateMaintenanceType(payload);
  };

  const handleDeleteClick = () => {
    deleteMaintenanceType();
  };

  return (
    <Box
      display="flex"
      gap={2}
      justifyContent="space-between"
      width={"100%"}
      flexDirection={{
        xs: "column",
        sm: "row",
      }}
    >
      <Box
        mt={1}
        display="flex"
        flexDirection="column"
        justifyContent="flex-start"
        width={{
          xs: "100%",
          sm: "60%",
        }}
      >
        <Typography variant="h3" color={colors.primary}>
          {count > 0
            ? `Τύποι συντήρησης (${count})`
            : "Δεν υπάρχουν τύποι συντήρησης"}
        </Typography>

        {count > 0 && (
          <CustomTable
            columns={tableHeaders}
            data={maintenanceTypeList}
            fadeEffect
            onItemClick={handleItemClick}
            page={page - 1}
            rowsPerPage={rowsPerPage}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            totalResults={count}
          />
        )}
      </Box>

      <Box
        borderLeft={{
          xs: "none",
          sm: `2px solid ${colors.lightGrey}`,
        }}
        borderTop={{
          xs: `2px solid ${colors.lightGrey}`,
          sm: "none",
        }}
        sx={{
          borderRadius: 6,
          opacity: "0.2",
        }}
      />

      <Box
        width={{
          xs: "100%",
          sm: "38%",
        }}
      >
        {selectedMaintenanceTypeId === null ? (
          <MaintenanceTypeForm
            onSave={handleCreateClick}
            onCancel={() => setSelectedMaintenanceTypeId(null)}
            isCreateForm
          />
        ) : (
          <MaintenanceTypeForm
            selectedMaintenanceType={selectedMaintenanceType}
            onSave={handleUpdateClick}
            onCancel={() => setSelectedMaintenanceTypeId(null)}
            onDelete={handleDeleteClick}
            isEditing={isEditing}
          />
        )}
      </Box>
    </Box>
  );
};

export default MaintenanceTypesOverview;
