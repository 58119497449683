import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Grid, Typography } from "@mui/material";
import { useIsMutating } from "@tanstack/react-query";
import CancelButton from "components/buttons/CancelButton";
import DeleteButton from "components/buttons/DeleteButton";
import SaveButton from "components/buttons/SaveButton";
import CustomTextField from "components/inputs/CustomTextField";
import DateField from "components/inputs/DateField";
import DropdownField from "components/inputs/DropdownField";
import DeleteModal from "components/modals/DeleteModal";
import enums from "config/enums";
import companyResponseFields from "config/forms/companyResponseFields";
import PropTypes from "prop-types";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useAuthStore } from "stores/AuthStore";
import * as yup from "yup";

const CompanyViewEditForm = ({
  selectedCompany,
  isEditing = false,
  onSave,
  onCancel,
  onDelete,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const isUpdateLoading = useIsMutating({ mutationKey: ["updateCompany"] }) > 0;
  const isDeleteLoading = useIsMutating({ mutationKey: ["deleteCompany"] }) > 0;
  const CompanyTypes = enums.CompanyTypes;
  const authStore = useAuthStore();

  const schema = yup.object(
    companyResponseFields.reduce((acc, field) => {
      let fieldSchema;

      if (
        field.type === "text" ||
        field.type === "textarea" ||
        field.type === "file"
      ) {
        fieldSchema = yup.string();
      } else if (field.type === "select" || field.type === "boolean") {
        fieldSchema = yup.string();
      } else if (field.type === "number") {
        fieldSchema = yup
          .number()
          .transform((value) => (isNaN(value) ? undefined : value));
      } else if (field.type === "date") {
        fieldSchema = yup.date().transform((value, originalValue) => {
          return isNaN(Date.parse(originalValue)) ? undefined : value;
        });
      }

      if (field.required) {
        fieldSchema = fieldSchema.required("Το πεδίο είναι υποχρεωτικό");
      } else {
        fieldSchema = fieldSchema.nullable();
      }

      if (field.validation === "custom" && field.regex) {
        fieldSchema = fieldSchema.matches(field.regex, field.validationMessage);
      }

      acc[field.name] = fieldSchema.typeError(
        field.validationMessage || "Μη έγκυρη τιμή"
      );

      return acc;
    }, {})
  );

  const getDataValueFromField = (fieldName) => {
    const fieldMap = companyResponseFields.reduce((acc, field) => {
      acc[field.name] = field.name;
      return acc;
    }, {});

    const path = fieldMap[fieldName];
    if (typeof path === "function") {
      return path();
    } else if (typeof path === "string") {
      const value = path
        .split(".")
        .reduce((acc, key) => (acc ? acc[key] : undefined), selectedCompany);
      return value !== undefined && value !== null ? value : null;
    } else {
      return "";
    }
  };

  const handleFormSubmit = (data) => {
    onSave(data);
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const formFields = companyResponseFields;

  const defaultValues = {
    ...Object.fromEntries(
      formFields.map((field) => [field.name, getDataValueFromField(field.name)])
    ),
    type: CompanyTypes.find((type) => type.id === getDataValueFromField("type"))
      ?.label,
  };

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isDirty },
    getValues,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  });

  const getInputElementByFieldType = (field) => {
    const defaultValue = getDataValueFromField(field.name);

    if (
      field.type === "text" ||
      field.type === "number" ||
      field.type === "textarea" ||
      field.type === "file"
    ) {
      return (
        <CustomTextField
          {...field}
          value={defaultValue}
          disabled={!isEditing}
          control={control}
        />
      );
    } else if (field.type === "select" || field.type === "boolean") {
      let availableOptions =
        field.type === "boolean" ? ["Ναι", "Όχι"] : field.options;

      if (field.name === "type") {
        availableOptions = CompanyTypes.map((option) => option.label);
      }
      return (
        <DropdownField
          {...field}
          control={control}
          value={defaultValue}
          disabled={!isEditing}
          options={availableOptions}
          boolean={field.type === "boolean"}
        />
      );
    } else if (field.type === "date") {
      return (
        <DateField
          {...field}
          value={defaultValue}
          disabled={!isEditing}
          control={control}
        />
      );
    }
  };

  return (
    <form onSubmit={handleSubmit(() => handleFormSubmit(getValues()))}>
      <Box display="flex" flexDirection="column" gap={4}>
        <Grid container spacing={2}>
          {formFields
            .filter((field) => field.display !== false)
            .map((field) => (
              <Grid item xs={12} md={6} key={field.name}>
                {getInputElementByFieldType(field)}
              </Grid>
            ))}
        </Grid>

        {Object.keys(errors).length > 0 && (
          <Typography color="error" fontSize={14}>
            Παρακαλώ ελέγξτε ότι όλα τα πεδία έχουν συμπληρωθεί σωστά
          </Typography>
        )}

        {!authStore.isUser && (
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            gap={2}
          >
            <Box display="flex" gap={2} justifyContent={"flex-start"}>
              <CancelButton
                disabled={!isEditing}
                onClick={() => {
                  reset();
                  onCancel();
                }}
              />
              <DeleteButton
                onClick={handleOpenModal}
                text="Διαγραφή Οργανισμού"
                disabled={!isEditing}
                isLoading={isDeleteLoading}
              />
            </Box>

            <SaveButton
              disabled={!isEditing || !isDirty}
              isLoading={isUpdateLoading}
            />

            <DeleteModal
              isOpen={isModalOpen}
              onClose={handleCloseModal}
              onDelete={onDelete}
              itemTitle={selectedCompany?.name}
              title="Πρόκειται να διαγράψετε τον οργανισμό"
              description="Είστε βέβαιοι ότι θέλετε να προχωρήσετε στη διαγραφή του οργανισμού;"
            />
          </Box>
        )}
      </Box>
    </form>
  );
};

CompanyViewEditForm.propTypes = {
  selectedCompany: PropTypes.object.isRequired,
  isEditing: PropTypes.bool,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  onDelete: PropTypes.func,
};

export default CompanyViewEditForm;
