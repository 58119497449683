import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Box, Grid, Typography } from "@mui/material";
import SaveButton from "components/buttons/SaveButton";
import CustomTextField from "components/inputs/CustomTextField";
import DropdownField from "components/inputs/DropdownField";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useIsMutating } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import DateField from "components/inputs/DateField";
import CancelButton from "components/buttons/CancelButton";
import { useNavigate } from "react-router";
import ticketRequestFields from "config/forms/ticketRequestFields";
import { useGetAllAssets } from "hooks/queries/useAssets";
import { useGetAllEquipment } from "hooks/queries/useEquipment";
import SpinningCircle from "components/spinners/SpinningCircle";
import AlfrescoFileUploader from "components/fileUploaders/AlfrescoFileUploader";

const TicketCreateForm = ({ onCreate }) => {
  const navigate = useNavigate();
  const [selectedAsset, setSelectedAsset] = useState(null);
  const isCreatingLoading =
    useIsMutating({ mutationKey: ["createTicket"] }) +
      useIsMutating({ mutationKey: ["uploadFiles"] }) +
      useIsMutating({ mutationKey: ["deleteMultipleFiles"] }) +
      useIsMutating({ mutationKey: ["deleteFile"] }) >
    0;

  const {
    data: assets,
    isLoading: isAssetsLoading,
    isError: isAssetsError,
    error: assetsError,
  } = useGetAllAssets();

  const {
    data: equipments,
    isLoading: isEquipmentsLoading,
    isError: isEquipmentsError,
    error: equipmentsError,
  } = useGetAllEquipment(
    {
      assetId: selectedAsset?.id,
    },
    {
      enabled: !!selectedAsset?.id,
    }
  );

  const schema = yup.object(
    ticketRequestFields.reduce((acc, field) => {
      let fieldSchema;

      if (field.type === "text" || field.type === "textarea") {
        fieldSchema = yup.string();
      } else if (field.type === "select" || field.type === "boolean") {
        fieldSchema = yup.object().shape({
          id: yup.string(),
          label: yup.string(),
        });
      } else if (field.type === "number") {
        fieldSchema = yup
          .number()
          .transform((value) => (isNaN(value) ? undefined : value));
      } else if (field.type === "date") {
        fieldSchema = yup.date().transform((value, originalValue) => {
          return isNaN(Date.parse(originalValue)) ? undefined : value;
        });
      } else if (field.type === "file") {
        const validTypes = field.validTypes || [];
        const maxSize = field.maxSize || 5000000;

        fieldSchema = yup.mixed().test({
          name: "fileValidation",
          exclusive: true,
          message: "Invalid file",
          test: (value) => {
            if (!value || value.length === 0) return true;

            for (const file of value) {
              if (
                !validTypes.includes(file.type) &&
                !validTypes.includes(file.mimeType)
              ) {
                return new yup.ValidationError(
                  "Μη έγκυρος τύπος αρχείου",
                  null,
                  "file"
                );
              }

              if (file.size > maxSize) {
                return new yup.ValidationError(
                  `Το μέγεθος του αρχείου δεν πρέπει να υπερβαίνει τα ${maxSize / 1000000}MB`,
                  null,
                  "file"
                );
              }
            }

            return true;
          },
        });
      }

      if (field.required) {
        fieldSchema = fieldSchema.required("Το πεδίο είναι υποχρεωτικό");
      } else {
        fieldSchema = fieldSchema.nullable();
      }

      if (field.validation === "custom" && field.regex) {
        fieldSchema = fieldSchema.matches(field.regex, field.validationMessage);
      }

      acc[field.name] = fieldSchema.typeError(
        field.validationMessage || "Μη έγκυρη τιμή"
      );

      return acc;
    }, {})
  );

  const defaultValues = ticketRequestFields.reduce((acc, field) => {
    acc[field.name] = field.defaultValue;
    return acc;
  }, {});

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  });

  const watchedAsset = watch("asset");

  useEffect(() => {
    if (watchedAsset) {
      setSelectedAsset(
        assets?.items?.find((asset) => asset.id === watchedAsset.id)
      );
    }
  }, [watchedAsset, assets?.items]);

  if (isAssetsLoading || isEquipmentsLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          height: "100%",
        }}
      >
        <SpinningCircle />
      </Box>
    );
  }

  if (isAssetsError || isEquipmentsError) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          height: "100%",
        }}
      >
        <p>Error: {assetsError?.message || equipmentsError?.message}</p>
      </Box>
    );
  }

  const getInputElementByFieldType = (field) => {
    if (
      field.type === "text" ||
      field.type === "number" ||
      field.type === "textarea"
    ) {
      return <CustomTextField {...field} control={control} />;
    } else if (field.type === "select" || field.type === "boolean") {
      let availableOptions =
        field.type === "boolean" ? ["Ναι", "Όχι"] : field.options;

      if (field.name === "asset") {
        availableOptions = assets?.items?.map((asset) => ({
          id: asset.id,
          label: asset.name,
        }));
      } else if (field.name === "equipment") {
        availableOptions = equipments?.items?.map((equipment) => ({
          id: equipment.id,
          label: equipment.name,
        }));
      }

      return (
        <DropdownField
          {...field}
          control={control}
          options={availableOptions}
          boolean={field.type === "boolean"}
        />
      );
    } else if (field.type === "date") {
      return <DateField {...field} control={control} />;
    } else if (field.type === "file") {
      return <AlfrescoFileUploader {...field} control={control} />;
    }
  };

  return (
    <form onSubmit={handleSubmit(onCreate)}>
      <Box display="flex" flexDirection="column" gap={2}>
        <Grid container spacing={2}>
          {ticketRequestFields
            .filter((field) => field.display !== false)
            .map((field) => {
              if (field.name === "equipment" && !watchedAsset) {
                return null;
              } else if (field.type === "file") {
                return (
                  <Grid item xs={12} key={field?.name}>
                    <Box width={{ xs: "100%", lg: "50%" }}>
                      {getInputElementByFieldType(field)}
                    </Box>
                  </Grid>
                );
              } else
                return (
                  <Grid item xs={12} md={4} key={field?.name}>
                    {getInputElementByFieldType(field)}
                  </Grid>
                );
            })}
        </Grid>

        {Object.keys(errors).length > 0 && (
          <Typography color="error" fontSize={14}>
            Παρακαλούμε ελέγξτε ότι όλα τα πεδία έχουν συμπληρωθεί σωστά
          </Typography>
        )}

        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          gap={2}
        >
          <Box display="flex" gap={2} justifyContent={"flex-start"}>
            <CancelButton
              onClick={() => {
                reset();
                navigate(-1);
              }}
            />
          </Box>
          <SaveButton
            text="Δημιουργία"
            isLoading={
              isCreatingLoading || isAssetsLoading || isEquipmentsLoading
            }
          />
        </Box>
      </Box>
    </form>
  );
};

TicketCreateForm.propTypes = {
  onCreate: PropTypes.func,
};

export default TicketCreateForm;
