import { requests } from "api/HttpClient";

const { getFile, post } = requests;

const bulkImportEquipment = {
  getBulkEquipmentTemplate: ({ equipmentTypeId = null, assetId = null }) => {
    const queryParams = {
      equipmentTypeId,
      assetId,
    };

    const queryString = Object.keys(queryParams)
      .filter((key) => queryParams[key])
      .map(
        (key) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`
      )
      .join("&");

    const url = `/BulkImportEquipment/GetBulkEquipmentTemplate?${queryString}`;

    return getFile(url);
  },
  importBulkEquipment: (body) =>
    post("/BulkImportEquipment/ImportBulkEquipment", body, {
      headers: {
        "Content-Type": "application/json",
      },
    }),
};

export default bulkImportEquipment;
