import { Box, Tooltip, Typography } from "@mui/material";
import CustomTable from "components/tables/CustomTable";
import { useMemo, useState } from "react";
import CertificationClassForm from "./CertificationClassForm";
import colors from "config/theme/colors";
import {
  useCreateCertificationClass,
  useDeleteCertificationClass,
  useGetAllCertificationClasses,
  useGetCertificationClassById,
  useUpdateCertificationClass,
} from "hooks/queries/useCertificationClass";
import SpinningCircle from "components/spinners/SpinningCircle";
import { useAlert } from "hooks/useAlert";
import EditCircleButton from "components/buttons/EditCircleButton";
import usePagination from "hooks/usePagination";

const CertificationClassesOverview = () => {
  const { showError, showSuccess } = useAlert();
  const { page, rowsPerPage, handleChangePage, handleChangeRowsPerPage } =
    usePagination();
  const [selectedCertificationClassId, setSelectedCertificationClassId] =
    useState(null);
  const [isEditing, setIsEditing] = useState(false);

  const { data: selectedCertificationClass } = useGetCertificationClassById(
    selectedCertificationClassId,
    { enabled: selectedCertificationClassId !== null }
  );

  const {
    data: certificationClasses,
    isLoading,
    error,
  } = useGetAllCertificationClasses(
    {
      pageNumber: page,
      pageSize: rowsPerPage,
    },
    { select: (data) => data?.result }
  );

  const { mutate: createCertificationClass } = useCreateCertificationClass({
    config: {
      onError: (error) => {
        showError(error.message);
      },
      onSuccess: (res) => {
        setSelectedCertificationClassId(res.result?.id);
        showSuccess("Η κλάση πιστοποίησης δημιουργήθηκε με επιτυχία");
      },
    },
  });
  const { mutate: updateCertificationClass } = useUpdateCertificationClass({
    id: selectedCertificationClassId,
    config: {
      onError: (error) => {
        showError(error.message);
      },
      onSuccess: () => {
        setIsEditing(false);
        showSuccess("Η κλάση πιστοποίησης ενημερώθηκε με επιτυχία");
      },
    },
  });
  const { mutate: deleteCertificationClass } = useDeleteCertificationClass({
    id: selectedCertificationClassId,
    config: {
      onError: (error) => {
        if (
          error.message?.includes(
            "Δεν μπορείτε να διαγράψετε αυτό το αντικείμενο!"
          )
        ) {
          showError(
            "Δεν μπορείτε να διαγράψετε αυτήν την κλάση πιστοποίησης, καθώς χρησιμοποιείται σε κάποιον τύπο πιστοποίησης",
            6000
          );
          return;
        }
        showError(error.message);
      },
      onSuccess: () => {
        setSelectedCertificationClassId(null);
        setIsEditing(false);
        showSuccess("Η κλάση πιστοποίησης διαγράφηκε με επιτυχία");
      },
    },
  });

  const tableHeaders = useMemo(
    () => [
      {
        id: "name",
        label: "Όνομα",
        align: "left",
        minWidth: 200,
      },
      {
        id: "button",
        label: "",
        align: "right",
      },
    ],
    []
  );

  if (isLoading) {
    return <SpinningCircle />;
  }

  if (error) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          height: "100%",
        }}
      >
        <p>Error: {error.message}</p>
      </Box>
    );
  }

  const certificationClassList = certificationClasses?.items.map((item) => ({
    ...item,
    button: (
      <Box display="flex" justifyContent="flex-end">
        <Tooltip title="Επεξεργασία" placement="left">
          <span>
            <EditCircleButton
              onClick={(e) => {
                setIsEditing(true);
                setSelectedCertificationClassId(item.id);
                e.stopPropagation();
              }}
            />
          </span>
        </Tooltip>
      </Box>
    ),
  }));

  const count = certificationClasses?.totalCount;

  const handleItemClick = (item) => {
    setIsEditing(false);
    if (item && item.id === selectedCertificationClassId) {
      setSelectedCertificationClassId(null);
      return;
    }
    setSelectedCertificationClassId(item.id);
  };

  const handleCreateClick = (data) => {
    const payload = {
      name: data.name,
      description: data.description,
      certificationGroupId: data.certificationGroup?.id,
    };

    createCertificationClass(payload);
  };

  const handleUpdateClick = (data) => {
    const payload = {
      name: data.name,
      description: data.description,
    };

    updateCertificationClass(payload);
  };

  const handleDeleteClick = () => {
    deleteCertificationClass();
  };

  return (
    <Box
      display="flex"
      gap={2}
      justifyContent="space-between"
      width={"100%"}
      flexDirection={{
        xs: "column",
        sm: "row",
      }}
    >
      <Box
        mt={1}
        display="flex"
        flexDirection="column"
        justifyContent="flex-start"
        width={{
          xs: "100%",
          sm: "60%",
        }}
      >
        <Typography variant="h3" color={colors.primary}>
          {count > 0
            ? `Κλάσεις Πιστοποίησης (${count})`
            : "Δεν υπάρχουν κλάσεις πιστοποίησης"}
        </Typography>

        {count > 0 && (
          <CustomTable
            columns={tableHeaders}
            data={certificationClassList}
            fadeEffect
            onItemClick={handleItemClick}
            page={page - 1}
            rowsPerPage={rowsPerPage}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            totalResults={count}
          />
        )}
      </Box>

      <Box
        borderLeft={{
          xs: "none",
          sm: `2px solid ${colors.lightGrey}`,
        }}
        borderTop={{
          xs: `2px solid ${colors.lightGrey}`,
          sm: "none",
        }}
        sx={{
          borderRadius: 6,
          opacity: "0.2",
        }}
      />

      <Box
        width={{
          xs: "100%",
          sm: "38%",
        }}
      >
        {selectedCertificationClassId === null ? (
          <CertificationClassForm
            onSave={handleCreateClick}
            onCancel={() => setSelectedCertificationClassId(null)}
            isCreateForm
          />
        ) : (
          <CertificationClassForm
            selectedCertificationClass={selectedCertificationClass}
            onSave={handleUpdateClick}
            onCancel={() => setSelectedCertificationClassId(null)}
            onDelete={handleDeleteClick}
            isEditing={isEditing}
          />
        )}
      </Box>
    </Box>
  );
};

export default CertificationClassesOverview;
