import { Badge, Tooltip, Typography } from "@mui/material";
import colors from "config/theme/colors";
import { MenuItem } from "react-pro-sidebar";
import { Link, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { forwardRef, useEffect } from "react";

const MenuItemWithRef = forwardRef(
  ({ menuTitle, to, icon, selected, setSelected, isNotificationOn }, ref) => {
    return (
      <MenuItem
        ref={ref}
        active={selected === menuTitle}
        style={{
          color: selected === menuTitle ? colors.primary : colors.grey,
          borderRight:
            selected === menuTitle ? `2px solid ${colors.primary}` : "",
        }}
        onClick={() => setSelected(menuTitle)}
        icon={
          isNotificationOn ? (
            <Badge
              variant="dot"
              color="primary"
              sx={{
                "& .MuiBadge-dot": {
                  top: "-1px",
                  right: "-2px",
                },
              }}
            >
              {icon}
            </Badge>
          ) : (
            icon
          )
        }
        component={<Link to={to} />}
      >
        <Typography
          fontWeight={selected === menuTitle ? "bold" : "normal"}
          sx={{
            textWrap: "wrap",
          }}
        >
          {menuTitle}
        </Typography>
      </MenuItem>
    );
  }
);

MenuItemWithRef.propTypes = {
  menuTitle: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  selected: PropTypes.string.isRequired,
  setSelected: PropTypes.func.isRequired,
  isNotificationOn: PropTypes.bool,
};

const SidebarItem = ({
  title,
  to,
  icon,
  selected,
  setSelected,
  isCollapsed = false,
  isNotificationOn = false,
}) => {
  const location = useLocation();

  useEffect(() => {
    const firstTerm = location.pathname.split("/")[1];
    if (firstTerm?.includes(to.split("/")[1])) {
      setSelected(title);
    }
  }, [location, setSelected, title, to]);

  return (
    <Tooltip
      title={isCollapsed ? title : ""}
      placement="right"
      PopperProps={{
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [0, -10],
            },
          },
        ],
      }}
    >
      <span>
        <MenuItemWithRef
          menuTitle={title}
          to={to}
          icon={icon}
          selected={selected}
          setSelected={setSelected}
          isNotificationOn={isNotificationOn}
        />
      </span>
    </Tooltip>
  );
};

SidebarItem.propTypes = {
  title: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  selected: PropTypes.string.isRequired,
  setSelected: PropTypes.func.isRequired,
  isCollapsed: PropTypes.bool,
  isNotificationOn: PropTypes.bool,
};

export default SidebarItem;
