import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import SpinningCircle from "components/spinners/SpinningCircle";
import CustomTable from "components/tables/CustomTable";
import { useMemo, useState, useCallback } from "react";
import { useNavigate } from "react-router";
import FilterButton from "components/buttons/FilterButton";
import usePagination from "hooks/usePagination";
import dayjs from "dayjs";
import StatusLabel from "components/labels/StatusLabel";
import enums from "config/enums";
import PropTypes from "prop-types";
import { useGetEquipmentMaintenancesByAssetId } from "hooks/queries/useMaintenances";
import CustomButton from "components/buttons/CustomButton";
import UploadFileOutlinedIcon from "@mui/icons-material/UploadFileOutlined";
import EquipmentMaintenancesMassImportStepperModal from "../MassImport/EquipmentMaintenancesMassImportStepperModal";
import UserInputs from "../MassImport/Steps/UserInputs";
import EquipmentsSelectionTable from "../MassImport/Steps/EquipmentsSelectionTable";
import EquipmentsMaintenancesCheck from "../MassImport/Steps/EquipmentsMaintenancesCheck";
import EquipmentsMaintenanceHistoryCreation from "../MassImport/Steps/EquipmentsMaintenanceHistoryCreation";

const EquipmentMaintenancesOverview = ({
  assetId = null,
  maintenanceTypes = [],
  equipmentTypes = [],
}) => {
  const navigate = useNavigate();
  const {
    page,
    setPage,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
  } = usePagination();
  const [selectedFilters, setSelectedFilters] = useState({});
  const [
    isEquipmentMaintenancesMassImportModalOpen,
    setIsEquipmentMaintenancesMassImportModalOpen,
  ] = useState(false);
  const [selectedMaintenanceTypeIds, setSelectedMaintenanceTypeIds] = useState(
    []
  );
  const [selectedEquipmentTypeIds, setSelectedEquipmentTypeIds] = useState([]);
  const [selectedEquipmentType, setSelectedEquipmentType] = useState(null);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [selectedContract, setSelectedContract] = useState(null);
  const [selectedEquipments, setSelectedEquipments] = useState([]);
  const [
    disableNextButtonForMaintenanceCheck,
    setDisableNextButtonForMaintenanceCheck,
  ] = useState(true);
  const {
    data: equipmentMaintenances,
    isLoading: isEquipmentMaintenancesLoading,
    error: errorEquipmentMaintenances,
    refetch: refetchEquipmentMaintenances,
  } = useGetEquipmentMaintenancesByAssetId({
    pageNumber: page,
    pageSize: rowsPerPage,
    assetId: assetId,
    maintenanceTypeIds: selectedMaintenanceTypeIds,
    equipmentTypeIds: selectedEquipmentTypeIds,
  });

  const equipmentsIds = selectedEquipments?.map((equipment) => equipment.id);
  const contractId = selectedContract?.id;

  const afterFinalSubmission = () => {
    setSelectedEquipments([]);
    setSelectedEquipmentType(null);
    setSelectedCompany(null);
    setSelectedContract(null);
    refetchEquipmentMaintenances();
    handleCloseEquipmentMaintenancesMassImportModal();
  };

  const steps = [
    {
      component: (
        <UserInputs
          equipmentTypes={equipmentTypes}
          selectedEquipmentType={selectedEquipmentType}
          setSelectedEquipmentType={setSelectedEquipmentType}
          selectedCompany={selectedCompany}
          setSelectedCompany={setSelectedCompany}
          selectedContract={selectedContract}
          setSelectedContract={setSelectedContract}
        />
      ),
      disableNextButton:
        !selectedEquipmentType || !selectedCompany || !selectedContract,
    },
    {
      component: (
        <EquipmentsSelectionTable
          selectedEquipmentType={selectedEquipmentType}
          assetId={assetId}
          selectedEquipments={selectedEquipments}
          setSelectedEquipments={setSelectedEquipments}
        />
      ),
      disableNextButton: selectedEquipments?.length === 0,
    },
    {
      component: (
        <EquipmentsMaintenancesCheck
          equipmentsIds={equipmentsIds}
          contractId={contractId}
          setDisableNextButton={setDisableNextButtonForMaintenanceCheck}
          selectedEquipmentType={selectedEquipmentType}
        />
      ),
      disableNextButton: disableNextButtonForMaintenanceCheck,
    },
    {
      component: (
        <EquipmentsMaintenanceHistoryCreation
          equipmentsIds={equipmentsIds}
          contractId={contractId}
          afterFinalSubmission={afterFinalSubmission}
        />
      ),
    },
  ];

  const filterGroups = [
    {
      title: "Τύπος Συντήρησης",
      filters: maintenanceTypes?.map((type) => ({
        id: type.id,
        label: type.name,
        type: "checkbox",
      })),
    },
    {
      title: "Τύπος Εξοπλισμού",
      filters: equipmentTypes?.map((type) => ({
        id: type.id,
        label: type.name,
        type: "checkbox",
      })),
    },
  ];

  const handleOpenEquipmentMaintenancesMassImportModal = () => {
    setIsEquipmentMaintenancesMassImportModalOpen(true);
  };

  const handleCloseEquipmentMaintenancesMassImportModal = () => {
    setIsEquipmentMaintenancesMassImportModalOpen(false);
  };

  const handleSubmitFilters = useCallback(
    (filters) => {
      const maintenanceTypeIds = filters["Τύπος Συντήρησης"] || [];
      const equipmentTypeIds = filters["Τύπος Εξοπλισμού"] || [];
      setSelectedFilters(filters);
      setSelectedMaintenanceTypeIds(maintenanceTypeIds);
      setSelectedEquipmentTypeIds(equipmentTypeIds);
      if (filters) {
        setPage(1);
      }

      if (maintenanceTypeIds.length === 0 && equipmentTypeIds.length === 0) {
        refetchEquipmentMaintenances();
      }
    },
    [
      setSelectedFilters,
      setSelectedMaintenanceTypeIds,
      setSelectedEquipmentTypeIds,
      refetchEquipmentMaintenances,
      setPage,
    ]
  );

  const tableHeaders = useMemo(
    () => [
      {
        id: "name",
        label: "Όνομα",
        isSortable: false,
      },
      {
        id: "equipment",
        label: "Όνομα Eξοπλισμού",
        isSortable: false,
      },
      {
        id: "contract",
        label: "Σύμβαση",
        isSortable: false,
      },
      {
        id: "frequency",
        label: "Συχνότητα (μέρες)",
        isSortable: false,
      },
      {
        id: "lastDate",
        label: "Ημ/νία Τελευταίας Συντήρησης",
        isSortable: false,
      },
      {
        id: "nextDate",
        label: "Ημ/νία Επόμενης Συντήρησης",
        isSortable: false,
      },
      {
        id: "maintenanceType",
        label: "Τύπος Συντήρησης",
        isSortable: false,
      },
      {
        id: "status",
        label: "Κατάσταση",
        isSortable: false,
      },
    ],
    []
  );

  if (isEquipmentMaintenancesLoading) {
    return <SpinningCircle />;
  }

  if (errorEquipmentMaintenances) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          height: "100%",
        }}
      >
        <p>Error: {errorEquipmentMaintenances?.message}</p>
      </Box>
    );
  }

  const equipmentMaintenancesCount = equipmentMaintenances?.totalCount;
  const equipmentMaintenancesList = equipmentMaintenances?.items?.map(
    (equipmentMaintenance) => ({
      ...equipmentMaintenance,
      name: equipmentMaintenance?.name || "-",
      equipment: equipmentMaintenance?.equipment?.name || "-",
      frequency: equipmentMaintenance?.frequency || "-",
      maintenanceType: equipmentMaintenance?.maintenanceType?.name || "-",
      contract: equipmentMaintenance?.contract?.name || "-",
      lastDate: equipmentMaintenance?.lastDate
        ? dayjs(equipmentMaintenance?.lastDate).format("DD/MM/YYYY")
        : "-",
      nextDate: equipmentMaintenance?.nextDate
        ? dayjs(equipmentMaintenance?.nextDate).format("DD/MM/YYYY")
        : "-",
      status: equipmentMaintenance?.status ? (
        <StatusLabel
          statusValue={equipmentMaintenance?.status}
          statusText={enums.MaintenanceStatus[equipmentMaintenance?.status]}
        />
      ) : (
        "-"
      ),
    })
  );

  const handleItemClick = (item) => {
    navigate(
      `/assets/${assetId}/equipment/${item.equipmentId}/maintenance/${item.id}`
    );
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={4}
      sx={{
        height: "100%",
      }}
    >
      <Box
        display="flex"
        flexDirection={{
          xs: "column",
          sm: "row",
        }}
        justifyContent={{
          xs: "space-between",
        }}
        flexGrow={1}
        flexWrap={{
          xs: "wrap",
          md: "nowrap",
        }}
        width={"100%"}
        gap={2}
      >
        <Box
          display="flex"
          gap={2}
          flexWrap={{
            xs: "wrap",
            sm: "nowrap",
          }}
          sx={{
            overflowX: "auto",
          }}
        />
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        flexGrow={1}
        gap={2}
        width={"100%"}
      >
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          gap={4}
          flexWrap={"wrap"}
        >
          <Typography variant="h3" color="primary">
            Συντηρήσεις Εξοπλισμού ({equipmentMaintenancesCount})
          </Typography>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              flexGrow: 1,
              gap: 2,
            }}
          >
            <FilterButton
              selectedFilters={selectedFilters}
              filterGroups={filterGroups}
              onApplyFilters={handleSubmitFilters}
            />

            <CustomButton
              icon={<UploadFileOutlinedIcon />}
              title="Μαζική Προσθήκη"
              orientation="row"
              fontSize={13}
              fontWeight={700}
              sx={{
                minHeight: "33px",
                maxHeight: "33px",
                borderRadius: 3,
                paddingY: 2.3,
              }}
              onClick={handleOpenEquipmentMaintenancesMassImportModal}
            />
          </Box>
        </Box>

        {equipmentMaintenancesList.length > 0 &&
        tableHeaders &&
        equipmentMaintenancesCount > 0 ? (
          <CustomTable
            columns={tableHeaders}
            data={equipmentMaintenancesList}
            fadeEffect
            onItemClick={handleItemClick}
            page={page - 1}
            rowsPerPage={rowsPerPage}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            totalResults={equipmentMaintenancesCount}
          />
        ) : (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{
              height: "100%",
            }}
          >
            <Typography variant="h4">Δεν υπάρχουν αποτελέσματα</Typography>
          </Box>
        )}

        <EquipmentMaintenancesMassImportStepperModal
          isOpen={isEquipmentMaintenancesMassImportModalOpen}
          handleClose={handleCloseEquipmentMaintenancesMassImportModal}
          steps={steps}
          setSelectedEquipments={setSelectedEquipments}
          setSelectedEquipmentType={setSelectedEquipmentType}
          setSelectedCompany={setSelectedCompany}
          setSelectedContract={setSelectedContract}
        />
      </Box>
    </Box>
  );
};

EquipmentMaintenancesOverview.propTypes = {
  assetId: PropTypes.number,
  maintenanceTypes: PropTypes.array,
  equipmentTypes: PropTypes.array,
};

export default EquipmentMaintenancesOverview;
