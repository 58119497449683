import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import SpinningCircle from "components/spinners/SpinningCircle";
import DropdownField from "components/inputs/DropdownField";
import { useGetAllCompanies } from "hooks/queries/useCompanies";
import { useGetAllContracts } from "hooks/queries/useContracts";
import PropTypes from "prop-types";
import colors from "config/theme/colors";

const UserInputs = ({
  equipmentTypes,
  selectedEquipmentType,
  setSelectedEquipmentType,
  selectedCompany,
  setSelectedCompany,
  selectedContract,
  setSelectedContract,
}) => {
  const {
    data: companies,
    isLoading: companiesLoading,
    isError: companiesError,
  } = useGetAllCompanies(
    {
      serviceType: ["Maintenance"],
    },
    {
      select: (data) => data?.result?.items,
      enabled: selectedEquipmentType ? true : false,
    }
  );

  const {
    data: contracts,
    isLoading: contractsLoading,
    isError: contractsError,
  } = useGetAllContracts(
    {
      companyId: selectedCompany?.id,
    },
    {
      select: (data) => data?.result?.items,
      enabled: selectedCompany ? true : false,
    }
  );

  const availableTypesOptions = equipmentTypes?.map((type) => ({
    id: type.id,
    label: type.name,
  }));

  const availableCompaniesOptions = companies?.map((company) => ({
    id: company.id,
    label: company.name,
  }));

  const availableContractsOptions = contracts?.map((contract) => ({
    id: contract.id,
    label: contract.name,
  }));

  if (companiesLoading || contractsLoading) {
    return <SpinningCircle />;
  }

  if (companiesError || contractsError) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          height: "100%",
        }}
      >
        <p>Error: {companiesError?.message || contractsError?.message}</p>
      </Box>
    );
  }

  return (
    <Box mt={2}>
      <Typography color={colors.primary} fontWeight={700} fontSize={21} mb={2}>
        Επιλέξτε τον τύπο του εξοπλισμού
      </Typography>

      <DropdownField
        label="Επιλογή Τύπου Εξοπλισμού"
        options={availableTypesOptions}
        value={selectedEquipmentType}
        onChange={(_, value) => setSelectedEquipmentType(value)}
      />

      <Box mt={3}>
        {selectedEquipmentType && (
          <>
            <Typography
              color={colors.primary}
              fontWeight={700}
              fontSize={21}
              mb={2}
            >
              Επιλέξτε τον επιθυμητό οργανισμό
            </Typography>

            <DropdownField
              label="Επιλογή Οργανισμού"
              options={availableCompaniesOptions}
              value={selectedCompany}
              onChange={(_, value) => setSelectedCompany(value)}
            />
          </>
        )}
      </Box>

      <Box mt={3}>
        {selectedCompany && (
          <>
            <Typography
              color={colors.primary}
              fontWeight={700}
              fontSize={21}
              mb={2}
            >
              Επιλέξτε την επιθυμητή σύμβαση
            </Typography>

            <DropdownField
              label="Επιλογή Σύμβασης"
              options={availableContractsOptions}
              value={selectedContract}
              onChange={(_, value) => setSelectedContract(value)}
            />
          </>
        )}
      </Box>
    </Box>
  );
};

UserInputs.propTypes = {
  equipmentTypes: PropTypes.array,
  selectedEquipmentType: PropTypes.object,
  setSelectedEquipmentType: PropTypes.func,
  selectedCompany: PropTypes.object,
  setSelectedCompany: PropTypes.func,
  selectedContract: PropTypes.object,
  setSelectedContract: PropTypes.func,
};

export default UserInputs;
