import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CertificationHistoryViewEditForm from "./CertificationHistoryViewEditForm";
import { useNavigate, useParams } from "react-router";
import { useState } from "react";
import SpinningCircle from "components/spinners/SpinningCircle";
import EditButton from "components/buttons/EditButton";
import { useAlert } from "hooks/useAlert";
import ReturnButton from "components/buttons/ReturnButton";
import colors from "config/theme/colors";
import {
  useDeleteCertificationHistory,
  useGetCertificationHistoryById,
  useUpdateCertificationHistory,
} from "hooks/queries/useCertificationsHistory";
import dayjs from "dayjs";

const CertificationHistoryViewEdit = () => {
  const { historyId, certificationId } = useParams();
  const navigate = useNavigate();
  const { showError, showSuccess } = useAlert();
  const [isDeleted, setIsDeleted] = useState(false);
  const {
    data: selectedCertificationHistory,
    isLoading: isCertificationHistoryLoading,
    isError: isErrorCertificationHistory,
    error: errorCertificationHistory,
  } = useGetCertificationHistoryById(historyId, {
    select: (data) => data.result,
    enabled: !!historyId && !isDeleted,
  });
  const { mutate: updateCertificationHistory } = useUpdateCertificationHistory({
    id: selectedCertificationHistory?.id,
    config: {
      onError: (error) => {
        showError(error.message);
      },
      onSuccess: () => {
        showSuccess("Το ιστορικό πιστοποίησης ενημερώθηκε με επιτυχία");
        navigate(-1);
      },
    },
  });
  const { mutate: deleteMaintenaceHistory } = useDeleteCertificationHistory({
    id: selectedCertificationHistory?.id,
    config: {
      onError: (error) => {
        showError(error.message);
        setIsDeleted(false);
      },
      onSuccess: () => {
        showSuccess("Το ιστορικό πιστοποίησης διαγράφηκε με επιτυχία");
        navigate(-1);
      },
    },
  });
  const [isEditing, setIsEditing] = useState(false);

  if (isCertificationHistoryLoading) {
    return <SpinningCircle />;
  }

  if (isErrorCertificationHistory) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          height: "100%",
        }}
      >
        <p>Error: {errorCertificationHistory?.message}</p>
      </Box>
    );
  }

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleCancelClick = () => {
    setIsEditing(false);
  };

  const handleSaveClick = (data) => {
    setIsEditing(false);
    const payload = {
      ...data,
      certificationId: parseInt(certificationId) || null,
      issueDateTime: dayjs(data?.issueDateTime).format("YYYY-MM-DD") || null,
      expireDateTime: dayjs(data?.expireDateTime).format("YYYY-MM-DD") || null,
    };
    updateCertificationHistory(payload);
  };

  const handleDeleteClick = () => {
    setIsEditing(false);
    setIsDeleted(true);
    deleteMaintenaceHistory();
  };

  return (
    selectedCertificationHistory && (
      <>
        <Box
          display="flex"
          alignItems={"center"}
          justifyContent="flex-start"
          gap={1}
        >
          <Typography color={colors.primary} fontWeight={700} fontSize={24}>
            Διαχείριση Ιστορικού Πιστοποίησης
          </Typography>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          gap={2}
          sx={{
            height: "100%",
          }}
          mt={4}
        >
          <Box
            mb={2}
            display="flex"
            gap={2}
            justifyContent="space-between"
            alignItems={{
              xs: "flex-start",
              sm: "center",
            }}
            flexWrap={"wrap"}
          >
            <Box
              display="flex"
              gap={2}
              alignItems="center"
              sx={{
                width: {
                  xs: "100%",
                  sm: "auto",
                },
              }}
            >
              <ReturnButton />
              <Typography fontSize={20} fontWeight={600} color={colors.primary}>
                {`${selectedCertificationHistory.name}`}
              </Typography>
            </Box>
            <Box
              display="flex"
              gap={2}
              sx={{
                width: {
                  xs: "100%",
                  sm: "auto",
                },
              }}
            >
              <EditButton onClick={handleEditClick} disabled={isEditing} />
            </Box>
          </Box>
          <Box mt={1} flexDirection="column" justifyContent="space-between">
            <CertificationHistoryViewEditForm
              selectedCertificationHistory={selectedCertificationHistory}
              isEditing={isEditing}
              onSave={handleSaveClick}
              onCancel={handleCancelClick}
              onDelete={handleDeleteClick}
            />
          </Box>
        </Box>
      </>
    )
  );
};

export default CertificationHistoryViewEdit;
