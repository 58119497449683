import colors from "./colors";

export const chartsPalette = [
  "#35C2FD", // Sky Blue
  colors.primary,
  "#2A6BFF", // Blue 1
  "#4A90E2", // Blue 2
  "#5EA3FF", // Blue 3
  "#87BEFF", // Blue 4
  "#8298AE", // Grey
  "#313131", // Black
  "#36454F", // Dark Grey
  "#6E7E85", // Light Grey 1
  "#9A9A9A", // Light Grey 2
  "#C0C0C0", // Light Grey 3
  "#BA1E1E", // Red 1
  "#E63946", // Red 2
  "#FF3200", // Red 3
  "#FF6B6B", // Red 4
  "#FF8B43", // Orange
  "#FFC107", // Yellow
  "#FFA726", // Orange 2
  "#FF7A00", // Orange 3
  "#008080", // Teal
  "#279500", // Green 1
  "#2ECC71", // Green 2
  "#A8E6CF", // Green 3
  "#6A41AD", // Purple
  "#8045E9", // Purple 2
  "#9575CD", // Purple 3
  "#B39DDB", // Purple 4
];
