import { Box, Typography } from "@mui/material";
import SpinningCircle from "components/spinners/SpinningCircle";
import CustomTable from "components/tables/CustomTable";
import { useNavigate } from "react-router";
import colors from "config/theme/colors";
import { useGetAllContracts } from "hooks/queries/useContracts";
import enums from "config/enums";
import dayjs from "dayjs";
import TagLabel from "components/labels/TagLabel";
import PropTypes from "prop-types";
import { useMemo } from "react";
import usePagination from "hooks/usePagination";
import StatusLabel from "components/labels/StatusLabel";

const CompanyContractsOverview = ({ companyId = null }) => {
  const { page, rowsPerPage, handleChangePage, handleChangeRowsPerPage } =
    usePagination();
  const navigate = useNavigate();
  const {
    data: contracts,
    isLoading: contractsLoading,
    isError: contractsError,
    error: contractsErrorData,
  } = useGetAllContracts(
    {
      pageNumber: page,
      pageSize: rowsPerPage,
      companyId,
    },
    {
      enabled: !!companyId,
    }
  );

  const tableHeaders = useMemo(
    () => [
      {
        id: "name",
        label: "Σύμβαση",
        isSortable: true,
      },
      {
        id: "number",
        label: "Αριθμός",
        isSortable: true,
      },
      {
        id: "dateStarted",
        label: "Ημ/νία ΄Eναρξης",
        isSortable: false,
      },
      {
        id: "dateExpires",
        label: "Ημ/νία Λήξης",
        isSortable: false,
      },
      {
        id: "type",
        label: "Τύπος Σύμβασης",
        isSortable: false,
      },
      {
        id: "status",
        label: "Κατάσταση",
        isSortable: false,
      },
    ],
    []
  );

  if (contractsLoading) {
    return <SpinningCircle />;
  }

  if (contractsError) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          height: "100%",
        }}
      >
        <p>Error: {contractsErrorData.message}</p>
      </Box>
    );
  }

  const contractsCount = contracts?.totalCount;
  const contractsList = contracts?.items?.map((contract) => ({
    id: contract.id,
    name: contract.name || "-",
    number: contract.number || "-",
    dateStarted: dayjs(contract.validFrom).format("DD/MM/YYYY") || "-",
    dateExpires: dayjs(contract.validTo).format("DD/MM/YYYY") || "-",
    type: enums.ContractTypes.find((type) => type.id === contract.type)?.label,
    status: contract.status ? (
      <StatusLabel
        statusValue={contract.status}
        statusText={
          enums.ContractStatuses.find((status) => status.id === contract.status)
            ?.label
        }
      />
    ) : (
      "-"
    ),
  }));

  const handleItemClick = (item) => {
    navigate(`contracts/${item.id}`);
  };

  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        gap={13}
      >
        <Typography fontSize={20} fontWeight={600} color={colors.secondary}>
          Ιστορικό Συμβάσεων
        </Typography>
      </Box>

      <Box display="flex" gap={2} mt={2}>
        <Typography variant="h3" color="primary">
          Συμβάσεις ({contractsCount})
        </Typography>
        <Box display="flex" flexDirection="row" gap={1} flexWrap={"wrap"}>
          {enums.ContractTypes.map((contractType) => {
            const count = contractsList?.filter(
              (contractItem) => contractItem.type === contractType.label
            ).length;
            return count > 0 ? (
              <TagLabel
                key={contractType.id}
                text={`${count} ${contractType.label}`}
              />
            ) : null;
          })}
        </Box>
      </Box>

      {contractsList.length > 0 && tableHeaders && contractsCount > 0 ? (
        <CustomTable
          columns={tableHeaders}
          data={contractsList}
          totalResults={contractsCount}
          page={page - 1}
          rowsPerPage={rowsPerPage}
          onItemClick={handleItemClick}
          fadeEffect
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      ) : (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{
            height: "100%",
            marginTop: "18%",
          }}
        >
          <Typography variant="h4">Δεν υπάρχουν αποτελέσματα</Typography>
        </Box>
      )}
    </Box>
  );
};

CompanyContractsOverview.propTypes = {
  companyId: PropTypes.number,
};

export default CompanyContractsOverview;
