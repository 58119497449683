import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import Slider from "react-slick";
import { Box, IconButton } from "@mui/material";
import { styled } from "@mui/material/styles";
import ChevronLeftOutlinedIcon from "@mui/icons-material/ChevronLeftOutlined";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import colors from "config/theme/colors";
import CarouselItem from "./CarouselItem";

const Arrow = ({
  onClick,
  icon,
  isNext = false,
  isPrev = false,
  ...restProps
}) => {
  const { className } = restProps;
  const isDisabled = className.includes("slick-disabled");

  return (
    <IconButton
      onClick={onClick}
      disabled={isDisabled}
      sx={{
        display: "block",
        zIndex: 2,
        position: "absolute",
        top: "50%",
        transform: "translateY(-50%)",
        right: isNext ? "-50px" : "auto",
        left: isPrev ? "-30px" : "auto",
      }}
    >
      {icon}
    </IconButton>
  );
};

Arrow.propTypes = {
  onClick: PropTypes.func,
  icon: PropTypes.node,
  isNext: PropTypes.bool,
  isPrev: PropTypes.bool,
};

const StyledContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  overflow: "hidden",
  position: "relative",
  padding: "0 40px",
}));

const StyledSlider = styled(Slider)(({ theme }) => ({
  "& .slick-slide": {},
  "& .slick-list": {
    overflow: "hidden",
  },
  "& .slick-track": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: theme.spacing(1),
  },
}));

const FilePreviewCarousel = ({ filePreviews, onDelete, onDownload }) => {
  const sliderRef = useRef(null);

  useEffect(() => {
    if (sliderRef.current) {
      sliderRef.current.slickGoTo(0);
    }
  }, [filePreviews]);

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: Math.min(filePreviews.length, 3),
    slidesToScroll: 1,
    nextArrow: (
      <Arrow
        icon={<ChevronRightOutlinedIcon color={colors.primary} />}
        isNext
      />
    ),
    prevArrow: (
      <Arrow icon={<ChevronLeftOutlinedIcon color={colors.primary} />} isPrev />
    ),
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <StyledContainer>
      <StyledSlider ref={sliderRef} {...settings}>
        {filePreviews?.map((file) => (
          <CarouselItem
            key={file.name}
            item={file}
            onDelete={onDelete}
            onDownload={onDownload}
          />
        ))}
      </StyledSlider>
    </StyledContainer>
  );
};

FilePreviewCarousel.propTypes = {
  filePreviews: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      preview: PropTypes.string,
      type: PropTypes.string,
      id: PropTypes.number,
    })
  ).isRequired,
  onDelete: PropTypes.func,
  onDownload: PropTypes.func,
};

export default FilePreviewCarousel;
