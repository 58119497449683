import { Box, Container, Typography } from "@mui/material";
import { InfoOutlined } from "@mui/icons-material";

const NoContentPage = () => {
  return (
    <Box sx={{ height: "100vh", display: "flex", flexDirection: "column" }}>
      <Container
        maxWidth="sm"
        sx={{
          mt: 8,
          mb: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          flexGrow: 1,
        }}
      >
        <Typography variant="h4" component="h1" gutterBottom>
          <InfoOutlined color="error" sx={{ mr: 2, verticalAlign: "bottom" }} />
          Δεν υπάρχει περιεχόμενο.
        </Typography>
        <Typography variant="subtitle1">
          Δοκιμάστε να επιλέξετε κάποιο άλλο μενού.
        </Typography>
        <Typography variant="body2" color="textSecondary">
          Ευχαριστούμε!
        </Typography>
      </Container>
    </Box>
  );
};

export default NoContentPage;
