import { requests } from "api/HttpClient";

const { post, get, getFile, delete: deleteRequest } = requests;

const urlToFile = async (file) => {
  const response = await fetch(file.preview);
  const blob = await response.blob();
  return new File([blob], file.name, { type: file.type });
};

const file = {
  getById: (id) => get(`/File/GetById/${id}`),
  upload: async ({ fileableId, fileableType, file, groupTag }) => {
    const formData = new FormData();
    formData.append("FileableId", fileableId);
    formData.append("FileableType", fileableType);
    if (groupTag) {
      formData.append("GroupTag", groupTag);
    }

    try {
      const fileObject = await urlToFile(file);
      formData.append("File", fileObject);

      return post("/File/Upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    } catch (error) {
      console.error("Error converting URL to file:", error);
      throw new Error("Failed to upload file");
    }
  },
  delete: (id) => deleteRequest(`/File/Delete/${id}`),
  getThumbnail: (id) => getFile(`/File/GetThumbnail/${id}`),
  download: (id) => getFile(`/File/Download/${id}`),
};

export default file;
