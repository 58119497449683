import { Box, Typography } from "@mui/material";
import { ReactComponent as AddIcon } from "assets/icons/add.svg";
import CustomTable from "components/tables/CustomTable";
import { useNavigate, useParams } from "react-router";
import SpinningCircle from "components/spinners/SpinningCircle";
import { useMemo, useState, useCallback } from "react";
import TagLabel from "components/labels/TagLabel";
import { useGetAllEquipment } from "hooks/queries/useEquipment";
import CustomButton from "components/buttons/CustomButton";
import colors from "config/theme/colors";
import FilterButton from "components/buttons/FilterButton";
import usePagination from "hooks/usePagination";
import { useGetAllEquipmentTypes } from "hooks/queries/useEquipmentType";
import UploadFileOutlinedIcon from "@mui/icons-material/UploadFileOutlined";

const EquipmentOverview = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const {
    page,
    setPage,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
  } = usePagination();
  const [selectedFilters, setSelectedFilters] = useState({});
  const [selectedEquipmentTypes, setSelectedEquipmentTypes] = useState([]);

  const {
    data: equipmentTypes,
    isLoading: isLoadingEquipmentTypes,
    error: errorEquipmentTypes,
  } = useGetAllEquipmentTypes();
  const {
    data: equipment,
    isLoading,
    error,
    refetch: refetchEquipments,
  } = useGetAllEquipment({
    pageNumber: page,
    pageSize: rowsPerPage,
    assetId: id,
    equipmentTypeIds: selectedEquipmentTypes,
  });

  const filterGroups = [
    {
      title: "Τύποι Εξοπλισμού",
      filters: equipmentTypes?.items?.map((equipmentType) => ({
        id: equipmentType.id,
        label: equipmentType.name,
        type: "checkbox",
      })),
    },
  ];

  const handleSubmitFilters = useCallback(
    (filters) => {
      const equipmentTypes = filters["Τύποι Εξοπλισμού"] || [];
      setSelectedFilters(filters);
      setSelectedEquipmentTypes(equipmentTypes);
      if (filters) {
        setPage(1);
      }

      if (equipmentTypes.length === 0) {
        refetchEquipments();
      }
    },
    [setSelectedFilters, setSelectedEquipmentTypes, refetchEquipments, setPage]
  );

  const tableHeaders = useMemo(
    () => [
      {
        id: "code",
        label: "Κωδικός",
        isSortable: false,
      },
      {
        id: "name",
        label: "Όνομα",
        isSortable: false,
      },
      {
        id: "type",
        label: "Τύπος",
        isSortable: false,
      },
      {
        id: "group",
        label: "Ομάδα",
        isSortable: false,
      },
      {
        id: "class",
        label: "Κλάση",
        isSortable: false,
      },
      {
        id: "floor",
        label: "Όροφος",
        isSortable: false,
      },
    ],
    []
  );

  if (isLoading || isLoadingEquipmentTypes) {
    return <SpinningCircle />;
  }

  if (error || errorEquipmentTypes) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          height: "100%",
        }}
      >
        <p>Error: {error.message || errorEquipmentTypes.message}</p>
      </Box>
    );
  }

  const equipmentCount = equipment?.totalCount;
  const equipmentList = equipment?.items.map((equipmentΙtem) => {
    return {
      id: equipmentΙtem?.id || "",
      name: equipmentΙtem?.name || "",
      type: equipmentΙtem?.equipmentClass?.equipmentGroup?.equipmentType?.name,
      group: equipmentΙtem?.equipmentClass?.equipmentGroup?.name,
      code: equipmentΙtem?.id || "",
      class: equipmentΙtem?.equipmentClass?.name || "",
      classId: equipmentΙtem?.equipmentClassId || "",
      typeId: equipmentΙtem?.equipmentClass?.equipmentGroup?.equipmentType?.id,
      floor: equipmentΙtem?.floor ?? "",
    };
  });

  const handleItemClick = (item) => {
    navigate(`${item.code}`);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={4}
      sx={{
        height: "100%",
      }}
    >
      <Box
        display="flex"
        flexDirection={{
          xs: "column",
          sm: "row",
        }}
        justifyContent={{
          xs: "flex-end",
        }}
        flexGrow={1}
        flexWrap={{
          xs: "wrap",
          md: "nowrap",
        }}
        width={"100%"}
        gap={2}
      >
        <Box
          display="flex"
          gap={2}
          flexWrap={{
            xs: "wrap",
            sm: "nowrap",
          }}
          sx={{
            overflowX: "auto",
          }}
        />
        <CustomButton
          icon={<AddIcon fill={colors.primary} width={20} height={20} />}
          title="Προσθήκη"
          orientation="row"
          fontSize={13}
          fontWeight={700}
          sx={{
            minHeight: "33px",
            maxHeight: "33px",
            borderRadius: 3,
            paddingY: 2.3,
          }}
          onClick={() => {
            navigate("new");
          }}
        />

        <CustomButton
          icon={<UploadFileOutlinedIcon />}
          title="Μαζική Προσθήκη"
          orientation="row"
          fontSize={13}
          fontWeight={700}
          sx={{
            minHeight: "33px",
            maxHeight: "33px",
            borderRadius: 3,
            paddingY: 2.3,
          }}
          onClick={() => {
            navigate("mass-import");
          }}
        />
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        flexGrow={1}
        gap={2}
        width={"100%"}
      >
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          gap={4}
          flexWrap={"wrap"}
        >
          <Box display="flex" gap={2}>
            <Typography variant="h3" color="primary">
              Εξοπλισμός ({equipmentCount})
            </Typography>
            <Box display="flex" flexDirection="row" gap={1} flexWrap={"wrap"}>
              {equipmentTypes?.items.map((equipmentType) => {
                const count = equipmentList?.filter(
                  (equipmentItem) => equipmentItem.typeId === equipmentType.id
                ).length;
                return count > 0 ? (
                  <TagLabel
                    key={equipmentType.id}
                    text={`${count} ${equipmentType.name}`}
                  />
                ) : null;
              })}
            </Box>
          </Box>
          <FilterButton
            selectedFilters={selectedFilters}
            filterGroups={filterGroups}
            onApplyFilters={handleSubmitFilters}
          />
        </Box>

        {equipmentCount > 0 ? (
          <CustomTable
            columns={tableHeaders}
            data={equipmentList}
            fadeEffect
            onItemClick={handleItemClick}
            page={page - 1}
            rowsPerPage={rowsPerPage}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            totalResults={equipmentCount}
          />
        ) : (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{
              height: "100%",
            }}
          >
            <Typography variant="h4">Δεν υπάρχουν αποτελέσματα</Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default EquipmentOverview;
