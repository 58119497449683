import { yupResolver } from "@hookform/resolvers/yup";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { useIsMutating } from "@tanstack/react-query";
import CancelButton from "components/buttons/CancelButton";
import DeleteButton from "components/buttons/DeleteButton";
import SaveButton from "components/buttons/SaveButton";
import CustomTextField from "components/inputs/CustomTextField";
import DateField from "components/inputs/DateField";
import DropdownField from "components/inputs/DropdownField";
import DeleteModal from "components/modals/DeleteModal";
import rentResponseFields from "config/forms/rentResponseFields";
import PropTypes from "prop-types";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import enums from "config/enums";
import { useGetAllContracts } from "hooks/queries/useContracts";
import SpinningCircle from "components/spinners/SpinningCircle";
import AlfrescoFileUploader from "components/fileUploaders/AlfrescoFileUploader";

const RentsViewEditForm = ({
  selectedRent,
  isEditing,
  onSave,
  onCancel,
  onDelete,
}) => {
  const {
    data: contracts,
    isLoading: isContractsLoading,
    isError: isContractsError,
    error: errorContracts,
  } = useGetAllContracts({
    contractType: "Rent",
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const isUpdateLoading =
    useIsMutating({ mutationKey: ["updateRent"] }) +
      useIsMutating({ mutationKey: ["uploadFiles"] }) +
      useIsMutating({ mutationKey: ["deleteMultipleFiles"] }) +
      useIsMutating({ mutationKey: ["deleteFile"] }) >
    0;
  const isDeleteLoading = useIsMutating({ mutationKey: ["deleteRent"] }) > 0;

  const schema = yup.object(
    rentResponseFields.rent.reduce((acc, field) => {
      let fieldSchema;

      if (field.type === "text" || field.type === "textarea") {
        fieldSchema = yup.string();
      } else if (field.type === "select" || field.type === "boolean") {
        if (field.name === "contractId") {
          fieldSchema = yup.object().shape({
            id: yup.number(),
            label: yup.string(),
          });
        } else fieldSchema = yup.string();
      } else if (field.type === "number") {
        fieldSchema = yup
          .number()
          .transform((value) => (isNaN(value) ? undefined : value));
      } else if (field.type === "date") {
        fieldSchema = yup.date().transform((value, originalValue) => {
          return isNaN(Date.parse(originalValue)) ? undefined : value;
        });
      } else if (field.type === "file") {
        const validTypes = field.validTypes || [];
        const maxSize = field.maxSize || 5000000;

        fieldSchema = yup.mixed().test({
          name: "fileValidation",
          exclusive: true,
          message: "Invalid file",
          test: (value) => {
            if (!value || value.length === 0) return true;

            for (const file of value) {
              if (
                !validTypes.includes(file.type) &&
                !validTypes.includes(file.mimeType)
              ) {
                return new yup.ValidationError(
                  "Μη έγκυρος τύπος αρχείου",
                  null,
                  "file"
                );
              }

              if (file.size > maxSize) {
                return new yup.ValidationError(
                  `Το μέγεθος του αρχείου δεν πρέπει να υπερβαίνει τα ${maxSize / 1000000}MB`,
                  null,
                  "file"
                );
              }
            }

            return true;
          },
        });
      }

      if (field.required) {
        fieldSchema = fieldSchema.required("Το πεδίο είναι υποχρεωτικό");
      } else {
        fieldSchema = fieldSchema.nullable();
      }

      if (field.validation === "custom" && field.regex) {
        fieldSchema = fieldSchema.matches(field.regex, field.validationMessage);
      }

      acc[field.name] = fieldSchema.typeError(
        field.validationMessage || "Μη έγκυρη τιμή"
      );

      return acc;
    }, {})
  );

  const getDataValueFromField = (fieldName) => {
    const fieldMap = rentResponseFields.rent.reduce((acc, field) => {
      acc[field.name] = field.name;
      return acc;
    }, {});

    const path = fieldMap[fieldName];
    if (typeof path === "function") {
      return path();
    } else if (typeof path === "string") {
      const value = path
        .split(".")
        .reduce((acc, key) => (acc ? acc[key] : undefined), selectedRent);
      return value !== undefined && value !== null ? value : null;
    } else {
      return "";
    }
  };

  const handleFormSubmit = (data) => {
    onSave(data);
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const formFields = rentResponseFields.rent;

  const defaultValues = useMemo(
    () => ({
      ...Object.fromEntries(
        formFields.map((field) => [
          field.name,
          getDataValueFromField(field.name),
        ])
      ),
      rentType: enums.RentTypes?.find(
        (option) => option.id === selectedRent.rentType
      )?.label,
      contractId: {
        id: selectedRent.contractId,
        label: contracts?.items?.find(
          (contract) => contract.id === selectedRent.contractId
        )?.name,
      },
      files: getDataValueFromField("files")?.map((file) => ({
        id: file.id,
        name: file.name,
        preview: file.url,
        type: file.mimeType,
        title: file.title,
      })),
    }),
    [contracts]
  );

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isDirty },
    getValues,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  });

  useEffect(() => {
    if (contracts) {
      reset(defaultValues);
    }
  }, [contracts, reset, defaultValues]);

  if (isContractsLoading) {
    return <SpinningCircle />;
  }

  if (isContractsError) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          height: "100%",
        }}
      >
        <p>Error: {errorContracts?.message}</p>
      </Box>
    );
  }

  const getInputElementByFieldType = (field) => {
    const defaultValue = getDataValueFromField(field.name);

    if (
      field.type === "text" ||
      field.type === "number" ||
      field.type === "textarea"
    ) {
      return (
        <CustomTextField {...field} control={control} disabled={!isEditing} />
      );
    } else if (field.type === "select" || field.type === "boolean") {
      let availableOptions =
        field.type === "boolean" ? ["Ναι", "Όχι"] : field.options;

      if (field.name === "rentType") {
        availableOptions = enums?.RentTypes?.map((option) => option?.label);
      }
      if (field.name === "contractId") {
        availableOptions = contracts?.items?.map((option) => ({
          id: option.id,
          label: option.name,
        }));
      }

      return (
        <DropdownField
          {...field}
          control={control}
          options={availableOptions}
          boolean={field.type === "boolean"}
          disabled={!isEditing}
        />
      );
    } else if (field.type === "date") {
      return <DateField disabled={!isEditing} {...field} control={control} />;
    } else if (field.type === "file") {
      return (
        <AlfrescoFileUploader
          {...field}
          control={control}
          value={defaultValue}
          disabled={!isEditing}
        />
      );
    }
  };

  return (
    <form onSubmit={handleSubmit(() => handleFormSubmit(getValues()))}>
      <Box display="flex" flexDirection="column" gap={4}>
        <Grid container spacing={2}>
          {formFields
            .filter((field) => field.display !== false)
            .map((field) => {
              if (field.type === "file") {
                return (
                  <Grid item xs={12} key={field?.name}>
                    {getInputElementByFieldType(field)}
                  </Grid>
                );
              } else
                return (
                  <Grid item xs={12} md={6} key={field.name}>
                    {getInputElementByFieldType(field)}
                  </Grid>
                );
            })}
        </Grid>

        {Object.keys(errors).length > 0 && (
          <Typography color="error" fontSize={14}>
            Παρακαλώ ελέγξτε ότι όλα τα πεδία έχουν συμπληρωθεί σωστά
          </Typography>
        )}

        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          gap={2}
        >
          <Box display="flex" gap={2} justifyContent={"flex-start"}>
            <CancelButton
              disabled={!isEditing}
              onClick={() => {
                reset();
                onCancel();
              }}
            />
            <DeleteButton
              onClick={handleOpenModal}
              text="Διαγραφή Ενοικίασης"
              disabled={!isEditing}
              isLoading={isDeleteLoading}
            />
          </Box>

          <SaveButton
            disabled={!isEditing || !isDirty}
            isLoading={isUpdateLoading}
          />

          <DeleteModal
            isOpen={isModalOpen}
            onClose={handleCloseModal}
            onDelete={onDelete}
            itemTitle={selectedRent?.title}
            title="Πρόκειται να διαγράψετε την ενοικίαση"
            description="Είστε βέβαιοι ότι θέλετε να προχωρήσετε στη διαγραφή της ενοικίασης;"
          />
        </Box>
      </Box>
    </form>
  );
};

RentsViewEditForm.propTypes = {
  selectedRent: PropTypes.object,
  isEditing: PropTypes.bool,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  onDelete: PropTypes.func,
};

export default RentsViewEditForm;
