import { Box } from "@mui/material";
import EquipmentViewEditForm from "./EquipmentViewEditForm";
import { useNavigate, useOutletContext } from "react-router";
import SpinningCircle from "components/spinners/SpinningCircle";
import {
  useDeleteEquipment,
  useUpdateEquipment,
} from "hooks/queries/useEquipment";
import { useAlert } from "hooks/useAlert";
import { useGetAllEquipmentClasses } from "hooks/queries/useEquipmentClass";
import { useDeleteMultipleFiles, useUploadFiles } from "hooks/queries/useFile";

const EquipmentViewEdit = () => {
  const {
    selectedAsset,
    selectedEquipment,
    setIsDeleted,
    isEditing,
    setIsEditing,
  } = useOutletContext();
  const { showError, showSuccess, showWarning } = useAlert();
  const navigate = useNavigate();
  const {
    data: equipmentClasses,
    isLoading: isLoadingEquipmentClasses,
    isError: isErrorEquipmentClasses,
    error: errorEquipmentClass,
  } = useGetAllEquipmentClasses({
    pageNumber: -1,
    pageSize: -1,
  });
  const { mutate: deleteEquipment } = useDeleteEquipment({
    id: selectedEquipment?.id,
    config: {
      onError: (error) => {
        showError(error.message);
        setIsDeleted(false);
      },
      onSuccess: () => {
        setIsEditing(false);
        setIsDeleted(true);
        showSuccess("Ο εξοπλισμός διαγράφηκε με επιτυχία");
        navigate(`/assets/${selectedAsset.id}/equipment`);
      },
    },
  });
  const { mutate: updateEquipment } = useUpdateEquipment({
    id: selectedEquipment?.id,
    config: {
      onError: (error) => {
        showError(error.message);
      },
      onSuccess: () => {
        showSuccess("Ο εξοπλισμός ενημερώθηκε με επιτυχία");
      },
    },
  });
  const { mutate: uploadFiles } = useUploadFiles({
    fileableId: selectedEquipment?.id,
    fileableType: "Equipment",
  });
  const { mutate: deleteMultipleFiles } = useDeleteMultipleFiles();

  if (isLoadingEquipmentClasses) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          height: "100%",
        }}
      >
        <SpinningCircle />
      </Box>
    );
  }

  if (isErrorEquipmentClasses) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          height: "100%",
        }}
      >
        <p>Error: {errorEquipmentClass?.message}</p>
      </Box>
    );
  }

  const handleUpdateWhenDeleteOnly = (payload, deletedFiles) => {
    deleteMultipleFiles(
      deletedFiles.map((file) => file.id),
      {
        onSuccess: () => {
          updateEquipment(payload);
        },
        onError: (error) => {
          showWarning(
            "Υπήρξε πρόβλημα με τη διαγραφή των αρχείων. Παρακαλώ δοκιμάστε ξανά την ενημέρωση των αρχείων"
          );
          showError(error.message);
        },
      }
    );
  };

  const handleUpdateWhenUploadOnly = (payload, newFiles) => {
    uploadFiles(
      { files: newFiles },
      {
        onSuccess: () => {
          updateEquipment(payload);
        },
        onError: (error) => {
          showWarning(
            "Υπήρξε πρόβλημα με το ανέβασμα των αρχείων. Παρακαλώ δοκιμάστε ξανά την ενημέρωση των αρχείων"
          );
          showError(error.message);
        },
      }
    );
  };

  const handleUpdateWhenDeleteAndUpload = (payload, deletedFiles, newFiles) => {
    deleteMultipleFiles(
      deletedFiles.map((file) => file.id),
      {
        onSuccess: () => {
          uploadFiles(
            { files: newFiles },
            {
              onSuccess: () => {
                updateEquipment(payload);
              },
              onError: (error) => {
                showWarning(
                  "Υπήρξε πρόβλημα με το ανέβασμα των αρχείων. Παρακαλώ δοκιμάστε ξανά την ενημέρωση των αρχείων"
                );
                showError(error.message);
              },
            }
          );
        },
        onError: (error) => {
          showWarning(
            "Υπήρξε πρόβλημα με τη διαγραφή των αρχείων. Παρακαλώ δοκιμάστε ξανά την ενημέρωση των αρχείων"
          );
          showError(error.message);
        },
      }
    );
  };

  const handleSaveClick = (data) => {
    const { supplyType } = data;
    setIsEditing(false);
    const formData = {
      name: data.name,
      description: data.description,
      equipmentClassId: equipmentClasses?.items.find(
        (classItem) => classItem.name === data.equipmentClassId
      )?.id,
      floor: data.floor ? parseInt(data.floor) : null,
      isRented: supplyType === "Ενοικίαση",
      isAcquired: supplyType === "Αγορά",
      assetId: selectedAsset.id,
    };

    const payload = Object.keys(formData).reduce((acc, key) => {
      if (formData[key] !== undefined && formData[key] !== null) {
        acc[key] = formData[key];
      }
      return acc;
    }, {});

    const newFiles = data.files?.filter((file) =>
      selectedEquipment.files.every((attachment) => attachment.id !== file.id)
    );

    const deletedFiles = selectedEquipment.files?.filter(
      (attachment) => !data.files?.some((file) => file.id === attachment.id)
    );

    if (deletedFiles.length === 0 && newFiles.length === 0) {
      updateEquipment(payload);
    } else if (deletedFiles.length > 0 && newFiles.length === 0) {
      handleUpdateWhenDeleteOnly(payload, deletedFiles);
    } else if (deletedFiles.length === 0 && newFiles.length > 0) {
      handleUpdateWhenUploadOnly(payload, newFiles);
    } else {
      handleUpdateWhenDeleteAndUpload(payload, deletedFiles, newFiles);
    }
  };

  const handleCancelClick = () => {
    setIsEditing(false);
  };

  const handleDeleteClick = () => {
    if (selectedEquipment.files.length === 0) {
      deleteEquipment();
    } else {
      deleteMultipleFiles(
        selectedEquipment.files.map((file) => file.id),
        {
          onSuccess: () => {
            deleteEquipment();
          },
          onError: (error) => {
            showWarning("Υπήρξε πρόβλημα με τη διαγραφή των αρχείων");
            showError(error.message);
            setIsDeleted(false);
          },
        }
      );
    }
  };

  return (
    selectedEquipment && (
      <EquipmentViewEditForm
        selectedEquipment={selectedEquipment}
        isEditing={isEditing}
        onSave={handleSaveClick}
        onCancel={handleCancelClick}
        onDelete={handleDeleteClick}
      />
    )
  );
};

export default EquipmentViewEdit;
