import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import colors from "config/theme/colors";
import PropTypes from "prop-types";

const ReturnButton = ({ disabled = false, onClick }) => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    if (onClick) {
      onClick();
    } else {
      navigate(-1);
    }
  };

  return (
    <IconButton
      onClick={handleGoBack}
      disabled={disabled}
      sx={{
        border: "1px solid",
        borderRadius: "10px",
        borderColor: colors.greyBackground,
        backgroundColor: colors.white,
        color: colors.text,
        "&:hover": {
          backgroundColor: colors.primary,
          color: colors.greyBackground,
        },
        "&:disabled": {
          opacity: 0.5,
          backgroundColor: colors.greyBackground,
          color: colors.primary,
        },
      }}
    >
      <ArrowBackIcon />
    </IconButton>
  );
};

ReturnButton.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};

export default ReturnButton;
