import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import CancelButton from "components/buttons/CancelButton";
import DeleteButton from "components/buttons/DeleteButton";
import PropTypes from "prop-types";
import colors from "config/theme/colors";
import { ReactComponent as CloseIcon } from "assets/icons/close.svg";
import Tooltip from "@mui/material/Tooltip";

const DeleteModal = ({
  title,
  itemTitle,
  description,
  isOpen,
  onClose,
  onDelete,
}) => {
  const handleCancel = () => {
    onClose();
  };

  const handleDelete = () => {
    onDelete();
    onClose();
  };

  return (
    <Modal
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      open={isOpen}
      onClose={handleCancel}
    >
      <Box
        sx={{
          backgroundColor: colors.white,
          borderRadius: "4px",
        }}
        boxShadow={"0px 4px 4px #2948981A"}
        gap={2}
      >
        <Box
          sx={{
            backgroundColor: colors.greyBackground,
          }}
          padding={2}
          display="flex"
          justifyContent="space-between"
          borderRadius={"4px 4px 0 0"}
        >
          <Typography color={colors.grey} fontSize="18px" fontWeight={600}>
            Απαιτείται Επιβεβαίωση
          </Typography>

          <Tooltip onClick={handleCancel} style={{ cursor: "pointer" }}>
            <CloseIcon stroke={colors.primary} />
          </Tooltip>
        </Box>

        <Box padding={3} maxWidth={700}>
          <Typography
            color={colors.text}
            fontSize="24px"
            fontWeight={700}
            mb={2}
          >
            {title} <span style={{ color: colors.primary }}>{itemTitle}</span>
          </Typography>

          <Typography color={colors.text} fontSize="18px" fontWeight={500}>
            {description}
          </Typography>

          <Typography color={colors.text} fontSize="18px" fontWeight={700}>
            Αυτή η ενέργεια δεν μπορεί να αναιρεθεί.
          </Typography>
        </Box>

        <Box
          display="flex"
          gap={2}
          justifyContent="space-between"
          alignItems="center"
          padding={2}
        >
          <CancelButton onClick={handleCancel} />
          <DeleteButton
            onClick={handleDelete}
            text="Επιβεβαίωση και Διαγραφή"
          />
        </Box>
      </Box>
    </Modal>
  );
};

DeleteModal.propTypes = {
  itemTitle: PropTypes.string,
  description: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default DeleteModal;
