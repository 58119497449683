import { Button, Typography } from "@mui/material";
import colors from "config/theme/colors";
import DeleteIcon from "@mui/icons-material/Delete";
import PropTypes from "prop-types";

const DeleteButton = ({
  onClick,
  text = "Διαγραφή",
  disabled = false,
  isLoading = false,
}) => {
  return (
    <Button
      onClick={onClick}
      startIcon={<DeleteIcon />}
      disabled={disabled || isLoading}
      sx={{
        backgroundColor: colors.error,
        color: colors.white,
        border: `1px solid ${colors.error}`,
        borderColor: colors.error,
        padding: "12px 14px",
        "&:hover": {
          backgroundColor: colors.white,
          color: colors.error,
        },
        "&:disabled": {
          opacity: 0.5,
          backgroundColor: colors.error,
          color: colors.white,
        },
        cursor: "pointer",
      }}
    >
      <Typography fontSize={12} fontWeight={700}>
        {text}
      </Typography>
    </Button>
  );
};

DeleteButton.propTypes = {
  onClick: PropTypes.func,
  text: PropTypes.string,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
};

export default DeleteButton;
