import Box from "@mui/material/Box";
import SubHeader from "components/headers/SubHeader";
import { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router";

const MAINTENANCE_TYPES_DETAILS_TABS = [
  {
    id: 0,
    description: "Τύπος",
    to: "types",
  },
];

const MaintenanceConfiguration = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    navigate(
      `/customize/maintenance/${MAINTENANCE_TYPES_DETAILS_TABS[selectedTab].to}`
    );
  }, [selectedTab, navigate]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={2}
      sx={{
        height: "100%",
      }}
    >
      <SubHeader
        tabs={MAINTENANCE_TYPES_DETAILS_TABS}
        setSelectedTab={setSelectedTab}
      />
      <Box
        display="flex"
        flexDirection="column"
        gap={2}
        flexGrow={1}
        width={"100%"}
      >
        <Outlet />
      </Box>
    </Box>
  );
};

export default MaintenanceConfiguration;
