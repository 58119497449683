import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { ReactComponent as EllipsisIcon } from "assets/icons/ellipsis.svg";
import PropTypes from "prop-types";

const MapCardViewButton = ({
  onClick,
  type,
  title,
  sx,
  fontWeight,
  fontSize,
  color,
}) => {
  return (
    <Button onClick={onClick} type={type} sx={sx}>
      <Box
        color={color}
        display="flex"
        justifyContent="center"
        alignItems="center"
        gap={1}
      >
        <Typography fontWeight={fontWeight} fontSize={fontSize}>
          {title}
        </Typography>
        <EllipsisIcon width={20} height={15} fill={color} />
      </Box>
    </Button>
  );
};

MapCardViewButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  type: PropTypes.string,
  title: PropTypes.string.isRequired,
  sx: PropTypes.object,
  fontWeight: PropTypes.number,
  fontSize: PropTypes.number,
  color: PropTypes.string,
};

export default MapCardViewButton;
