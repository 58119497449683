import PropTypes from "prop-types";
import DataGridTable from "components/tables/DataGridTable";
import { Box, Tooltip, Typography, Switch } from "@mui/material";
import * as Yup from "yup";
import { useEffect, useRef, useState, useMemo } from "react";
import CustomButton from "components/buttons/CustomButton";

const validationSchema = Yup.object().shape({
  Τύπος: Yup.string()
    .strict()
    .required("Ο τύπος εξοπλισμού είναι υποχρεωτικός")
    .typeError("Ο τύπος εξοπλισμού πρέπει να είναι αλφαριθμητικό"),
  Ομάδα: Yup.string()
    .strict()
    .required("Η ομάδα εξοπλισμού είναι υποχρεωτική")
    .typeError("Η ομάδα εξοπλισμού πρέπει να είναι αλφαριθμητικό"),
  Κλάση: Yup.string()
    .strict()
    .required("Η κλάση εξοπλισμού είναι υποχρεωτική")
    .typeError("Η κλάση εξοπλισμού πρέπει να είναι αλφαριθμητικό"),
  Όνομα: Yup.string()
    .strict()
    .required("Το όνομα εξοπλισμού είναι υποχρεωτικό")
    .typeError("Το όνομα εξοπλισμού πρέπει να είναι αλφαριθμητικό"),
  Περιγραφή: Yup.string()
    .strict()
    .required("Η περιγραφή εξοπλισμού είναι υποχρεωτική")
    .typeError("Η περιγραφή εξοπλισμού πρέπει να είναι αλφαριθμητικό"),
  Όροφος: Yup.number()
    .required("Ο όροφος εξοπλισμού είναι υποχρεωτικός")
    .typeError("Ο όροφος εξοπλισμού πρέπει να είναι αριθμός"),
  "Τύπος προμήθειας": Yup.string()
    .strict()
    .oneOf(
      ["Αγορά", "Ενοικίαση"],
      "Ο τύπος προμήθειας πρέπει να είναι Αγορά ή Ενοικίαση"
    ),
});

const DataValidationStep = ({
  fileData,
  onValidationErrors,
  onFileDataUpdate,
  handleCloseUploadModal,
}) => {
  const [rows, setRows] = useState([]);
  const [errors, setErrors] = useState({});
  const [showAllErrors, setShowAllErrors] = useState(false);
  const [showErrorRowsOnly, setShowErrorRowsOnly] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const prevErrorsRef = useRef(null);

  useEffect(() => {
    const validateData = async () => {
      const newErrors = {};
      const validatedRows = await Promise.all(
        fileData.map(async (data, index) => {
          try {
            await validationSchema.validate(data, { abortEarly: false });
            return { id: index, ...data };
          } catch (err) {
            const errorMessages = err.inner.reduce((acc, error) => {
              acc[error.path] = error.message;
              return acc;
            }, {});
            newErrors[index] = errorMessages;
            return { id: index, ...data };
          }
        })
      );

      setRows(validatedRows);
      if (JSON.stringify(newErrors) !== JSON.stringify(prevErrorsRef.current)) {
        setErrors(newErrors);
        onValidationErrors(
          Object.keys(newErrors).length > 0 ? newErrors : null
        );
        prevErrorsRef.current = newErrors;
      }
    };

    validateData();
  }, [fileData, onValidationErrors]);

  const validateRow = async (row) => {
    try {
      await validationSchema.validate(row, { abortEarly: false });
      return { id: row.id, ...row, errors: {} };
    } catch (err) {
      const errorMessages = err.inner.reduce((acc, error) => {
        acc[error.path] = error.message;
        return acc;
      }, {});
      return { id: row.id, ...row, errors: errorMessages };
    }
  };

  const handleCellEdit = async (updatedRow) => {
    const updatedRows = rows.map((row) =>
      row.id === updatedRow.id ? { ...row, ...updatedRow } : row
    );
    setRows(updatedRows);
    const updatedFileData = fileData.map((data, index) =>
      index === updatedRow.id ? updatedRow : data
    );
    onFileDataUpdate(updatedFileData);
    const validatedRows = await Promise.all(updatedFileData.map(validateRow));

    const newErrors = validatedRows.reduce((acc, row) => {
      if (row.errors && Object.keys(row.errors).length > 0) {
        acc[row.id] = row.errors;
      }
      return acc;
    }, {});

    setRows(validatedRows);
    if (JSON.stringify(newErrors) !== JSON.stringify(prevErrorsRef.current)) {
      setErrors(newErrors);
      onValidationErrors(Object.keys(newErrors).length > 0 ? newErrors : null);
      prevErrorsRef.current = newErrors;
    }

    if (showErrorRowsOnly && Object.keys(newErrors).length === 0) {
      setShowErrorRowsOnly(false);
    }
  };

  const handleDeleteRows = () => {
    const remainingRows = rows.filter((row) => !selectedRows.includes(row.id));
    const updatedFileData = remainingRows.map((row) => {
      const { id, ...rest } = row;
      return rest;
    });

    setRows(remainingRows);
    setSelectedRows([]);
    onFileDataUpdate(updatedFileData);

    if (remainingRows.length === 0) {
      handleCloseUploadModal();
    } else if (remainingRows.every((row) => !errors[row.id])) {
      setShowErrorRowsOnly(false);
    }
  };

  const columns = useMemo(
    () =>
      Object.keys(fileData[0] || {})
        .filter((key) => key !== "id")
        .map((key) => ({
          field: key,
          headerName: key,
          width: 150,
          editable:
            key !== "Τύπος" &&
            key !== "Ακίνητο" &&
            key !== "Ομάδα" &&
            key !== "Κλάση" &&
            key !== "Τύπος προμήθειας",
          renderCell: (params) => {
            const rowError = errors[params.row.id] || {};
            const hasError = rowError[key] !== undefined;

            return (
              <Tooltip title={hasError ? rowError[key] : ""}>
                <Typography
                  sx={{
                    color: hasError ? "red" : "inherit",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    marginTop: 2,
                  }}
                >
                  {params.value}
                </Typography>
              </Tooltip>
            );
          },
        })),
    [fileData, errors]
  );

  const columnErrors = useMemo(() => {
    const errorMap = {};
    Object.values(errors).forEach((rowErrors) => {
      Object.keys(rowErrors).forEach((key) => {
        if (!errorMap[key]) {
          errorMap[key] = rowErrors[key];
        }
      });
    });
    return errorMap;
  }, [errors]);

  const errorKeys = Object.keys(columnErrors);
  const displayedErrors = showAllErrors ? errorKeys : errorKeys.slice(0, 2);
  const hasMoreErrors = errorKeys.length > 2;

  const filteredRows = showErrorRowsOnly
    ? rows.filter((row) => errors[row.id])
    : rows;

  const hasErrors = Object.keys(errors).length > 0;

  return (
    <Box width={"100%"}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: 1.5,
          marginLeft: 1,
        }}
      >
        {hasErrors && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <Typography variant="body1" sx={{ marginRight: 1 }}>
              Εμφάνιση μόνο σειρών με σφάλματα
            </Typography>
            <Switch
              checked={showErrorRowsOnly}
              onChange={() => setShowErrorRowsOnly((prev) => !prev)}
            />
          </Box>
        )}

        {selectedRows.length > 0 && (
          <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
            <CustomButton
              title="Διαγραφή επιλεγμένων σειρών"
              onClick={handleDeleteRows}
              orientation="row"
              fontSize={14}
              fontWeight={600}
              sx={{
                minWidth: 120,
                maxHeight: 20,
                paddingY: 3,
                borderRadius: 3,
              }}
            />
          </Box>
        )}
      </Box>

      <DataGridTable
        sx={{
          width: "100%",
          marginTop: 3,
          padding: 0.5,
          maxHeight: "30vh",
          height: "100%",
          overflow: "auto",
        }}
        key={JSON.stringify(rows)}
        columns={columns}
        rows={filteredRows}
        onCellEdit={handleCellEdit}
        checkboxSelection={true}
        onRowSelectionModelChange={(newSelection) => {
          setSelectedRows(newSelection);
        }}
      />

      <Box sx={{ marginTop: 3, maxHeight: "25vh", overflowY: "auto" }}>
        {displayedErrors.map((key) => (
          <Box key={key} sx={{ marginBottom: 1, marginTop: 2 }}>
            <Typography
              variant="h6"
              sx={{ fontWeight: "bold", marginBottom: 1 }}
            >
              {key}
            </Typography>
            <Typography sx={{ color: "red" }}>{columnErrors[key]}</Typography>
          </Box>
        ))}

        {hasMoreErrors && (
          <CustomButton
            orientation="row"
            fontSize={14}
            fontWeight={600}
            sx={{
              minWidth: 120,
              maxHeight: 20,
              paddingY: 3,
              borderRadius: 3,
              marginTop: 2,
            }}
            title={showAllErrors ? "Λιγότερα" : "Περισσότερα"}
            onClick={() => setShowAllErrors((prev) => !prev)}
          />
        )}
      </Box>
    </Box>
  );
};

DataValidationStep.propTypes = {
  fileData: PropTypes.array,
  onValidationErrors: PropTypes.func.isRequired,
  onFileDataUpdate: PropTypes.func.isRequired,
  handleCloseUploadModal: PropTypes.func.isRequired,
};

export default DataValidationStep;
