import { requests } from "api/HttpClient";

const { post, get, put, delete: deleteRequest } = requests;

const users = {
  getAll: ({
    pageNumber = -1,
    pageSize = -1,
    searchParameter,
    role,
    status,
    orderBy,
    sortingOrder,
  }) => {
    let url = "/User/GetAll";

    const queryParams = [];

    if (pageNumber && pageSize) {
      queryParams.push(`pageNumber=${pageNumber}`);
      queryParams.push(`pageSize=${pageSize}`);
    }

    if (searchParameter) {
      queryParams.push(`searchParameter=${searchParameter}`);
    }

    if (orderBy) {
      queryParams.push(`orderBy=${orderBy}`);
    }

    if (sortingOrder) {
      queryParams.push(`sortingOrder=${sortingOrder}`);
    }

    if (role) {
      role.forEach((role) => {
        queryParams.push(`Role=${encodeURIComponent(role)}`);
      });
    }

    if (status) {
      status.forEach((status) => {
        queryParams.push(`Status=${encodeURIComponent(status)}`);
      });
    }

    if (queryParams.length > 0) {
      url += `?${queryParams.join("&")}`;
    }

    return get(url);
  },
  getById: (id) => get(`/User/${id}`),
  getByEmail: (email) => get(`/User/GetByEmail/${email}`),
  getUserRolesCount: () => get("User/GetUserRolesCount"),
  create: (data) =>
    post("/User/CreateUser", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }),
  update: (id, data) =>
    put(`/User/UpdateUser/${id}`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }),
  delete: (id) => deleteRequest(`/User/DeleteUser/${id}`),
};

export default users;
