import { Box } from "@mui/material";
import MainPageLayout from "components/main/MainPageLayout";
import SpinningCircle from "components/spinners/SpinningCircle";
import { useGetAssetById } from "hooks/queries/useAssets";
import { useState } from "react";
import { Outlet, useParams } from "react-router";

const ASSET_MAIN_TABS = [
  {
    id: 0,
    label: "Λεπτομέρειες",
    to: ``,
  },
  {
    id: 1,
    label: "Εξοπλισμός",
    to: `equipment`,
  },
  {
    id: 2,
    label: "Συντηρήσεις",
    to: `maintenance`,
  },
  {
    id: 3,
    label: "Πιστοποιήσεις",
    to: `certification`,
  },
  {
    id: 4,
    label: "Συμβάσεις",
    to: `contracts`,
  },
  {
    id: 5,
    label: "Ενοικιάσεις",
    to: `rents`,
  },
];

const AssetViewEditPageParent = () => {
  const { id } = useParams();
  const [isDeleted, setIsDeleted] = useState(false);
  const {
    data: selectedAsset,
    isLoading,
    isError,
    error,
  } = useGetAssetById(id, {
    enabled: !!id && !isDeleted,
  });

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          height: "100%",
        }}
      >
        <SpinningCircle />
      </Box>
    );
  }

  if (isError) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          height: "100%",
        }}
      >
        <p>Error: {error.message}</p>
      </Box>
    );
  }

  return (
    selectedAsset && (
      <MainPageLayout
        pageTitle={selectedAsset?.name}
        pageSubTitle={selectedAsset?.assetCategory?.description}
        headerTabs={ASSET_MAIN_TABS.map((tab) => ({
          ...tab,
          to: `/assets/${id}/${tab.to}`,
        }))}
      >
        <Outlet context={{ selectedAsset, setIsDeleted }} />
      </MainPageLayout>
    )
  );
};

export default AssetViewEditPageParent;
