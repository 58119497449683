import { requests } from "api/HttpClient";

const { post, get, put, delete: deleteRequest } = requests;

const config = {
  headers: {
    "Content-Type": "multipart/form-data",
  },
};

const rentHistory = {
  getAll: ({ pageNumber = -1, pageSize = -1, rentId = null }) => {
    let url = "/RentHistory/GetRentHistoryList";
    if (pageNumber && pageSize && rentId) {
      url += `?pageNumber=${pageNumber}&pageSize=${pageSize}&rentId=${rentId}`;
    }
    if (pageNumber && pageSize && !rentId) {
      url += `?pageNumber=${pageNumber}&pageSize=${pageSize}`;
    }
    return get(url);
  },
  getById: (id) => get(`/RentHistory/GetRentHistoryById/${id}`),
  create: (data) => post("/RentHistory/CreateRentHistory", data, config),
  update: (id, data) =>
    put(`/RentHistory/UpdateRentHistory/${id}`, data, config),
  delete: (id) => deleteRequest(`/RentHistory/DeleteRentHistory/${id}`),
};

export default rentHistory;
