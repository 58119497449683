import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from "@mui/material";
import CustomTableItem from "./CustomTableItem";
import colors from "config/theme/colors";

const CustomTable = ({
  columns,
  data,
  fadeEffect = false,
  onItemClick,
  page,
  rowsPerPage,
  handleChangePage,
  handleChangeRowsPerPage,
  totalResults,
  orderDirection,
  valueToOrderBy,
  onSortChange,
  disableClick = false,
  maxHeight,
  textWeight,
}) => {
  const [selectedItem, setSelectedItem] = useState(null);

  const handleRequestSort = (columnId) => {
    const isAscending = valueToOrderBy === columnId && orderDirection === "asc";
    onSortChange(columnId, isAscending ? "desc" : "asc");
  };

  return (
    <>
      <Box
        sx={{
          position: "relative",
          "&::after, &::before": {
            content: '""',
            position: "absolute",
            display: fadeEffect ? "block" : "none",
            left: 0,
            right: 0,
            pointerEvents: "none",
          },
          "&::after": {
            height: "100px",
            bottom: 0,
            background:
              "linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 1))",
          },
          "&::before": {
            height: "130px",
            top: 0,
            background:
              "linear-gradient(rgba(255, 255, 255, 1), rgba(255, 255, 255, 0))",
          },
        }}
      >
        <TableContainer
          component={Paper}
          sx={{
            border: "none",
            boxShadow: "none",
            paddingBottom: "2rem",
            maxHeight: maxHeight || `calc(100vh - 440px)`,
          }}
        >
          <Table
            stickyHeader
            sx={{
              borderCollapse: "separate",
              borderSpacing: "0 1rem",
              "& th": {
                borderBottom: 0,
                backgroundColor: colors.white,
              },
            }}
          >
            <TableHead>
              <TableRow>
                {columns?.map((column) => (
                  <TableCell key={column.id}>
                    {column.isSortable ? (
                      <TableSortLabel
                        active={valueToOrderBy === column.id}
                        direction={
                          valueToOrderBy === column.id ? orderDirection : "asc"
                        }
                        onClick={() => handleRequestSort(column.id)}
                      >
                        <Typography fontSize={14} fontWeight={700}>
                          {column.label}
                        </Typography>
                      </TableSortLabel>
                    ) : (
                      <Typography fontSize={14} fontWeight={700}>
                        {column.label}
                      </Typography>
                    )}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.map((item, index) => (
                <CustomTableItem
                  key={item.id || index}
                  item={item}
                  onClick={() => {
                    selectedItem?.id === item.id
                      ? setSelectedItem(null)
                      : setSelectedItem(item);
                    onItemClick(item);
                  }}
                  headers={columns}
                  isSelected={selectedItem?.id === item.id}
                  disableClick={disableClick}
                  textWeight={textWeight}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 100]}
        component="div"
        count={totalResults}
        size="small"
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage="Αποτελέσματα ανά σελίδα"
        labelDisplayedRows={({ from, to, count }) =>
          `${from}-${to} από ${count}`
        }
        sx={{
          display: "flex",
          flexWrap: "wrap",
          width: "100%",
          justifyContent: "center",
          "& .MuiTablePagination-input": {
            fontSize: 14,
          },
          "& .MuiTablePagination-actions": {
            fontSize: 14,
          },
        }}
      />
    </>
  );
};

CustomTable.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      isSortable: PropTypes.bool,
    }).isRequired
  ).isRequired,
  data: PropTypes.array.isRequired,
  fadeEffect: PropTypes.bool,
  onItemClick: PropTypes.func,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  totalResults: PropTypes.number.isRequired,
  orderDirection: PropTypes.string,
  valueToOrderBy: PropTypes.string,
  onSortChange: PropTypes.func,
  handleChangePage: PropTypes.func.isRequired,
  handleChangeRowsPerPage: PropTypes.func.isRequired,
  disableClick: PropTypes.bool,
  maxHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  textWeight: PropTypes.number,
};

export default CustomTable;
