export const formatNumbersWithSeparator = (value, decimalDigits = 0) => {
  if (value === undefined || value === null || value === "" || isNaN(value))
    return value;

  if (decimalDigits > 0) {
    return new Intl.NumberFormat("el-GR", {
      minimumFractionDigits: decimalDigits,
      maximumFractionDigits: decimalDigits,
    }).format(value);
  }

  return new Intl.NumberFormat("el-GR").format(value);
};
