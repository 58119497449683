import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Agent } from "api/agent";

export const useGetAllContracts = (
  {
    pageNumber = -1,
    pageSize = -1,
    maintenanceTypeId,
    companyId,
    assetId,
    searchParameter = "",
    facilityType = [],
    serviceType = [],
    status = [],
    orderBy = "",
    sortingOrder = "",
  } = {
    pageNumber: -1,
    pageSize: -1,
    maintenanceTypeId: null,
    companyId: null,
    assetId: null,
    searchParameter: "",
    facilityType: [],
    serviceType: [],
    status: [],
    orderBy: "",
    sortingOrder: "",
  },
  options = {}
) => {
  return useQuery({
    queryKey: [
      "getAllContracts",
      pageNumber,
      pageSize,
      maintenanceTypeId,
      companyId,
      assetId,
      searchParameter,
      facilityType,
      serviceType,
      status,
      orderBy,
      sortingOrder,
    ],
    queryFn: () =>
      Agent.contracts.getAll({
        pageNumber,
        pageSize,
        maintenanceTypeId,
        companyId,
        assetId,
        searchParameter,
        facilityType,
        serviceType,
        status,
        orderBy,
        sortingOrder,
      }),
    staleTime: 1000 * 60 * 60,
    ...options,
  });
};

export const useGetContractById = (contractId, options = {}) => {
  return useQuery({
    queryKey: ["getContractById", contractId],
    queryFn: () => Agent.contracts.getById(contractId),
    staleTime: 1000 * 60 * 10,
    ...options,
  });
};

export const useGetContractTypeCount = (options = {}) => {
  return useQuery({
    queryKey: ["getContractTypeCount"],
    queryFn: () => Agent.contracts.getContractTypeCount(),
    staleTime: 1000 * 60 * 10,
    ...options,
  });
};

export const useCreateContract = ({ config = {} }) => {
  const queryClient = useQueryClient();
  const { onSuccess, ...restConfig } = config;

  return useMutation({
    mutationKey: ["createContract"],
    mutationFn: (body) => Agent.contracts.create(body),
    onSuccess: (...args) => {
      queryClient.invalidateQueries({ queryKey: ["getAllContracts"] });
      queryClient.invalidateQueries({ queryKey: ["getContractTypeCount"] });
      onSuccess?.(...args);
    },
    ...restConfig,
  });
};

export const useUpdateContract = ({ contractId, config = {} }) => {
  const queryClient = useQueryClient();
  const { onSuccess, ...restConfig } = config;

  return useMutation({
    mutationKey: ["updateContract"],
    mutationFn: (body) => Agent.contracts.update(contractId, body),
    onSuccess: (...args) => {
      queryClient.invalidateQueries({ queryKey: ["getAllContracts"] });
      queryClient.invalidateQueries({
        queryKey: ["getContractById", contractId],
      });
      onSuccess?.(...args);
    },
    ...restConfig,
  });
};

export const useDeleteContract = ({ contractId, config = {} }) => {
  const queryClient = useQueryClient();
  const { onSuccess, ...restConfig } = config;

  return useMutation({
    mutationKey: ["deleteContract"],
    mutationFn: () => Agent.contracts.delete(contractId),
    onSuccess: (...args) => {
      queryClient.invalidateQueries({ queryKey: ["getAllContracts"] });
      queryClient.invalidateQueries({
        queryKey: ["getContractById"],
        contractId,
      });
      queryClient.invalidateQueries({ queryKey: ["getContractTypeCount"] });
      onSuccess?.(...args);
    },
    ...restConfig,
  });
};
