import MainPageLayout from "components/main/MainPageLayout";
import { useNavigate } from "react-router";
import { useAlert } from "hooks/useAlert";
import TicketCreateForm from "./TicketCreateForm";
import { useCreateTicket } from "hooks/queries/useTickets";
import { useUploadFiles } from "hooks/queries/useFile";

const TicketCreate = () => {
  const navigate = useNavigate();
  const { showError, showSuccess, showWarning } = useAlert();
  const { mutate: uploadFiles } = useUploadFiles({
    fileableType: "Ticket",
    config: {
      onError: (error) => {
        showWarning(
          "Το αίτημα δημιουργήθηκε, αλλά προέκυψε σφάλμα κατά την αποθήκευση των αρχείων. Δοκιμάστε ξανά."
        );
        navigate("/tickets");
        console.error(error);
      },
      onSuccess: () => {
        showSuccess("Το αίτημα δημιουργήθηκε με επιτυχία");
        navigate("/tickets");
      },
    },
  });
  const { mutate: createTicket } = useCreateTicket({
    config: {
      onError: (error) => {
        showError(error.message);
      },
      onSuccess: () => {
        showSuccess("Το αίτημα δημιουργήθηκε με επιτυχία");
        navigate("/tickets");
      },
    },
  });

  const handleCreateClick = (data) => {
    const payload = {
      name: data.name,
      description: data.description,
      assetId:
        data.asset?.id && data.equipment?.id ? null : parseInt(data.asset?.id),
      equipmentId: data.equipment?.id ? parseInt(data.equipment?.id) : null,
    };

    const files = data.files;

    createTicket(payload, {
      onSuccess: (ticket) => {
        if (files?.length > 0) {
          uploadFiles({ files, entityId: ticket?.result?.id });
        } else {
          showSuccess("Το αίτημα δημιουργήθηκε με επιτυχία");
          navigate("/tickets");
        }
      },
    });
  };

  return (
    <MainPageLayout
      pageTitle="Νέο Αίτημα"
      pageSubTitle="Δημιουργία νέου αιτήματος"
    >
      <TicketCreateForm onCreate={handleCreateClick} />
    </MainPageLayout>
  );
};

export default TicketCreate;
