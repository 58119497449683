import { makeAutoObservable } from "mobx";

class SidebarStore {
  isCollapsed = false;

  constructor() {
    makeAutoObservable(this);
  }

  toggleSidebar() {
    this.isCollapsed = !this.isCollapsed;
  }

  setSidebar(state) {
    this.isCollapsed = state;
  }
}

const sidebarStore = new SidebarStore();
export default sidebarStore;
