import { useState } from "react";
import MainPageLayout from "components/main/MainPageLayout";
import DropdownField from "components/inputs/DropdownField";
import Box from "@mui/material/Box";
import CompanyReports from "./companies/CompanyReports";
import TicketReports from "./tickets/TicketReports";
import TaskReports from "./tasks/TaskReports";
import enums from "config/enums";
import ContractReports from "./contracts/ContractReports";
import AssetReports from "./assets/AssetsReports";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

const Reports = () => {
  const [selectedReportReference, setSelectedReportReference] = useState("");

  const availableReportTypeOptions = enums.ReportReferences.map(
    (type) => type.label
  );

  function renderReportsPerType() {
    switch (selectedReportReference) {
      case "Ακίνητα":
        return <AssetReports />;
      case "Οργανισμοί":
        return <CompanyReports />;
      case "Τεχνικά Αιτήματα":
        return <TicketReports />;
      case "Εργασίες":
        return <TaskReports />;
      case "Συμβάσεις":
        return <ContractReports />;
      default:
        return null;
    }
  }

  return (
    <MainPageLayout pageTitle="Αναφορές">
      <Box
        display="flex"
        flexDirection="column"
        gap={2}
        sx={{
          width: "100%",
        }}
        mb={2}
      >
        <DropdownField
          label="Επιλέξτε Είδος Αναφοράς"
          value={selectedReportReference}
          onChange={(_, value) =>
            setSelectedReportReference(
              enums.ReportReferences.find((item) => item.label === value)?.label
            )
          }
          options={availableReportTypeOptions}
          required
        />
      </Box>

      {renderReportsPerType()}
    </MainPageLayout>
  );
};

export default Reports;
