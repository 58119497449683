import React, { forwardRef } from "react";
import IconButton from "@mui/material/IconButton";
import BorderColorRoundedIcon from "@mui/icons-material/BorderColorRounded";
import PropTypes from "prop-types";
import colors from "config/theme/colors";

const EditCircleButton = forwardRef(({ onClick, isEditing = false }, ref) => {
  return (
    <IconButton
      ref={ref}
      onClick={onClick}
      size="small"
      sx={{
        backgroundColor: isEditing ? colors.primary : colors.white,
        color: isEditing ? colors.white : colors.primary,
        border: `1px solid ${colors.primary}`,
        "&:hover": {
          backgroundColor: colors.primary,
          color: colors.white,
        },
      }}
    >
      <BorderColorRoundedIcon fontSize="small" />
    </IconButton>
  );
});

EditCircleButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  isEditing: PropTypes.bool,
};

export default EditCircleButton;
