import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Agent } from "api/agent";

export const useGetAllMaintenanceTypes = (
  { pageNumber = -1, pageSize = -1, assetCategoryId, equipmentTypeIds } = {
    pageNumber: -1,
    pageSize: -1,
    assetCategoryId: null,
    equipmentTypeIds: null,
  },
  options = {}
) => {
  return useQuery({
    queryKey: [
      "getAllMaintenanceTypes",
      pageNumber,
      pageSize,
      assetCategoryId,
      equipmentTypeIds,
    ],
    queryFn: () =>
      Agent.maintenanceType.getAll({
        pageNumber: pageNumber,
        pageSize: pageSize,
        assetCategoryId: assetCategoryId,
        equipmentTypeIds: equipmentTypeIds,
      }),
    staleTime: 1000 * 60 * 60,
    ...options,
  });
};

export const useGetMaintenanceTypeById = (id, options = {}) => {
  return useQuery({
    queryKey: ["getMaintenanceTypeById", id],
    queryFn: () => Agent.maintenanceType.getById(id),
    staleTime: 1000 * 60 * 10,
    ...options,
  });
};

export const useCreateMaintenanceType = ({ config = {} }) => {
  const queryClient = useQueryClient();
  const { onSuccess, ...restConfig } = config;

  return useMutation({
    mutationKey: ["createMaintenanceType"],
    mutationFn: (body) => Agent.maintenanceType.create(body),
    onSuccess: (...args) => {
      queryClient.invalidateQueries({
        queryKey: ["getAllMaintenanceTypes"],
      });
      onSuccess?.(...args);
    },
    ...restConfig,
  });
};

export const useUpdateMaintenanceType = ({ id, config = {} }) => {
  const queryClient = useQueryClient();
  const { onSuccess, ...restConfig } = config;

  return useMutation({
    mutationKey: ["updateMaintenanceType"],
    mutationFn: (body) => Agent.maintenanceType.update(id, body),
    onSuccess: (...args) => {
      queryClient.invalidateQueries({
        queryKey: ["getAllMaintenanceTypes"],
      });
      queryClient.invalidateQueries({
        queryKey: ["getMaintenanceTypeById", id],
      });
      onSuccess?.(...args);
    },
    ...restConfig,
  });
};

export const useDeleteMaintenanceType = ({ id, config = {} }) => {
  const queryClient = useQueryClient();
  const { onSuccess, ...restConfig } = config;

  return useMutation({
    mutationKey: ["deleteMaintenanceType"],
    mutationFn: () => Agent.maintenanceType.delete(id),
    onSuccess: (...args) => {
      queryClient.invalidateQueries({
        queryKey: ["getAllMaintenanceTypes"],
      });
      onSuccess?.(...args);
    },
    ...restConfig,
  });
};
