import React from "react";
import { Typography, Box, Button } from "@mui/material";
import colors from "config/theme/colors";

const NoAccessPage = () => {
  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: colors.greyBackground,
      }}
    >
      <Typography
        variant="h3"
        sx={{ color: colors.error, marginBottom: "20px" }}
      >
        Απαγορεύεται η πρόσβαση
      </Typography>
      <Typography
        variant="h6"
        sx={{
          color: colors.text,
          maxWidth: "600px",
          textAlign: "center",
          marginBottom: "20px",
        }}
      >
        Δεν έχετε πρόσβαση σε αυτή τη σελίδα. Παρακαλούμε επικοινωνήστε με τον
        διαχειριστή του συστήματος για περισσότερες πληροφορίες.
      </Typography>
      <Button
        variant="contained"
        color="secondary"
        sx={{ fontWeight: "bold" }}
        href="/"
      >
        Επιστροφή στην αρχική σελίδα
      </Button>
    </Box>
  );
};

export default NoAccessPage;
