import { Grid } from "@mui/material";
import CountCard from "components/cards/CountCard";
import SpinningCircle from "components/spinners/SpinningCircle";
import { chartsPalette } from "config/theme/chartsColorScheme";
import dayjs from "dayjs";
import { useGetAssetCategoryCount } from "hooks/queries/useAssets";
import { useGetGeneralStats } from "hooks/queries/useDashboard";

const palette = [...chartsPalette.slice(10)];

const DashboardBoxStats = () => {
  const {
    data: assetsCategoriesCount,
    isLoading: isAssetsCategoriesCountLoading,
    error: assetsCategoriesCountError,
  } = useGetAssetCategoryCount({
    select: (data) => ({
      buildings:
        data.result
          ?.filter(
            (item) =>
              item.name === "Κτήριο" ||
              item.name === "Σχολείο" ||
              item.name === "Αθλητικές εγκαταστάσεις"
          )
          ?.reduce((acc, item) => acc + item.count, 0) || 0,
      lands:
        data.result
          ?.filter((item) => item.name === "Οικόπεδο" || item.name === "Ρέμα")
          ?.reduce((acc, item) => acc + item.count, 0) || 0,
      publicSpaces:
        data.result
          ?.filter((item) => item.name === "Κοινόχρηστος χώρος / Πάρκο")
          ?.reduce((acc, item) => acc + item.count, 0) || 0,
    }),
  });

  const {
    data: generalStats,
    isLoading: isGeneralStatsLoading,
    error: generalStatsError,
  } = useGetGeneralStats(
    {
      startDate: dayjs().startOf("year").format("YYYY-01-01"),
      endDate: dayjs().format("YYYY-MM-DD"),
    },
    {
      select: (data) => {
        return data.result;
      },
    }
  );

  if (isGeneralStatsLoading || isAssetsCategoriesCountLoading) {
    return <SpinningCircle />;
  }

  return (
    <Grid container spacing={2} marginBottom={2}>
      {!assetsCategoriesCountError && (
        <>
          <Grid item xs={12} sm={6} md={3}>
            <CountCard
              title="Κτηριακά Συγκροτήματα"
              count={assetsCategoriesCount?.buildings ?? "-"}
              info="Κτήρια, Σχολεία, Αθλητικές Εγκαταστάσεις"
              backgroundColor={palette[0]}
              boxSx={{
                minWidth: { xs: "100%", lg: "250px" },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <CountCard
              title="Ανοικοδόμητες Εκτάσεις"
              count={assetsCategoriesCount?.lands ?? "-"}
              info="Οικόπεδα, Ρέματα"
              backgroundColor={palette[3]}
              boxSx={{
                minWidth: { xs: "100%", lg: "250px" },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <CountCard
              title="Δημόσιοι Ανοιχτοί Χώροι"
              count={assetsCategoriesCount?.publicSpaces ?? "-"}
              info="Κοινόχρηστοι χώροι, Πάρκα"
              backgroundColor={palette[8]}
              boxSx={{
                minWidth: { xs: "100%", lg: "250px" },
              }}
            />
          </Grid>
        </>
      )}
      {!generalStatsError && (
        <>
          <Grid item xs={12} sm={6} md={3}>
            <CountCard
              title="Πλήθος Εξοπλισμών"
              count={generalStats?.totalEquipments ?? "-"}
              info="Συνολικός αριθμός εξοπλισμών στα κτήρια"
              backgroundColor={palette[10]}
              boxSx={{
                minWidth: { xs: "100%", lg: "250px" },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <CountCard
              title="Συνολικά έσοδα ενοικίων"
              count={generalStats?.totalRentIncome ?? "-"}
              info="Τα έσοδα από τις ενοικιάσεις των κτηρίων"
              endTextSymbol="€"
              backgroundColor={palette[11]}
              boxSx={{
                minWidth: { xs: "100%", lg: "250px" },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <CountCard
              title="Συντηρήσεις τρέχοντος έτους"
              count={generalStats?.totalMaintenances ?? "-"}
              info="Αριθμός συντηρήσεων που έχουν ολοκληρωθεί φέτος"
              backgroundColor={palette[14]}
              boxSx={{
                minWidth: { xs: "100%", lg: "250px" },
              }}
            />
          </Grid>
        </>
      )}
      <Grid item xs={12} sm={6} md={3}>
        <CountCard
          title="Συντηρήσεις που λήγουν στον τρέχοντα μήνα"
          count={generalStats?.expiringMaintenancesEndOfMonth ?? "-"}
          info="Αριθμός συντηρήσεων που λήγουν μέχρι το τέλος του τρέχοντα μήνα"
          backgroundColor={palette[4]}
          boxSx={{
            minWidth: { xs: "100%", lg: "250px" },
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <CountCard
          title="Συμβάσεις που λήγουν στον τρέχοντα μήνα"
          count={generalStats?.expiringContractsEndOfMonth ?? "-"}
          info="Αριθμός συμβάσεων που λήγουν μέχρι το τέλος του τρέχοντα μήνα"
          backgroundColor={palette[6]}
          boxSx={{
            minWidth: { xs: "100%", lg: "250px" },
          }}
        />
      </Grid>
    </Grid>
  );
};

export default DashboardBoxStats;
