import { Box, Typography } from "@mui/material";
import SpinningCircle from "components/spinners/SpinningCircle";
import CustomTable from "components/tables/CustomTable";
import { useMemo, useState, useCallback } from "react";
import { useNavigate, useOutletContext } from "react-router";
import { useGetAllContracts } from "hooks/queries/useContracts";
import enums from "config/enums";
import dayjs from "dayjs";
import TagLabel from "components/labels/TagLabel";
import FilterButton from "components/buttons/FilterButton";
import usePagination from "hooks/usePagination";
import useSorting from "hooks/useSorting";
import StatusLabel from "components/labels/StatusLabel";

const AssetContractsOverview = () => {
  const navigate = useNavigate();
  const { selectedAsset } = useOutletContext();
  const {
    page,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
    setPage,
  } = usePagination();
  const [selectedFilters, setSelectedFilters] = useState({});
  const [selectedFacilityTypes, setSelectedFacilityTypes] = useState([]);
  const [selectedServiceTypes, setSelectedServiceTypes] = useState([]);
  const [selectedStatuses, setSelectedStatuses] = useState([]);
  const { orderDirection, valueToOrderBy, handleSortChange } = useSorting();
  const {
    data: contracts,
    isLoading: isContractsLoading,
    error: errorContracts,
    refetch: refetchContracts,
  } = useGetAllContracts({
    pageNumber: page,
    pageSize: rowsPerPage,
    assetId: selectedAsset?.id,
    facilityType: selectedFacilityTypes,
    serviceType: selectedServiceTypes,
    status: selectedStatuses,
    orderBy: valueToOrderBy,
    sortingOrder: orderDirection === "asc" ? "Ascending" : "Descending",
  });

  const filterGroups = [
    {
      title: "Τύπος Εγκατάστασης",
      filters: enums.FacilityTypes.map((type) => ({
        id: type.id,
        label: type.label,
        type: "checkbox",
      })),
    },
    {
      title: "Τύπος Σύμβασης",
      filters: enums.ContractTypes.map((type) => ({
        id: type.id,
        label: type.label,
        type: "checkbox",
      })),
    },
    {
      title: "Κατάσταση Σύμβασης",
      filters: enums.ContractStatuses.map((type) => ({
        id: type.id,
        label: type.label,
        type: "checkbox",
      })),
    },
  ];

  const handleSubmitFilters = useCallback(
    (filters) => {
      const facilityTypes = filters["Τύπος Εγκατάστασης"] || [];
      const contractTypes = filters["Τύπος Σύμβασης"] || [];
      const contractStatuses = filters["Κατάσταση Σύμβασης"] || [];
      setSelectedFilters(filters);
      setSelectedFacilityTypes(facilityTypes);
      setSelectedServiceTypes(contractTypes);
      setSelectedStatuses(contractStatuses);
      if (filters) {
        setPage(1);
      }

      if (
        facilityTypes.length === 0 &&
        contractTypes.length === 0 &&
        contractStatuses.length === 0
      ) {
        refetchContracts();
      }
    },
    [
      setSelectedFilters,
      setSelectedFacilityTypes,
      setSelectedServiceTypes,
      setSelectedStatuses,
      setPage,
      refetchContracts,
    ]
  );

  const tableHeaders = useMemo(
    () => [
      {
        id: "name",
        label: "Σύμβαση",
        isSortable: true,
      },
      {
        id: "number",
        label: "Αριθμός",
        isSortable: true,
      },
      {
        id: "externalCompanyContactPerson",
        label: "Συμβαλλόμενος",
        isSortable: true,
      },
      {
        id: "validFrom",
        label: "Ημ/νία ΄Eναρξης",
        isSortable: true,
      },
      {
        id: "validTo",
        label: "Ημ/νία Λήξης",
        isSortable: true,
      },
      {
        id: "type",
        label: "Τύπος Σύμβασης",
        isSortable: false,
      },
      {
        id: "status",
        label: "Κατάσταση",
        isSortable: false,
      },
    ],
    []
  );

  if (isContractsLoading) {
    return <SpinningCircle />;
  }

  if (errorContracts) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          height: "100%",
        }}
      >
        <p>Error: {errorContracts.message}</p>
      </Box>
    );
  }

  const contractsCount = contracts?.totalCount;
  const contractsList = contracts?.items?.map((contract) => ({
    id: contract.id,
    name: contract.name || "-",
    externalCompanyContactPerson: contract?.externalCompany?.name || "-",
    number: contract.number || "-",
    validFrom: dayjs(contract.validFrom).format("DD/MM/YYYY") || "-",
    validTo: dayjs(contract.validTo).format("DD/MM/YYYY") || "-",
    type: enums.ContractTypes.find((type) => type.id === contract.type)?.label,
    status: contract.status ? (
      <StatusLabel
        statusValue={contract.status}
        statusText={
          enums.ContractStatuses.find((status) => status.id === contract.status)
            ?.label
        }
      />
    ) : (
      "-"
    ),
  }));

  const handleItemClick = (item) => {
    navigate(`${item.id}`);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={2}
      sx={{
        height: "100%",
      }}
    >
      <Box
        mt={4}
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        flexGrow={1}
        gap={2}
        width={"100%"}
      >
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          gap={4}
          flexWrap={"wrap"}
        >
          <Box display="flex" gap={2}>
            <Typography variant="h3" color="primary">
              Συμβάσεις ({contractsCount})
            </Typography>
            <Box display="flex" flexDirection="row" gap={1} flexWrap={"wrap"}>
              {enums.ContractTypes.map((contractType) => {
                const count = contractsList?.filter(
                  (contractItem) => contractItem.type === contractType.label
                ).length;
                return count > 0 ? (
                  <TagLabel
                    key={contractType.id}
                    text={`${count} ${contractType.label}`}
                  />
                ) : null;
              })}
            </Box>
          </Box>
          <Box display="flex" flexGrow={1} justifyContent="flex-end">
            <FilterButton
              selectedFilters={selectedFilters}
              filterGroups={filterGroups}
              onApplyFilters={handleSubmitFilters}
            />
          </Box>
        </Box>

        {contractsList.length > 0 && tableHeaders && contractsCount > 0 ? (
          <CustomTable
            columns={tableHeaders}
            data={contractsList}
            fadeEffect
            onItemClick={handleItemClick}
            page={page - 1}
            rowsPerPage={rowsPerPage}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            totalResults={contractsCount}
            orderDirection={orderDirection}
            valueToOrderBy={valueToOrderBy}
            onSortChange={handleSortChange}
          />
        ) : null}
      </Box>
    </Box>
  );
};

export default AssetContractsOverview;
