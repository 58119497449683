import { Box, Grid, Typography } from "@mui/material";
import { ReactComponent as LogoIcon } from "assets/logo.svg";
import colors from "config/theme/colors";

const PromoPage = () => {
  return (
    <Grid
      container
      item
      mt={{ xs: 2, md: 0 }}
      xs={12}
      md={6}
      paddingBottom={10}
      justifyContent={"center"}
      alignItems={"center"}
      sx={{ background: colors.primary }}
    >
      <Grid item display="flex" flexDirection="column" justifyContent="center">
        <div className="flex items-center justify-center p-10">
          <LogoIcon fill={colors.primary} width={100} height={100} />
          <div className="flex flex-col">
            <Typography
              fontWeight={900}
              fontSize={40}
              color={colors.white}
              lineHeight={"40px"}
              letterSpacing={"0.25px"}
            >
              Facility
            </Typography>
            <Typography
              fontWeight={400}
              fontSize={16}
              textTransform={"uppercase"}
              color={colors.white}
              textAlign={"right"}
            >
              Manager
            </Typography>
          </div>
        </div>
        <p
          style={{
            color: "white",
            textAlign: "center",
            width: "100%",
            paddingLeft: "25%",
            paddingRight: "25%",
          }}
        >
          Διαχειριστείτε αβίαστα τα περιουσιακά σας στοιχεία και τις ιδιοκτησίες
          σας με την ολοκληρωμένη εφαρμογή μας διαχείρισης περιουσιακών
          στοιχείων
        </p>
        <Box
          sx={{
            backgroundColor: colors.white,
            height: "4px",
            width: "15px",
            borderRadius: "4px",
            margin: "auto",
            marginTop: "1rem",
          }}
        />
      </Grid>
    </Grid>
  );
};

export default PromoPage;
