import { Grid } from "@mui/material";
import CustomBarChart from "components/charts/CustomBarChart";
import CustomLineChart from "components/charts/CustomLineChart";
import CustomHorizontalBarChart from "components/charts/CustomHorizontalBarChart";
import SpinningCircle from "components/spinners/SpinningCircle";
import dayjs from "dayjs";
import {
  useGetCountOfMaintenancesAndCertificationsPerAsset,
  useGetRentIncomeExpensePerMonth,
  useGetTotalMaintenanceCostPerAsset,
  useGetTotalMaintenanceCostPerMonth,
  useGetTotalMaintenanceCostPerEquipmentType,
} from "hooks/queries/useDashboard";
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(customParseFormat);

const DashboardCharts = () => {
  const {
    data: maintenanceAndCertificationCountsPerAsset,
    isLoading: isMaintenanceAndCertificationCountsPerAssetLoading,
    error: maintenanceAndCertificationCountsPerAssetError,
  } = useGetCountOfMaintenancesAndCertificationsPerAsset(
    {
      startDate: dayjs().startOf("year").format("YYYY-01-01"),
      endDate: dayjs().format("YYYY-MM-DD"),
    },
    {
      select: (data) => {
        const chartData = {
          labels: data.result?.map((item) => item.assetName) || [],
          datasets: [
            {
              label: "Πλήθος Συντηρήσεων",
              data: data.result?.map((item) => item.maintenanceCount) || [],
              borderRadius: 4,
            },
            {
              label: "Πλήθος Πιστοποιήσεων",
              data: data.result?.map((item) => item.certificationCount) || [],
              borderRadius: 4,
            },
          ],
        };
        return chartData;
      },
    }
  );

  const {
    data: maintenanceCostsPerAsset,
    isLoading: isMaintenanceCostsPerAssetLoading,
    error: maintenanceCostsPerAssetError,
  } = useGetTotalMaintenanceCostPerAsset(
    {
      startDate: dayjs().startOf("year").format("YYYY-01-01"),
      endDate: dayjs().format("YYYY-MM-DD"),
    },
    {
      select: (data) => {
        const maintenanceTypes = data.result?.reduce((acc, assetItem) => {
          assetItem.maintenanceTypeCosts.forEach((item) => {
            if (!acc.includes(item.maintenanceType)) {
              acc.push(item.maintenanceType);
            }
          });
          return acc;
        }, []);

        const chartData = {
          labels: data.result?.map((item) => item.assetName) || [],
          datasets: maintenanceTypes.map((maintenanceType) => {
            return {
              label: maintenanceType,
              data: data.result?.map((assetItem) => {
                const maintenanceTypeCost = assetItem.maintenanceTypeCosts.find(
                  (item) => item.maintenanceType === maintenanceType
                );
                return maintenanceTypeCost?.totalMaintenanceCost || 0;
              }),
            };
          }),
        };
        return chartData;
      },
    }
  );

  const {
    data: maintenanceCostsPerMonth,
    isLoading: isMaintenanceCostsPerMonthLoading,
    error: maintenanceCostsPerMonthError,
  } = useGetTotalMaintenanceCostPerMonth(
    {
      startDate: dayjs().startOf("year").format("YYYY-01-01"),
      endDate: dayjs().format("YYYY-MM-DD"),
    },
    {
      select: (data) => {
        const monthList = Array.from({ length: dayjs().month() + 1 }, (_, i) =>
          dayjs().month(i).format("MMMM")
        );
        const chartData = {
          labels: monthList,
          datasets: [
            {
              label: "Συνολικό Κόστος Συντηρήσεων",
              data: monthList.map((month) => {
                const monthCost = data.result.find(
                  (item) => dayjs(item.date, "M/YYYY").format("MMMM") === month
                );
                return monthCost?.totalMaintenanceCost || 0;
              }),
              fill: false,
            },
          ],
        };
        return chartData;
      },
    }
  );

  const {
    data: rentIncomeExpensePerMonth,
    isLoading: isRentIncomeExpensePerMonthLoading,
    error: rentIncomeExpensePerMonthError,
  } = useGetRentIncomeExpensePerMonth(
    {
      startDate: dayjs().startOf("year").format("YYYY-01-01"),
      endDate: dayjs().format("YYYY-MM-DD"),
    },
    {
      select: (data) => {
        const monthList = Array.from({ length: dayjs().month() + 1 }, (_, i) =>
          dayjs().month(i).format("MMMM")
        );
        const chartData = {
          labels: monthList,
          datasets: [
            {
              label: "Έσοδα",
              data: monthList.map((month) => {
                const monthCost = data.result.find(
                  (item) => dayjs(item.date, "M/YYYY").format("MMMM") === month
                );
                return monthCost?.income || 0;
              }),
              fill: false,
            },
            {
              label: "Έξοδα",
              data: monthList.map((month) => {
                const monthCost = data.result.find(
                  (item) => dayjs(item.date, "M/YYYY").format("MMMM") === month
                );
                return monthCost?.expenses || 0;
              }),
              fill: false,
            },
          ],
        };
        return chartData;
      },
    }
  );

  const {
    data: totalMaintenanceCostPerEquipmentType,
    isLoading: isTotalMaintenanceCostPerEquipmentTypeLoading,
    error: totalMaintenanceCostPerEquipmentTypeError,
  } = useGetTotalMaintenanceCostPerEquipmentType();

  const transformMaintenanceCostsPerEquipmentTypeData = (data) => {
    if (!data || data.length === 0) return { labels: [], datasets: [] };

    const labels = data?.map((item) => item?.equipmentTypeName);
    const dataset = {
      label: "Συνολικό Κόστος Συντηρήσεων",
      data: data?.map((item) => item?.maintenanceCost),
    };

    return { labels, datasets: [dataset] };
  };

  const maintenanceCostsPerEquipmentTypeData =
    transformMaintenanceCostsPerEquipmentTypeData(
      totalMaintenanceCostPerEquipmentType || []
    );

  if (
    isMaintenanceAndCertificationCountsPerAssetLoading ||
    isMaintenanceCostsPerAssetLoading ||
    isMaintenanceCostsPerMonthLoading ||
    isRentIncomeExpensePerMonthLoading ||
    isTotalMaintenanceCostPerEquipmentTypeLoading
  ) {
    return <SpinningCircle />;
  }

  return (
    <Grid container spacing={4} sx={{ marginBottom: 4 }}>
      {!maintenanceAndCertificationCountsPerAssetError && (
        <Grid item xs={12} md={6}>
          <CustomBarChart
            title="Πλήθος Συντηρήσεων και Πιστοποιήσεων ανά Κτήριο"
            data={maintenanceAndCertificationCountsPerAsset}
            showDataLabels
          />
        </Grid>
      )}
      {!rentIncomeExpensePerMonthError && (
        <Grid item xs={12} md={6}>
          <CustomLineChart
            title="Έσοδα & Έξοδα Ενοικιάσεων"
            data={rentIncomeExpensePerMonth}
          />
        </Grid>
      )}
      {!maintenanceCostsPerAssetError && (
        <Grid item xs={12} md={6}>
          <CustomBarChart
            title="Συνολικό Κόστος Συντηρήσεων ανά Κτήριο"
            data={maintenanceCostsPerAsset}
            yAxisTitle="Ευρώ"
            tooltipIdentifier="€"
            stacked
            extraLabelText="€"
          />
        </Grid>
      )}
      <Grid item xs={12} md={6}>
        {!maintenanceCostsPerMonthError && (
          <CustomLineChart
            title="Συνολικό Κόστος Συντηρήσεων"
            data={maintenanceCostsPerMonth}
            isLegendVisible={false}
          />
        )}
      </Grid>

      <Grid item xs={12} md={6}>
        {!totalMaintenanceCostPerEquipmentTypeError && (
          <CustomHorizontalBarChart
            title="Συνολικό Κόστος Συντηρήσεων ανά Τύπο Εξοπλισμού"
            data={maintenanceCostsPerEquipmentTypeData}
            tooltipIdentifier="€"
            extraLabelText="€"
            displayLegend={false}
            barWidth={20}
            barBorderRadius={4}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default DashboardCharts;
