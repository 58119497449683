import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useGetAllMaintenances } from "hooks/queries/useMaintenances";
import PropTypes from "prop-types";
import SpinningCircle from "components/spinners/SpinningCircle";
import colors from "config/theme/colors";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import CustomTextField from "components/inputs/CustomTextField";
import DateField from "components/inputs/DateField";
import { Grid } from "@mui/material";
import SaveButton from "components/buttons/SaveButton";
import { useAlert } from "hooks/useAlert";
import { useIsMutating } from "@tanstack/react-query";
import { useAddMaintenanceHistoryInMultipleMaintenances } from "hooks/queries/useMaintenancesHistory";
import dayjs from "dayjs";

const maintenanceHistorySchema = yup.object().shape({
  name: yup.string().required("Το πεδίο είναι υποχρεωτικό"),
  date: yup.date().transform((value, originalValue) => {
    const parsedDate = new Date(originalValue);
    return isNaN(parsedDate) ? undefined : parsedDate;
  }),
  amount: yup
    .string()
    .required("Το πεδίο είναι υποχρεωτικό")
    .transform((value, originalValue) => {
      const cleanedValue = originalValue.replace(/\./g, "").replace(/,/g, ".");

      return cleanedValue;
    })
    .test("is-valid-format", "Μη έγκυρη μορφή αριθμού", (value, context) => {
      const isValidFormat = /^[0-9]+(\.[0-9]{1,2})?$/.test(value);
      return isValidFormat;
    })
    .test(
      "is-decimal",
      "Το πεδίο πρέπει να είναι αριθμός",
      (value) => !isNaN(parseFloat(value)) && isFinite(value)
    )
    .typeError("Το πεδίο πρέπει να είναι αριθμός"),
});

const EquipmentsMaintenanceHistoryCreation = ({
  equipmentsIds,
  contractId,
  afterFinalSubmission,
}) => {
  const { showError, showSuccess } = useAlert();

  const isMaintenancesHistoryCreateLoading =
    useIsMutating({
      mutationKey: ["addMaintenanceHistoryInMultipleMaintenances"],
    }) > 0;

  const { mutate: addMaintenanceHistoryInMultipleMaintenances } =
    useAddMaintenanceHistoryInMultipleMaintenances({
      config: {
        onError: (error) => {
          showError(error.message);
        },
        onSuccess: () => {
          afterFinalSubmission();
          showSuccess("Τα ιστορικά συντηρήσεων δημιουργήθηκαν με επιτυχία");
        },
      },
    });

  const {
    data: maintenancesIds,
    isLoading: isMaintenancesLoading,
    error: errorMaintenances,
  } = useGetAllMaintenances(
    {
      equipmentIds: equipmentsIds,
      contractId: contractId,
    },
    {
      select: (data) =>
        data?.result?.items
          ?.filter((maintenance) => maintenance?.status === "Active")
          .sort((a, b) => dayjs(b?.lastDate) - dayjs(a?.lastDate))
          ?.reduce(
            (acc, maintenance) => {
              if (!acc.equipments.includes(maintenance?.equipmentId)) {
                acc.equipments.push(maintenance?.equipmentId);
                acc.maintenances.push(maintenance);
              }
              return acc;
            },
            {
              equipments: [],
              maintenances: [],
            }
          )
          ?.maintenances?.map((maintenance) => maintenance?.id),
    }
  );

  const onSubmit = (data) => {
    const payload = {
      name: data.name,
      date: data.date,
      amount: data.amount,
      maintenanceIds: maintenancesIds,
      contractId: contractId,
    };
    addMaintenanceHistoryInMultipleMaintenances(payload);
  };

  const {
    control,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm({
    resolver: yupResolver(maintenanceHistorySchema),
  });

  if (isMaintenancesLoading) {
    return <SpinningCircle />;
  }

  if (errorMaintenances) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          height: "100%",
        }}
      >
        <p>Error: {errorMaintenances?.message}</p>
      </Box>
    );
  }

  return (
    <Box mt={2}>
      <Typography color={colors.primary} fontWeight={700} fontSize={21} mb={2}>
        Δημιουργία Ιστορικού Συντηρήσεων
      </Typography>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid item xs={12} md={4}>
          <CustomTextField
            label="Τίτλος"
            name="name"
            type="text"
            control={control}
            required
          />
          <Grid item xs={12} md={4} mt={3}>
            <CustomTextField
              label="Συνολικό Κόστος Συντηρήσεων (€)"
              name="amount"
              type="number"
              control={control}
              required
              isDecimal
              description="Το συνολικό κόστος επιμερίζεται ισόποσα σε όλα τα επιλεγμένα στοιχεία εξοπλισμού"
            />

            <Grid item xs={12} md={4} mt={3}>
              <DateField
                label="Ημερομηνία"
                name="date"
                control={control}
                required
                dateTime
              />
            </Grid>
          </Grid>

          {Object.keys(errors).length > 0 && (
            <Typography mt={2} color="error" fontSize={14}>
              Παρακαλώ ελέγξτε ότι όλα τα πεδία έχουν συμπληρωθεί σωστά
            </Typography>
          )}

          <Box
            sx={{
              display: "flex",
              justifyContent: "start",
              alignItems: "start",
              marginTop: 3,
            }}
          >
            <SaveButton
              text="Δημιουργία"
              disabled={!isDirty}
              isLoading={isMaintenancesHistoryCreateLoading}
            />
          </Box>
        </Grid>
      </form>
    </Box>
  );
};

EquipmentsMaintenanceHistoryCreation.propTypes = {
  equipmentsIds: PropTypes.array.isRequired,
  contractId: PropTypes.number,
  afterFinalSubmission: PropTypes.func.isRequired,
};

export default EquipmentsMaintenanceHistoryCreation;
