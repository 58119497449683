import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Agent } from "api/agent";

export const useGetAllCompanies = (
  {
    pageNumber = -1,
    pageSize = -1,
    searchParameter = "",
    serviceType = [],
    orderBy = "",
    sortingOrder = "",
  } = {
    pageNumber: -1,
    pageSize: -1,
    serviceType: [],
    orderBy: "",
    sortingOrder: "",
  },
  options = {}
) => {
  return useQuery({
    queryKey: [
      "getAllCompanies",
      pageNumber,
      pageSize,
      searchParameter,
      serviceType,
      orderBy,
      sortingOrder,
    ],
    queryFn: () =>
      Agent.companies.getAll({
        pageNumber: pageNumber,
        pageSize: pageSize,
        searchParameter: searchParameter,
        serviceType,
        orderBy,
        sortingOrder,
      }),
    staleTime: 1000 * 60 * 60,
    ...options,
  });
};

export const useGetCompanyById = (companyId, options = {}) => {
  return useQuery({
    queryKey: ["getCompanyById", companyId],
    queryFn: () => Agent.companies.getById(companyId),
    staleTime: 1000 * 60 * 10,
    ...options,
  });
};

export const useGetCompanyTypeCount = (options = {}) => {
  return useQuery({
    queryKey: ["getCompanyTypeCount"],
    queryFn: () => Agent.companies.getExternalCompanyTypeCount(),
    staleTime: 1000 * 60 * 10,
    ...options,
  });
};

export const useGetCompanyContractListById = (
  { companyId, pageNumber = -1, pageSize = -1 },
  options = {}
) => {
  return useQuery({
    queryKey: ["getCompanyContractListById", companyId, pageNumber, pageSize],
    queryFn: () =>
      Agent.companies.getCompanyContractListById({
        id: companyId,
        pageNumber: pageNumber,
        pageSize: pageSize,
      }),
    staleTime: 1000 * 60 * 10,
    ...options,
  });
};

export const useCreateCompany = ({ config = {} }) => {
  const queryClient = useQueryClient();
  const { onSuccess, ...restConfig } = config;

  return useMutation({
    mutationKey: ["createCompany"],
    mutationFn: (body) => Agent.companies.create(body),
    onSuccess: (...args) => {
      queryClient.invalidateQueries({ queryKey: ["getAllCompanies"] });
      queryClient.invalidateQueries({ queryKey: ["getCompanyTypeCount"] });
      onSuccess?.(...args);
    },
    ...restConfig,
  });
};

export const useUpdateCompany = ({ id, config = {} }) => {
  const queryClient = useQueryClient();
  const { onSuccess, ...restConfig } = config;

  return useMutation({
    mutationKey: ["updateCompany"],
    mutationFn: (body) => Agent.companies.update(id, body),
    onSuccess: (...args) => {
      queryClient.invalidateQueries({ queryKey: ["getAllCompanies"] });
      queryClient.invalidateQueries({ queryKey: ["getCompanyById", id] });
      onSuccess?.(...args);
    },
    ...restConfig,
  });
};

export const useDeleteCompany = ({ id, config = {} }) => {
  const queryClient = useQueryClient();
  const { onSuccess, ...restConfig } = config;

  return useMutation({
    mutationKey: ["deleteCompany"],
    mutationFn: () => Agent.companies.delete(id),
    onSuccess: (...args) => {
      queryClient.invalidateQueries({ queryKey: ["getAllCompanies"] });
      queryClient.invalidateQueries({ queryKey: ["getCompanyById", id] });
      queryClient.invalidateQueries({ queryKey: ["getCompanyTypeCount"] });
      onSuccess?.(...args);
    },
    ...restConfig,
  });
};
