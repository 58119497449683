import React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import colors from "config/theme/colors";
import { ReactComponent as MapIcon } from "assets/icons/map.svg";
import FormatListBulletedOutlinedIcon from "@mui/icons-material/FormatListBulletedOutlined";
import { IconButton } from "@mui/material";

const LayoutSwitcher = ({ onLayoutChange, activeLayout }) => {
  const toggleSelectedLayout = (type) => {
    onLayoutChange(type);
  };

  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      justifyContent="center"
      gap={1}
    >
      <Tooltip title="Λίστα">
        <IconButton onClick={() => toggleSelectedLayout("listView")}>
          <FormatListBulletedOutlinedIcon
            style={{
              color:
                activeLayout === "listView" ? colors.text : colors.lightGrey,
            }}
          />
        </IconButton>
      </Tooltip>

      <Tooltip title="Χάρτης">
        <IconButton onClick={() => toggleSelectedLayout("mapView")}>
          <MapIcon
            width={25}
            height={25}
            fill={activeLayout === "mapView" ? colors.text : colors.lightGrey}
          />
        </IconButton>
      </Tooltip>
    </Box>
  );
};

LayoutSwitcher.propTypes = {
  activeLayout: PropTypes.string.isRequired,
  onLayoutChange: PropTypes.func.isRequired,
};

export default LayoutSwitcher;
