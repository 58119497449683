import CustomTextField from "components/inputs/CustomTextField";
import SaveButton from "components/buttons/SaveButton";
import loginRequestFields from "config/forms/loginRequestFields";
import colors from "../../config/theme/colors";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
} from "@mui/material";
import { observer } from "mobx-react-lite";
import { useAuthStore } from "stores/AuthStore";
import { useLogin } from "hooks/queries/useAuth";
import { useAlert } from "hooks/useAlert";
import PromoPage from "../special/PromoPage";
import { useIsMutating } from "@tanstack/react-query";

const LoginPage = () => {
  const authStore = useAuthStore();
  const { showError } = useAlert();
  const navigate = useNavigate();
  const { mutate: login } = useLogin();
  const isLoginLoading = useIsMutating({ mutationKey: ["login"] }) > 0;

  const onLogin = (data) => {
    login(data, {
      onError: (error) => {
        if (error.status === 400) {
          showError("Λάθος email ή κωδικός πρόσβασης");
        } else {
          showError("Σφάλμα κατά την σύνδεση");
        }
      },
      onSuccess: (data) => {
        authStore.storeToken(data);
      },
    });
  };

  const schema = yup.object(
    loginRequestFields.reduce((acc, field) => {
      if (field.type === "email") {
        acc[field.name] = field.required
          ? yup
              .string()
              .email("Πληκτρολογήστε ένα πραγματικό email")
              .trim("Απαγορεύεται να έχετε κενά")
              .strict(true)
              .required("Το πεδίο ειναι υποχρεωτικό")
          : yup.string().nullable();
      } else if (field.type === "text" && field.name === "password") {
        acc[field.name] = field.required
          ? yup.string().required("Το πεδίο είναι υποχρεωτικό")
          : yup.string().nullable();
      }
      return acc;
    }, {})
  );

  const {
    control,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm({ resolver: yupResolver(schema) });

  const getInputElementByFieldType = (field) => {
    if (
      field.type === "email" ||
      (field.type === "text" && field.name === "password")
    ) {
      return <CustomTextField {...field} control={control} />;
    }
  };

  const formFields = loginRequestFields;

  return (
    <form onSubmit={handleSubmit(() => onLogin(getValues()))}>
      <Box
        display="flex"
        flexDirection="column"
        gap={2}
        sx={{
          backgroundColor: colors.white,
        }}
      >
        <Grid
          container
          sx={{
            minHeight: "100vh",
          }}
        >
          <Grid
            item
            xs={12}
            md={6}
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            paddingTop={{
              xs: 5,
              md: 0,
            }}
            paddingX={{
              xs: 6,
              sm: 6,
              md: 10,
              lg: 15,
              xl: 20,
            }}
          >
            <h1 style={{ fontSize: "48px" }}>
              <b>Σύνδεση</b>
            </h1>
            <p>Εισάγετε τα στοιχεία σας για να συνδεθείτε στο λογαριασμό σας</p>
            <div>
              <Grid container spacing={2} mt={4}>
                {formFields
                  .filter((field) => field.display !== false)
                  .map((field) => (
                    <Grid item xs={12} md={12} key={field?.name}>
                      {getInputElementByFieldType(field)}
                    </Grid>
                  ))}
              </Grid>
              {Object.keys(errors).length > 0 && (
                <Typography color="error" fontSize={14}>
                  Παρακαλώ ελέγξτε ότι όλα τα πεδία έχουν συμπληρωθεί σωστά
                </Typography>
              )}
              <Grid container sx={{ paddingTop: "50px", alignItems: "center" }}>
                <Grid item xs={12} md={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        sx={{ color: colors.greyBackground }}
                        checked={authStore.rememberMe}
                        onChange={() =>
                          authStore.setRememberMe(!authStore.rememberMe)
                        }
                      />
                    }
                    label="Θυμήσου με"
                    sx={{
                      "& .MuiTypography-body1": {
                        fontSize: 14,
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography
                    color={colors.text}
                    fontWeight={700}
                    fontSize={14}
                    textAlign={"right"}
                    onClick={() => navigate("/forgot-password")}
                    sx={{ cursor: "pointer" }}
                  >
                    Ξεχάσατε τον κωδικό σας;
                  </Typography>
                </Grid>
              </Grid>

              <Box
                display="flex"
                justifyContent="flex-center"
                alignItems="center"
                gap={2}
                style={{ paddingTop: "50px", width: "100%" }}
              >
                <SaveButton
                  isLoading={isLoginLoading}
                  text={"Είσοδος"}
                  width={"100%"}
                />
              </Box>
            </div>
          </Grid>

          <PromoPage />
        </Grid>
      </Box>
    </form>
  );
};

export default observer(LoginPage);
