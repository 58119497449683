import { useCallback, useState } from "react";
import { ReactComponent as FiltersIcon } from "assets/icons/filters.svg";
import colors from "config/theme/colors";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Badge from "@mui/material/Badge";
import FilterOptionsModal from "components/modals/FilterOptionsModal";
import PropTypes from "prop-types";

const FilterButton = ({ filterGroups, onApplyFilters, selectedFilters }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [filtersState, setFiltersState] = useState(setInitialState());
  const [selectedCount, setSelectedCount] = useState(
    calculateSelectedCount(selectedFilters)
  );

  const toggleModal = useCallback(() => {
    setIsModalOpen(!isModalOpen);
  }, [isModalOpen]);

  function setInitialState() {
    return filterGroups?.map((group) => ({
      ...group,
      filters: group.filters?.map((filter) => ({
        ...filter,
        checked: selectedFilters[group.title]?.includes(filter.id) || false,
      })),
    }));
  }

  function calculateSelectedCount(filters = []) {
    return Object.values(filters)?.reduce((count, array) => {
      return count + (array?.length > 0 ? 1 : 0);
    }, 0);
  }

  const handleFilterChange = (groupIndex, filterIndex) => {
    setFiltersState((prevState) => {
      const newState = prevState?.map((group, gIndex) =>
        gIndex === groupIndex
          ? {
              ...group,
              filters: group.filters?.map((filter, fIndex) =>
                fIndex === filterIndex
                  ? { ...filter, checked: !filter.checked }
                  : filter
              ),
            }
          : group
      );
      const updatedSelectedFilters = getUpdatedSelectedFilters(newState);
      setSelectedCount(calculateSelectedCount(updatedSelectedFilters));
      return newState;
    });
  };

  const handleSelectAll = (groupIndex) => {
    setFiltersState((prevState) => {
      const newState = prevState?.map((group, gIndex) =>
        gIndex === groupIndex
          ? {
              ...group,
              filters: group.filters?.map((filter) => ({
                ...filter,
                checked: true,
              })),
            }
          : group
      );
      const updatedSelectedFilters = getUpdatedSelectedFilters(newState);
      setSelectedCount(calculateSelectedCount(updatedSelectedFilters));
      return newState;
    });
  };

  const handleDeselectAll = (groupIndex) => {
    setFiltersState((prevState) => {
      const newState = prevState?.map((group, gIndex) =>
        gIndex === groupIndex
          ? {
              ...group,
              filters: group.filters?.map((filter) => ({
                ...filter,
                checked: false,
              })),
            }
          : group
      );
      const updatedSelectedFilters = getUpdatedSelectedFilters(newState);
      setSelectedCount(calculateSelectedCount(updatedSelectedFilters));
      return newState;
    });
  };

  const handleApplyFilters = useCallback(() => {
    const selectedFilters = getUpdatedSelectedFilters(filtersState);
    onApplyFilters(selectedFilters);
    toggleModal();
  }, [filtersState, onApplyFilters, toggleModal]);

  function getUpdatedSelectedFilters(state) {
    return state?.reduce((acc, group) => {
      const selected = group.filters
        ?.filter((filter) => filter.checked)
        ?.map((filter) => filter.id);
      return { ...acc, [group.title]: selected };
    }, {});
  }

  return (
    <>
      <Badge
        badgeContent={selectedCount}
        color="primary"
        invisible={selectedCount === 0}
        overlap="circular"
      >
        <Box
          onClick={toggleModal}
          sx={{
            position: "relative",
            backgroundColor: colors.lightGreyBg,
            color: colors.grey,
            padding: 1,
            minWidth: "133.81px",
            minHeight: "36px",
            cursor: "pointer",
            borderRadius: "4px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          gap={2}
        >
          <Box>
            <FiltersIcon
              fill={colors.grey}
              stroke={colors.grey}
              width={24}
              height={24}
            />
          </Box>
          <Box>
            <Typography fontWeight={600} fontSize={16} variant="h3">
              Φίλτρα
            </Typography>
          </Box>
        </Box>
      </Badge>
      <FilterOptionsModal
        isOpen={isModalOpen}
        onCloseModal={toggleModal}
        filterGroups={filtersState}
        onChangeFilter={handleFilterChange}
        onSelectAll={handleSelectAll}
        onDeselectAll={handleDeselectAll}
        onApplyFilters={handleApplyFilters}
      />
    </>
  );
};

FilterButton.propTypes = {
  filterGroups: PropTypes.array.isRequired,
  onApplyFilters: PropTypes.func.isRequired,
  selectedFilters: PropTypes.object,
};

export default FilterButton;
