import React from "react";
import PropTypes from "prop-types";

const GuestContent = ({ children }) => {
  return <div>{children}</div>;
};

GuestContent.propTypes = {
  children: PropTypes.node.isRequired,
};

export default GuestContent;
