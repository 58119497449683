import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Agent } from "api/agent";

export const useGetAllCertificationTypes = (
  { pageNumber = -1, pageSize = -1, facilityType, assetId, equipmentId } = {
    pageNumber: -1,
    pageSize: -1,
    facilityType: null,
    assetId: null,
    equipmentId: null,
  },
  options = {}
) => {
  return useQuery({
    queryKey: [
      "getAllCertificationTypes",
      pageNumber,
      pageSize,
      facilityType,
      assetId,
      equipmentId,
    ],
    queryFn: () =>
      Agent.certificationType.getAll({
        pageNumber: pageNumber,
        pageSize: pageSize,
        facilityType: facilityType,
        assetId: assetId,
        equipmentId: equipmentId,
      }),
    staleTime: 1000 * 60 * 60,
    ...options,
  });
};

export const useGetCertificationTypeById = (id, options = {}) => {
  return useQuery({
    queryKey: ["getCertificationTypeById", id],
    queryFn: () => Agent.certificationType.getById(id),
    staleTime: 1000 * 60 * 10,
    ...options,
  });
};

export const useCreateCertificationType = ({ config = {} }) => {
  const queryClient = useQueryClient();
  const { onSuccess, ...restConfig } = config || {};

  return useMutation({
    mutationKey: ["createCertificationType"],
    mutationFn: (body) => Agent.certificationType.create(body),
    onSuccess: (...args) => {
      queryClient.invalidateQueries({ queryKey: ["getAllCertificationTypes"] });
      onSuccess?.(...args);
    },
    ...restConfig,
  });
};

export const useUpdateCertificationType = ({ id, config = {} }) => {
  const queryClient = useQueryClient();
  const { onSuccess, ...restConfig } = config || {};

  return useMutation({
    mutationKey: ["updateCertificationType"],
    mutationFn: (body) => Agent.certificationType.update(id, body),
    onSuccess: (...args) => {
      queryClient.invalidateQueries({ queryKey: ["getAllCertificationTypes"] });
      queryClient.invalidateQueries({
        queryKey: ["getCertificationTypeById", id],
      });
      onSuccess?.(...args);
    },
    ...restConfig,
  });
};

export const useDeleteCertificationType = ({ id, config = {} }) => {
  const queryClient = useQueryClient();
  const { onSuccess, ...restConfig } = config || {};

  return useMutation({
    mutationKey: ["deleteCertificationType"],
    mutationFn: () => Agent.certificationType.delete(id),
    onSuccess: (...args) => {
      queryClient.invalidateQueries({ queryKey: ["getAllCertificationTypes"] });
      onSuccess?.(...args);
    },
    ...restConfig,
  });
};
