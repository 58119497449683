import { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import SaveButton from "components/buttons/SaveButton";
import CustomTable from "components/tables/CustomTable";
import DateField from "components/inputs/DateField";
import DropdownField from "components/inputs/DropdownField";
import ReportsAccordion from "components/accordions/ReportsAccordion";
import usePagination from "hooks/usePagination";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useGetAllAssets } from "hooks/queries/useAssets";
import SpinningCircle from "components/spinners/SpinningCircle";
import dayjs from "dayjs";
import { formatDateTime } from "utils/formatDateTime";
import colors from "config/theme/colors";
import CopyButton from "components/buttons/CopyButton";
import AssetReportsExportButtons from "./AssetReportsExportButtons";
import { formatNumbersWithSeparator } from "utils/formatNumbersWithSeperator";
import {
  useGetReportAssetMaintenanceCostsPerAsset,
  useGetReportAssetMaintenanceCostsPerType,
  useGetReportAssetMaintenances,
} from "hooks/queries/useAssetReports";

const availableAssetReportTypeOptions = [
  { label: "Συντηρήσεις που Λήγουν", value: "ExpiringMaintenances" },
  { label: "Ολοκληρωμένες Συντηρήσεις", value: "CompletedMaintenances" },
  {
    label: "Κόστος Συντήρησης Ανά Ακίνητο",
    value: "MaintenanceCostsPerAsset",
  },
  {
    label: "Κόστος Συντήρησης Ανά Τύπο Συντήρησης",
    value: "MaintenanceCostsPerType",
  },
];

const columns = {
  ExpiringMaintenances: [
    { id: "assetName", label: "Ακίνητο", isSortable: false },
    { id: "maintenanceName", label: "Συντήρηση", isSortable: false },
    { id: "dateOfExpiration", label: "Ημερομηνία Λήξης", isSortable: false },
    { id: "maintenanceType", label: "Τύπος Συντήρησης", isSortable: false },
    { id: "kaek", label: "ΚΑΕΚ", isSortable: false },
  ],
  CompletedMaintenances: [
    { id: "assetName", label: "Ακίνητο", isSortable: false },
    { id: "maintenanceName", label: "Συντήρηση", isSortable: false },
    { id: "dateOfMaintenance", label: "Ημερομηνία", isSortable: false },
    { id: "maintenanceCost", label: "Κόστος", isSortable: false },
    { id: "maintenanceType", label: "Τύπος Συντήρησης", isSortable: false },
    { id: "kaek", label: "ΚΑΕΚ", isSortable: false },
  ],
  MaintenanceCostsPerAsset: [
    { id: "assetName", label: "Ακίνητο", isSortable: false },
    { id: "maintenanceType", label: "Τύπος Συντήρησης", isSortable: false },
    { id: "maintenanceCost", label: "Συνολικό Κόστος", isSortable: false },
    { id: "kaek", label: "ΚΑΕΚ", isSortable: false },
  ],
  MaintenanceCostsPerType: [
    { id: "maintenanceType", label: "Τύπος Συντήρησης", isSortable: false },
    { id: "maintenanceCost", label: "Συνολικό Κόστος", isSortable: false },
  ],
};

const getValidationSchema = (reportType) => {
  if (reportType === "MaintenanceCostsPerAsset") {
    return yup.object().shape({
      dateFrom: yup.date().required("Ημερομηνία από είναι υποχρεωτική"),
      dateTo: yup.date().required("Ημερομηνία έως είναι υποχρεωτική"),
      asset: yup
        .array()
        .of(yup.object().shape({ id: yup.string(), label: yup.string() }))
        .nullable(),
    });
  } else {
    return yup.object().shape({
      dateFrom: yup.date().required("Ημερομηνία από είναι υποχρεωτική"),
      dateTo: yup.date().required("Ημερομηνία έως είναι υποχρεωτική"),
    });
  }
};

const renderKaek = (item) => {
  return (
    <Box
      px={2}
      borderRadius={2}
      bgcolor={colors.greyBackground}
      display="flex"
      justifyContent="space-between"
      alignItems="center"
    >
      <Typography fontSize={12} fontWeight={700} color={colors.grey}>
        {item.kaek}
      </Typography>
      <CopyButton textToCopy={item.kaek} />
    </Box>
  );
};

const AssetReports = () => {
  const [reportType, setReportType] = useState("");
  const [data, setData] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const {
    page,
    rowsPerPage,
    setPage,
    handleChangePage,
    handleChangeRowsPerPage,
  } = usePagination();
  const {
    data: assets,
    isLoading: assetsLoading,
    error: assetsError,
  } = useGetAllAssets(
    {
      pageNumber: -1,
      pageSize: -1,
    },
    { enabled: reportType === "MaintenanceCostsPerAsset" }
  );
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
    watch,
  } = useForm({
    resolver: yupResolver(getValidationSchema(reportType)),
  });

  const dateFrom = watch("dateFrom");
  const dateTo = watch("dateTo");
  const asset = watch("asset");

  const fetchedData = {
    ExpiringMaintenances: useGetReportAssetMaintenances(
      {
        status: "Expired",
        dateFrom: dayjs(dateFrom).utc(true).format(),
        dateTo: dayjs(dateTo).utc(true).format(),
        pageNumber: page,
        pageSize: rowsPerPage,
      },
      { enabled: reportType === "ExpiringMaintenances" && isSubmitting }
    ),
    CompletedMaintenances: useGetReportAssetMaintenances(
      {
        status: "Completed",
        dateFrom: dayjs(dateFrom).utc(true).format(),
        dateTo: dayjs(dateTo).utc(true).format(),
        pageNumber: page,
        pageSize: rowsPerPage,
      },
      { enabled: reportType === "CompletedMaintenances" && isSubmitting }
    ),
    MaintenanceCostsPerAsset: useGetReportAssetMaintenanceCostsPerAsset(
      {
        assetIds: asset?.map((a) => a.id) || null,
        dateFrom: dayjs(dateFrom).utc(true).format(),
        dateTo: dayjs(dateTo).utc(true).format(),
        pageNumber: page,
        pageSize: rowsPerPage,
      },
      { enabled: reportType === "MaintenanceCostsPerAsset" && isSubmitting }
    ),
    MaintenanceCostsPerType: useGetReportAssetMaintenanceCostsPerType(
      {
        dateFrom: dayjs(dateFrom).utc(true).format(),
        dateTo: dayjs(dateTo).utc(true).format(),
        pageNumber: page,
        pageSize: rowsPerPage,
      },
      { enabled: reportType === "MaintenanceCostsPerType" && isSubmitting }
    ),
  };

  useEffect(() => {
    if (isSubmitting) {
      const activeQuery = fetchedData[reportType];
      setData(activeQuery.data || {});
    }
  }, [fetchedData, reportType, isSubmitting]);

  const isLoading = fetchedData[reportType]?.isLoading;

  const formattedData = useMemo(
    () =>
      data?.items?.map((item, i) => {
        switch (reportType) {
          case "ExpiringMaintenances":
            return {
              id: `${item.dateOfExpiration}-${item.kaek}-${i}`,
              dateOfExpiration: formatDateTime(item.dateOfExpiration, true),
              kaek: item.kaek ? renderKaek(item) : "-",
              assetName: item.assetName || "-",
              maintenanceName: item.maintenanceName || "-",
              maintenanceType: item.maintenanceType || "-",
            };
          case "CompletedMaintenances":
            return {
              assetName: item.assetName || "-",
              dateOfMaintenance: formatDateTime(item.dateOfMaintenance, true),
              id: `${item.dateOfMaintenance}-${item.maintenanceType}-${item.maintenanceCost}-${i}`,
              kaek: item.kaek ? renderKaek(item) : "-",
              maintenanceCost: item.cost
                ? `${formatNumbersWithSeparator(item.cost, 2)}€`
                : "-",
              maintenanceName: item.maintenanceName || "-",
              maintenanceType: item.maintenanceType || "-",
            };
          case "MaintenanceCostsPerAsset":
            return {
              ...item,
              id: `${item.assetName}-${item.maintenanceType}`,
              kaek: item.kaek ? renderKaek(item) : "-",
              maintenanceCost: item.maintenanceCost
                ? `${formatNumbersWithSeparator(item.maintenanceCost, 2)} €`
                : "-",
            };
          case "MaintenanceCostsPerType":
            return {
              ...item,
              id: item.maintenanceType,
              maintenanceCost: item.maintenanceCost
                ? `${formatNumbersWithSeparator(item.maintenanceCost, 2)} €`
                : "-",
            };
          default:
            return item;
        }
      }),
    [data, reportType]
  );

  const assetOptions = assets?.items?.map((asset) => ({
    id: asset.id,
    label: asset.name,
  }));
  const totalResults = data?.totalCount ?? null;
  const assetReportTypeOptions = availableAssetReportTypeOptions.map(
    (option) => option.label
  );

  const handleOnFilterChange = () => {
    setIsSubmitting(false);
    setPage(1);
  };

  const handleAssetReportTypeChange = (event, value) => {
    const selectedReport = availableAssetReportTypeOptions.find(
      (option) => option.label === value
    )?.value;

    setReportType(selectedReport || "");
    reset();
    handleOnFilterChange();
  };

  const onSubmit = () => {
    setIsSubmitting(true);
  };

  const getMinMaxDates = useCallback((reportType) => {
    const currentDate = dayjs().format("YYYY-MM-DD");
    const pastDate = dayjs().subtract(1, "year").format("YYYY-MM-DD");
    const futureDate = dayjs().add(1, "year").format("YYYY-MM-DD");

    switch (reportType) {
      case "ExpiringMaintenances":
        return { min: currentDate, max: futureDate };
      case "CompletedMaintenances":
        return { min: pastDate, max: currentDate };
      case "MaintenanceCostsPerAsset":
      case "MaintenanceCostsPerType":
      default:
        return { min: null, max: null };
    }
  }, []);

  const { min: dateFromMin, max: dateFromMax } = getMinMaxDates(reportType);
  const { min: dateToMin, max: dateToMax } = getMinMaxDates(reportType);

  if (assetsLoading) {
    return <SpinningCircle />;
  }

  if (assetsError) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          height: "100%",
        }}
      >
        <p>Error: {assetsError?.message}</p>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        marginTop: 4,
      }}
    >
      <Typography variant="h4" color="primary">
        Αναφορές Ακινήτων
      </Typography>

      <Box
        sx={{
          marginTop: 3,
        }}
      >
        <DropdownField
          label="Επιλογή Κατηγορίας"
          value={
            availableAssetReportTypeOptions.find(
              (option) => option.value === reportType
            )?.label || ""
          }
          onChange={handleAssetReportTypeChange}
          options={assetReportTypeOptions}
          required
        />

        <Box sx={{ marginTop: 3 }}>
          {availableAssetReportTypeOptions
            .map((option) => option.value)
            .includes(reportType) && (
            <ReportsAccordion
              sx={{
                marginTop: 2,
                width: "100%",
                padding: 1.7,
                borderRadius: 2,
                boxShadow: 3,
                border: "1px solid",
                borderColor: "divider",
              }}
              title={`Φόρμα Αναφοράς ( ${
                availableAssetReportTypeOptions.find(
                  (option) => option.value === reportType
                )?.label
              } )`}
            >
              <form onSubmit={handleSubmit(onSubmit)}>
                <Box display="flex" gap={2} flexWrap="wrap">
                  <DateField
                    label="Ημερομηνία Από"
                    name="dateFrom"
                    control={control}
                    min={dateFromMin}
                    max={dateFromMax}
                    required
                    onChange={() => handleOnFilterChange()}
                  />
                  <DateField
                    label="Ημερομηνία Εώς"
                    name="dateTo"
                    control={control}
                    min={dateToMin}
                    max={dateToMax}
                    required
                    onChange={() => handleOnFilterChange()}
                  />
                  {reportType === "MaintenanceCostsPerAsset" && (
                    <DropdownField
                      label="Επιλογή Ακινήτου (-ων)"
                      name="asset"
                      control={control}
                      options={assetOptions}
                      multiple
                      onChange={() => handleOnFilterChange()}
                      sx={{
                        minWidth: 300,
                      }}
                    />
                  )}

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: 4,
                    }}
                  >
                    {Object.keys(errors).length > 0 && (
                      <Typography color="error" fontSize={14}>
                        Παρακαλώ ελέγξτε ότι όλα τα πεδία έχουν συμπληρωθεί
                      </Typography>
                    )}
                  </Box>
                </Box>

                <Box sx={{ marginTop: 3 }}>
                  <SaveButton text="Δημιουργία" isLoading={isLoading} />
                </Box>
              </form>
            </ReportsAccordion>
          )}

          <Box
            sx={{
              marginTop: 3.5,
              display: "flex",
              alignItems: "center",
            }}
          >
            {totalResults > 0 && isSubmitting && (
              <>
                <Typography variant="h3" color="primary">
                  ({totalResults}) Αποτελέσματα
                </Typography>

                <Box
                  sx={{
                    display: "flex",
                    flexGrow: 1,
                    justifyContent: "flex-end",
                  }}
                >
                  <AssetReportsExportButtons
                    reportType={reportType}
                    filters={{
                      dateFrom: dayjs(dateFrom).utc(true).format(),
                      dateTo: dayjs(dateTo).utc(true).format(),
                      assetIds: asset?.map((a) => a.id) || null,
                      status:
                        reportType === "ExpiringMaintenances"
                          ? "Expired"
                          : "Completed",
                    }}
                  />
                </Box>
              </>
            )}
          </Box>

          {totalResults > 0 && isSubmitting && (
            <Box
              sx={{
                marginTop: 3,
                marginBottom: 5,
                width: "100%",
                padding: 1.7,
                borderRadius: 2,
                boxShadow: 3,
                border: "1px solid",
                borderColor: "divider",
              }}
            >
              <CustomTable
                columns={columns[reportType]}
                data={formattedData}
                page={page - 1}
                rowsPerPage={rowsPerPage}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                totalResults={totalResults}
                disableClick
                maxHeight={650}
                textWeight={500}
              />
            </Box>
          )}

          {totalResults === 0 && isSubmitting && (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{
                height: "100%",
                marginTop: 2,
              }}
            >
              <Typography variant="h4">Δεν υπάρχουν δεδομένα</Typography>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default AssetReports;
