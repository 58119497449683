import { Box } from "@mui/material";
import SubHeader from "components/headers/SubHeader";
import { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router";

const CERTIFICATE_DETAILS_TABS = [
  {
    id: 0,
    description: "Κλάση",
    to: "classes",
  },
  {
    id: 1,
    description: "Τύπος",
    to: "types",
  },
];

const CertificationConfiguration = () => {
  const [selectedTab, setSelectedTab] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (selectedTab !== null && selectedTab !== undefined)
      navigate(
        `/customize/certification/${CERTIFICATE_DETAILS_TABS[selectedTab].to}`
      );
  }, [selectedTab, navigate]);

  useEffect(() => {
    setSelectedTab(
      CERTIFICATE_DETAILS_TABS.find((tab) => location.pathname.includes(tab.to))
        ?.id || 0
    );
  }, [location.pathname, navigate]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={2}
      sx={{
        height: "100%",
      }}
    >
      <SubHeader
        tabs={CERTIFICATE_DETAILS_TABS}
        setSelectedTab={setSelectedTab}
      />
      <Box
        display="flex"
        flexDirection="column"
        gap={2}
        flexGrow={1}
        width={"100%"}
      >
        <Outlet />
      </Box>
    </Box>
  );
};

export default CertificationConfiguration;
