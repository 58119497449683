import { Box, Tooltip, Typography } from "@mui/material";
import CustomTable from "components/tables/CustomTable";
import { useMemo, useState } from "react";
import EquipmentGroupForm from "./EquipmentGroupForm";
import colors from "config/theme/colors";
import {
  useCreateEquipmentGroup,
  useDeleteEquipmentGroup,
  useGetAllEquipmentGroups,
  useGetEquipmentGroupById,
  useUpdateEquipmentGroup,
} from "hooks/queries/useEquipmentGroup";
import SpinningCircle from "components/spinners/SpinningCircle";
import { useAlert } from "hooks/useAlert";
import EditCircleButton from "components/buttons/EditCircleButton";
import usePagination from "hooks/usePagination";

const EquipmentGroupsOverview = () => {
  const { showError, showSuccess } = useAlert();
  const { page, rowsPerPage, handleChangePage, handleChangeRowsPerPage } =
    usePagination();
  const [selectedEquipmentGroupId, setSelectedEquipmentGroupId] =
    useState(null);
  const [isEditing, setIsEditing] = useState(false);

  const { data: selectedEquipmentGroup } = useGetEquipmentGroupById(
    selectedEquipmentGroupId,
    { enabled: selectedEquipmentGroupId !== null }
  );

  const {
    data: equipmentGroups,
    isLoading,
    error,
  } = useGetAllEquipmentGroups(
    {
      pageNumber: page,
      pageSize: rowsPerPage,
    },
    { select: (data) => data?.result }
  );

  const { mutate: createEquipmentGroup } = useCreateEquipmentGroup({
    config: {
      onError: (error) => {
        showError(error.message);
      },
      onSuccess: (res) => {
        setSelectedEquipmentGroupId(res.result.id);
        showSuccess("Η ομάδα εξοπλισμού δημιουργήθηκε με επιτυχία");
      },
    },
  });
  const { mutate: updateEquipmentGroup } = useUpdateEquipmentGroup({
    id: selectedEquipmentGroupId,
    config: {
      onError: (error) => {
        showError(error.message);
      },
      onSuccess: () => {
        setIsEditing(false);
        showSuccess("Η ομάδα εξοπλισμού ενημερώθηκε με επιτυχία");
      },
    },
  });
  const { mutate: deleteEquipmentGroup } = useDeleteEquipmentGroup({
    id: selectedEquipmentGroupId,
    config: {
      onError: (error) => {
        if (
          error.message?.includes(
            "Δεν μπορείτε να διαγράψετε αυτό το αντικείμενο!"
          )
        ) {
          showError(
            "Δεν μπορείτε να διαγράψετε αυτή την ομάδα εξοπλισμού, καθώς χρησιμοποιείται σε κάποια κλάση εξοπλισμού",
            6000
          );
          return;
        }
        showError(error.message);
      },
      onSuccess: () => {
        setSelectedEquipmentGroupId(null);
        setIsEditing(false);
        showSuccess("Η ομάδα εξοπλισμού διαγράφηκε με επιτυχία");
      },
    },
  });

  const tableHeaders = useMemo(
    () => [
      {
        id: "name",
        label: "Όνομα",
        align: "left",
        minWidth: 200,
      },
      {
        id: "equipmentType",
        label: "Τύπος Εξοπλισμού",
        align: "left",
        minWidth: 200,
      },
      {
        id: "button",
        label: "",
        align: "right",
      },
    ],
    []
  );

  if (isLoading) {
    return <SpinningCircle />;
  }

  if (error) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          height: "100%",
        }}
      >
        <p>Error: {error.message}</p>
      </Box>
    );
  }

  const equipmentGroupList = equipmentGroups?.items.map((item) => ({
    ...item,
    equipmentType: item.equipmentType?.name || "",
    equipmentTypeId: item.equipmentType?.id || "",
    button: (
      <Box display="flex" justifyContent="flex-end">
        <Tooltip title="Επεξεργασία" placement="left">
          <span>
            <EditCircleButton
              onClick={(e) => {
                setIsEditing(true);
                setSelectedEquipmentGroupId(item.id);
                e.stopPropagation();
              }}
            />
          </span>
        </Tooltip>
      </Box>
    ),
  }));
  const count = equipmentGroups?.totalCount;

  const handleItemClick = (item) => {
    setIsEditing(false);
    if (item && item.id === selectedEquipmentGroupId) {
      setSelectedEquipmentGroupId(null);
      return;
    }
    setSelectedEquipmentGroupId(item.id);
  };

  const handleCreateClick = (data) => {
    const payload = {
      name: data.name,
      description: data.description,
      equipmentTypeId: data.equipmentTypeId?.id,
    };
    createEquipmentGroup(payload);
  };

  const handleUpdateClick = (data) => {
    const payload = {
      name: data.name,
      description: data.description,
      equipmentTypeId: data.equipmentTypeId?.id,
    };
    updateEquipmentGroup(payload);
  };

  const handleDeleteClick = () => {
    deleteEquipmentGroup();
  };

  return (
    <Box
      display="flex"
      gap={2}
      justifyContent="space-between"
      width={"100%"}
      flexDirection={{
        xs: "column",
        sm: "row",
      }}
    >
      <Box
        mt={1}
        display="flex"
        flexDirection="column"
        justifyContent="flex-start"
        width={{
          xs: "100%",
          sm: "60%",
        }}
      >
        <Typography variant="h3" color={colors.primary}>
          {count > 0
            ? `Ομάδες Εξοπλισμού (${count})`
            : "Δεν υπάρχουν ομάδες εξοπλισμού"}
        </Typography>

        {count > 0 && (
          <CustomTable
            columns={tableHeaders}
            data={equipmentGroupList}
            fadeEffect
            onItemClick={handleItemClick}
            page={page - 1}
            rowsPerPage={rowsPerPage}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            totalResults={count}
          />
        )}
      </Box>

      <Box
        borderLeft={{
          xs: "none",
          sm: `2px solid ${colors.lightGrey}`,
        }}
        borderTop={{
          xs: `2px solid ${colors.lightGrey}`,
          sm: "none",
        }}
        sx={{
          borderRadius: 6,
          opacity: "0.2",
        }}
      />

      <Box
        width={{
          xs: "100%",
          sm: "38%",
        }}
      >
        {selectedEquipmentGroupId === null ? (
          <EquipmentGroupForm
            onSave={handleCreateClick}
            onCancel={() => setSelectedEquipmentGroupId(null)}
            isCreateForm
          />
        ) : (
          <EquipmentGroupForm
            selectedEquipmentGroup={selectedEquipmentGroup}
            onSave={handleUpdateClick}
            onCancel={() => setSelectedEquipmentGroupId(null)}
            onDelete={handleDeleteClick}
            isEditing={isEditing}
          />
        )}
      </Box>
    </Box>
  );
};

export default EquipmentGroupsOverview;
