import { emailRegex, phoneRegex } from "config/regex";

const userResponseFields = [
  {
    label: "Username",
    name: "userName",
    type: "text",
    required: true,
    editable: false,
  },
  {
    label: "Email",
    name: "email",
    type: "text",
    required: true,
    editable: false,
    validation: "custom",
    regex: emailRegex,
    validationMessage: "Παρακαλώ εισάγετε μια έγκυρη διεύθυνση email",
  },
  {
    label: "Ρόλος",
    name: "role",
    type: "select",
    required: true,
    options: [],
  },
  {
    label: "Κατάσταση",
    name: "status",
    type: "select",
    required: true,
    editable: false,
    options: [
      {
        label: "Ενεργός",
        id: "Active",
      },
      {
        label: "Ανενεργός",
        id: "Inactive",
      },
    ],
  },
  {
    label: "Όνομα",
    name: "name",
    type: "text",
    required: true,
  },
  {
    label: "Επώνυμο",
    name: "surname",
    type: "text",
    required: true,
  },
  {
    label: "Τηλέφωνο",
    name: "phoneNumber",
    type: "text",
    required: true,
    validation: "custom",
    regex: phoneRegex,
    validationMessage: "Ο αριθμός τηλεφώνου που καταχωρήσατε δεν είναι έγκυρος",
  },
];

export default userResponseFields;
