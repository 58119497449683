import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import RentsViewEditForm from "./RentsViewEditForm";
import { useNavigate } from "react-router";
import { useState } from "react";
import SpinningCircle from "components/spinners/SpinningCircle";
import EditButton from "components/buttons/EditButton";
import {
  useDeleteRent,
  useGetRentById,
  useUpdateRent,
} from "hooks/queries/useRents";
import { useAlert } from "hooks/useAlert";
import colors from "config/theme/colors";
import enums from "config/enums";
import RentHistoryOverview from "pages/rentHistory/Overview/RentHistoryOverview";
import PropTypes from "prop-types";
import { useDeleteMultipleFiles, useUploadFiles } from "hooks/queries/useFile";

const RentsViewEdit = ({ assetId, rentId }) => {
  const navigate = useNavigate();
  const { showError, showSuccess, showWarning } = useAlert();
  const [isEditing, setIsEditing] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const {
    data: selectedRent,
    isLoading: isRentLoading,
    isError: isRentError,
    error: rentError,
  } = useGetRentById(rentId, {
    select: (data) => data?.result,
    enabled: !!rentId && !isDeleted,
  });

  const { mutate: updateRent } = useUpdateRent({
    id: selectedRent?.id,
    config: {
      onError: (error) => {
        showError(error.message);
      },
      onSuccess: () => {
        showSuccess("Η ενοικίαση ενημερώθηκε με επιτυχία");
      },
    },
  });
  const { mutate: deleteRent } = useDeleteRent({
    id: selectedRent?.id,
    config: {
      onError: (error) => {
        showError(error.message);
        setIsDeleted(false);
      },
      onSuccess: () => {
        showSuccess("Η ενοικίαση διαγράφηκε με επιτυχία");
        navigate(-1);
      },
    },
  });
  const { mutate: uploadFiles } = useUploadFiles({
    fileableId: selectedRent?.id,
    fileableType: "Rent",
  });
  const { mutate: deleteMultipleFiles } = useDeleteMultipleFiles();

  if (isRentLoading) {
    return <SpinningCircle />;
  }

  if (isRentError) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          height: "100%",
        }}
      >
        <p>Error: {rentError?.message}</p>
      </Box>
    );
  }

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleCancelClick = () => {
    setIsEditing(false);
  };

  const handleUpdateWhenDeleteOnly = (payload, deletedFiles) => {
    deleteMultipleFiles(
      deletedFiles.map((file) => file.id),
      {
        onSuccess: () => {
          updateRent(payload);
        },
        onError: (error) => {
          showWarning(
            "Υπήρξε πρόβλημα με τη διαγραφή των αρχείων. Παρακαλώ δοκιμάστε ξανά την ενημέρωση των αρχείων"
          );
          showError(error.message);
        },
      }
    );
  };

  const handleUpdateWhenUploadOnly = (payload, newFiles) => {
    uploadFiles(
      { files: newFiles },
      {
        onSuccess: () => {
          updateRent(payload);
        },
        onError: (error) => {
          showWarning(
            "Υπήρξε πρόβλημα με το ανέβασμα των αρχείων. Παρακαλώ δοκιμάστε ξανά την ενημέρωση των αρχείων"
          );
          showError(error.message);
        },
      }
    );
  };

  const handleUpdateWhenDeleteAndUpload = (payload, deletedFiles, newFiles) => {
    deleteMultipleFiles(
      deletedFiles.map((file) => file.id),
      {
        onSuccess: () => {
          uploadFiles(
            { files: newFiles },
            {
              onSuccess: () => {
                updateRent(payload);
              },
              onError: (error) => {
                showWarning(
                  "Υπήρξε πρόβλημα με το ανέβασμα των αρχείων. Παρακαλώ δοκιμάστε ξανά την ενημέρωση των αρχείων"
                );
                showError(error.message);
              },
            }
          );
        },
        onError: (error) => {
          showWarning(
            "Υπήρξε πρόβλημα με τη διαγραφή των αρχείων. Παρακαλώ δοκιμάστε ξανά την ενημέρωση των αρχείων"
          );
          showError(error.message);
        },
      }
    );
  };

  const handleSaveClick = (data) => {
    setIsEditing(false);

    const payload = {
      ...data,
      contractId: data?.contractId?.id,
      rentType: enums.RentTypes.find((type) => type.label === data.rentType)
        ?.id,
      assetId,
    };

    const newFiles = data.files?.filter((file) =>
      selectedRent.files.every((attachment) => attachment.id !== file.id)
    );

    const deletedFiles = selectedRent.files?.filter(
      (attachment) => !data.files?.some((file) => file.id === attachment.id)
    );

    if (deletedFiles.length === 0 && newFiles.length === 0) {
      updateRent(payload);
    } else if (deletedFiles.length > 0 && newFiles.length === 0) {
      handleUpdateWhenDeleteOnly(payload, deletedFiles);
    } else if (deletedFiles.length === 0 && newFiles.length > 0) {
      handleUpdateWhenUploadOnly(payload, newFiles);
    } else {
      handleUpdateWhenDeleteAndUpload(payload, deletedFiles, newFiles);
    }
  };

  const handleDeleteClick = () => {
    setIsEditing(false);
    setIsDeleted(true);
    deleteRent();
  };

  return (
    selectedRent && (
      <>
        <Box
          display="flex"
          alignItems={"center"}
          justifyContent="flex-start"
          gap={1}
        >
          <Typography color={colors.primary} fontWeight={700} fontSize={24}>
            Διαχείριση Ενοικίασης
          </Typography>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          gap={2}
          sx={{
            height: "100%",
          }}
          mt={4}
        >
          <Box
            mb={2}
            display="flex"
            gap={2}
            justifyContent="space-between"
            alignItems={{
              xs: "flex-start",
              sm: "center",
            }}
            flexWrap={"wrap"}
          >
            <Box
              display="flex"
              gap={2}
              alignItems="center"
              sx={{
                width: {
                  xs: "100%",
                  sm: "auto",
                },
              }}
            >
              <Typography fontSize={20} fontWeight={600} color={colors.primary}>
                {selectedRent.title}
              </Typography>
            </Box>
            <Box
              display="flex"
              gap={2}
              sx={{
                width: {
                  xs: "100%",
                  sm: "auto",
                },
              }}
            >
              <EditButton onClick={handleEditClick} disabled={isEditing} />
            </Box>
          </Box>
          <Box
            display="flex"
            gap={2}
            justifyContent="space-between"
            sx={{
              width: "100%",
            }}
          >
            <RentsViewEditForm
              selectedRent={selectedRent}
              isEditing={isEditing}
              onSave={handleSaveClick}
              onCancel={handleCancelClick}
              onDelete={handleDeleteClick}
            />
            <Box
              sx={{
                borderLeft: "2px solid",
                borderColor: colors.lightGrey,
                borderRadius: 6,
                opacity: "0.2",
                marginLeft: 2,
              }}
            />
            <RentHistoryOverview rentId={rentId} />
          </Box>
        </Box>
      </>
    )
  );
};

RentsViewEdit.propTypes = {
  assetId: PropTypes.string,
  rentId: PropTypes.string,
};

export default RentsViewEdit;
