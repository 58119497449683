import CustomTextField from "components/inputs/CustomTextField";
import SaveButton from "components/buttons/SaveButton";
import colors from "../../config/theme/colors";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { Box, Grid, Typography } from "@mui/material";
import { useResetPassword } from "hooks/queries/useAuth";
import { useAlert } from "hooks/useAlert";
import { passwordRegex } from "config/regex";
import PromoPage from "../special/PromoPage";
import { useIsMutating } from "@tanstack/react-query";

const ResetPasswordPage = () => {
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get("code");
  const email = urlParams.get("email");
  const { showError, showSuccess } = useAlert();

  const { mutate: resetPassword } = useResetPassword();
  const isOnResetPasswordLoading =
    useIsMutating({ mutationKey: ["resetPassword"] }) > 0;

  const onResetPassword = (data) => {
    const payload = {
      newPassword: data.newPassword,
      confirmNewPassword: data.confirmNewPassword,
      email,
      token,
    };
    resetPassword(payload, {
      onError: (error) => {
        showError(error.message);
      },
      onSuccess: () => {
        showSuccess("Ο κωδικός σας άλλαξε επιτυχώς!");
        navigate("/");
      },
    });
  };

  const schema = yup.object().shape({
    newPassword: yup
      .string()
      .min(8, "Ο κωδικός πρέπει να είναι τουλάχιστον 8 χαρακτήρες")
      .max(32, "Ο κωδικός πρέπει να είναι το πολύ 32 χαρακτήρες")
      .matches(
        passwordRegex,
        "Ο κωδικός πρόσβασης πρέπει να περιέχει ένα μικρό γράμμα, ένα κεφαλαίο και έναν ειδικό χαρακτήρα"
      )
      .required("Το πεδίο είναι υποχρεωτικό"),
    confirmNewPassword: yup
      .string()
      .matches(
        passwordRegex,
        "Ο κωδικός πρόσβασης πρέπει να περιέχει ένα μικρό γράμμα, ένα κεφαλαίο και έναν ειδικό χαρακτήρα"
      )
      .oneOf([yup.ref("newPassword"), null], "Οι κωδικοί δεν ταιριάζουν")
      .required("Το πεδίο είναι υποχρεωτικό"),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm({ resolver: yupResolver(schema) });

  return (
    <form onSubmit={handleSubmit(() => onResetPassword(getValues()))}>
      <Box
        display="flex"
        flexDirection="column"
        gap={2}
        sx={{
          backgroundColor: colors.white,
        }}
      >
        <Grid
          container
          sx={{
            minHeight: "100vh",
          }}
        >
          <Grid
            item
            xs={12}
            md={6}
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            paddingTop={{
              xs: 5,
              md: 0,
            }}
            paddingX={{
              xs: 6,
              sm: 6,
              md: 10,
              lg: 15,
              xl: 20,
            }}
          >
            <h1 style={{ fontSize: "48px" }}>
              <b>Αλλαγή Κωδικού</b>
            </h1>
            <p> Παρακαλώ εισάγετε τον νέο κωδικό σας παρακάτω.</p>
            <div className="flex flex-col gap-4 mt-10">
              <div>
                <Grid item xs={12} md={12}>
                  <CustomTextField
                    label="Νέος Κωδικός"
                    name="newPassword"
                    type="text"
                    sensitive
                    control={control}
                    disabled={false}
                    placeholder="Πληκτρολογήστε τον νέο κωδικό σας"
                  />
                </Grid>
              </div>
              <div>
                <Grid item xs={12} md={12}>
                  <CustomTextField
                    label="Επιβεβαίωση Κωδικού"
                    name="confirmNewPassword"
                    type="text"
                    sensitive
                    control={control}
                    disabled={false}
                    placeholder="Επιβεβαιώστε τον κωδικό σας"
                  />
                </Grid>
              </div>
              <div>
                <Grid item xs={12} md={12}>
                  <CustomTextField
                    label="Email"
                    name="email"
                    type="email"
                    control={control}
                    disabled={true}
                    defaultValue={email}
                  />
                </Grid>
              </div>

              {Object.keys(errors).length > 0 && (
                <Typography color="error" fontSize={14}>
                  Παρακαλώ ελέγξτε ότι όλα τα πεδία έχουν συμπληρωθεί σωστά
                </Typography>
              )}
              <Box
                display="flex"
                justifyContent="flex-center"
                alignItems="center"
                gap={2}
                style={{ paddingTop: "50px", width: "100%" }}
              >
                <SaveButton
                  text={"Αλλάγη Κωδικού"}
                  width={"100%"}
                  isLoading={isOnResetPasswordLoading}
                />
              </Box>
            </div>
          </Grid>

          <PromoPage />
        </Grid>
      </Box>
    </form>
  );
};

export default ResetPasswordPage;
