import { useState } from "react";

const useSearchInput = () => {
  const [searchInput, setSearchInput] = useState("");

  const handleSearch = (textInput) => {
    setSearchInput(textInput);
  };

  const handleReset = () => {
    setSearchInput("");
  };

  return {
    searchInput,
    handleSearch,
    handleReset,
  };
};

export default useSearchInput;
