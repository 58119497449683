import { requests } from "api/HttpClient";

const { post, get, put, delete: deleteRequest } = requests;

const assets = {
  getAllAssets: ({
    pageNumber = -1,
    pageSize = -1,
    searchParameter,
    assetUse,
    assetCategoryIds,
    orderBy,
    sortingOrder,
  }) => {
    let url = "/Asset/GetAssetList";

    const queryParams = [];

    if (pageNumber && pageSize) {
      queryParams.push(`pageNumber=${pageNumber}`);
      queryParams.push(`pageSize=${pageSize}`);
    }

    if (searchParameter) {
      queryParams.push(`searchParameter=${searchParameter}`);
    }

    if (orderBy) {
      queryParams.push(`orderBy=${orderBy}`);
    }

    if (sortingOrder) {
      queryParams.push(`sortingOrder=${sortingOrder}`);
    }

    if (assetCategoryIds) {
      assetCategoryIds.forEach((category) => {
        queryParams.push(`AssetCategoryIds=${encodeURIComponent(category)}`);
      });
    }

    if (assetUse) {
      assetUse.forEach((use) => {
        queryParams.push(`AssetUse=${encodeURIComponent(use)}`);
      });
    }

    if (queryParams.length > 0) {
      url += `?${queryParams.join("&")}`;
    }

    return get(url);
  },
  getAssetById: (id) => get(`/Asset/GetById/${id}`),
  getAssetCategoryCount: () => get("Asset/GetAssetCategoryCount"),
  createAsset: (body) => post("/Asset/AddAsset", body),
  updateAsset: (id, body) => put(`/Asset/UpdateAsset/${id}`, body),
  deleteAsset: (id) => deleteRequest(`/Asset/DeleteAsset/${id}`),
};

export default assets;
