import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import MainPageLayout from "components/main/MainPageLayout";
import TasksViewEditForm from "./TasksViewEditForm";
import { useNavigate, useParams } from "react-router";
import SpinningCircle from "components/spinners/SpinningCircle";
import ReturnButton from "components/buttons/ReturnButton";
import colors from "config/theme/colors";
import {
  useGetTaskById,
  useUpdateTask,
  useForceCompleteTask,
} from "hooks/queries/useTasks";
import { useEffect, useState } from "react";
import { useAlert } from "hooks/useAlert";
import EditButton from "components/buttons/EditButton";
import { useQueryClient } from "@tanstack/react-query";

const TasksViewEdit = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [isEditing, setIsEditing] = useState(false);
  const { showError, showSuccess } = useAlert();
  const { taskId } = useParams();
  const {
    data: selectedTask,
    isLoading: isSelectedTaskLoading,
    isError: isErrorSelectedTask,
    error: errorSelectedTask,
    isSuccess: isSuccessSelectedTask,
  } = useGetTaskById(taskId, {
    select: (data) => data.result,
    enabled: !!taskId,
  });
  const assetId =
    selectedTask?.maintenance?.equipment?.assetId ||
    selectedTask?.rent?.asset?.id ||
    selectedTask?.maintenance?.assetId;
  const equipmentId =
    selectedTask?.maintenance?.equipment?.id ||
    selectedTask?.maintenance?.equipmentId;
  const maintenanceId = selectedTask?.maintenance?.id;

  const onActionNavigation = () => {
    if (assetId && equipmentId && maintenanceId) {
      navigate(
        `/assets/${assetId}/equipment/${equipmentId}/maintenance/${maintenanceId}/history/new`,
        {
          state: {
            taskId: selectedTask?.id,
          },
        }
      );
    } else if (assetId && maintenanceId) {
      navigate(`/assets/${assetId}/maintenance/${maintenanceId}/history/new`, {
        state: {
          taskId: selectedTask?.id,
        },
      });
    } else if (assetId) {
      navigate(`/assets/${assetId}/rents/history/new`, {
        state: {
          taskId: selectedTask?.id,
        },
      });
    } else if (assetId && equipmentId) {
      //TODO: navigate to correct page when/if the rent page for equipment is created
      return;
    } else {
      return;
    }
  };

  const { mutate: updateTask } = useUpdateTask({
    id: selectedTask?.id,
    config: {
      onError: (error) => {
        showError(error.message);
      },
      onSuccess: () => {
        showSuccess("Η εργασία ενημερώθηκε με επιτυχία!");
      },
    },
  });

  const { mutate: forceCompleteTask } = useForceCompleteTask({
    id: selectedTask?.id,
    config: {
      onError: (error) => {
        showError(error.message);
      },
      onSuccess: () => {
        showSuccess("Η εργασία ολοκληρώθηκε με επιτυχία!");
      },
    },
  });

  const handleSaveClick = (data) => {
    setIsEditing(false);
    const payload = {
      notes: data.notes || "",
    };
    updateTask(payload);
  };

  const handleActionClick = () => {
    onActionNavigation();
  };

  const handleCompleteClick = () => {
    forceCompleteTask();
  };

  const handleCancelClick = () => {
    setIsEditing(false);
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  useEffect(() => {
    if (isSuccessSelectedTask) {
      queryClient.invalidateQueries("getAllTasks");
      queryClient.invalidateQueries(["getTaskById", +taskId]);
    }
  }, [isSuccessSelectedTask, queryClient, taskId]);

  if (isSelectedTaskLoading) {
    return <SpinningCircle />;
  }

  if (isErrorSelectedTask) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          height: "100%",
        }}
      >
        <p>Error: {errorSelectedTask?.message}</p>
      </Box>
    );
  }

  return (
    selectedTask && (
      <MainPageLayout
        pageTitle={
          selectedTask.status === "Ολοκληρωμένο"
            ? "Προβολή Εργασίας"
            : "Προβολή - Επεξεργασία Εργασίας"
        }
      >
        <Box
          display="flex"
          flexDirection="column"
          gap={2}
          sx={{
            height: "100%",
          }}
        >
          <Box
            mb={2}
            display="flex"
            gap={2}
            justifyContent="space-between"
            alignItems={{
              xs: "flex-start",
              sm: "center",
            }}
            flexWrap={"wrap"}
          >
            <Box
              display="flex"
              gap={2}
              alignItems="center"
              sx={{
                width: {
                  xs: "100%",
                  sm: "auto",
                },
              }}
            >
              <ReturnButton />
              <Typography fontSize={20} fontWeight={600} color={colors.primary}>
                {`${selectedTask.name}`}
              </Typography>
            </Box>
            {selectedTask.status !== "Ολοκληρωμένο" && (
              <Box
                display="flex"
                gap={2}
                sx={{
                  width: {
                    xs: "100%",
                    sm: "auto",
                  },
                }}
              >
                <EditButton onClick={handleEditClick} disabled={isEditing} />
              </Box>
            )}
          </Box>
          <Box mt={1} flexDirection="column" justifyContent="space-between">
            <TasksViewEditForm
              selectedTask={selectedTask}
              isEditing={isEditing}
              onSave={handleSaveClick}
              onAction={handleActionClick}
              onComplete={handleCompleteClick}
              onCancel={handleCancelClick}
            />
          </Box>
        </Box>
      </MainPageLayout>
    )
  );
};

export default TasksViewEdit;
