import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Agent } from "api/agent";

export const useGetAllRoles = (options = {}) => {
  return useQuery({
    queryKey: ["getAllRoles"],
    queryFn: () => Agent.authSetup.getAllRoles(),
    staleTime: 1000 * 60 * 60,
    ...options,
  });
};

export const useGetUserRoles = (userId, options = {}) => {
  return useQuery({
    queryKey: ["getUserRoles", userId],
    queryFn: () => Agent.authSetup.getUserRoles(userId),
    enabled: !!userId,
    staleTime: 1000 * 60 * 10,
    ...options,
  });
};

export const useGetAllClaims = (options = {}) => {
  return useQuery({
    queryKey: ["getAllClaims"],
    queryFn: Agent.authSetup.getAllClaims(),
    staleTime: 1000 * 60 * 60,
    ...options,
  });
};

export const useGetUserClaims = (userId, options = {}) => {
  return useQuery({
    queryKey: ["getUserClaims", userId],
    queryFn: () => Agent.authSetup.getUserClaims(userId),
    enabled: !!userId,
    staleTime: 1000 * 60 * 10,
    ...options,
  });
};

export const useAddClaimToUser = (options = {}) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => Agent.authSetup.AddClaimToUser(),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["getUserClaims"] });
    },
    ...options,
  });
};

export const useRemoveClaimFromUser = (options = {}) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => Agent.authSetup.RemoveClaimFromUser(),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["getUserClaims"] });
    },
    ...options,
  });
};

export const useAddRoleToUser = (userId, options = {}) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (roleId) =>
      Agent.authSetup.AddRoleToUser({
        userId: userId,
        roleId: roleId,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["getUserRoles"] });
    },
    ...options,
  });
};

export const useRemoveRoleFromUser = (userId, options = {}) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (roleId) =>
      Agent.authSetup.RemoveRoleFromUser({
        userId: userId,
        roleId: roleId,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["getUserRoles"] });
    },
    ...options,
  });
};
