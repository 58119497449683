import { requests } from "api/HttpClient";

const { post, get, patch } = requests;

const headers = {
  headers: {
    "Content-Type": "multipart/form-data",
  },
};

const auth = {
  login: (data) => post("/Auth/login", data, headers),
  logout: (token, refreshToken) =>
    post(
      "/Auth/logout",
      {
        token,
        refreshToken,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    ),
  getProfile: () => get("/Auth/profile"),
  updateProfile: (phoneNumber) =>
    patch("/Auth/profile", { phoneNumber: `${phoneNumber}` }),
  changePassword: (body) => patch("/Auth/updatePassword", body),
  forgotPassword: (email) =>
    post("/Auth/forgotPassword", { email: `${email}` }, headers),
  resetPassword: (data) =>
    post("/Auth/resetPassword", data, {
      headers: {
        "Content-Type": "application/json",
      },
    }),
  refreshToken: (data) => post("/Auth/refreshToken", data),
};

export default auth;
