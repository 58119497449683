import MainPageLayout from "components/main/MainPageLayout";
import { useNavigate } from "react-router";
import { useCreateUser } from "hooks/queries/useUsers";
import { useAlert } from "hooks/useAlert";
import transformDotFieldsToObject from "utils/transformDotFieldsToJSON";
import UserCreateForm from "./UserCreateForm";

const UserCreate = () => {
  const { showError, showSuccess } = useAlert();
  const { mutate: createUser } = useCreateUser();
  const navigate = useNavigate();

  const handleCreateClick = (data) => {
    const formData = transformDotFieldsToObject(data);

    const payload = {
      ...formData,
    };
    createUser(payload, {
      onError: (error) => {
        showError(error.message);
      },
      onSuccess: () => {
        showSuccess("Ο χρήστης δημιουργήθηκε με επιτυχία");
        navigate("/users");
      },
    });
  };

  return (
    <MainPageLayout
      pageTitle={"Νέος Χρήστης"}
      pageSubTitle={"Δημιουργία νέου χρήστη"}
    >
      <UserCreateForm onCreate={handleCreateClick} />
    </MainPageLayout>
  );
};

export default UserCreate;
