import { Box } from "@mui/material";
import colors from "config/theme/colors";
import Topbar from "pages/topbar/Topbar";
import PropTypes from "prop-types";

const MainPageLayout = ({
  children,
  pageTitle,
  pageSubTitle = "",
  headerTabs = [],
  sx = {},
}) => {
  return (
    <Box
      sx={{
        height: "100%",
        maxHeight: "100vh",
        overflow: "hidden",
        width: "100%",
      }}
    >
      <Topbar
        title={pageTitle}
        subTitle={pageSubTitle}
        headerTabs={headerTabs}
      />
      <Box
        sx={{
          maxHeight:
            headerTabs.length > 0 ? "calc(100% - 111px)" : "calc(100% - 66px)",
          overflowY: "auto",
          backgroundColor: colors.white,
          paddingLeft: "42px",
          paddingRight: "42px",
          paddingTop: "35px",
          paddingBottom: "12px",
          ...sx,
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

MainPageLayout.propTypes = {
  children: PropTypes.node.isRequired,
  pageTitle: PropTypes.string.isRequired,
  pageSubTitle: PropTypes.string,
  headerTabs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
      to: PropTypes.string.isRequired,
    })
  ),
  sx: PropTypes.object,
};

export default MainPageLayout;
