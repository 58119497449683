import { requests } from "api/HttpClient";

const { post, get, put, delete: deleteRequest } = requests;

const config = {
  headers: {
    "Content-Type": "multipart/form-data",
  },
};

const maintenanceType = {
  getAll: ({
    pageNumber = -1,
    pageSize = -1,
    assetCategoryId,
    equipmentTypeIds,
  }) => {
    let url = "/MaintenanceType/GetMaintenanceTypeList";
    let queryParams = [];

    if (assetCategoryId !== undefined && assetCategoryId !== null) {
      queryParams.push(`assetCategoryId=${assetCategoryId}`);
    }
    if (equipmentTypeIds !== undefined && equipmentTypeIds !== null) {
      equipmentTypeIds.forEach((id) => {
        queryParams.push(`equipmentTypeIds=${id}`);
      });
    }
    if (pageNumber !== undefined && pageNumber !== null) {
      queryParams.push(`PageNumber=${pageNumber}`);
    }
    if (pageSize !== undefined && pageSize !== null) {
      queryParams.push(`PageSize=${pageSize}`);
    }

    if (queryParams.length > 0) {
      url += "?" + queryParams.join("&");
    }

    return get(url);
  },
  getById: (id) => get(`/MaintenanceType/GetById/${id}`),
  create: (body) => post("/MaintenanceType/AddMaintenanceType", body, config),
  update: (id, body) =>
    put(`/MaintenanceType/UpdateMaintenanceType/${id}`, body, config),
  delete: (id) => deleteRequest(`/MaintenanceType/DeleteMaintenanceType/${id}`),
};

export default maintenanceType;
