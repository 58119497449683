import { requests } from "api/HttpClient";

const { post, get, put, delete: deleteRequest } = requests;

const equipmentType = {
  getAll: ({ pageNumber = -1, pageSize = -1, assetCategoryId }) => {
    let url = "/EquipmentType/GetEquipmentTypeList";
    const queryParams = [];

    if (pageNumber && pageSize) {
      queryParams.push(`pageNumber=${pageNumber}`);
      queryParams.push(`pageSize=${pageSize}`);
    }
    if (assetCategoryId) {
      queryParams.push(`assetCategoryId=${assetCategoryId}`);
    }

    if (queryParams.length > 0) {
      url += `?${queryParams.join("&")}`;
    }

    return get(url);
  },
  getById: (id) => get(`/EquipmentType/GetEquipmentTypeById/${id}`),
  create: (body) =>
    post("/EquipmentType/AddEquipmentType", body, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }),
  update: (id, body) =>
    put(`/EquipmentType/UpdateEquipmentType/${id}`, body, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }),
  delete: (id) => deleteRequest(`/EquipmentType/DeleteEquipmentType/${id}`),
};

export default equipmentType;
