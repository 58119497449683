import { ReactComponent as CloseIcon } from "assets/icons/close.svg";
import colors from "config/theme/colors";
import PropTypes from "prop-types";
import { Box, Modal, DialogActions, Tooltip, Typography } from "@mui/material";
import CustomButton from "components/buttons/CustomButton";
import DataGridTable from "components/tables/DataGridTable";

const EquipmentMassImportErrorModal = ({
  modalOpen,
  handleModalClose,
  modalTitle,
  errorsResponse,
  fileData,
}) => {
  const columns = [
    {
      field: "equipmentTypeName",
      headerName: "Τύπος",
      width: 150,
      editable: false,
      renderCell: (params) => (
        <Typography
          sx={{
            color: params.row.errors?.EquipmentTypeName ? "red" : "inherit",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            marginTop: 2,
          }}
        >
          {params.value}
        </Typography>
      ),
    },
    {
      field: "equipmentGroupName",
      headerName: "Ομάδα",
      width: 150,
      editable: false,
      renderCell: (params) => (
        <Typography
          sx={{
            color: params.row.errors?.EquipmentGroupName ? "red" : "inherit",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            marginTop: 2,
          }}
        >
          {params.value}
        </Typography>
      ),
    },
    {
      field: "equipmentClassName",
      headerName: "Κλάση",
      width: 150,
      editable: false,
      renderCell: (params) => (
        <Typography
          sx={{
            color: params.row.errors?.EquipmentClassName ? "red" : "inherit",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            marginTop: 2,
          }}
        >
          {params.value}
        </Typography>
      ),
    },
    {
      field: "name",
      headerName: "Όνομα",
      width: 150,
      editable: false,
      renderCell: (params) => (
        <Typography
          sx={{
            color: params.row.errors?.Name ? "red" : "inherit",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            marginTop: 2,
          }}
        >
          {params.value}
        </Typography>
      ),
    },
    {
      field: "description",
      headerName: "Περιγραφή",
      width: 150,
      editable: false,
      renderCell: (params) => (
        <Typography
          sx={{
            color: params.row.errors?.Description ? "red" : "inherit",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            marginTop: 2,
          }}
        >
          {params.value}
        </Typography>
      ),
    },
    {
      field: "floor",
      headerName: "Όροφος",
      width: 150,
      editable: false,
      renderCell: (params) => (
        <Typography
          sx={{
            color: params.row.errors?.Floor ? "red" : "inherit",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            marginTop: 2,
          }}
        >
          {params.value}
        </Typography>
      ),
    },
    {
      field: "typeOfSupply",
      headerName: "Τύπος Προμήθειας",
      width: 150,
      editable: false,
      renderCell: (params) => (
        <Typography
          sx={{
            color: params.row.errors?.TypeOfSupply ? "red" : "inherit",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            marginTop: 2,
          }}
        >
          {params.value}
        </Typography>
      ),
    },
  ];

  const errorsMap = errorsResponse?.reduce((acc, { index, errors }) => {
    acc[index] = errors?.reduce((errorAcc, field) => {
      errorAcc[field] = true;
      return errorAcc;
    }, {});
    return acc;
  }, {});

  const rows = fileData
    ?.map((item, index) => ({
      id: index + 1,
      ...item,
      errors: errorsMap[index + 1] || {},
    }))
    ?.filter((row) => Object.keys(row.errors).length > 0);

  return (
    <Modal
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      open={modalOpen}
      onClose={handleModalClose}
      disableEscapeKeyDown
    >
      <Box
        sx={{
          backgroundColor: colors.white,
          borderRadius: "4px",
          minWidth: 600,
          maxHeight: "90vh",
          minHeight: 100,
          padding: 2,
        }}
        boxShadow={"0px 4px 4px #2948981A"}
        gap={2}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            gap: 2,
          }}
        >
          <Typography variant="h3" color="error">
            {modalTitle}
          </Typography>

          <Box display="flex" justifyContent="end" borderRadius={"4px 4px 0 0"}>
            <Tooltip onClick={handleModalClose} style={{ cursor: "pointer" }}>
              <CloseIcon stroke={colors.primary} />
            </Tooltip>
          </Box>
        </Box>

        <Box
          sx={{
            marginTop: 4,
            minHeight: 200,
          }}
        >
          <DataGridTable
            key={JSON.stringify(rows)}
            columns={columns}
            rows={rows}
          />
        </Box>

        <DialogActions>
          <CustomButton
            orientation="row"
            fontSize={14}
            fontWeight={600}
            sx={{
              minWidth: 120,
              maxHeight: 20,
              paddingY: 3,
              borderRadius: 3,
              marginTop: 3,
            }}
            title="OK"
            onClick={handleModalClose}
          />
        </DialogActions>
      </Box>
    </Modal>
  );
};

EquipmentMassImportErrorModal.propTypes = {
  modalOpen: PropTypes.bool.isRequired,
  handleModalClose: PropTypes.func.isRequired,
  modalTitle: PropTypes.string.isRequired,
  errorsResponse: PropTypes.arrayOf(
    PropTypes.shape({
      index: PropTypes.number.isRequired,
    })
  ).isRequired,
  fileData: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default EquipmentMassImportErrorModal;
