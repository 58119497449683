import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Agent } from "api/agent";

const baseQueryConfig = {
  staleTime: Infinity,
  enabled: false,
  retry: false,
  refetchOnReconnect: false,
  refetchOnWindowFocus: false,
  refetchOnMount: false,
};

export const useGetBulkEquipmentTemplate = ({
  equipmentTypeId = null,
  assetId = null,
}) => {
  return useQuery({
    queryKey: ["getBulkEquipmentTemplate", equipmentTypeId, assetId],
    queryFn: () =>
      Agent.bulkImportEquipment.getBulkEquipmentTemplate({
        equipmentTypeId: equipmentTypeId,
        assetId: assetId,
      }),
    ...baseQueryConfig,
  });
};

export const useImportBulkEquipment = ({ config = {} }) => {
  const queryClient = useQueryClient();
  const { onSuccess, ...restConfig } = config || {};

  return useMutation({
    mutationKey: ["importBulkEquipment"],
    mutationFn: (body) => Agent.bulkImportEquipment.importBulkEquipment(body),
    onSuccess: (...args) => {
      queryClient.invalidateQueries(["getAllEquipment"]);
      queryClient.invalidateQueries(["getEquipmentsByAssetId"]);
      onSuccess?.(...args);
    },
    ...restConfig,
  });
};
