import { Box, Typography } from "@mui/material";
import CustomButton from "components/buttons/CustomButton";
import SearchBar from "components/inputs/SearchBar";
import SpinningCircle from "components/spinners/SpinningCircle";
import CustomTable from "components/tables/CustomTable";
import { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router";
import { ReactComponent as AddIcon } from "assets/icons/add.svg";
import colors from "config/theme/colors";
import MainPageLayout from "components/main/MainPageLayout";
import {
  useGetAllCompanies,
  useGetCompanyTypeCount,
} from "hooks/queries/useCompanies";
import enums from "config/enums";
import { ReactComponent as CompanyIcon } from "assets/icons/company.svg";
import TagLabel from "components/labels/TagLabel";
import CountCard from "components/cards/CountCard";
import useSearchInput from "hooks/useSearchInput";
import FilterButton from "components/buttons/FilterButton";
import useSorting from "hooks/useSorting";
import usePagination from "hooks/usePagination";
import { useAuthStore } from "stores/AuthStore";

const colorPalette = ["#444444", "#1373A9D6", "#133D8F"];

const CompaniesOverview = () => {
  const authStore = useAuthStore();
  const navigate = useNavigate();
  const {
    page,
    setPage,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
  } = usePagination();
  const [selectedFilters, setSelectedFilters] = useState({});
  const [selectedCompanyTypes, setSelectedCompanyTypes] = useState([]);
  const { orderDirection, valueToOrderBy, handleSortChange } = useSorting();
  const { searchInput, handleSearch } = useSearchInput();
  const CompanyTypes = enums.CompanyTypes;
  const {
    data: companies,
    isLoading: isCompaniesLoading,
    error: errorCompanies,
    refetch: refetchCompanies,
  } = useGetAllCompanies({
    pageNumber: page,
    pageSize: rowsPerPage,
    searchParameter: searchInput,
    serviceType: selectedCompanyTypes,
    orderBy: valueToOrderBy,
    sortingOrder: orderDirection === "asc" ? "Ascending" : "Descending",
  });
  const {
    data: companyTypesCount,
    isLoading: isLoadingCompanyTypesCount,
    isError: isErrorCompanyTypesCount,
    error: errorCompanyTypesCount,
  } = useGetCompanyTypeCount();

  const filterGroups = [
    {
      title: "Τύπος Εταιρείας",
      filters: CompanyTypes.map((type) => ({
        id: type.id,
        label: type.label,
        type: "checkbox",
      })),
    },
  ];

  const handleSubmitFilters = useCallback(
    (filters) => {
      const companyTypes = filters["Τύπος Εταιρείας"] || [];
      setSelectedFilters(filters);
      setSelectedCompanyTypes(companyTypes);
      if (filters) {
        setPage(1);
      }

      if (companyTypes.length === 0) {
        refetchCompanies();
      }
    },
    [setSelectedFilters, setSelectedCompanyTypes, refetchCompanies, setPage]
  );

  const tableHeaders = useMemo(
    () => [
      {
        id: "icon",
        label: "",
        isSortable: false,
      },
      {
        id: "name",
        label: "Όνομα",
        isSortable: true,
      },
      {
        id: "vat",
        label: "ΑΦΜ",
        isSortable: true,
      },
      {
        id: "contactPerson",
        label: "Yπεύθυνος",
        isSortable: false,
      },
      {
        id: "contactPhone",
        label: "Τηλέφωνο",
        isSortable: false,
      },
      {
        id: "address",
        label: "Διεύθυνση",
        isSortable: false,
      },
      {
        id: "email",
        label: "Email",
        isSortable: false,
      },
      {
        id: "type",
        label: "Τύπος",
        isSortable: true,
      },
    ],
    []
  );

  if (isCompaniesLoading || isLoadingCompanyTypesCount) {
    return <SpinningCircle />;
  }

  if (errorCompanies || isErrorCompanyTypesCount) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          height: "100%",
        }}
      >
        <p>Error: {errorCompanies.message || errorCompanyTypesCount.message}</p>
      </Box>
    );
  }

  const companiesCount = companies?.totalCount;
  const companiesList = companies?.items?.map((company) => ({
    id: company.id,
    icon: <CompanyIcon width={30} height={30} fill="#757B81" />,
    name: company.name || "-",
    vat: company.vat || "-",
    contactPerson: company.contactPerson || "-",
    contactPhone: company.contactPhone || "-",
    address: company.address || "-",
    email: company.email || "-",
    type: CompanyTypes.find((type) => type.id === company.type)?.label || "-",
  }));

  const handleItemClick = (item) => {
    navigate(`${item.id}`);
  };

  return (
    <MainPageLayout pageTitle="Οργανισμοί">
      <Box
        display="flex"
        flexDirection={{
          xs: "column",
          sm: "row",
        }}
        justifyContent={{
          xs: "space-between",
        }}
        flexGrow={1}
        flexWrap={{
          xs: "wrap",
          md: "nowrap",
        }}
        width={"100%"}
        gap={2}
      >
        <Box
          display="flex"
          gap={2}
          flexWrap={{
            xs: "wrap",
            sm: "nowrap",
          }}
          sx={{
            overflowX: "auto",
          }}
        >
          {CompanyTypes?.map((companyType) => {
            const categoryData = companyTypesCount?.find(
              (item) => item.name === companyType.id
            );
            const count = categoryData?.count || 0;
            return (
              <CountCard
                key={companyType.id}
                title={companyType.label}
                backgroundColor={
                  colorPalette[CompanyTypes?.indexOf(companyType)]
                }
                icon={
                  <CompanyIcon width={30} height={30} fill={colors.white} />
                }
                count={count}
                boxSx={{
                  minWidth: { xs: "100%", sm: "250px" },
                }}
              />
            );
          })}
        </Box>
        {!authStore.isUser && (
          <CustomButton
            icon={<AddIcon fill={colors.primary} width={30} height={30} />}
            title="Προσθήκη"
            sx={{
              minWidth: 120,
            }}
            onClick={() => {
              navigate("new");
            }}
          />
        )}
      </Box>
      <Box
        mt={4}
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent={{
          xs: "space-between",
          lg: "flex-start",
        }}
        flexGrow={1}
        gap={2}
        height={40}
      >
        <SearchBar
          placeholder="Αναζήτηση σε Όνομα, ΑΦΜ, Υπεύθυνο, Διεύθυνση, Τηλέφωνο, Email..."
          handleSearch={handleSearch}
          searchKey={searchInput}
        />
      </Box>
      <Box
        mt={4}
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        flexGrow={1}
        width={"100%"}
      >
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="start"
          gap={4}
          flexWrap={"wrap"}
          alignItems={"center"}
        >
          <Typography variant="h3" color="primary">
            Οργανισμοί ({companiesCount})
          </Typography>
          <Box display="flex" flexDirection="row" gap={1} flexWrap={"wrap"}>
            {CompanyTypes?.map((companyType) => {
              const count = companiesList?.filter(
                (companyItem) => companyItem.type === companyType.label
              ).length;
              return count > 0 ? (
                <TagLabel
                  key={companyType.id}
                  text={`${count} ${companyType.label}`}
                  color={colorPalette[CompanyTypes?.indexOf(companyType)]}
                />
              ) : null;
            })}
          </Box>
          <Box display="flex" flexGrow={1} justifyContent="flex-end">
            <FilterButton
              filterGroups={filterGroups}
              onApplyFilters={handleSubmitFilters}
              selectedFilters={selectedFilters}
            />
          </Box>
        </Box>

        {companiesList.length > 0 && tableHeaders && companiesCount > 0 ? (
          <CustomTable
            columns={tableHeaders}
            data={companiesList}
            fadeEffect
            onItemClick={handleItemClick}
            page={page - 1}
            rowsPerPage={rowsPerPage}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            totalResults={companiesCount}
            orderDirection={orderDirection}
            valueToOrderBy={valueToOrderBy}
            onSortChange={handleSortChange}
          />
        ) : (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{
              height: "100%",
            }}
          >
            <Typography variant="h4">Δεν υπάρχουν αποτελέσματα</Typography>
          </Box>
        )}
      </Box>
    </MainPageLayout>
  );
};

export default CompaniesOverview;
