import { Button, Typography } from "@mui/material";
import colors from "config/theme/colors";
import PropTypes from "prop-types";

const CancelButton = ({ onClick, text = "Ακύρωση", disabled = false }) => {
  return (
    <Button
      onClick={onClick}
      disabled={disabled}
      sx={{
        backgroundColor: colors.greyBackground,
        color: colors.primary,
        border: `1px solid ${colors.greyBackground}`,
        padding: "12px 22px",
        "&:hover": {
          backgroundColor: colors.primary,
          color: colors.greyBackground,
        },
        "&:disabled": {
          opacity: 0.5,
          backgroundColor: colors.greyBackground,
          color: colors.primary,
        },
        cursor: "pointer",
      }}
    >
      <Typography fontSize={12} fontWeight={700}>
        {text}
      </Typography>
    </Button>
  );
};

CancelButton.propTypes = {
  onClick: PropTypes.func,
  text: PropTypes.string,
  disabled: PropTypes.bool,
};

export default CancelButton;
