import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";

const MapCardInfoRow = ({
  labelColor,
  valueColor,
  label,
  value,
  Component,
}) => (
  <Box display="flex" alignItems="center" width="100%" gap={1}>
    <Box width={100}>
      <Box
        display="flex"
        alignItems="center"
        gap={1}
        justifyContent={"flex-end"}
      >
        <Typography
          fontSize={14}
          fontWeight={700}
          color={labelColor}
          textAlign="right"
          component={"span"}
        >
          {label}
        </Typography>
      </Box>
    </Box>

    <Box>
      <Box
        display="flex"
        alignItems="center"
        gap={1}
        justifyContent={"flex-start"}
      >
        <Typography
          ml={1.5}
          fontSize={14}
          fontWeight={500}
          color={valueColor}
          textAlign="left"
          display={"flex"}
          alignItems={"center"}
          flexWrap={"nowrap"}
          component={"span"}
        >
          {value}
          {Component}
        </Typography>
      </Box>
    </Box>
  </Box>
);

MapCardInfoRow.propTypes = {
  labelColor: PropTypes.string,
  valueColor: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  Component: PropTypes.element,
};

export default MapCardInfoRow;
