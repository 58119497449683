import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Grid, Typography } from "@mui/material";
import { useIsMutating } from "@tanstack/react-query";
import CancelButton from "components/buttons/CancelButton";
import DeleteButton from "components/buttons/DeleteButton";
import SaveButton from "components/buttons/SaveButton";
import CustomTextField from "components/inputs/CustomTextField";
import DateField from "components/inputs/DateField";
import DropdownField from "components/inputs/DropdownField";
import AlfrescoFileUploader from "components/fileUploaders/AlfrescoFileUploader";
import DeleteModal from "components/modals/DeleteModal";
import SpinningCircle from "components/spinners/SpinningCircle";
import enums from "config/enums";
import contractResponseFields from "config/forms/contractResponseFields";
import { useGetAllCompanies } from "hooks/queries/useCompanies";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useAuthStore } from "stores/AuthStore";
import * as yup from "yup";

const ContractViewEditForm = ({
  selectedContract,
  isEditing = false,
  onSave,
  onCancel,
  onDelete,
  isCompanyPreSelected = false,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedContractType, setSelectedContractType] = useState(
    enums.ContractTypes?.find((type) => type.id === selectedContract.type)?.id
  );
  const authStore = useAuthStore();
  const isUpdateLoading =
    useIsMutating({ mutationKey: ["updateContract"] }) +
      useIsMutating({ mutationKey: ["uploadFiles"] }) +
      useIsMutating({ mutationKey: ["deleteMultipleFiles"] }) +
      useIsMutating({ mutationKey: ["deleteFile"] }) >
    0;
  const isDeleteLoading =
    useIsMutating({ mutationKey: ["deleteContract"] }) > 0;
  const ContractTypes = enums.ContractTypes;
  const {
    data: companies,
    isLoading: isCompaniesLoading,
    error: errorCompanies,
  } = useGetAllCompanies(
    {
      pageNumber: -1,
      pageSize: -1,
      serviceType: [selectedContractType],
    },
    { enabled: !!selectedContractType && !isCompanyPreSelected }
  );

  const schema = yup.object(
    contractResponseFields.reduce((acc, field) => {
      let fieldSchema;

      if (field.type === "text" || field.type === "textarea") {
        fieldSchema = yup.string();
      } else if (field.type === "select" || field.type === "boolean") {
        if (field.name === "status") {
          fieldSchema = yup.object().shape({
            id: yup.string(),
            label: yup.string(),
          });
        } else fieldSchema = yup.string();
      } else if (field.type === "number") {
        fieldSchema = yup
          .number()
          .transform((value) => (isNaN(value) ? undefined : value));
      } else if (field.type === "date") {
        fieldSchema = yup.date().transform((value, originalValue) => {
          return isNaN(Date.parse(originalValue)) ? undefined : value;
        });
      } else if (field.type === "file") {
        const validTypes = field.validTypes || [];
        const maxSize = field.maxSize || 5000000;

        fieldSchema = yup.mixed().test({
          name: "fileValidation",
          exclusive: true,
          message: "Invalid file",
          test: (value) => {
            if (!value || value.length === 0) return true;

            for (const file of value) {
              if (
                !validTypes.includes(file.type) &&
                !validTypes.includes(file.mimeType)
              ) {
                return new yup.ValidationError(
                  "Μη έγκυρος τύπος αρχείου",
                  null,
                  "file"
                );
              }

              if (file.size > maxSize) {
                return new yup.ValidationError(
                  `Το μέγεθος του αρχείου δεν πρέπει να υπερβαίνει τα ${maxSize / 1000000}MB`,
                  null,
                  "file"
                );
              }
            }

            return true;
          },
        });
      }

      if (field.required) {
        fieldSchema = fieldSchema.required("Το πεδίο είναι υποχρεωτικό");
      } else {
        fieldSchema = fieldSchema.nullable();
      }

      if (field.validation === "custom" && field.regex) {
        fieldSchema = fieldSchema.matches(field.regex, field.validationMessage);
      }

      acc[field.name] = fieldSchema.typeError(
        field.validationMessage || "Μη έγκυρη τιμή"
      );

      return acc;
    }, {})
  );

  const getDataValueFromField = (fieldName) => {
    const fieldMap = contractResponseFields.reduce((acc, field) => {
      acc[field.name] = field.name;
      return acc;
    }, {});

    const path = fieldMap[fieldName];
    if (typeof path === "function") {
      return path();
    } else if (typeof path === "string") {
      const value = path
        .split(".")
        .reduce((acc, key) => (acc ? acc[key] : undefined), selectedContract);
      return value !== undefined && value !== null ? value : null;
    } else {
      return "";
    }
  };

  const handleFormSubmit = (data) => {
    onSave(data);
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const formFields = contractResponseFields;

  const defaultValues = {
    ...Object.fromEntries(
      formFields.map((field) => [field.name, getDataValueFromField(field.name)])
    ),
    type: ContractTypes.find(
      (type) => type.id === getDataValueFromField("type")
    )?.label,
    externalCompany: getDataValueFromField("externalCompany")?.name,
    status: {
      id: getDataValueFromField("status"),
      label:
        getDataValueFromField("status") === "Active" ? "Ενεργή" : "Ανενεργή",
    },
    files: getDataValueFromField("files")?.map((file) => ({
      id: file.id,
      name: file.name,
      preview: file.url,
      type: file.mimeType,
      title: file.title,
    })),
  };

  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { errors, isDirty },
    getValues,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  });

  const type = watch("type");

  useEffect(() => {
    setSelectedContractType(
      ContractTypes.find((t) => t.label === type)?.id || null
    );
  }, [type, ContractTypes]);

  if (isCompaniesLoading) {
    return <SpinningCircle />;
  }

  if (errorCompanies) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          height: "100%",
        }}
      >
        <p>Error: {errorCompanies.message}</p>
      </Box>
    );
  }

  const getInputElementByFieldType = (field) => {
    const defaultValue = getDataValueFromField(field.name);

    if (
      field.type === "text" ||
      field.type === "number" ||
      field.type === "textarea"
    ) {
      return (
        <CustomTextField
          {...field}
          value={defaultValue}
          disabled={!isEditing}
          control={control}
        />
      );
    } else if (field.type === "select" || field.type === "boolean") {
      let availableOptions =
        field.type === "boolean" ? ["Ναι", "Όχι"] : field.options;

      if (field.name === "type") {
        availableOptions = ContractTypes.map((option) => option.label);
      }

      if (field.name === "externalCompany" && companies?.items) {
        availableOptions = companies?.items.map((company) => company.name);
      }

      return (
        <DropdownField
          {...field}
          control={control}
          value={defaultValue}
          disabled={!isEditing}
          options={availableOptions}
          boolean={field.type === "boolean"}
          onChange={(e) => {
            if (field.name === "type") {
              setValue("externalCompany", null);
            }
          }}
        />
      );
    } else if (field.type === "date") {
      return (
        <DateField
          {...field}
          value={defaultValue}
          disabled={!isEditing}
          control={control}
        />
      );
    } else if (field.type === "file") {
      return (
        <AlfrescoFileUploader
          {...field}
          control={control}
          value={defaultValue}
          disabled={!isEditing}
        />
      );
    }
  };

  return (
    <form
      style={{ width: "100%" }}
      onSubmit={handleSubmit(() =>
        handleFormSubmit({
          ...getValues(),
          externalCompanyId: companies?.items.find(
            (company) => company.name === getValues("externalCompany")
          )?.id,
        })
      )}
    >
      <Box display="flex" flexDirection="column" gap={4}>
        <Grid container spacing={2} width={"100%"}>
          {formFields
            .filter((field) => field.display !== false)
            .map((field) => {
              if (field.name === "type") {
                return (
                  <Grid key={field?.name} container item>
                    <Grid item xs={6}>
                      {getInputElementByFieldType(field)}
                    </Grid>
                  </Grid>
                );
              } else if (selectedContractType) {
                if (field.type === "file") {
                  return (
                    <Grid item xs={12} key={field?.name}>
                      <Box width={{ xs: "100%", lg: "50%" }}>
                        {getInputElementByFieldType(field)}
                      </Box>
                    </Grid>
                  );
                } else
                  return (
                    <Grid item xs={12} md={4} key={field?.name}>
                      {getInputElementByFieldType(field)}
                    </Grid>
                  );
              }
              return null;
            })}
        </Grid>

        {Object.keys(errors).length > 0 && (
          <Typography color="error" fontSize={14}>
            Παρακαλώ ελέγξτε ότι όλα τα πεδία έχουν συμπληρωθεί σωστά
          </Typography>
        )}

        {!authStore.isUser && (
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            gap={2}
          >
            <Box display="flex" gap={2} justifyContent={"flex-start"}>
              <CancelButton
                disabled={!isEditing}
                onClick={() => {
                  reset();
                  onCancel();
                }}
              />
              <DeleteButton
                onClick={handleOpenModal}
                text="Διαγραφή Σύμβασης"
                disabled={!isEditing}
                isLoading={isDeleteLoading}
              />
            </Box>

            <SaveButton
              disabled={!isEditing || !isDirty}
              isLoading={isUpdateLoading}
            />

            <DeleteModal
              isOpen={isModalOpen}
              onClose={handleCloseModal}
              onDelete={onDelete}
              itemTitle={selectedContract?.name}
              title="Πρόκειται να διαγράψετε τη σύμβαση"
              description="Είστε βέβαιοι ότι θέλετε να προχωρήσετε στη διαγραφή της σύμβασης;"
            />
          </Box>
        )}
      </Box>
    </form>
  );
};

ContractViewEditForm.propTypes = {
  selectedContract: PropTypes.object.isRequired,
  isEditing: PropTypes.bool,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  onDelete: PropTypes.func,
  isCompanyPreSelected: PropTypes.bool,
};

export default ContractViewEditForm;
