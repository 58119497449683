import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useNavigate, useParams } from "react-router";
import { useCreateRent } from "hooks/queries/useRents";
import { useAlert } from "hooks/useAlert";
import RentsCreateForm from "./RentsCreateForm";
import colors from "config/theme/colors";
import enums from "config/enums";
import { useUploadFiles } from "hooks/queries/useFile";
const RentsCreate = () => {
  const { showError, showSuccess, showWarning } = useAlert();
  const { id: assetId } = useParams();
  const navigate = useNavigate();

  const { mutate: uploadFiles } = useUploadFiles({
    fileableType: "Rent",
    config: {
      onError: (error) => {
        showWarning(
          "Η ενοικίαση δημιουργήθηκε, αλλά προέκυψε σφάλμα κατά την αποθήκευση των αρχείων. Δοκιμάστε ξανά."
        );
        navigate(-1);
        console.error(error);
      },
      onSuccess: () => {
        showSuccess("Η νέα ενοικίαση δημιουργήθηκε επιτυχώς!");
        navigate(-1);
      },
    },
  });

  const { mutate: createRent } = useCreateRent({
    config: {
      onError: (error) => {
        showError(error.message);
      },
      onSuccess: () => {
        showSuccess("Η ενοικίαση δημιουργήθηκε με επιτυχία");
        navigate(-1);
      },
    },
  });

  const handleCreateClick = (data) => {
    const payload = {
      ...data,
      contractId: data?.contractId?.id,
      rentType: enums.RentTypes.find((type) => type.label === data.rentType)
        ?.id,
      assetId,
    };

    const files = data.files;

    createRent(payload, {
      onSuccess: (rent) => {
        if (files?.length > 0) {
          uploadFiles({ files, entityId: rent?.result?.id });
        } else {
          showSuccess("Η νέα ενοικίαση δημιουργήθηκε επιτυχώς!");
          navigate(-1);
        }
      },
    });
  };

  return (
    <>
      <Box
        display="flex"
        alignItems={"center"}
        justifyContent="flex-start"
        gap={1}
      >
        <Typography color={colors.primary} fontWeight={700} fontSize={24}>
          Νέα Ενοικίαση
        </Typography>
        <Typography color={colors.text} fontWeight={500} fontSize={18}>
          Δημιουργία νέας ενοικίασης
        </Typography>
      </Box>

      <Box mt={4.5}>
        <RentsCreateForm onCreate={handleCreateClick} />
      </Box>
    </>
  );
};

export default RentsCreate;
