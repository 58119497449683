import { useAlert } from "hooks/useAlert";
import { useNavigate, useOutletContext } from "react-router";
import { Box, Typography } from "@mui/material";
import colors from "config/theme/colors";
import { useGetAllCertificationTypes } from "hooks/queries/useCertificationType";
import SpinningCircle from "components/spinners/SpinningCircle";
import CertificationCreateForm from "./CertificationCreateForm";
import { useCreateCertification } from "hooks/queries/useCertifications";
import { useGetAllCompanies } from "hooks/queries/useCompanies";
import PropTypes from "prop-types";
import { useUploadFiles } from "hooks/queries/useFile";

const CertificationCreate = ({ facilityType = null }) => {
  const { showError, showSuccess, showWarning } = useAlert();
  const { selectedAsset, selectedEquipment } = useOutletContext();
  const navigate = useNavigate();
  const {
    data: certificationTypes,
    isLoading: certificationTypesLoading,
    isError: certificationTypesError,
  } = useGetAllCertificationTypes({
    facilityType,
  });
  const {
    data: companies,
    isLoading: companiesLoading,
    isError: companiesError,
  } = useGetAllCompanies(
    {},
    {
      select: (data) => data?.result?.items,
    }
  );
  const { mutate: uploadFiles } = useUploadFiles({
    fileableType: "Certification",
    config: {
      onError: (error) => {
        showWarning(
          "Η νέα πιστοποίηση δημιουργήθηκε, αλλά προέκυψε σφάλμα κατά την αποθήκευση των αρχείων. Δοκιμάστε ξανά."
        );
        navigate(-1, { replace: true });
        console.error(error);
      },
      onSuccess: () => {
        showSuccess("Η νέα πιστοποίηση δημιουργήθηκε επιτυχώς!");
        navigate(-1, { replace: true });
      },
    },
  });
  const { mutate: createCertification } = useCreateCertification({
    config: {
      onError: (error) => {
        showError(error.message);
      },
      onSuccess: (res) => {
        showSuccess("Η νέα πιστοποίηση δημιουργήθηκε επιτυχώς!");
        navigate(-1, { replace: true });
      },
    },
  });

  const extractCompanyName = (input) => {
    const regex = /(.*)\s*\([^)]*\)\s*$/;
    const match = input.match(regex);
    return match ? match[1].trim() : input.trim();
  };

  const handleCreateClick = (data) => {
    const formData = {
      ...data,
      certificationTypeId: certificationTypes?.items.find(
        (type) => type.name === data.certificationTypeId
      )?.id,
      externalCompanyId: companies?.find(
        (company) => company.name === extractCompanyName(data.externalCompanyId)
      )?.id,
      assetId: facilityType === "asset" ? selectedAsset?.id : null,
      equipmentId: facilityType === "equipment" ? selectedEquipment?.id : null,
      frequency: data.frequency?.id || null,
    };

    const payload = Object.keys(formData).reduce((acc, key) => {
      if (formData[key] !== undefined && formData[key] !== null) {
        acc[key] = formData[key];
      }
      return acc;
    }, {});

    const files = data.files;

    createCertification(payload, {
      onSuccess: (certification) => {
        if (files?.length > 0) {
          uploadFiles({ files, entityId: certification?.result?.id });
        } else {
          showSuccess("Η νέα πιστοποίηση δημιουργήθηκε επιτυχώς!");
          navigate(-1, { replace: true });
        }
      },
    });
  };

  if (certificationTypesLoading || companiesLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          height: "100%",
        }}
      >
        <SpinningCircle />
      </Box>
    );
  }

  if (certificationTypesError || companiesError) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          height: "100%",
        }}
      >
        <p>
          Error: {certificationTypesError?.message || companiesError?.message}
        </p>
      </Box>
    );
  }

  return (
    <Box display={"flex"} flexDirection={"column"} width={"100%"} gap={2}>
      <Box
        display="flex"
        alignItems={"center"}
        justifyContent="flex-start"
        gap={1}
      >
        <Typography color={colors.primary} fontWeight={700} fontSize={24}>
          Νέα Πιστοποίηση
        </Typography>
        <Typography color={colors.text} fontWeight={500} fontSize={18}>
          Δημιουργία νέας πιστοποίησης
        </Typography>
      </Box>
      <CertificationCreateForm
        onCreate={handleCreateClick}
        facilityType={facilityType}
      />
    </Box>
  );
};

CertificationCreate.propTypes = {
  facilityType: PropTypes.oneOf(["equipment", "asset"]).isRequired,
};

export default CertificationCreate;
