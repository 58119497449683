const ticketRequestFields = [
  {
    label: "Τίτλος",
    name: "name",
    type: "text",
    required: true,
  },
  {
    label: "Περιγραφή",
    name: "description",
    type: "text",
    required: true,
  },
  {
    label: "Ακίνητο",
    name: "asset",
    type: "select",
    required: true,
    options: [],
  },
  {
    label: "Εξοπλισμός",
    name: "equipment",
    type: "select",
    required: false,
    options: [],
  },
  {
    label: "Φωτογραφίες",
    name: "files",
    type: "file",
    description: "Επισύναψη φωτογραφιών από επιτόπιο έλεγχο",
    multiple: true,
    validTypes: ["image/jpeg", "image/png"],
    maxSize: 5000000,
  },
];

export default ticketRequestFields;
