const certificationRequestFields = {
  certification: [
    {
      label: "Τίτλος",
      name: "name",
      type: "text",
      required: true,
    },
    {
      label: "Περιγραφή",
      name: "description",
      type: "textarea",
      required: false,
    },
    {
      label: "Τύπος",
      name: "certificationTypeId",
      type: "select",
      required: true,
    },
    {
      label: "Εταιρεία",
      name: "externalCompanyId",
      type: "select",
      required: true,
    },
    {
      label: "Κωδικός ταυτοποίησης",
      name: "code",
      type: "text",
      required: true,
      validation: "custom",
      regex: /^.{1,10}$/,
      validationMessage:
        "Ο κωδικός ταυτοποίησης πρέπει να είναι μέχρι 10 χαρακτήρες",
    },
    {
      label: "Συχνότητα",
      name: "frequency",
      type: "select",
      required: true,
      options: [
        { id: 365, label: "Ετήσια" },
        { id: 180, label: "Εξαμηνιαία" },
        { id: 90, label: "Τριμηνιαία" },
        { id: 30, label: "Μηνιαία" },
      ],
    },
    {
      label: "Σημειώσεις",
      name: "notes",
      type: "textarea",
      required: false,
    },
    {
      label: "Αρχεία",
      name: "files",
      type: "file",
      multiple: true,
      validTypes: ["image/jpeg", "image/png", "application/pdf"],
      maxSize: 5000000,
    },
  ],
  certificationHistory: [
    {
      label: "Τίτλος",
      name: "name",
      type: "text",
      required: true,
    },
    {
      label: "Περιγραφή",
      name: "description",
      type: "textarea",
      required: false,
    },
    {
      label: "Ποσό (€)",
      name: "amount",
      type: "number",
      required: false,
    },
    {
      label: "Ημερομηνία έκδοσης",
      name: "issueDateTime",
      type: "date",
      required: false,
    },
    {
      label: "Ημερομηνία λήξης",
      name: "expireDateTime",
      type: "date",
      required: false,
    },
    {
      label: "Σημειώσεις",
      name: "notes",
      type: "textarea",
      required: false,
    },
  ],
};

export default certificationRequestFields;
