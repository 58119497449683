import { requests } from "api/HttpClient";

const { get } = requests;

const dashboard = {
  getMaintenanceAndCertificationCountsPerAsset: ({ startDate, endDate }) => {
    let url = "/Dashboard/GetCountOfMaintenancesAndCertificationsPerAsset";

    const queryParams = [];

    if (startDate) {
      queryParams.push(`startDate=${startDate}`);
    }

    if (endDate) {
      queryParams.push(`endDate=${endDate}`);
    }

    if (queryParams.length > 0) {
      url += `?${queryParams.join("&")}`;
    }

    return get(url);
  },

  getTotalMaintenanceCostPerAsset: ({ startDate, endDate }) => {
    let url = "/Dashboard/GetTotalMaintenanceCostPerAsset";

    const queryParams = [];

    if (startDate) {
      queryParams.push(`startDate=${startDate}`);
    }

    if (endDate) {
      queryParams.push(`endDate=${endDate}`);
    }

    if (queryParams.length > 0) {
      url += `?${queryParams.join("&")}`;
    }

    return get(url);
  },

  getTotalMaintenanceCostPerMonth: ({ startDate, endDate }) => {
    let url = "/Dashboard/GetTotalMaintenanceCostPerMonth";

    const queryParams = [];

    if (startDate) {
      queryParams.push(`startDate=${startDate}`);
    }

    if (endDate) {
      queryParams.push(`endDate=${endDate}`);
    }

    if (queryParams.length > 0) {
      url += `?${queryParams.join("&")}`;
    }

    return get(url);
  },

  getRentIncomeExpensePerMonth: ({ startDate, endDate }) => {
    let url = "/Dashboard/GetRentIncomeExpensesPerMonth";

    const queryParams = [];

    if (startDate) {
      queryParams.push(`startDate=${startDate}`);
    }

    if (endDate) {
      queryParams.push(`endDate=${endDate}`);
    }

    if (queryParams.length > 0) {
      url += `?${queryParams.join("&")}`;
    }

    return get(url);
  },

  getGeneralStats: ({ startDate, endDate }) => {
    let url = "/Dashboard/GetGeneralStats";

    const queryParams = [];

    if (startDate) {
      queryParams.push(`startDate=${startDate}`);
    }

    if (endDate) {
      queryParams.push(`endDate=${endDate}`);
    }

    if (queryParams.length > 0) {
      url += `?${queryParams.join("&")}`;
    }

    return get(url);
  },

  getTotalMaintenanceCostPerEquipmentType: () =>
    get("/Dashboard/GetTotalMaintenanceCostPerEquipmentType"),
};

export default dashboard;
