import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CustomButton from "components/buttons/CustomButton";
import SpinningCircle from "components/spinners/SpinningCircle";
import { useNavigate, useParams } from "react-router";
import colors from "config/theme/colors";
import { useGetAllRents } from "hooks/queries/useRents";
import { ReactComponent as AddIcon } from "assets/icons/add.svg";
import RentsViewEdit from "../ViewEdit/RentsViewEdit";

const RentOverview = () => {
  const navigate = useNavigate();
  const { id: assetId } = useParams();
  const {
    data: rents,
    isLoading: isRentsLoading,
    isError: isErrorRents,
    error: errorRents,
  } = useGetAllRents({
    assetId,
  });
  const rentId = rents?.items?.[0]?.id;

  if (isRentsLoading) {
    return <SpinningCircle />;
  }

  if (isErrorRents) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          height: "100%",
        }}
      >
        <p>Error: {errorRents?.message}</p>
      </Box>
    );
  }

  const rentsCount = rents?.totalCount;

  return (
    <>
      {!rentsCount && (
        <Box
          display="flex"
          flexDirection={{
            xs: "column",
            sm: "row",
          }}
          justifyContent={{
            xs: "flex-end",
          }}
          flexGrow={1}
          flexWrap={{
            xs: "wrap",
            md: "nowrap",
          }}
          width={"100%"}
          gap={2}
        >
          <CustomButton
            icon={<AddIcon fill={colors.primary} width={20} height={20} />}
            title="Προσθήκη"
            orientation="row"
            fontSize={13}
            fontWeight={700}
            sx={{
              minHeight: "33px",
              maxHeight: "33px",
              borderRadius: 3,
              paddingY: 2.3,
            }}
            onClick={() => {
              navigate("new");
            }}
          />
        </Box>
      )}

      {!rentsCount ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          marginTop={5}
          sx={{
            height: "100%",
          }}
        >
          <Typography variant="h4">Δεν υπάρχει ενεργή ενοικίαση</Typography>
        </Box>
      ) : (
        <RentsViewEdit assetId={assetId} rentId={rentId} />
      )}
    </>
  );
};

export default RentOverview;
