import { useQuery } from "@tanstack/react-query";
import { Agent } from "api/agent";

const baseQueryConfig = {
  staleTime: Infinity,
  enabled: false,
  retry: false,
  refetchOnReconnect: false,
  refetchOnWindowFocus: false,
  refetchOnMount: false,
};

export const useGetReportAssetMaintenances = (
  { status = "", dateFrom = "", dateTo = "", pageNumber = -1, pageSize = -1 },
  options = {}
) => {
  return useQuery({
    queryKey: [
      "getReportAssetMaintenances",
      status,
      dateFrom,
      dateTo,
      pageNumber,
      pageSize,
    ],
    queryFn: () =>
      Agent.assetReports.getAssetMaintenances({
        status: status,
        dateFrom: dateFrom,
        dateTo: dateTo,
        pageNumber: pageNumber,
        pageSize: pageSize,
      }),
    staleTime: 1000 * 60 * 60,
    ...options,
  });
};

export const useGetReportAssetMaintenanceCostsPerAsset = (
  { dateFrom = "", dateTo = "", pageNumber = -1, pageSize = -1, assetIds = [] },
  options = {}
) => {
  return useQuery({
    queryKey: [
      "getReportAssetMaintenanceCostsPerAsset",
      dateFrom,
      dateTo,
      pageNumber,
      pageSize,
      assetIds?.join(","),
    ],
    queryFn: () =>
      Agent.assetReports.getAssetMaintenanceCostsPerAsset({
        dateFrom: dateFrom,
        dateTo: dateTo,
        pageNumber: pageNumber,
        pageSize: pageSize,
        assetIds: assetIds,
      }),
    staleTime: 1000 * 60 * 60,
    ...options,
  });
};

export const useGetReportAssetMaintenanceCostsPerType = (
  { dateFrom = "", dateTo = "", pageNumber = -1, pageSize = -1 },
  options = {}
) => {
  return useQuery({
    queryKey: [
      "getReportAssetMaintenanceCostsPerType",
      dateFrom,
      dateTo,
      pageNumber,
      pageSize,
    ],
    queryFn: () =>
      Agent.assetReports.getAssetMaintenancesCostsPerType({
        dateFrom: dateFrom,
        dateTo: dateTo,
        pageNumber: pageNumber,
        pageSize: pageSize,
      }),
    staleTime: 1000 * 60 * 60,
    ...options,
  });
};

export const useExportAssetMaintenances = ({
  status = "",
  exportType = "",
  dateFrom = "",
  dateTo = "",
}) => {
  return useQuery({
    queryKey: ["exportAssetMaintenances", exportType, dateFrom, dateTo, status],
    queryFn: () =>
      Agent.assetReports.exportAssetMaintenances({
        status: status,
        exportType: exportType,
        dateFrom: dateFrom,
        dateTo: dateTo,
      }),
    ...baseQueryConfig,
  });
};

export const useExportAssetMaintenanceCostsPerAsset = ({
  exportType = "",
  assetIds = [],
  dateFrom = "",
  dateTo = "",
}) => {
  return useQuery({
    queryKey: [
      "exportAssetMaintenanceCostsPerAsset",
      exportType,
      assetIds?.join(","),
      dateFrom,
      dateTo,
    ],
    queryFn: () =>
      Agent.assetReports.exportAssetMaintenanceCostsPerAsset({
        exportType: exportType,
        assetIds: assetIds,
        dateFrom: dateFrom,
        dateTo: dateTo,
      }),
    ...baseQueryConfig,
  });
};

export const useExportAssetMaintenanceCostsPerType = ({
  exportType = "",
  dateFrom = "",
  dateTo = "",
}) => {
  return useQuery({
    queryKey: [
      "exportAssetMaintenanceCostsPerType",
      exportType,
      dateFrom,
      dateTo,
    ],
    queryFn: () =>
      Agent.assetReports.exportAssetMaintenancesCostsPerType({
        exportType: exportType,
        dateFrom: dateFrom,
        dateTo: dateTo,
      }),
    ...baseQueryConfig,
  });
};
