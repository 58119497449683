import PropTypes from "prop-types";
import { Box, Modal, Tooltip } from "@mui/material";
import colors from "config/theme/colors";
import EquipmentMaintenancesMassImportStepper from "./EquipmentMaintenancesMassImportStepper";
import { ReactComponent as CloseIcon } from "assets/icons/close.svg";

const EquipmentMaintenancesMassImportStepperModal = ({
  isOpen = false,
  handleClose = () => {},
  steps = [],
  setSelectedEquipments,
  setSelectedEquipmentType,
  setSelectedCompany,
  setSelectedContract,
}) => {
  const handleCancel = () => {
    setSelectedEquipments([]);
    setSelectedEquipmentType(null);
    setSelectedCompany(null);
    setSelectedContract(null);
    handleClose();
  };

  const onClose = (event, reason) => {
    if (reason && reason === "backdropClick") {
      return;
    }
    setSelectedEquipments([]);
    setSelectedEquipmentType(null);
    setSelectedCompany(null);
    setSelectedContract(null);
    handleClose();
  };

  return (
    <Modal
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        mx: 2,
      }}
      open={isOpen}
      onClose={onClose}
      disableEscapeKeyDown
    >
      <Box
        sx={{
          backgroundColor: colors.white,
          borderRadius: "4px",
          minWidth: 600,
          maxHeight: "90vh",
          minHeight: 100,
          padding: 2,
        }}
        boxShadow={"0px 4px 4px #2948981A"}
        gap={2}
      >
        <Box display="flex" justifyContent="end" borderRadius={"4px 4px 0 0"}>
          <Tooltip onClick={handleCancel} style={{ cursor: "pointer" }}>
            <CloseIcon stroke={colors.primary} />
          </Tooltip>
        </Box>

        <Box
          sx={{
            mt: 4,
          }}
        >
          <EquipmentMaintenancesMassImportStepper
            steps={steps}
            setSelectedEquipments={setSelectedEquipments}
            setSelectedEquipmentType={setSelectedEquipmentType}
            setSelectedCompany={setSelectedCompany}
            setSelectedContract={setSelectedContract}
          />
        </Box>
      </Box>
    </Modal>
  );
};

EquipmentMaintenancesMassImportStepperModal.propTypes = {
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func,
  steps: PropTypes.array,
  disableSubmitButton: PropTypes.bool,
  disableNextButton: PropTypes.bool,
  setSelectedEquipments: PropTypes.func,
  setSelectedEquipmentType: PropTypes.func,
  setSelectedCompany: PropTypes.func,
  setSelectedContract: PropTypes.func,
};

export default EquipmentMaintenancesMassImportStepperModal;
