const loginRequestFields = [
  {
    label: "Email",
    name: "email",
    type: "email",
    required: true,
    validation: "email",
    placeholder: "Πληκτρολογήστε το email σας",
  },
  {
    label: "Κωδικός πρόσβασης",
    name: "password",
    type: "text",
    placeholder: "Πληκτρολογήστε τον κωδικό σας",
    required: true,
    sensitive: true,
    validation: "custom",
    regex: /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*\W).{8,}$/,
  },
];

export default loginRequestFields;
