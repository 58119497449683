import { requests } from "api/HttpClient";

const { post, get, put, delete: deleteRequest } = requests;

const config = {
  headers: {
    "Content-Type": "multipart/form-data",
  },
};

const companies = {
  getAll: ({
    pageNumber = -1,
    pageSize = -1,
    searchParameter,
    serviceType,
    orderBy,
    sortingOrder,
  }) => {
    let url = "/ExternalCompany/GetCompanyList";

    const queryParams = [];

    if (pageNumber && pageSize) {
      queryParams.push(`pageNumber=${pageNumber}`);
      queryParams.push(`pageSize=${pageSize}`);
    }

    if (searchParameter) {
      queryParams.push(`searchParameter=${searchParameter}`);
    }

    if (serviceType) {
      serviceType.forEach((type) => {
        queryParams.push(`ServiceType=${encodeURIComponent(type)}`);
      });
    }

    if (searchParameter) {
      queryParams.push(`searchParameter=${searchParameter}`);
    }

    if (orderBy) {
      queryParams.push(`orderBy=${orderBy}`);
    }

    if (sortingOrder) {
      queryParams.push(`sortingOrder=${sortingOrder}`);
    }

    if (queryParams.length > 0) {
      url += `?${queryParams.join("&")}`;
    }

    return get(url);
  },
  getById: (id) => get(`/ExternalCompany/GetExternalCompanyById/${id}`),
  getExternalCompanyTypeCount: () =>
    get("ExternalCompany/GetExternalCompanyTypeCount"),
  getCompanyContractListById: ({ pageNumber = -1, pageSize = -1, id }) => {
    let url = `/ExternalCompany/GetCompanyContractListByID/${id}`;
    if (pageNumber && pageSize) {
      url += `?pageNumber=${pageNumber}&pageSize=${pageSize}`;
    }
    return get(url);
  },
  create: (data) => post("/ExternalCompany/AddCompany", data, config),
  update: (id, data) =>
    put(`/ExternalCompany/UpdateCompany/${id}`, data, config),
  delete: (id) => deleteRequest(`/ExternalCompany/DeleteCompany/${id}`),
};

export default companies;
