import { requests } from "api/HttpClient";

const { post, get, put, delete: deleteRequest } = requests;

const equipmentClass = {
  getAll: ({ pageNumber = -1, pageSize = -1, equipmentGroupId = null }) => {
    let url = "/EquipmentClass/GetEquipmentClassList";
    const queryParams = [];

    if (pageNumber && pageSize) {
      queryParams.push(`pageNumber=${pageNumber}`);
      queryParams.push(`pageSize=${pageSize}`);
    }
    if (equipmentGroupId) {
      queryParams.push(`equipmentGroupId=${equipmentGroupId}`);
    }

    if (queryParams.length > 0) {
      url += `?${queryParams.join("&")}`;
    }

    return get(url);
  },
  getById: (id) => get(`/EquipmentClass/GetEquipmentClassById/${id}`),
  create: (body) =>
    post("/EquipmentClass/AddEquipmentClass", body, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }),
  update: (id, body) =>
    put(`/EquipmentClass/UpdateEquipmentClass/${id}`, body, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }),
  delete: (id) => deleteRequest(`/EquipmentClass/DeleteEquipmentClass/${id}`),
};

export default equipmentClass;
