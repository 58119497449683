import { Box, Button, CircularProgress, Typography } from "@mui/material";
import colors from "config/theme/colors";
import PropTypes from "prop-types";

const SaveButton = ({
  onClick,
  text = "Αποθήκευση",
  disabled = false,
  isLoading = false,
  width,
  type,
}) => {
  return (
    <Button
      onClick={onClick}
      disabled={disabled || isLoading}
      type={type || "submit"}
      sx={{
        backgroundColor: colors.primary,
        color: colors.white,
        border: `1px solid ${colors.primary}`,
        padding: "12px 22px",
        "&:hover": {
          backgroundColor: colors.white,
          color: colors.primary,
        },
        "&:disabled": {
          opacity: 0.5,
          backgroundColor: colors.primary,
          color: colors.white,
        },
        cursor: "pointer",
        width: width,
      }}
    >
      {isLoading ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100px",
          }}
        >
          <CircularProgress
            size={20}
            sx={{
              color: colors.white,
            }}
          />
        </Box>
      ) : (
        <Typography fontSize={12} fontWeight={700}>
          {text}
        </Typography>
      )}
    </Button>
  );
};

SaveButton.propTypes = {
  onClick: PropTypes.func,
  text: PropTypes.string,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  width: PropTypes.string,
  type: PropTypes.string,
};

export default SaveButton;
