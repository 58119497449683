import GenericMap from "components/maps/GenericMap";
import PropTypes from "prop-types";

const AssetsMap = ({
  mapMarkers,
  selectedMarker,
  setSelectedMarker,
  infoWindowRenderer,
  enableClustering,
  center,
  initialZoom,
  className,
}) => {
  return (
    <GenericMap
      center={center}
      markers={mapMarkers}
      infoWindowRenderer={infoWindowRenderer}
      className={className}
      initialZoom={initialZoom}
      selectedMarker={selectedMarker}
      setSelectedMarker={setSelectedMarker}
      enableClustering={enableClustering}
    />
  );
};

AssetsMap.propTypes = {
  mapMarkers: PropTypes.array,
  selectedMarker: PropTypes.object,
  setSelectedMarker: PropTypes.func,
  infoWindowRenderer: PropTypes.func,
  enableClustering: PropTypes.bool,
  center: PropTypes.object,
  initialZoom: PropTypes.number,
  className: PropTypes.string,
};

export default AssetsMap;
