import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Agent } from "api/agent";

export const useGetAllRents = (
  { pageNumber = -1, pageSize = -1, assetId = null } = {
    pageNumber: -1,
    pageSize: -1,
    assetId: null,
  },
  options = {}
) => {
  return useQuery({
    queryKey: ["getAllRents", pageNumber, pageSize, assetId],
    queryFn: () => Agent.rents.getAll({ pageNumber, pageSize, assetId }),
    staleTime: 1000 * 60 * 60,
    ...options,
  });
};

export const useGetRentById = (rentId, options = {}) => {
  return useQuery({
    queryKey: ["getRentById", +rentId],
    queryFn: () => Agent.rents.getById(rentId),
    staleTime: 1000 * 60 * 10,
    ...options,
  });
};

export const useCreateRent = ({ config = {} }) => {
  const queryClient = useQueryClient();
  const { onSuccess, ...restConfig } = config;

  return useMutation({
    mutationKey: ["createRent"],
    mutationFn: (body) => Agent.rents.create(body),
    onSuccess: (...args) => {
      queryClient.invalidateQueries({ queryKey: ["getAllRents"] });
      queryClient.invalidateQueries({
        queryKey: ["getAllTasks"],
      });
      queryClient.invalidateQueries({
        queryKey: ["getTaskById"],
      });
      onSuccess?.(...args);
    },
    ...restConfig,
  });
};

export const useUpdateRent = ({ id, config = {} }) => {
  const queryClient = useQueryClient();
  const { onSuccess, ...restConfig } = config;

  return useMutation({
    mutationKey: ["updateRent"],
    mutationFn: (body) => Agent.rents.update(id, body),
    onSuccess: (...args) => {
      queryClient.invalidateQueries({ queryKey: ["getAllRents"] });
      queryClient.invalidateQueries({ queryKey: ["getRentById", +id] });
      onSuccess?.(...args);
    },
    ...restConfig,
  });
};

export const useDeleteRent = ({ id, config = {} }) => {
  const queryClient = useQueryClient();
  const { onSuccess, ...restConfig } = config;

  return useMutation({
    mutationKey: ["deleteRent"],
    mutationFn: () => Agent.rents.delete(id),
    onSuccess: (...args) => {
      queryClient.invalidateQueries({ queryKey: ["getAllRents"] });
      queryClient.invalidateQueries({ queryKey: ["getRentById", +id] });
      onSuccess?.(...args);
    },
    ...restConfig,
  });
};
