import { Box, Tooltip, Typography } from "@mui/material";
import CustomTable from "components/tables/CustomTable";
import { useMemo, useState } from "react";
import EquipmentClassForm from "./EquipmentClassForm";
import colors from "config/theme/colors";
import {
  useCreateEquipmentClass,
  useDeleteEquipmentClass,
  useGetAllEquipmentClasses,
  useGetEquipmentClassById,
  useUpdateEquipmentClass,
} from "hooks/queries/useEquipmentClass";
import SpinningCircle from "components/spinners/SpinningCircle";
import { useAlert } from "hooks/useAlert";
import { useGetAllEquipmentGroups } from "hooks/queries/useEquipmentGroup";
import EditCircleButton from "components/buttons/EditCircleButton";
import usePagination from "hooks/usePagination";

const EquipmentClassesOverview = () => {
  const { showError, showSuccess } = useAlert();
  const { page, rowsPerPage, handleChangePage, handleChangeRowsPerPage } =
    usePagination();
  const [selectedEquipmentClassId, setSelectedEquipmentClassId] =
    useState(null);
  const [isEditing, setIsEditing] = useState(false);

  const { data: selectedEquipmentClass } = useGetEquipmentClassById(
    selectedEquipmentClassId,
    { enabled: selectedEquipmentClassId !== null }
  );

  const {
    data: equipmentGroupOptions,
    isLoading: equipmentGroupsLoading,
    error: equipmentGroupsError,
  } = useGetAllEquipmentGroups(
    {
      pageNumber: -1,
      pageSize: -1,
    },
    {
      select: (data) => data?.result?.items,
    }
  );

  const {
    data: equipmentClasses,
    isLoading,
    error,
  } = useGetAllEquipmentClasses(
    {
      pageNumber: page,
      pageSize: rowsPerPage,
    },
    { select: (data) => data?.result }
  );

  const { mutate: createEquipmentClass } = useCreateEquipmentClass({
    config: {
      onError: (error) => {
        showError(error.message);
      },
      onSuccess: (res) => {
        setSelectedEquipmentClassId(res.result.id);
        showSuccess("Η κλάση εξοπλισμού δημιουργήθηκε με επιτυχία");
      },
    },
  });
  const { mutate: updateEquipmentClass } = useUpdateEquipmentClass({
    id: selectedEquipmentClassId,
    config: {
      onError: (error) => {
        showError(error.message);
      },
      onSuccess: () => {
        setIsEditing(false);
        showSuccess("Η κλάση εξοπλισμού ενημερώθηκε με επιτυχία");
      },
    },
  });
  const { mutate: deleteEquipmentClass } = useDeleteEquipmentClass({
    id: selectedEquipmentClassId,
    config: {
      onError: (error) => {
        if (
          error.message?.includes(
            "Δεν μπορείτε να διαγράψετε αυτό το αντικείμενο!"
          )
        ) {
          showError(
            "Δεν μπορείτε να διαγράψετε αυτή την κλάση εξοπλισμού, καθώς χρησιμοποιείται σε κάποιον εξοπλισμό",
            6000
          );
          return;
        }
        showError(error.message);
      },
      onSuccess: () => {
        setSelectedEquipmentClassId(null);
        setIsEditing(false);
        showSuccess("Η κλάση εξοπλισμού διαγράφηκε με επιτυχία");
      },
    },
  });

  const tableHeaders = useMemo(
    () => [
      {
        id: "name",
        label: "Όνομα",
        align: "left",
        minWidth: 200,
      },
      {
        id: "equipmentGroup",
        label: "Ομάδα Εξοπλισμού",
        align: "left",
        minWidth: 200,
      },
      {
        id: "button",
        label: "",
        align: "right",
      },
    ],
    []
  );

  if (isLoading || equipmentGroupsLoading) {
    return <SpinningCircle />;
  }

  if (error || equipmentGroupsError) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          height: "100%",
        }}
      >
        <p>Error: {error.message}</p>
      </Box>
    );
  }

  const equipmentClassList = equipmentClasses?.items.map((item) => ({
    ...item,
    equipmentGroup:
      equipmentGroupOptions.find((group) => group.id === item.equipmentGroupId)
        .name || "",
    button: (
      <Box display="flex" justifyContent="flex-end">
        <Tooltip title="Επεξεργασία" placement="left">
          <span>
            <EditCircleButton
              onClick={(e) => {
                setIsEditing(true);
                setSelectedEquipmentClassId(item.id);
                e.stopPropagation();
              }}
            />
          </span>
        </Tooltip>
      </Box>
    ),
  }));

  const count = equipmentClasses?.totalCount;

  const handleItemClick = (item) => {
    setIsEditing(false);
    if (item && item.id === selectedEquipmentClassId) {
      setSelectedEquipmentClassId(null);
      return;
    }
    setSelectedEquipmentClassId(item.id);
  };

  const handleCreateClick = (data) => {
    const payload = {
      name: data.name,
      description: data.description,
      equipmentGroupId: data.equipmentGroupId?.id,
    };

    createEquipmentClass(payload);
  };

  const handleUpdateClick = (data) => {
    const payload = {
      name: data.name,
      description: data.description,
      equipmentGroupId: data.equipmentGroupId?.id,
    };

    updateEquipmentClass(payload);
  };

  const handleDeleteClick = () => {
    deleteEquipmentClass();
  };

  return (
    <Box
      display="flex"
      gap={2}
      justifyContent="space-between"
      width={"100%"}
      flexDirection={{
        xs: "column",
        sm: "row",
      }}
    >
      <Box
        mt={1}
        display="flex"
        flexDirection="column"
        justifyContent="flex-start"
        width={{
          xs: "100%",
          sm: "60%",
        }}
      >
        <Typography variant="h3" color={colors.primary}>
          {count > 0
            ? `Κλάσεις Εξοπλισμού (${count})`
            : "Δεν υπάρχουν κλάσεις εξοπλισμού"}
        </Typography>

        {count > 0 && (
          <CustomTable
            columns={tableHeaders}
            data={equipmentClassList}
            fadeEffect
            onItemClick={handleItemClick}
            page={page - 1}
            rowsPerPage={rowsPerPage}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            totalResults={count}
          />
        )}
      </Box>

      <Box
        borderLeft={{
          xs: "none",
          sm: `2px solid ${colors.lightGrey}`,
        }}
        borderTop={{
          xs: `2px solid ${colors.lightGrey}`,
          sm: "none",
        }}
        sx={{
          borderRadius: 6,
          opacity: "0.2",
        }}
      />

      <Box
        width={{
          xs: "100%",
          sm: "38%",
        }}
      >
        {selectedEquipmentClassId === null ? (
          <EquipmentClassForm
            onSave={handleCreateClick}
            onCancel={() => setSelectedEquipmentClassId(null)}
            isCreateForm
          />
        ) : (
          <EquipmentClassForm
            selectedEquipmentClass={selectedEquipmentClass}
            onSave={handleUpdateClick}
            onCancel={() => setSelectedEquipmentClassId(null)}
            onDelete={handleDeleteClick}
            isEditing={isEditing}
          />
        )}
      </Box>
    </Box>
  );
};

export default EquipmentClassesOverview;
