import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Agent } from "api/agent";

export const useGetAllRentsHistory = (
  { pageNumber = -1, pageSize = -1, rentId = null } = {
    pageNumber: -1,
    pageSize: -1,
    rentId: null,
  },
  options = {}
) => {
  return useQuery({
    queryKey: ["getAllRentsHistory", pageNumber, pageSize, rentId],
    queryFn: () => Agent.rentHistory.getAll({ pageNumber, pageSize, rentId }),
    staleTime: 1000 * 60 * 60,
    ...options,
  });
};

export const useGetRentHistoryById = (rentId, options = {}) => {
  return useQuery({
    queryKey: ["getRentHistoryById", +rentId],
    queryFn: () => Agent.rentHistory.getById(rentId),
    staleTime: 1000 * 60 * 10,
    ...options,
  });
};

export const useCreateRentHistory = ({ config = {} }) => {
  const queryClient = useQueryClient();
  const { onSuccess, ...restConfig } = config;

  return useMutation({
    mutationKey: ["createRentHistory"],
    mutationFn: (body) => Agent.rentHistory.create(body),
    onSuccess: (...args) => {
      queryClient.invalidateQueries({ queryKey: ["getAllRentsHistory"] });
      onSuccess?.(...args);
    },
    ...restConfig,
  });
};

export const useUpdateRentHistory = ({ id, config = {} }) => {
  const queryClient = useQueryClient();
  const { onSuccess, ...restConfig } = config;

  return useMutation({
    mutationKey: ["updateRentHistory"],
    mutationFn: (body) => Agent.rentHistory.update(id, body),
    onSuccess: (...args) => {
      queryClient.invalidateQueries({ queryKey: ["getAllRentsHistory"] });
      queryClient.invalidateQueries({ queryKey: ["getRentHistoryById", +id] });
      onSuccess?.(...args);
    },
    ...restConfig,
  });
};

export const useDeleteRentHistory = ({ id, config = {} }) => {
  const queryClient = useQueryClient();
  const { onSuccess, ...restConfig } = config;

  return useMutation({
    mutationKey: ["deleteRentHistory"],
    mutationFn: () => Agent.rentHistory.delete(id),
    onSuccess: (...args) => {
      queryClient.invalidateQueries({ queryKey: ["getAllRentsHistory"] });
      queryClient.invalidateQueries({ queryKey: ["getRentHistoryById", +id] });
      onSuccess?.(...args);
    },
    ...restConfig,
  });
};
