import { requests } from "api/HttpClient";

const { post, get, put, delete: deleteRequest } = requests;

const config = {
  headers: {
    "Content-Type": "multipart/form-data",
  },
};

const certificationHistory = {
  getAll: ({ pageNumber = -1, pageSize = -1, certificationId }) => {
    let url = "/CertificationHistory/GetCertificationHistoryList";
    if (pageNumber && pageSize) {
      url += `?pageNumber=${pageNumber}&pageSize=${pageSize}`;
    }
    if (certificationId) {
      url += `&certificationId=${certificationId}`;
    }

    return get(url);
  },
  getById: (id) =>
    get(`/CertificationHistory/GetCertificationHistoryById/${id}`),
  create: (data) =>
    post("/CertificationHistory/CreateCertificationHistory", data, config),
  update: (id, data) =>
    put(`/CertificationHistory/UpdateCertificationHistory/${id}`, data, config),
  delete: (id) =>
    deleteRequest(`/CertificationHistory/DeleteCertificationHistory/${id}`),
};

export default certificationHistory;
