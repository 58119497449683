import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Grid, Typography } from "@mui/material";
import { useIsMutating } from "@tanstack/react-query";
import CancelButton from "components/buttons/CancelButton";
import SaveButton from "components/buttons/SaveButton";
import CustomTextField from "components/inputs/CustomTextField";
import { phoneRegex } from "config/regex";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import PropTypes from "prop-types";
import { useEffect, useMemo } from "react";

const ProfileViewEditForm = ({ userProfile, isEditing, onSave, onCancel }) => {
  const isUpdateLoading =
    useIsMutating({ mutationKey: ["updateUserProfile"] }) > 0;

  const profileSchema = yup.object().shape({
    phoneNumber: yup
      .string()
      .matches(
        phoneRegex,
        "Ο αριθμός τηλεφώνου που καταχωρήσατε δεν είναι έγκυρος"
      )
      .required("Το πεδίο είναι υποχρεωτικό"),
  });

  const handleFormSubmit = (data) => {
    onSave(data);
  };

  const defaultValues = useMemo(() => {
    return {
      name: userProfile?.name,
      surname: userProfile?.surname,
      userName: userProfile?.userName,
      email: userProfile?.email,
      role: userProfile?.role,
      phoneNumber: userProfile?.phoneNumber,
    };
  }, [userProfile]);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isDirty },
    getValues,
  } = useForm({
    resolver: yupResolver(profileSchema),
    defaultValues: defaultValues,
  });

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  return (
    <form onSubmit={handleSubmit(() => handleFormSubmit(getValues()))}>
      <Box display="flex" flexDirection="column" gap={4}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <CustomTextField
              label="Όνομα"
              name="name"
              type="text"
              control={control}
              disabled={true}
              editable={false}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <CustomTextField
              label="Επώνυμο"
              name="surname"
              type="text"
              control={control}
              disabled={true}
              editable={false}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <CustomTextField
              label="Όνομα χρήστη"
              name="userName"
              type="text"
              control={control}
              disabled={true}
              editable={false}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <CustomTextField
              label="Email"
              name="email"
              type="text"
              control={control}
              disabled={true}
              editable={false}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <CustomTextField
              label="Ρόλος"
              name="role"
              type="text"
              control={control}
              disabled={true}
              editable={false}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <CustomTextField
              label="Τηλέφωνο"
              name="phoneNumber"
              type="text"
              control={control}
              disabled={!isEditing}
              required
            />
          </Grid>
        </Grid>

        {Object.keys(errors).length > 0 && (
          <Typography color="error" fontSize={14}>
            Παρακαλώ ελέγξτε ότι όλα τα πεδία έχουν συμπληρωθεί σωστά
          </Typography>
        )}

        <Box display="flex" alignItems="center" gap={2}>
          <CancelButton
            disabled={!isEditing}
            onClick={() => {
              reset();
              onCancel();
            }}
          />

          <SaveButton
            disabled={!isEditing || !isDirty}
            isLoading={isUpdateLoading}
          />
        </Box>
      </Box>
    </form>
  );
};

ProfileViewEditForm.propTypes = {
  userProfile: PropTypes.object.isRequired,
  isEditing: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default ProfileViewEditForm;
