import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CustomButton from "components/buttons/CustomButton";
import SpinningCircle from "components/spinners/SpinningCircle";
import CustomTable from "components/tables/CustomTable";
import { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router";
import colors from "config/theme/colors";
import {
  useGetAllTickets,
  useGetTicketStatusCount,
} from "hooks/queries/useTickets";
import { ReactComponent as AddIcon } from "assets/icons/add.svg";
import useSearchInput from "hooks/useSearchInput";
import enums from "config/enums";
import dayjs from "dayjs";
import MainPageLayout from "components/main/MainPageLayout";
import TagLabel from "components/labels/TagLabel";
import SearchBar from "components/inputs/SearchBar";
import CountCard from "components/cards/CountCard";
import { ReactComponent as TechnicalIcon } from "assets/icons/technical.svg";
import { useAuthStore } from "stores/AuthStore";
import usePagination from "hooks/usePagination";
import useSorting from "hooks/useSorting";
import FilterButton from "components/buttons/FilterButton";
import { ReactComponent as TicketIcon } from "assets/icons/ticket.svg";
import StatusLabel from "components/labels/StatusLabel";

const colorPalette = ["#D81111D6", "#138F36", "#444444"];

const TicketsOverview = () => {
  const navigate = useNavigate();
  const authStore = useAuthStore();
  const {
    page,
    setPage,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
  } = usePagination();
  const { orderDirection, valueToOrderBy, handleSortChange } = useSorting();
  const [selectedFilters, setSelectedFilters] = useState({});
  const [selectedTicketStatuses, setSelectedTicketStatuses] = useState([]);
  const { searchInput, handleSearch } = useSearchInput();
  const {
    data: tickets,
    isLoading: isTicketsLoading,
    isError: isErrorTickets,
    error: errorTickets,
    refetch: refetchTickets,
  } = useGetAllTickets(
    {
      pageNumber: page,
      pageSize: rowsPerPage,
      searchParameter: searchInput,
      status: selectedTicketStatuses,
      orderBy: valueToOrderBy,
      sortingOrder: orderDirection === "asc" ? "Ascending" : "Descending",
    },
    {
      select: (data) => data.result,
    }
  );
  const {
    data: ticketStatusCount,
    isLoading: isTicketStatusCountLoading,
    isError: isErrorTicketStatusCount,
    error: errorTicketStatusCount,
  } = useGetTicketStatusCount();

  const isCreateAvailable = authStore.isSuperAdmin || authStore.isUser;

  const filterGroups = [
    {
      title: "Κατάσταση",
      filters: Object.keys(enums.TicketStatus).map((statusKey) => ({
        id: statusKey,
        label: enums.TicketStatus[statusKey],
        type: "checkbox",
      })),
    },
  ];

  const handleSubmitFilters = useCallback(
    (filters) => {
      const ticketStatuses = filters["Κατάσταση"] || [];
      setSelectedFilters(filters);
      setSelectedTicketStatuses(ticketStatuses);
      if (filters) {
        setPage(1);
      }
      if (ticketStatuses.length === 0) {
        refetchTickets();
      }
    },
    [setSelectedFilters, setSelectedTicketStatuses, refetchTickets, setPage]
  );

  const tableHeaders = useMemo(
    () => [
      {
        id: "icon",
        label: "",
        isSortable: false,
      },
      {
        id: "name",
        label: "Τίτλος",
        isSortable: true,
      },
      {
        id: "assetName",
        label: "Ακίνητο",
        isSortable: true,
      },
      {
        id: "equipmentName",
        label: "Εξοπλισμός",
        isSortable: true,
      },
      {
        id: "status",
        label: "Κατάσταση",
        isSortable: false,
      },
      {
        id: "createdAt",
        label: "Ημ/νία Δημιουργίας",
        isSortable: true,
      },
      {
        id: "completedAt",
        label: "Ημ/νία Ολοκλήρωσης",
        isSortable: true,
      },
    ],
    []
  );

  if (isTicketsLoading || isTicketStatusCountLoading) {
    return <SpinningCircle />;
  }

  if (isErrorTickets || isErrorTicketStatusCount) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          height: "100%",
        }}
      >
        <p>Error: {errorTickets?.message || errorTicketStatusCount?.message}</p>
      </Box>
    );
  }

  const ticketsCount = tickets?.totalCount;
  const ticketsList = tickets?.items?.map((ticket) => ({
    ...ticket,
    icon: <TicketIcon width={30} height={30} />,
    assetName: ticket.assetName || "-",
    equipmentName: ticket.equipmentName || "-",
    status: (
      <StatusLabel
        statusValue={ticket.status}
        statusText={enums.TicketStatus[ticket.status]}
        isInactive={ticket.status === "Rejected"}
        isExpired={ticket.status === "Pending"}
      />
    ),
    createdAt: ticket.entityCreatedAt
      ? dayjs(ticket.entityCreatedAt).format("DD/MM/YYYY")
      : "-",
    completedAt: ticket.completedAt
      ? dayjs(ticket.completedAt).format("DD/MM/YYYY")
      : "-",
  }));

  const handleItemClick = (item) => {
    navigate(`${item.id}`);
  };

  return (
    <MainPageLayout pageTitle="Αιτήματα">
      <Box
        display="flex"
        flexDirection={{
          xs: "column",
          sm: "row",
        }}
        justifyContent={{
          xs: "space-between",
        }}
        flexGrow={1}
        flexWrap={{
          xs: "wrap",
          md: "nowrap",
        }}
        width={"100%"}
        gap={2}
      >
        <Box
          display="flex"
          gap={2}
          flexWrap={{
            xs: "wrap",
            sm: "nowrap",
          }}
          sx={{
            overflowX: "auto",
          }}
        >
          {Object.keys(enums.TicketStatus).map((statusKey) => {
            const statusData = ticketStatusCount?.find(
              (status) => status.name === statusKey
            );
            const count = statusData?.count || 0;
            return (
              <CountCard
                key={statusKey}
                title={enums.TicketStatus[statusKey]}
                backgroundColor={
                  colorPalette[
                    Object.keys(enums.TicketStatus).indexOf(statusKey)
                  ]
                }
                icon={
                  <TechnicalIcon width={30} height={30} fill={colors.white} />
                }
                count={count}
                boxSx={{
                  minWidth: { xs: "100%", sm: "250px" },
                }}
              />
            );
          })}
        </Box>
        {isCreateAvailable && (
          <CustomButton
            icon={<AddIcon fill={colors.primary} width={30} height={30} />}
            title="Προσθήκη"
            sx={{
              minWidth: 120,
            }}
            onClick={() => {
              navigate("/tickets/new");
            }}
          />
        )}
      </Box>
      <Box
        mt={4}
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent={{
          xs: "space-between",
          lg: "flex-start",
        }}
        flexGrow={1}
        gap={2}
        height={40}
      >
        <SearchBar
          placeholder="Αναζήτηση σε Τίτλο, Ακίνητο, Εξοπλισμό..."
          handleSearch={handleSearch}
          searchKey={searchInput}
        />
      </Box>
      <Box
        mt={4}
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        flexGrow={1}
        width={"100%"}
      >
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="start"
          gap={4}
          flexWrap={"wrap"}
          alignItems={"center"}
        >
          <Typography variant="h3" color="primary">
            Αιτήματα ({ticketsCount})
          </Typography>
          <Box display="flex" flexDirection="row" gap={1} flexWrap={"wrap"}>
            {Object.keys(enums.TicketStatus).map((keyStatus) => {
              const count = ticketsList.filter(
                (ticket) => ticket.status === enums.TicketStatus[keyStatus]
              ).length;

              return count > 0 ? (
                <TagLabel
                  key={keyStatus}
                  text={`${count} ${enums.TicketStatus[keyStatus]}`}
                  color={
                    colorPalette[
                      Object.keys(enums.TicketStatus).indexOf(keyStatus)
                    ]
                  }
                />
              ) : null;
            })}
          </Box>
          <Box display="flex" flexGrow={1} justifyContent="flex-end">
            <FilterButton
              filterGroups={filterGroups}
              onApplyFilters={handleSubmitFilters}
              selectedFilters={selectedFilters}
            />
          </Box>
        </Box>

        {ticketsCount > 0 ? (
          <CustomTable
            columns={tableHeaders}
            data={ticketsList}
            totalResults={ticketsCount}
            page={page - 1}
            rowsPerPage={rowsPerPage}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            onItemClick={handleItemClick}
            fadeEffect
            orderDirection={orderDirection}
            valueToOrderBy={valueToOrderBy}
            onSortChange={handleSortChange}
          />
        ) : (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{
              height: "100%",
            }}
          >
            <Typography variant="h4">Δεν υπάρχουν αποτελέσματα</Typography>
          </Box>
        )}
      </Box>
    </MainPageLayout>
  );
};

export default TicketsOverview;
