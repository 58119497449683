import axios from "axios";
import { baseUrl } from "config/constants";
import { authStore } from "stores/AuthStore";

axios.defaults.baseURL = baseUrl;

export const initBearerToken = () => {
  axios.interceptors.request.use(
    (config) => {
      const token =
        JSON.parse(localStorage.getItem("token"))?.token ||
        JSON.parse(sessionStorage.getItem("token"))?.token;
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      const errorToThrow = new Error(error.message);
      return Promise.reject(errorToThrow);
    }
  );
};

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    const { response } = error;
    let message = "";
    let internal_error_messages = [];

    if (response) {
      internal_error_messages = response.data || [];
      switch (response.status) {
        case 400:
          message =
            "Το αίτημά σας δεν είναι έγκυρο! Παρακαλώ ελέγξτε τα δεδομένα σας!";
          break;
        case 401:
          message = "Δεν είστε συνδεδεμένος! Παρακαλώ συνδεθείτε!";
          authStore.logout();
          window.location.replace("/");
          break;
        case 403:
          message = "Δεν έχετε πρόσβαση σε αυτή την ενέργεια!";
          break;
        case 404:
          message = "Δεν βρέθηκε η σελίδα που ζητήσατε!";
          break;
        case 500:
          message = "Σφάλμα στον server!";
          break;
        default:
          message = "Υπήρξε ένα σφάλμα!";
          break;
      }

      if (
        response.data[0]?.includes(
          "Cannot delete because of existing relation"
        ) ||
        response.data[0]?.includes(
          "Error Could not delete entry because of related items"
        ) ||
        response.data[0]?.includes(
          "Error An error occurred while saving the entity changes. See the inner exception for details."
        )
      ) {
        message = "Δεν μπορείτε να διαγράψετε αυτό το αντικείμενο!";
      } else if (
        response.data[0]?.includes("The current password is not valid")
      ) {
        message = "Ο τρέχων κωδικός δεν είναι σωστός!";
      } else if (response.data[0]?.includes("KAEK must be unique")) {
        message = "Ο αριθμός ΚΑΕΚ υπάρχει ήδη! Παρακαλώ εισάγετε διαφορετικό!";
      } else if (
        response.data[0]?.includes("Active maintenance already exists")
      ) {
        message =
          "Υπάρχει ήδη ενεργή συντήρηση! Επιτρέπεται μόνο μία ενεργή συντήρηση ανά ακίνητο ή εξοπλισμό.";
      } else if (response.data[0]?.includes("User not found.")) {
        message = "Ο χρήστης δεν υπάρχει!";
      } else if (response.data[0]?.includes("Invalid token.")) {
        message = "Μη έγκυρο αίτημα!";
      }
    } else if (error.code === "ERR_NETWORK") {
      message = "Σφάλμα στην σύνδεση με τον server!";
    }
    const errorToThrow = new Error(message);
    errorToThrow.status = response?.status;
    errorToThrow.data = response?.data;

    console.error("Internal error messages:", internal_error_messages);

    return Promise.reject(errorToThrow);
  }
);

const responseBody = (response) => response?.data;

export const requests = {
  get: (url) => axios.get(url).then(responseBody),
  getFile: (url, headers) =>
    axios.get(url, { responseType: "blob", ...headers }).then(responseBody),
  post: (url, body, config = {}) =>
    axios.post(url, body, config).then(responseBody),
  patch: (url, body) => axios.patch(url, body).then(responseBody),
  put: (url, body, config) => axios.put(url, body, config).then(responseBody),
  delete: (url, body) => axios.delete(url, body).then(responseBody),
};
