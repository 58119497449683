import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Agent } from "api/agent";

export const useGetAllCertifications = (
  {
    pageNumber = -1,
    pageSize = -1,
    assetId,
    equipmentId,
    facilityType,
    certificationTypeIds = [],
  } = {
    pageNumber: -1,
    pageSize: -1,
    assetId: null,
    equipmentId: null,
    facilityType: null,
    certificationTypeIds: [],
  },
  options = {}
) => {
  return useQuery({
    queryKey: [
      "getAllCertifications",
      pageNumber,
      pageSize,
      assetId,
      equipmentId,
      facilityType,
      certificationTypeIds,
    ],
    queryFn: () =>
      Agent.certifications.getAllCertifications({
        pageNumber: pageNumber,
        pageSize: pageSize,
        assetId: assetId,
        equipmentId: equipmentId,
        facilityType: facilityType,
        certificationTypeIds,
      }),
    staleTime: 1000 * 60 * 60,
    ...options,
  });
};

export const useGetCertificationById = (certificationId, options = {}) => {
  return useQuery({
    queryKey: ["getCertificationById", +certificationId],
    queryFn: () => Agent.certifications.getCertificationById(certificationId),
    staleTime: 1000 * 60 * 10,
    ...options,
  });
};

export const useCreateCertification = ({ config = {} }) => {
  const queryClient = useQueryClient();
  const { onSuccess, ...restConfig } = config || {};

  return useMutation({
    mutationKey: ["createCertification"],
    mutationFn: (body) => Agent.certifications.createCertification(body),
    onSuccess: (...args) => {
      queryClient.invalidateQueries(["getAllCertifications"]);
      onSuccess?.(...args);
    },
    ...restConfig,
  });
};

export const useUpdateCertification = ({ id, config = {} }) => {
  const queryClient = useQueryClient();
  const { onSuccess, ...restConfig } = config || {};

  return useMutation({
    mutationKey: ["updateCertification"],
    mutationFn: (body) => Agent.certifications.updateCertification(+id, body),
    onSuccess: (...args) => {
      queryClient.invalidateQueries(["getAllCertifications"]);
      queryClient.invalidateQueries(["getCertificationById", +id]);
      onSuccess?.(...args);
    },
    ...restConfig,
  });
};

export const useDeleteCertification = ({ id, config = {} }) => {
  const queryClient = useQueryClient();
  const { onSuccess, ...restConfig } = config || {};

  return useMutation({
    mutationKey: ["deleteCertification"],
    mutationFn: () => Agent.certifications.deleteCertification(id),
    onSuccess: (...args) => {
      queryClient.invalidateQueries(["getAllCertifications"]);
      onSuccess?.(...args);
    },
    ...restConfig,
  });
};
