import MainPageLayout from "components/main/MainPageLayout";
import { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router";

const ASSET_MAIN_TABS = [
  {
    id: 0,
    label: "Εξοπλισμός",
    to: `equipment`,
  },
  {
    id: 1,
    label: "Πιστοποιήσεις",
    to: `certification`,
  },
  {
    id: 2,
    label: "Συντηρήσεις",
    to: `maintenance`,
  },
];

const CustomizationParent = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/customize") {
      navigate(`/customize/${ASSET_MAIN_TABS[0].to}`);
    }
  }, [location, navigate]);

  return (
    <MainPageLayout
      pageTitle={"Παραμετροποίηση"}
      pageSubTitle={"Προσαρμογή των ρυθμίσεων της εφαρμογής"}
      headerTabs={ASSET_MAIN_TABS.map((tab) => ({
        ...tab,
        to: `/customize/${tab.to}`,
      }))}
      sx={{
        maxHeight: {
          xs: "calc(100% - 300px)",
          sm: "calc(100% - 111px)",
        },
      }}
    >
      <Outlet />
    </MainPageLayout>
  );
};

export default CustomizationParent;
