import React, { useState } from "react";
import {
  Typography,
  IconButton,
  Box,
  Dialog,
  DialogContent,
} from "@mui/material";
import { Bar } from "react-chartjs-2";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { chartsPalette } from "config/theme/chartsColorScheme";
import colors from "config/theme/colors";

const customLegendPaddingPlugin = {
  id: "increase-legend-spacing",
  beforeInit(chart) {
    const originalFit = chart.legend.fit;
    chart.legend.fit = function fit() {
      originalFit.bind(chart.legend)();
      this.height += 20;
    };
  },
};

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels,
  customLegendPaddingPlugin
);

const CustomHorizontalBarChart = ({
  title,
  data,
  tooltipIdentifier = "",
  extraLabelText = "",
  displayLegend = true,
  barWidth = 10,
  barBorderRadius = 5,
}) => {
  const [isFullscreen, setIsFullscreen] = useState(false);

  const toggleFullscreen = () => {
    setIsFullscreen(!isFullscreen);
  };

  const generateColors = (dataset) => {
    return dataset.data.map(
      (_, index) => chartsPalette[index % chartsPalette.length]
    );
  };

  const updatedData = {
    ...data,
    datasets: data.datasets.map((dataset) => ({
      ...dataset,
      backgroundColor: generateColors(dataset),
      barThickness: barWidth,
      borderRadius: barBorderRadius,
    })),
  };

  const chartOptions = {
    indexAxis: "y",
    responsive: true,
    maintainAspectRatio: true,
    scales: {
      x: {
        stacked: false,
        title: {
          display: true,
          text: extraLabelText,
          font: {
            size: 14,
          },
        },
      },
      y: {
        stacked: false,
        title: {
          display: true,
        },
        position: "right",
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: function (context) {
            const value = context.parsed.x;
            return context.dataset.label + ": " + value + tooltipIdentifier;
          },
        },
      },
      legend: {
        display: displayLegend,
        position: "top",
        align: "start",
        labels: {
          usePointStyle: true,
          pointStyle: "rectRounded",
        },
      },
      datalabels: {
        display: "auto",
        color: colors.white,
        align: "end",
        anchor: "start",
        clamp: true,
        font: {
          weight: "700",
          size: 12,
        },
        formatter: function (value) {
          return extraLabelText ? value + " " + extraLabelText : value;
        },
      },
    },
  };

  return (
    <Box
      sx={{
        position: "relative",
        margin: "0 auto",
        boxShadow: "0 12.01px 32.77px rgba(0, 0, 0, 0.04)",
        padding: 2,
        backgroundColor: colors.white,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          variant="h5"
          fontSize={14}
          fontWeight={600}
          sx={{ marginBottom: "20px" }}
        >
          {title}
        </Typography>
        <IconButton onClick={toggleFullscreen}>
          <FullscreenIcon />
        </IconButton>
      </Box>
      <Bar data={updatedData} options={chartOptions} />
      <Dialog
        open={isFullscreen}
        onClose={toggleFullscreen}
        maxWidth="xl"
        fullWidth
      >
        <DialogContent
          sx={{
            padding: 0,
            height: "80vh",
            maxHeight: "80vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              padding: 2,
            }}
          >
            <Typography variant="h5">{title}</Typography>
            <IconButton onClick={toggleFullscreen}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box
            sx={{
              width: "100%",
              height: "100%",
              padding: 2,
              boxSizing: "border-box",
            }}
          >
            <Bar
              data={updatedData}
              options={{
                ...chartOptions,
                maintainAspectRatio: false,
                scales: {
                  x: {
                    stacked: false,
                    title: {
                      display: false,
                    },
                  },
                  y: {
                    stacked: false,
                    title: {
                      display: false,
                    },
                    position: "right",
                  },
                },
              }}
            />
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

CustomHorizontalBarChart.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  tooltipIdentifier: PropTypes.string,
  extraLabelText: PropTypes.string,
  displayLegend: PropTypes.bool,
  barWidth: PropTypes.number,
  barBorderRadius: PropTypes.number,
};

export default CustomHorizontalBarChart;
