import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import SaveButton from "components/buttons/SaveButton";
import CustomTable from "components/tables/CustomTable";
import DateField from "components/inputs/DateField";
import DropdownField from "components/inputs/DropdownField";
import ReportsAccordion from "components/accordions/ReportsAccordion";
import usePagination from "hooks/usePagination";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useGetAllCompanies } from "hooks/queries/useCompanies";
import SpinningCircle from "components/spinners/SpinningCircle";
import {
  useGetReportContracts,
  useGetReportContractsForSpecificOrganization,
} from "hooks/queries/useContractReports";
import { formatDateTime } from "utils/formatDateTime";
import dayjs from "dayjs";
import ContractReportsExportButtons from "./ContractReportsExportButtons";
import enums from "config/enums";

const availableContractReportTypeOptions = [
  { label: "Νέες Συμβάσεις", value: "NewContracts" },
  { label: "Συμβάσεις που Λήγουν", value: "ExpiringContracts" },
  { label: "Συμβάσεις ανά Οργανισμό", value: "ContractsByOrganization" },
];

const columns = {
  NewContracts: [
    { id: "name", label: "Τίτλος Σύμβασης", isSortable: false },
    { id: "number", label: "Αριθμός Σύμβασης", isSortable: false },
    { id: "externalCompanyName", label: "Οργανισμός", isSortable: false },
    { id: "startDate", label: "Ημ/νία Έναρξης", isSortable: false },
    { id: "endDate", label: "Ημ/νία Λήξης", isSortable: false },
    { id: "type", label: "Τύπος Σύμβασης", isSortable: false },
  ],
  ExpiringContracts: [
    { id: "name", label: "Τίτλος Σύμβασης", isSortable: false },
    { id: "number", label: "Αριθμός Σύμβασης", isSortable: false },
    { id: "externalCompanyName", label: "Οργανισμός", isSortable: false },
    { id: "startDate", label: "Ημ/νία Έναρξης", isSortable: false },
    { id: "endDate", label: "Ημ/νία Λήξης", isSortable: false },
    { id: "type", label: "Τύπος Σύμβασης", isSortable: false },
  ],
  ContractsByOrganization: [
    { id: "externalCompanyName", label: "Οργανισμός", isSortable: false },
    { id: "name", label: "Τίτλος Σύμβασης", isSortable: false },
    { id: "number", label: "Αριθμός Σύμβασης", isSortable: false },
    { id: "startDate", label: "Ημ/νία Έναρξης", isSortable: false },
    { id: "endDate", label: "Ημ/νία Λήξης", isSortable: false },
    { id: "type", label: "Τύπος Σύμβασης", isSortable: false },
    { id: "status", label: "Κατάσταση", isSortable: false },
  ],
};

const getValidationSchema = (reportType) => {
  if (reportType === "NewContracts" || reportType === "ExpiringContracts") {
    return yup.object().shape({
      dateFrom: yup.date().required("Το πεδίο είναι υποχρεωτικό"),
      dateTo: yup.date().required("Το πεδίο είναι υποχρεωτικό"),
    });
  } else if (reportType === "ContractsByOrganization") {
    return yup.object().shape({
      organization: yup
        .array()
        .of(yup.object().shape({ id: yup.string(), label: yup.string() }))
        .nullable(),
    });
  } else {
    return yup.object().shape({});
  }
};

const ContractReports = () => {
  const [reportType, setReportType] = useState("");
  const [data, setData] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const {
    page,
    rowsPerPage,
    setPage,
    handleChangePage,
    handleChangeRowsPerPage,
  } = usePagination();
  const {
    data: companies,
    isLoading: isCompaniesLoading,
    error: errorCompanies,
  } = useGetAllCompanies(
    { pageNumber: -1, pageSize: -1 },
    {
      enabled: reportType === "ContractsByOrganization",
    }
  );
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
    watch,
  } = useForm({
    resolver: yupResolver(getValidationSchema(reportType)),
  });

  const dateFrom = watch("dateFrom");
  const dateTo = watch("dateTo");
  const organization = watch("organization");

  const fetchedData = {
    NewContracts: useGetReportContracts(
      {
        dateOption: "startDate",
        dateFrom: dayjs(dateFrom).utc(true).format(),
        dateTo: dayjs(dateTo).utc(true).format(),
        pageNumber: page,
        pageSize: rowsPerPage,
      },
      { enabled: reportType === "NewContracts" && isSubmitting }
    ),
    ExpiringContracts: useGetReportContracts(
      {
        dateOption: "endDate",
        dateFrom: dayjs(dateFrom).utc(true).format(),
        dateTo: dayjs(dateTo).utc(true).format(),
        pageNumber: page,
        pageSize: rowsPerPage,
      },
      { enabled: reportType === "ExpiringContracts" && isSubmitting }
    ),
    ContractsByOrganization: useGetReportContractsForSpecificOrganization(
      {
        externalCompanyId: organization?.map((a) => a.id) || null,
        pageNumber: page,
        pageSize: rowsPerPage,
      },
      { enabled: reportType === "ContractsByOrganization" && isSubmitting }
    ),
  };

  useEffect(() => {
    if (isSubmitting) {
      const activeQuery = fetchedData[reportType];
      setData(activeQuery.data || {});
    }
  }, [fetchedData, reportType, isSubmitting]);

  const isLoading = fetchedData[reportType]?.isLoading;

  const formattedData = useMemo(
    () =>
      data?.items?.map((item, i) => {
        switch (reportType) {
          case "NewContracts":
            return {
              id: `${item.startDate}-${item.endDate}-${i}`,
              name: item.name || "-",
              externalCompanyName: item.externalCompanyName || "-",
              number: item.number || "-",
              startDate: formatDateTime(item.startDate, true),
              endDate: formatDateTime(item.endDate, true),
              type:
                enums.ContractTypes.find((type) => type.id === item.type)
                  ?.label || "-",
            };
          case "ExpiringContracts":
            return {
              id: `${item.startDate}-${item.endDate}-${i}`,
              name: item.name || "-",
              externalCompanyName: item.externalCompanyName || "-",
              number: item.number || "-",
              startDate: formatDateTime(item.startDate, true),
              endDate: formatDateTime(item.endDate, true),
              type:
                enums.ContractTypes.find((type) => type.id === item.type)
                  ?.label || "-",
            };
          case "ContractsByOrganization":
            return {
              id: `${item.startDate}-${item.endDate}-${i}`,
              externalCompanyName: item.externalCompanyName || "-",
              name: item.name || "-",
              number: item.number || "-",
              startDate: formatDateTime(item.startDate, true),
              endDate: formatDateTime(item.endDate, true),
              type:
                enums.ContractTypes.find((type) => type.id === item.type)
                  ?.label || "-",
              status:
                enums.ContractStatuses.find(
                  (status) => status.id === item.status
                )?.label || "-",
            };
          default:
            return item;
        }
      }),
    [data, reportType]
  );

  const organizationOptions = companies?.items?.map((company) => ({
    id: company.id,
    label: company.name,
  }));
  const totalResults = data?.totalCount ?? null;
  const contractReportTypeOptions = availableContractReportTypeOptions.map(
    (option) => option.label
  );

  const handleOnFilterChange = () => {
    setIsSubmitting(false);
    setPage(1);
  };

  const handleReportTypeChange = (event, value) => {
    const selectedReport = availableContractReportTypeOptions.find(
      (option) => option.label === value
    )?.value;

    setReportType(selectedReport || "");
    reset();
    handleOnFilterChange();
  };

  const onSubmit = () => {
    setIsSubmitting(true);
  };

  if (isCompaniesLoading) {
    return <SpinningCircle />;
  }

  if (errorCompanies) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          height: "100%",
        }}
      >
        <p>Error: {errorCompanies?.message}</p>
      </Box>
    );
  }

  return (
    <Box sx={{ marginTop: 4 }}>
      <Typography variant="h4" color="primary">
        Αναφορές Συμβάσεων
      </Typography>

      <Box sx={{ marginTop: 3 }}>
        <DropdownField
          label="Επιλογή Κατηγορίας Αναφοράς"
          value={
            availableContractReportTypeOptions.find(
              (option) => option.value === reportType
            )?.label || ""
          }
          onChange={handleReportTypeChange}
          options={contractReportTypeOptions}
          required
        />

        <Box sx={{ marginTop: 3 }}>
          {availableContractReportTypeOptions
            .map((option) => option.value)
            .includes(reportType) && (
            <ReportsAccordion
              sx={{
                marginTop: 2,
                width: "100%",
                padding: 1.7,
                borderRadius: 2,
                boxShadow: 3,
                border: "1px solid",
                borderColor: "divider",
              }}
              title={`Φόρμα Αναφοράς ( ${
                availableContractReportTypeOptions.find(
                  (option) => option.value === reportType
                )?.label
              } )`}
            >
              <form onSubmit={handleSubmit(onSubmit)}>
                <Box display="flex" gap={2} flexWrap="wrap">
                  {reportType !== "ContractsByOrganization" && (
                    <>
                      <DateField
                        label="Ημερομηνία Από"
                        name="dateFrom"
                        control={control}
                        required
                        onChange={() => handleOnFilterChange()}
                      />
                      <DateField
                        label="Ημερομηνία Εώς"
                        name="dateTo"
                        control={control}
                        required
                        onChange={() => handleOnFilterChange()}
                      />
                    </>
                  )}
                  {reportType === "ContractsByOrganization" && (
                    <DropdownField
                      label="Επιλογή Οργανισμού"
                      name="organization"
                      control={control}
                      options={organizationOptions}
                      multiple
                      onChange={() => handleOnFilterChange()}
                      sx={{
                        minWidth: 300,
                      }}
                    />
                  )}
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: 4,
                      marginLeft: 4,
                    }}
                  >
                    {Object.keys(errors).length > 0 && (
                      <Typography color="error" fontSize={14}>
                        Παρακαλώ ελέγξτε ότι όλα τα πεδία έχουν συμπληρωθεί
                        σωστά
                      </Typography>
                    )}
                  </Box>
                </Box>
                <Box sx={{ marginTop: 3 }}>
                  <SaveButton text="Δημιουργία" isLoading={isLoading} />
                </Box>
              </form>
            </ReportsAccordion>
          )}

          <Box sx={{ marginTop: 3.5, display: "flex", alignItems: "center" }}>
            {totalResults > 0 && isSubmitting && (
              <>
                <Typography variant="h3" color="primary">
                  ({totalResults}) Αποτελέσματα
                </Typography>

                <Box
                  sx={{
                    display: "flex",
                    flexGrow: 1,
                    justifyContent: "flex-end",
                  }}
                >
                  <ContractReportsExportButtons
                    reportType={reportType}
                    filters={{
                      dateFrom: dayjs(dateFrom).utc(true).format(),
                      dateTo: dayjs(dateTo).utc(true).format(),
                      externalCompanyId: organization?.map((o) => o.id) || null,
                      dateOption:
                        reportType === "NewContracts" ? "startDate" : "endDate",
                    }}
                  />
                </Box>
              </>
            )}
          </Box>

          {totalResults > 0 && isSubmitting && (
            <Box
              sx={{
                marginTop: 3,
                marginBottom: 5,
                width: "100%",
                padding: 1.7,
                borderRadius: 2,
                boxShadow: 3,
                border: "1px solid",
                borderColor: "divider",
              }}
            >
              <CustomTable
                columns={columns[reportType]}
                data={formattedData}
                page={page - 1}
                rowsPerPage={rowsPerPage}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                totalResults={totalResults}
                disableClick
                maxHeight={650}
                textWeight={500}
              />
            </Box>
          )}

          {totalResults === 0 && isSubmitting && (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{
                height: "100%",
                marginTop: 2,
              }}
            >
              <Typography variant="h4">Δεν υπάρχουν αναφορές</Typography>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default ContractReports;
