import PropTypes from "prop-types";
import { DataGrid } from "@mui/x-data-grid";
import { elGR } from "@mui/x-data-grid/locales";
import { Box } from "@mui/material";

const DataGridTable = ({
  columns,
  rows,
  sx,
  onCellEdit,
  onRowSelectionModelChange,
  checkboxSelection = false,
  key,
  rowSelectionModel,
}) => {
  return (
    <Box sx={sx}>
      <DataGrid
        key={key}
        columns={columns}
        rows={rows}
        processRowUpdate={onCellEdit}
        checkboxSelection={checkboxSelection}
        onRowSelectionModelChange={onRowSelectionModelChange}
        localeText={elGR.components.MuiDataGrid.defaultProps.localeText}
        rowSelectionModel={rowSelectionModel}
      />
    </Box>
  );
};

DataGridTable.propTypes = {
  columns: PropTypes.array.isRequired,
  rows: PropTypes.array.isRequired,
  sx: PropTypes.object,
  onCellEdit: PropTypes.func,
  checkboxSelection: PropTypes.bool,
  onRowSelectionModelChange: PropTypes.func,
  key: PropTypes.string,
  rowSelectionModel: PropTypes.array,
};

export default DataGridTable;
