const ticketResponseFields = [
  {
    label: "Τίτλος",
    name: "name",
    type: "text",
    required: true,
  },
  {
    label: "Περιγραφή",
    name: "description",
    type: "text",
    required: true,
  },
  {
    label: "Ακίνητο",
    name: "assetName",
    type: "text",
    editable: false,
    required: false,
    options: [],
  },
  {
    label: "Εξοπλισμός",
    name: "equipmentName",
    type: "text",
    editable: false,
    required: false,
    options: [],
  },
  {
    label: "Hμερομηνία Δημιουργίας",
    name: "entityCreatedAt",
    type: "date",
    required: true,
  },
  {
    label: "Δημιουργήθηκε Από",
    name: "createdBy",
    type: "text",
    editable: false,
  },
  {
    label: "Hμερομηνία Ολοκλήρωσης",
    name: "completedAt",
    type: "date",
    editable: false,
  },
  {
    label: "Ολοκληρώθηκε Από",
    name: "completedBy",
    type: "text",
    editable: false,
  },
  {
    label: "Κατάσταση",
    name: "status",
    type: "text",
    editable: false,
  },
  {
    label: "Φωτογραφίες",
    name: "files",
    type: "file",
    description: "Επισύναψη φωτογραφιών από επιτόπιο έλεγχο",
    multiple: true,
    validTypes: ["image/jpeg", "image/png"],
    maxSize: 5000000,
  },
];

export default ticketResponseFields;
