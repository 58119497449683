import sidebarStore from "stores/SidebarStore";

export const useSidebar = () => {
  const store = sidebarStore;

  const toggleSidebar = () => {
    store.toggleSidebar();
  };

  const setSidebar = (state) => {
    store.setSidebar(state);
  };

  const isCollapsed = store.isCollapsed;

  return {
    isCollapsed,
    toggleSidebar,
    setSidebar,
  };
};
