import React, { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { useIsMutating } from "@tanstack/react-query";
import CancelButton from "components/buttons/CancelButton";
import SaveButton from "components/buttons/SaveButton";
import CustomTextField from "components/inputs/CustomTextField";
import DateField from "components/inputs/DateField";
import DropdownField from "components/inputs/DropdownField";
import DeleteModal from "components/modals/DeleteModal";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import DeleteButton from "components/buttons/DeleteButton";
import certificationResponseFields from "config/forms/certificationResponseFields";
import dayjs from "dayjs";

const CertificationHistoryViewEditForm = ({
  selectedCertificationHistory,
  isEditing,
  onSave,
  onCancel,
  onDelete,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const isUpdateLoading =
    useIsMutating({ mutationKey: ["updateCertificationHistory"] }) > 0;
  const isDeleteLoading =
    useIsMutating({ mutationKey: ["deleteCertificationHistory"] }) > 0;

  const schema = yup.object(
    certificationResponseFields.certificationHistory.reduce((acc, field) => {
      let fieldSchema;

      if (
        field.type === "text" ||
        field.type === "textarea" ||
        field.type === "file"
      ) {
        fieldSchema = yup.string();
      } else if (field.type === "select" || field.type === "boolean") {
        fieldSchema = yup.string();
      } else if (field.type === "number") {
        fieldSchema = yup
          .number()
          .transform((value) => (isNaN(value) ? undefined : value));
      } else if (field.type === "date") {
        fieldSchema = yup.date().transform((value, originalValue) => {
          const parsedDate = new Date(originalValue);
          return isNaN(parsedDate) ? undefined : parsedDate;
        });
      }

      if (field.required) {
        fieldSchema = fieldSchema.required("Το πεδίο είναι υποχρεωτικό");
      } else {
        fieldSchema = fieldSchema.nullable();
      }

      if (field.validation === "custom" && field.regex) {
        fieldSchema = fieldSchema.matches(field.regex, field.validationMessage);
      }

      acc[field.name] = fieldSchema.typeError(
        field.validationMessage || "Μη έγκυρη τιμή"
      );

      return acc;
    }, {})
  );

  const getDataValueFromField = (fieldName) => {
    const fieldMap = certificationResponseFields.certificationHistory.reduce(
      (acc, field) => {
        acc[field.name] = field.name;
        return acc;
      },
      {}
    );

    const path = fieldMap[fieldName];
    if (typeof path === "function") {
      return path();
    } else if (typeof path === "string") {
      const value = path
        .split(".")
        .reduce(
          (acc, key) => (acc ? acc[key] : undefined),
          selectedCertificationHistory
        );
      return value !== undefined && value !== null ? value : null;
    } else {
      return "";
    }
  };

  const handleFormSubmit = (data) => {
    onSave(data);
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const formFields = certificationResponseFields.certificationHistory;

  const defaultValues = {
    ...Object.fromEntries(
      formFields.map((field) => [field.name, getDataValueFromField(field.name)])
    ),
  };

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isDirty },
    getValues,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  });

  const getInputElementByFieldType = (field) => {
    if (
      field.type === "text" ||
      field.type === "number" ||
      field.type === "textarea" ||
      field.type === "file"
    ) {
      return (
        <CustomTextField {...field} control={control} disabled={!isEditing} />
      );
    } else if (field.type === "select" || field.type === "boolean") {
      let availableOptions =
        field.type === "boolean" ? ["Ναι", "Όχι"] : field.options;

      return (
        <DropdownField
          {...field}
          control={control}
          options={availableOptions}
          boolean={field.type === "boolean"}
          disabled={!isEditing}
        />
      );
    } else if (field.type === "date") {
      return (
        <DateField
          disabled={!isEditing}
          {...field}
          control={control}
          max={
            field.name === "issueDateTime" ? dayjs().format("YYYY-MM-DD") : null
          }
        />
      );
    }
  };

  return (
    <form onSubmit={handleSubmit(() => handleFormSubmit(getValues()))}>
      <Box display="flex" flexDirection="column" gap={4}>
        <Grid container spacing={2}>
          {formFields
            .filter((field) => field.display !== false)
            .map((field) => (
              <Grid item xs={12} md={4} key={field?.name}>
                {getInputElementByFieldType(field)}
              </Grid>
            ))}
        </Grid>

        {Object.keys(errors).length > 0 && (
          <Typography color="error" fontSize={14}>
            Παρακαλώ ελέγξτε ότι όλα τα πεδία έχουν συμπληρωθεί σωστά
          </Typography>
        )}

        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          gap={2}
        >
          <Box display="flex" gap={2} justifyContent={"flex-start"}>
            <CancelButton
              disabled={!isEditing}
              onClick={() => {
                reset();
                onCancel();
              }}
            />
            <DeleteButton
              onClick={handleOpenModal}
              text="Διαγραφή Ιστορικού Πιστοποίησης"
              disabled={!isEditing}
              isLoading={isDeleteLoading}
            />
          </Box>

          <SaveButton
            disabled={!isEditing || !isDirty}
            isLoading={isUpdateLoading}
          />

          <DeleteModal
            isOpen={isModalOpen}
            onClose={handleCloseModal}
            onDelete={onDelete}
            itemTitle={selectedCertificationHistory?.name}
            title="Πρόκειται να διαγράψετε το ιστορικό πιστοποίησης"
            description="Είστε βέβαιοι ότι θέλετε να προχωρήσετε στη διαγραφή του ιστορικού πιστοποίησης;"
          />
        </Box>
      </Box>
    </form>
  );
};

CertificationHistoryViewEditForm.propTypes = {
  selectedCertificationHistory: PropTypes.object,
  isEditing: PropTypes.bool,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  onDelete: PropTypes.func,
};

export default CertificationHistoryViewEditForm;
