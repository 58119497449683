import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useNavigate, useOutletContext } from "react-router";
import { useCreateMaintenance } from "hooks/queries/useMaintenances";
import { useAlert } from "hooks/useAlert";
import MaintenanceCreateForm from "./MaintenanceCreateForm";
import colors from "config/theme/colors";
import { useGetAllMaintenanceTypes } from "hooks/queries/useMaintenanceType";
import SpinningCircle from "components/spinners/SpinningCircle";
import { useUserProfile } from "hooks/queries/useAuth";
import PropTypes from "prop-types";
import { useUploadFiles } from "hooks/queries/useFile";

const MaintenanceCreate = ({ facilityType = null }) => {
  const { showError, showSuccess, showWarning } = useAlert();
  const { selectedAsset, selectedEquipment } = useOutletContext();
  const navigate = useNavigate();
  const {
    data: maintenancesTypes,
    isLoading: isMaintenancesTypesLoading,
    error: errorMaintenancesTypes,
  } = useGetAllMaintenanceTypes({
    assetCategoryId:
      facilityType === "asset" ? selectedAsset?.assetCategory?.id : null,
    equipmentTypeIds:
      facilityType === "equipment" &&
      selectedEquipment?.equipmentClass?.equipmentGroup?.equipmentType?.id
        ? [selectedEquipment?.equipmentClass?.equipmentGroup?.equipmentType?.id]
        : null,
  });
  const {
    data: userProfile,
    isLoading: isProfileLoading,
    error: profileError,
  } = useUserProfile();
  const { mutate: uploadFiles } = useUploadFiles({
    fileableType: "Maintenance",
    config: {
      onError: (error) => {
        showWarning(
          "Η συντήρηση δημιουργήθηκε, αλλά προέκυψε σφάλμα κατά την αποθήκευση των αρχείων. Δοκιμάστε ξανά."
        );
        navigate(-1);
        console.error(error);
      },
      onSuccess: () => {
        showSuccess("Η νέα συντήρηση δημιουργήθηκε επιτυχώς!");
        navigate(-1);
      },
    },
  });
  const { mutate: createMaintenance } = useCreateMaintenance({
    config: {
      onError: (error) => {
        showError(error.message);
      },
      onSuccess: () => {
        showSuccess("Η συντήρηση δημιουργήθηκε με επιτυχία");
        navigate(-1);
      },
    },
  });

  if (isMaintenancesTypesLoading || isProfileLoading) {
    return <SpinningCircle />;
  }

  if (errorMaintenancesTypes || profileError) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          height: "100%",
        }}
      >
        <p>
          Error:
          {errorMaintenancesTypes.message || profileError.message}
        </p>
      </Box>
    );
  }

  const handleCreateClick = (data) => {
    const payload = {
      name: data.name || "",
      description: data.description || "",
      frequency: parseInt(data.frequency) || "",
      notes: data.notes || "",
      userId: userProfile?.userDto?.id || "",
      maintenanceTypeId:
        maintenancesTypes?.items?.find(
          (type) => type.name === data.maintenanceTypeId
        )?.id || null,
      contractId: data.contractId?.id || null,
      assetId: facilityType === "asset" ? selectedAsset.id : null,
      equipmentId: facilityType === "equipment" ? selectedEquipment.id : null,
      status: data.status?.id || "",
    };
    const files = data.files;

    createMaintenance(payload, {
      onSuccess: (maintenance) => {
        if (files?.length > 0) {
          uploadFiles({ files, entityId: maintenance?.result?.id });
        } else {
          showSuccess("Η νέα συντήρηση δημιουργήθηκε επιτυχώς!");
          navigate(-1);
        }
      },
    });
  };

  return (
    <>
      <Box
        display="flex"
        alignItems={"center"}
        justifyContent="flex-start"
        gap={1}
      >
        <Typography color={colors.primary} fontWeight={700} fontSize={24}>
          Νέα Συντήρηση
        </Typography>
        <Typography color={colors.text} fontWeight={500} fontSize={18}>
          Δημιουργία νέας συντήρησης
        </Typography>
      </Box>

      <Box mt={4.5}>
        <MaintenanceCreateForm
          onCreate={handleCreateClick}
          selectedAsset={facilityType === "asset" ? selectedAsset : null}
          selectedEquipment={
            facilityType === "equipment" ? selectedEquipment : null
          }
        />
      </Box>
    </>
  );
};

MaintenanceCreate.propTypes = {
  facilityType: PropTypes.oneOf(["equipment", "asset"]).isRequired,
};

export default MaintenanceCreate;
