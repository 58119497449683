import React, { useCallback, useMemo, useRef, useState } from "react";
import { GoogleMap, MarkerF } from "@react-google-maps/api";
import {
  TextField,
  Typography,
  List,
  ListItemText,
  ListItemButton,
  Box,
  InputAdornment,
  IconButton,
} from "@mui/material";
import PropTypes from "prop-types";
import useMapLoader from "hooks/useMapLoader";
import colors from "config/theme/colors";
import { debounce } from "lodash";
import SearchIcon from "@mui/icons-material/Search";

const DEFAULT_CONTAINER_STYLE = {
  width: "100%",
  height: "400px",
};

const center = {
  lat: 37.98,
  lng: 23.73,
};

function AutocompleteMap({
  onMarkerChange,
  marker,
  error,
  readOnly,
  draggable = false,
  containerStyle,
  inputFieldLabel,
  required,
}) {
  const [inputValue, setInputValue] = useState(marker?.formattedAddress || "");
  const [predictions, setPredictions] = useState([]);
  const [isFocused, setIsFocused] = useState(false);
  const zoom = useMemo(() => (marker ? 18 : 15), [marker]);
  const { isLoaded } = useMapLoader();
  const boxRef = useRef();

  const extractAddressComponents = (addressComponents) => {
    let city = "";
    let postalCode = "";

    addressComponents?.forEach((component) => {
      if (component.types?.includes("locality")) {
        city = component.long_name;
      }
      if (component.types?.includes("postal_code")) {
        postalCode = component.long_name;
      }
    });

    return { city, postalCode };
  };

  const onPlaceSelected = (placeId) => {
    const service = new window.google.maps.places.PlacesService(
      document.createElement("div")
    );
    service.getDetails({ placeId }, (place, status) => {
      if (status === window.google.maps.places.PlacesServiceStatus.OK) {
        const { formatted_address, geometry, address_components } = place;
        const { city, postalCode } =
          extractAddressComponents(address_components);

        setInputValue(formatted_address);
        setPredictions([]);

        onMarkerChange?.({
          formattedAddress: formatted_address,
          lat: geometry?.location?.lat(),
          lng: geometry?.location?.lng(),
          city,
          postalCode,
        });
      }
    });
  };

  const fetchPredictions = useCallback((value) => {
    if (!value) {
      setPredictions([]);
      return;
    }

    const service = new window.google.maps.places.AutocompleteService();
    service.getPlacePredictions(
      { input: value, componentRestrictions: { country: "gr" } },
      (results, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          setPredictions(results);
        } else {
          setPredictions([]);
        }
      }
    );
  }, []);

  const debouncedFetchPredictions = useMemo(
    () => debounce(fetchPredictions, 3000),
    [fetchPredictions]
  );

  const handleInputChange = (event) => {
    const value = event.target.value;
    if (value === "" || value.includes?.(inputValue)) {
      setPredictions([]);
    }
    setInputValue(value);
    // debouncedFetchPredictions(value);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      event.nativeEvent.stopImmediatePropagation();
      event.stopPropagation();
      debouncedFetchPredictions.cancel();
      fetchPredictions(inputValue);
    }
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setTimeout(() => {
      setIsFocused(false);
    }, 150);
  };

  const mapOptions = {
    disableDefaultUI: true,
    styles: [
      {
        featureType: "poi",
        elementType: "labels",
        stylers: [{ visibility: "off" }],
      },
      {
        featureType: "transit",
        stylers: [{ visibility: "off" }],
      },
    ],
  };

  return (
    <div className="relative">
      {isLoaded && (
        <>
          <div>
            <Typography
              fontSize={12}
              fontWeight={700}
              display="flex"
              alignItems="center"
              gap={1}
            >
              {inputFieldLabel} {required && "*"}
            </Typography>

            <div className="mt-3" style={{ position: "relative" }}>
              <TextField
                placeholder="Επιλέξτε τοποθεσία στο χάρτη"
                fullWidth
                error={!!error}
                value={inputValue}
                disabled={readOnly}
                sx={{
                  backgroundColor: readOnly
                    ? colors.disabledInputBackground
                    : undefined,
                  "& .Mui-disabled": {
                    color: colors.text,
                    "-webkit-text-fill-color": colors.text,
                  },
                }}
                onChange={handleInputChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                onKeyDown={(e) => handleKeyDown(e)}
                helperText={error ? "Το πεδίο είναι υποχρεωτικό" : null}
                InputProps={{
                  readOnly: readOnly,
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => {
                          debouncedFetchPredictions.cancel();
                          fetchPredictions(inputValue);
                        }}
                        edge="end"
                        disabled={readOnly}
                      >
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                inputProps={{
                  readOnly: readOnly,
                }}
              />
              {predictions.length > 0 && isFocused && (
                <Box
                  ref={boxRef}
                  sx={{
                    position: "absolute",
                    top: "100%",
                    left: 0,
                    right: 0,
                    zIndex: 10,
                    backgroundColor: "white",
                    boxShadow: 1,
                    maxHeight: 200,
                    overflow: "auto",
                    borderRadius: 2,
                  }}
                >
                  <List component="nav" aria-label="predictions">
                    {predictions.map((prediction) => (
                      <ListItemButton
                        key={prediction.place_id}
                        onClick={() => onPlaceSelected(prediction.place_id)}
                      >
                        <ListItemText primary={prediction.description} />
                      </ListItemButton>
                    ))}
                  </List>
                </Box>
              )}
            </div>
          </div>

          <div className="mt-2">
            <GoogleMap
              inputProps={{ readOnly: readOnly }}
              mapContainerStyle={containerStyle || DEFAULT_CONTAINER_STYLE}
              center={marker || center}
              zoom={zoom}
              version="weekly"
              options={mapOptions}
            >
              {marker && (
                <div>
                  <MarkerF position={marker} draggable={draggable} />
                </div>
              )}
            </GoogleMap>
          </div>
        </>
      )}
    </div>
  );
}

AutocompleteMap.propTypes = {
  onMarkerChange: PropTypes.func.isRequired,
  marker: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
    formattedAddress: PropTypes.string,
    city: PropTypes.string,
    postalCode: PropTypes.string,
    address: PropTypes.string,
  }),
  error: PropTypes.bool,
  readOnly: PropTypes.bool,
  draggable: PropTypes.bool,
  containerStyle: PropTypes.object,
  inputFieldLabel: PropTypes.string,
  required: PropTypes.bool,
};

export default AutocompleteMap;
