import { Box, Typography } from "@mui/material";
import PropTypes from "prop-types";

const textColorPalette = ["#008767", "#747A80", "#DF0404"];
const bgColorPalette = ["#9EDFD04D", "#747A804D", "#FFC5C54D"];

const StatusLabel = ({ statusText, statusValue, isExpired, isInactive }) => {
  const isExpiredInternal = statusValue === "Expired" || isExpired;
  const isInactiveInternal = statusValue === "Inactive" || isInactive;

  const getBgColor = () => {
    if (isExpiredInternal) return bgColorPalette[2];
    if (isInactiveInternal) return bgColorPalette[1];
    return bgColorPalette[0];
  };

  const getTextColor = () => {
    if (isExpiredInternal) return textColorPalette[2];
    if (isInactiveInternal) return textColorPalette[1];
    return textColorPalette[0];
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      padding={"6px 12px"}
      sx={{
        backgroundColor: getBgColor(),
        borderRadius: 2,
      }}
    >
      <Typography
        color={getTextColor()}
        fontWeight={500}
        fontSize={14}
        textAlign={"center"}
      >
        {statusText}
      </Typography>
    </Box>
  );
};

StatusLabel.propTypes = {
  statusText: PropTypes.string.isRequired,
  statusValue: PropTypes.string.isRequired,
  isExpired: PropTypes.bool,
  isInactive: PropTypes.bool,
};

export default StatusLabel;
