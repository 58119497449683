import React from "react";
import PropTypes from "prop-types";
import { Box, TextField, Typography } from "@mui/material";
import { InfoOutlined } from "@mui/icons-material";
import Tooltip from "@mui/material/Tooltip";
import { Controller } from "react-hook-form";
import colors from "config/theme/colors";
import dayjs from "dayjs";
import { DatePicker, DateTimePicker } from "@mui/x-date-pickers";
import "dayjs/locale/el";
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(customParseFormat);
dayjs.locale("el");

const DateField = ({
  label,
  name,
  value,
  onChange: handleChange,
  disabled = false,
  required = false,
  control,
  editable = true,
  yearOnly = false,
  dateTime = false,
  sx,
  min,
  max,
}) => {
  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Typography
        fontSize={12}
        fontWeight={700}
        display="flex"
        alignItems="center"
        gap={1}
      >
        {label} {required && "*"}
        {!editable && (
          <Tooltip title="Μη επεξεργάσιμο πεδίο" placement="right">
            <InfoOutlined
              fontSize="small"
              sx={{
                color: colors.primary,
                cursor: "pointer",
                maxHeight: "0.9em",
              }}
            />
          </Tooltip>
        )}
      </Typography>
      <Controller
        name={name}
        control={control}
        render={({
          field: { onChange, onBlur, value, ref },
          fieldState: { error },
        }) =>
          dateTime ? (
            <DateTimePicker
              value={value ? dayjs(value).locale("el") : null}
              onChange={(newValue) => {
                if (newValue?.isValid()) {
                  onChange(newValue.toISOString());
                } else {
                  onChange(null);
                }
                handleChange &&
                  newValue?.isValid() &&
                  handleChange(newValue.toISOString());
              }}
              onBlur={onBlur}
              readOnly={disabled || !editable}
              format="DD/MM/YYYY HH:mm"
              minDate={min ? dayjs(min) : null}
              maxDate={max ? dayjs(max) : null}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  required={required}
                  error={!!error}
                  helperText={error ? error.message : ""}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    ...params.InputProps,
                    readOnly: disabled || !editable,
                    style: {
                      fontSize: 14,
                      fontWeight: 400,
                      color: colors.text,
                      ...sx,
                    },
                  }}
                />
              )}
            />
          ) : (
            <DatePicker
              views={yearOnly ? ["year"] : ["year", "month", "day"]}
              value={value ? dayjs(value).locale("el") : null}
              onChange={(newValue) => {
                if (newValue?.isValid()) {
                  if (yearOnly) {
                    onChange(newValue.endOf("year").toISOString());
                  } else {
                    onChange(newValue.toISOString());
                  }
                } else {
                  onChange(null);
                }
                handleChange &&
                  newValue?.isValid() &&
                  handleChange(newValue.toISOString());
              }}
              onBlur={onBlur}
              readOnly={disabled || !editable}
              format="DD/MM/YYYY"
              minDate={min ? dayjs(min) : null}
              maxDate={max ? dayjs(max) : null}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  required={required}
                  error={!!error}
                  helperText={error ? error.message : ""}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    ...params.InputProps,
                    readOnly: disabled || !editable,
                    style: {
                      fontSize: 14,
                      fontWeight: 400,
                      color: colors.text,
                      ...sx,
                    },
                  }}
                />
              )}
            />
          )
        }
      />
    </Box>
  );
};

DateField.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  control: PropTypes.object.isRequired,
  editable: PropTypes.bool,
  yearOnly: PropTypes.bool,
  dateTime: PropTypes.bool,
  sx: PropTypes.object,
  min: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  max: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
};

export default DateField;
