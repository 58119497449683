import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Agent } from "api/agent";

export const useLogin = () => {
  return useMutation({
    mutationKey: ["login"],
    mutationFn: Agent.auth.login,
  });
};

export const useUserProfile = () => {
  return useQuery({
    queryKey: ["userProfile"],
    queryFn: Agent.auth.getProfile,
    staleTime: 1000 * 60 * 60,
  });
};

export const useUpdateUserProfile = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ["updateUserProfile"],
    mutationFn: Agent.auth.updateProfile,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["userProfile"] });
    },
  });
};

export const useChangePassword = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ["changePassword"],
    mutationFn: Agent.auth.changePassword,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["userProfile"] });
    },
  });
};

export const useForgotPassword = () => {
  return useMutation({
    mutationKey: ["forgotPassword"],
    mutationFn: Agent.auth.forgotPassword,
  });
};

export const useResetPassword = () => {
  return useMutation({
    mutationKey: ["resetPassword"],
    mutationFn: Agent.auth.resetPassword,
  });
};

export const useRefreshToken = () => {
  return useMutation({
    mutationFn: Agent.auth.refreshToken,
  });
};
