import Box from "@mui/material/Box";
import colors from "config/theme/colors";
import PropTypes from "prop-types";

const MapCardContainer = ({ children }) => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        padding: 0.5,
        borderRadius: "12px",
        backgroundColor: colors.white,
        boxShadow: `0px 20px 50px 0px ${colors.boxShadowColor}`,
      }}
    >
      {children}
    </Box>
  );
};

MapCardContainer.propTypes = {
  children: PropTypes.node,
};

export default MapCardContainer;
