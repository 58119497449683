import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Agent } from "api/agent";

export const useGetAllEquipmentGroups = (
  { pageNumber = -1, pageSize = -1, equipmentTypeId = null } = {
    pageNumber: -1,
    pageSize: -1,
    equipmentTypeId: null,
  },
  options = {}
) => {
  return useQuery({
    queryKey: ["getAllEquipmentGroups", pageNumber, pageSize, equipmentTypeId],
    queryFn: () =>
      Agent.equipmentGroup.getAll({
        pageNumber: pageNumber,
        pageSize: pageSize,
        equipmentTypeId: equipmentTypeId,
      }),
    staleTime: 1000 * 60 * 60,
    ...options,
  });
};

export const useGetEquipmentGroupById = (id, options = {}) => {
  return useQuery({
    queryKey: ["getEquipmentGroupById", +id],
    queryFn: () => Agent.equipmentGroup.getById(id),
    staleTime: 1000 * 60 * 10,
    ...options,
  });
};

export const useCreateEquipmentGroup = ({ config = {} }) => {
  const queryClient = useQueryClient();
  const { onSuccess, ...restConfig } = config || {};

  return useMutation({
    mutationKey: ["createEquipmentGroup"],
    mutationFn: (body) => Agent.equipmentGroup.create(body),
    onSuccess: (...args) => {
      queryClient.invalidateQueries({ queryKey: ["getAllEquipmentGroups"] });
      onSuccess?.(...args);
    },
    ...restConfig,
  });
};

export const useUpdateEquipmentGroup = ({ id, config = {} }) => {
  const queryClient = useQueryClient();
  const { onSuccess, ...restConfig } = config || {};

  return useMutation({
    mutationKey: ["updateEquipmentGroup"],
    mutationFn: (body) => Agent.equipmentGroup.update(id, body),
    onSuccess: (...args) => {
      queryClient.invalidateQueries({ queryKey: ["getAllEquipmentGroups"] });
      queryClient.invalidateQueries({
        queryKey: ["getEquipmentGroupById", +id],
      });
      onSuccess?.(...args);
    },
    ...restConfig,
  });
};

export const useDeleteEquipmentGroup = ({ id, config = {} }) => {
  const queryClient = useQueryClient();
  const { onSuccess, ...restConfig } = config || {};

  return useMutation({
    mutationKey: ["deleteEquipmentGroup"],
    mutationFn: () => Agent.equipmentGroup.delete(id),
    onSuccess: (...args) => {
      queryClient.invalidateQueries({ queryKey: ["getAllEquipmentGroups"] });
      onSuccess?.(...args);
    },
    ...restConfig,
  });
};
