function clearDotAnnotatedFields(data) {
  let newData = { ...data };

  Object.keys(newData).forEach((key) => {
    if (key.includes(".")) {
      delete newData[key];
    }
  });

  Object.keys(newData).forEach((key) => {
    if (newData[key] === null || newData[key] === undefined) {
      delete newData[key];
    }

    if (typeof newData[key] === "object") {
      const transformedData = clearDotAnnotatedFields(newData[key]);
      if (Object.keys(transformedData).length === 0) {
        delete newData[key];
      } else {
        newData[key] = transformedData;
      }
    }
  });

  return newData;
}

export default clearDotAnnotatedFields;
