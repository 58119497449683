import { requests } from "api/HttpClient";

const { get, getFile } = requests;

const companyReports = {
  getMaintenanceOrganizations: ({
    dateFrom = "",
    dateTo = "",
    pageNumber = -1,
    pageSize = -1,
    organizationId = [],
  }) => {
    const queryParams = {
      dateFrom,
      dateTo,
      pageNumber,
      pageSize,
      organizationId,
    };

    const queryString = Object.keys(queryParams)
      .filter((key) => queryParams[key])
      .map((key) => {
        if (queryParams[key] instanceof Array) {
          return queryParams[key]
            .map((id) => `${encodeURIComponent(key)}=${encodeURIComponent(id)}`)
            .join("&");
        }
        return `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`;
      })
      .join("&");

    const url = `/OrganizationReport/GetMaintenanceOrganizations?${queryString}`;

    return get(url);
  },

  getMaintenanceOrganizationCosts: ({
    dateFrom = "",
    dateTo = "",
    pageNumber = -1,
    pageSize = -1,
  }) => {
    const queryParams = {
      dateFrom,
      dateTo,
      pageNumber,
      pageSize,
    };

    const queryString = Object.keys(queryParams)
      .filter((key) => queryParams[key])
      .map(
        (key) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`
      )
      .join("&");

    const url = `/OrganizationReport/GetMaintenanceOrganizationCosts?${queryString}`;

    return get(url);
  },

  exportMaintenanceOrganizations: ({
    exportType = "",
    organizationId = [],
    dateFrom = "",
    dateTo = "",
  }) => {
    const queryParams = {
      exportType,
      dateFrom,
      dateTo,
      organizationId,
    };
    const queryString = Object.keys(queryParams)
      .filter((key) => queryParams[key])
      .map((key) => {
        if (queryParams[key] instanceof Array) {
          return queryParams[key]
            .map((id) => `${encodeURIComponent(key)}=${encodeURIComponent(id)}`)
            .join("&");
        }
        return `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`;
      })
      .join("&");

    const url = `/OrganizationReport/ExportMaintenanceOrganizationsToFile?${queryString}`;

    return getFile(url);
  },

  exportMaintenanceOrganizationsCostsToFile: ({
    exportType = "",
    dateFrom = "",
    dateTo = "",
  }) => {
    const queryParams = {
      exportType,
      dateFrom,
      dateTo,
    };
    const queryString = Object.keys(queryParams)
      .filter((key) => queryParams[key])
      .map((key) => {
        if (queryParams[key] instanceof Array) {
          return queryParams[key]
            .map((id) => `${encodeURIComponent(key)}=${encodeURIComponent(id)}`)
            .join("&");
        }
        return `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`;
      })
      .join("&");

    const url = `/OrganizationReport/ExportMaintenanceOrganizationCostsToFile?${queryString}`;

    return getFile(url);
  },
};

export default companyReports;
