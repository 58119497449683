import { requests } from "api/HttpClient";

const { post, get, put, delete: deleteRequest } = requests;

const certificationType = {
  getAll: ({
    pageNumber = -1,
    pageSize = -1,
    facilityType,
    assetId,
    equipmentId,
  }) => {
    let url = "/CertificationType/GetCertificationTypeList";
    if (pageNumber && pageSize) {
      url += `?pageNumber=${pageNumber}&pageSize=${pageSize}`;
    }
    if (facilityType) {
      url += `&facilityType=${facilityType}`;
    }

    if (assetId) {
      url += `&assetId=${assetId}`;
    }

    if (equipmentId) {
      url += `&equipmentId=${equipmentId}`;
    }
    return get(url);
  },
  getById: (id) => get(`/CertificationType/GetCertificationTypeById/${id}`),
  create: (body) =>
    post("/CertificationType/CreateCertificationType", body, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }),
  update: (id, body) =>
    put(`/CertificationType/UpdateCertificationType/${id}`, body, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }),
  delete: (id) =>
    deleteRequest(`/CertificationType/DeleteCertificationType/${id}`),
};

export default certificationType;
