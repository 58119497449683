import { useQuery } from "@tanstack/react-query";
import { Agent } from "api/agent";

const baseQueryConfig = {
  staleTime: Infinity,
  enabled: false,
  retry: false,
  refetchOnReconnect: false,
  refetchOnWindowFocus: false,
  refetchOnMount: false,
};

export const useGetReportTickets = (
  { status = "", dateFrom = "", dateTo = "", pageNumber = -1, pageSize = -1 },
  options = {}
) => {
  return useQuery({
    queryKey: ["getTickets", status, dateFrom, dateTo, pageNumber, pageSize],
    queryFn: () =>
      Agent.ticketReports.getTickets({
        status: status,
        dateFrom: dateFrom,
        dateTo: dateTo,
        pageNumber: pageNumber,
        pageSize: pageSize,
      }),
    staleTime: 1000 * 60 * 60,
    ...options,
  });
};

export const useGetReportTicketsPerAsset = (
  { dateFrom = "", dateTo = "", pageNumber = -1, pageSize = -1 },
  options = {}
) => {
  return useQuery({
    queryKey: ["getTicketsPerAsset", dateFrom, dateTo, pageNumber, pageSize],
    queryFn: () =>
      Agent.ticketReports.getTicketsPerAsset({
        dateFrom: dateFrom,
        dateTo: dateTo,
        pageNumber: pageNumber,
        pageSize: pageSize,
      }),
    staleTime: 1000 * 60 * 60,
    ...options,
  });
};

export const useExportTickets = ({
  status = "",
  exportType = "",
  dateFrom = "",
  dateTo = "",
}) => {
  return useQuery({
    queryKey: ["exportTickets", exportType, dateFrom, dateTo, status],
    queryFn: () =>
      Agent.ticketReports.exportTickets({
        status: status,
        exportType: exportType,
        dateFrom: dateFrom,
        dateTo: dateTo,
      }),
    ...baseQueryConfig,
  });
};

export const useExportTicketsPerAsset = ({
  exportType = "",
  dateFrom = "",
  dateTo = "",
}) => {
  return useQuery({
    queryKey: ["exportTicketsPerAsset", exportType, dateFrom, dateTo],
    queryFn: () =>
      Agent.ticketReports.exportTicketsPerAsset({
        exportType: exportType,
        dateFrom: dateFrom,
        dateTo: dateTo,
      }),
    ...baseQueryConfig,
  });
};
