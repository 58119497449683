import { useQuery } from "@tanstack/react-query";
import { Agent } from "api/agent";
import dayjs from "dayjs";

export const useGetCountOfMaintenancesAndCertificationsPerAsset = (
  { startDate, endDate } = { startDate: null, endDate: null },
  options = {}
) => {
  return useQuery({
    queryKey: [
      "getCountOfMaintenancesAndCertificationsPerAsset",
      startDate,
      endDate,
    ],
    queryFn: () =>
      Agent.dashboard.getMaintenanceAndCertificationCountsPerAsset({
        startDate: dayjs(startDate).format("YYYY-MM-DD"),
        endDate: dayjs(endDate).format("YYYY-MM-DD"),
      }),
    staleTime: 1000 * 60 * 60,
    ...options,
  });
};

export const useGetTotalMaintenanceCostPerAsset = (
  { startDate, endDate } = { startDate: null, endDate: null },
  options = {}
) => {
  return useQuery({
    queryKey: ["getTotalMaintenanceCostPerAsset", startDate, endDate],
    queryFn: () =>
      Agent.dashboard.getTotalMaintenanceCostPerAsset({
        startDate: dayjs(startDate).format("YYYY-MM-DD"),
        endDate: dayjs(endDate).format("YYYY-MM-DD"),
      }),
    staleTime: 1000 * 60 * 60,
    ...options,
  });
};

export const useGetTotalMaintenanceCostPerMonth = (
  { startDate, endDate } = { startDate: null, endDate: null },
  options = {}
) => {
  return useQuery({
    queryKey: ["getTotalMaintenanceCostPerMonth", startDate, endDate],
    queryFn: () =>
      Agent.dashboard.getTotalMaintenanceCostPerMonth({
        startDate: dayjs(startDate).format("YYYY-MM-DD"),
        endDate: dayjs(endDate).format("YYYY-MM-DD"),
      }),
    staleTime: 1000 * 60 * 60,
    ...options,
  });
};

export const useGetRentIncomeExpensePerMonth = (
  { startDate, endDate } = { startDate: null, endDate: null },
  options = {}
) => {
  return useQuery({
    queryKey: ["getRentIncomeExpensePerMonth", startDate, endDate],
    queryFn: () =>
      Agent.dashboard.getRentIncomeExpensePerMonth({
        startDate: dayjs(startDate).format("YYYY-MM-DD"),
        endDate: dayjs(endDate).format("YYYY-MM-DD"),
      }),
    staleTime: 1000 * 60 * 60,
    ...options,
  });
};

export const useGetGeneralStats = (
  { startDate, endDate } = { startDate: null, endDate: null },
  options = {}
) => {
  return useQuery({
    queryKey: ["getGeneralStats", startDate, endDate],
    queryFn: () =>
      Agent.dashboard.getGeneralStats({
        startDate: dayjs(startDate).format("YYYY-MM-DD"),
        endDate: dayjs(endDate).format("YYYY-MM-DD"),
      }),
    staleTime: 1000 * 60 * 60,
    ...options,
  });
};

export const useGetTotalMaintenanceCostPerEquipmentType = (options = {}) => {
  return useQuery({
    queryKey: ["getTotalMaintenanceCostPerEquipmentType"],
    queryFn: () => Agent.dashboard.getTotalMaintenanceCostPerEquipmentType(),
    staleTime: 1000 * 60 * 10,
    ...options,
  });
};
