import { Box, Typography } from "@mui/material";
import EditButton from "components/buttons/EditButton";
import ReturnButton from "components/buttons/ReturnButton";
import SubHeader from "components/headers/SubHeader";
import SpinningCircle from "components/spinners/SpinningCircle";
import { useGetEquipmentById } from "hooks/queries/useEquipment";
import { useEffect, useState } from "react";
import {
  Outlet,
  useLocation,
  useNavigate,
  useOutletContext,
  useParams,
} from "react-router";
import colors from "config/theme/colors";

const EXTRA_MENU_TABS = [
  {
    id: 0,
    description: "Γενικές Πληροφορίες",
    to: "general",
  },
  {
    id: 1,
    description: "Συντηρήσεις",
    to: "maintenance",
  },
  {
    id: 2,
    description: "Πιστοποιήσεις",
    to: "certification",
  },
];

const EquipmentViewEditParent = () => {
  const [isEditing, setIsEditing] = useState(false);
  const [selectedTab, setSelectedTab] = useState(null);
  const { equipmentId: id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [isDeleted, setIsDeleted] = useState(false);
  const { selectedAsset } = useOutletContext();
  const {
    data: selectedEquipment,
    isLoading,
    isError,
    error,
  } = useGetEquipmentById(id, {
    enabled: !!id && !isDeleted,
  });

  useEffect(() => {
    if (
      selectedTab !== null &&
      selectedTab !== undefined &&
      !location.pathname.includes(EXTRA_MENU_TABS[selectedTab].to)
    ) {
      navigate(
        `/assets/${selectedAsset.id}/equipment/${id}/${EXTRA_MENU_TABS[selectedTab].to}`
      );
    }
  }, [selectedTab, navigate]);

  useEffect(() => {
    setSelectedTab(
      EXTRA_MENU_TABS.find((tab) => location.pathname.includes(tab.to))?.id || 0
    );
  }, [location.pathname, navigate]);

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          height: "100%",
        }}
      >
        <SpinningCircle />
      </Box>
    );
  }

  if (isError) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          height: "100%",
        }}
      >
        <p>Error: {error.message}</p>
      </Box>
    );
  }

  const handleEditClick = () => {
    setIsEditing(true);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={2}
      sx={{
        height: "100%",
      }}
    >
      <Box
        display="flex"
        justifyContent={{ xs: "center", sm: "space-between" }}
        alignItems="center"
        gap={2}
      >
        <Box
          display="flex"
          gap={2}
          alignItems="center"
          sx={{
            width: {
              xs: "100%",
              sm: "auto",
            },
          }}
        >
          <ReturnButton
            onClick={() => navigate(`/assets/${selectedAsset.id}/equipment`)}
          />
          <Typography fontSize={20} fontWeight={600} color={colors.primary}>
            {selectedEquipment?.name}
          </Typography>
        </Box>
      </Box>
      <Box
        display="flex"
        gap={2}
        justifyContent={{ xs: "center", sm: "space-between" }}
      >
        <SubHeader tabs={EXTRA_MENU_TABS} setSelectedTab={setSelectedTab} />
        {EXTRA_MENU_TABS.find((tab) => tab.id === selectedTab)?.to ===
          "general" && (
          <Box
            display="flex"
            gap={2}
            sx={{
              width: {
                xs: "100%",
                sm: "auto",
              },
            }}
          >
            <EditButton onClick={handleEditClick} disabled={isEditing} />
          </Box>
        )}
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        gap={2}
        flexGrow={1}
        width={"100%"}
      >
        <Outlet
          context={{
            selectedAsset,
            setIsDeleted,
            selectedEquipment,
            isEditing,
            setIsEditing,
          }}
        />
      </Box>
    </Box>
  );
};

export default EquipmentViewEditParent;
