import CustomTextField from "components/inputs/CustomTextField";
import SaveButton from "components/buttons/SaveButton";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { Box, Grid, Typography } from "@mui/material";
import { useChangePassword } from "hooks/queries/useAuth";
import { useAlert } from "hooks/useAlert";
import { passwordRegex } from "config/regex";
import CancelButton from "components/buttons/CancelButton";
import PropTypes from "prop-types";
import { useIsMutating } from "@tanstack/react-query";

const ChangePasswordForm = ({
  isChangingPassword,
  setIsChangingPassword,
  onCancel,
}) => {
  const { showSuccess, showError } = useAlert();

  const { mutate: changePassword } = useChangePassword();
  const isChangePasswordLoading =
    useIsMutating({ mutationKey: ["changePassword"] }) > 0;

  const onChangePassword = (data) => {
    changePassword(data, {
      onError: (error) => {
        showError(error?.message);
      },
      onSuccess: () => {
        setIsChangingPassword(false);
        showSuccess("Ο κωδικός πρόσβασης άλλαξε επιτυχώς");
      },
    });
  };

  const changePasswordSchema = yup.object().shape({
    currentPassword: yup.string().required("Το πεδίο είναι υποχρεωτικό"),
    newPassword: yup
      .string()
      .min(8, "Ο κωδικός πρέπει να είναι τουλάχιστον 8 χαρακτήρες")
      .max(32, "Ο κωδικός πρέπει να είναι το πολύ 32 χαρακτήρες")
      .matches(
        passwordRegex,
        "Ο κωδικός πρόσβασης πρέπει να περιέχει ένα μικρό γράμμα, ένα κεφαλαίο και έναν ειδικό χαρακτήρα"
      )
      .required("Το πεδίο είναι υποχρεωτικό"),
    confirmNewPassword: yup
      .string()
      .required("Το πεδίο είναι υποχρεωτικό")
      .oneOf([yup.ref("newPassword"), null], "Οι κωδικοί πρέπει να ταιριάζουν"),
  });

  const {
    control,
    handleSubmit,
    formState: { errors, isDirty },
    getValues,
    reset,
  } = useForm({ resolver: yupResolver(changePasswordSchema) });

  return (
    <form onSubmit={handleSubmit(() => onChangePassword(getValues()))}>
      <Box display="flex" flexDirection="column" gap={4}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <CustomTextField
              label="Τρέχων Κωδικός"
              name="currentPassword"
              type="text"
              sensitive
              control={control}
              disabled={!isChangingPassword}
              placeholder="Πληκτρολογήστε τον τρέχοντα κωδικό σας"
              required
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <CustomTextField
              label="Νέος Κωδικός"
              name="newPassword"
              type="text"
              sensitive
              control={control}
              disabled={!isChangingPassword}
              placeholder="Πληκτρολογήστε τον νέο κωδικό σας"
              required
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <CustomTextField
              label="Επιβεβαίωση Νέου Κωδικού"
              name="confirmNewPassword"
              type="text"
              sensitive
              control={control}
              disabled={!isChangingPassword}
              placeholder="Επιβεβαιώστε τον νέο κωδικό σας"
              required
            />
          </Grid>
        </Grid>

        {Object.keys(errors).length > 0 && (
          <Typography color="error" fontSize={14}>
            Παρακαλώ ελέγξτε ότι όλα τα πεδία έχουν συμπληρωθεί σωστά
          </Typography>
        )}

        <Box display="flex" alignItems="center" gap={2}>
          <CancelButton
            disabled={!isChangingPassword}
            onClick={() => {
              reset();
              onCancel();
            }}
          />

          <SaveButton
            disabled={!isChangingPassword || !isDirty}
            isLoading={isChangePasswordLoading}
          />
        </Box>
      </Box>
    </form>
  );
};

ChangePasswordForm.propTypes = {
  isChangingPassword: PropTypes.bool.isRequired,
  setIsChangingPassword: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default ChangePasswordForm;
