import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Agent } from "api/agent";

export const useGetAllMaintenances = (
  {
    pageNumber = -1,
    pageSize = -1,
    assetId,
    equipmentIds = [],
    maintenanceTypeIds = [],
    contractId,
  } = {
    pageNumber: -1,
    pageSize: -1,
    assetId: null,
    equipmentIds: [],
    maintenanceTypeIds: [],
    contractId: null,
  },
  options = {}
) => {
  return useQuery({
    queryKey: [
      "getAllMaintenances",
      pageNumber,
      pageSize,
      assetId,
      equipmentIds,
      maintenanceTypeIds,
      contractId,
    ],
    queryFn: () =>
      Agent.maintenance.getAll({
        pageNumber,
        pageSize,
        assetId,
        equipmentIds,
        maintenanceTypeIds,
        contractId,
      }),
    staleTime: 1000 * 60 * 60,
    ...options,
  });
};

export const useGetEquipmentMaintenancesByAssetId = (
  {
    pageNumber = -1,
    pageSize = -1,
    assetId,
    maintenanceTypeIds = [],
    equipmentTypeIds = [],
  } = {
    pageNumber: -1,
    pageSize: -1,
    assetId: null,
    maintenanceTypeIds: [],
    equipmentTypeIds: [],
  },
  options = {}
) => {
  return useQuery({
    queryKey: [
      "getEquipmentMaintenancesByAssetId",
      pageNumber,
      pageSize,
      assetId,
      maintenanceTypeIds,
      equipmentTypeIds,
    ],
    queryFn: () =>
      Agent.maintenance.getEquipmentMaintenancesByAssetId({
        pageNumber,
        pageSize,
        assetId,
        maintenanceTypeIds,
        equipmentTypeIds,
      }),
    staleTime: 1000 * 60 * 60,
    ...options,
  });
};

export const useGetMaintenanceById = (maintenanceId, options = {}) => {
  return useQuery({
    queryKey: ["getMaintenanceById", +maintenanceId],
    queryFn: () => Agent.maintenance.getById(maintenanceId),
    staleTime: 1000 * 60 * 10,
    ...options,
  });
};

export const useCreateMaintenance = ({ config = {} }) => {
  const queryClient = useQueryClient();
  const { onSuccess, ...restConfig } = config;

  return useMutation({
    mutationKey: ["createMaintenance"],
    mutationFn: (body) => Agent.maintenance.create(body),
    onSuccess: (...args) => {
      queryClient.invalidateQueries(["getAllMaintenances"]);
      onSuccess?.(...args);
    },
    ...restConfig,
  });
};

export const useAddMaintenanceInMultipleEquipments = ({ config = {} }) => {
  const queryClient = useQueryClient();
  const { onSuccess, ...restConfig } = config;

  return useMutation({
    mutationKey: ["addMaintenanceInMultipleEquipments"],
    mutationFn: (body) =>
      Agent.maintenance.addMaintenanceInMultipleEquipments(body),
    onSuccess: (...args) => {
      queryClient.invalidateQueries(["getAllMaintenances"]);
      onSuccess?.(...args);
    },
    ...restConfig,
  });
};

export const useUpdateMaintenance = ({ id, config = {} }) => {
  const queryClient = useQueryClient();
  const { onSuccess, ...restConfig } = config;

  return useMutation({
    mutationKey: ["updateMaintenance"],
    mutationFn: (body) => Agent.maintenance.update(id, body),
    onSuccess: (...args) => {
      queryClient.invalidateQueries(["getAllMaintenances"]);
      queryClient.invalidateQueries(["getMaintenanceById", +id]);
      onSuccess?.(...args);
    },
    ...restConfig,
  });
};

export const useDeleteMaintenance = ({ id, config = {} }) => {
  const queryClient = useQueryClient();
  const { onSuccess, ...restConfig } = config;

  return useMutation({
    mutationKey: ["deleteMaintenance"],
    mutationFn: () => Agent.maintenance.delete(id),
    onSuccess: (...args) => {
      queryClient.invalidateQueries(["getAllMaintenances"]);
      queryClient.invalidateQueries(["getMaintenanceById", +id]);
      onSuccess?.(...args);
    },
    ...restConfig,
  });
};
