import EquipmentCreateForm from "./EquipmentCreateForm";
import { useAlert } from "hooks/useAlert";
import { useCreateEquipment } from "hooks/queries/useEquipment";
import { useNavigate, useOutletContext } from "react-router";
import { Box, Typography } from "@mui/material";
import colors from "config/theme/colors";
import { useGetAllEquipmentClasses } from "hooks/queries/useEquipmentClass";
import SpinningCircle from "components/spinners/SpinningCircle";
import { useUploadFiles } from "hooks/queries/useFile";

const EquipmentCreate = () => {
  const { selectedAsset } = useOutletContext();
  const { showError, showSuccess, showWarning } = useAlert();
  const {
    data: equipmentClasses,
    isLoading: equipmentClassesLoading,
    isError: equipmentClassesError,
  } = useGetAllEquipmentClasses({
    pageNumber: null,
    pageSize: null,
  });
  const { mutate: uploadFiles } = useUploadFiles({
    fileableType: "Equipment",
    config: {
      onError: (error) => {
        showWarning(
          "Ο νέος εξοπλισμός δημιουργήθηκε, αλλά προέκυψε σφάλμα κατά την αποθήκευση των αρχείων. Δοκιμάστε ξανά."
        );
        navigate(`/assets/${selectedAsset.id}/equipment`);
        console.error(error);
      },
      onSuccess: () => {
        showSuccess("Ο νέος εξοπλισμός δημιουργήθηκε επιτυχώς!");
        navigate(`/assets/${selectedAsset.id}/equipment`);
      },
    },
  });
  const { mutate: createEquipment } = useCreateEquipment({
    config: {
      onError: (error) => {
        showError(error.message);
      },
      onSuccess: (res) => {
        showSuccess("Ο νέος εξοπλισμός δημιουργήθηκε επιτυχώς!");
        navigate(`/assets/${selectedAsset.id}/equipment/${res.result.id}`);
      },
    },
  });
  const navigate = useNavigate();

  const handleCreateClick = (data) => {
    const { supplyType } = data;

    const formData = {
      name: data.name,
      description: data.description,
      equipmentClassId: equipmentClasses?.items.find(
        (classItem) => classItem.name === data.equipmentClassId
      )?.id,
      floor: data.floor ? parseInt(data.floor) : null,
      isRented: supplyType === "Ενοικίαση",
      isAcquired: supplyType === "Αγορά",
      assetId: selectedAsset.id,
    };

    const payload = Object.keys(formData).reduce((acc, key) => {
      if (formData[key] !== undefined && formData[key] !== null) {
        acc[key] = formData[key];
      }
      return acc;
    }, {});

    const files = data.files;

    createEquipment(payload, {
      onSuccess: (equipment) => {
        if (files?.length > 0) {
          uploadFiles({ files, entityId: equipment?.result?.id });
        } else {
          showSuccess("Ο νέος εξοπλισμός δημιουργήθηκε επιτυχώς!");
          navigate(
            `/assets/${selectedAsset.id}/equipment/${equipment?.result?.id}`
          );
        }
      },
    });
  };

  if (equipmentClassesLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          height: "100%",
        }}
      >
        <SpinningCircle />
      </Box>
    );
  }

  if (equipmentClassesError) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          height: "100%",
        }}
      >
        <p>Error: {equipmentClassesError?.message}</p>
      </Box>
    );
  }

  return (
    <Box display={"flex"} flexDirection={"column"} width={"100%"} gap={2}>
      <Box
        display="flex"
        alignItems={"center"}
        justifyContent="flex-start"
        gap={1}
      >
        <Typography color={colors.primary} fontWeight={700} fontSize={24}>
          Νέος Εξοπλισμός
        </Typography>
        <Typography color={colors.text} fontWeight={500} fontSize={18}>
          Δημιουργία νέου εξοπλισμού
        </Typography>
      </Box>
      <EquipmentCreateForm onCreate={handleCreateClick} />
    </Box>
  );
};

export default EquipmentCreate;
