import { Box, Typography } from "@mui/material";
import { useNavigate, useOutletContext, useParams } from "react-router";
import { useState } from "react";
import SpinningCircle from "components/spinners/SpinningCircle";
import EditButton from "components/buttons/EditButton";
import { useAlert } from "hooks/useAlert";
import { useGetAllCertificationTypes } from "hooks/queries/useCertificationType";
import { useGetAllCompanies } from "hooks/queries/useCompanies";
import ReturnButton from "components/buttons/ReturnButton";
import colors from "config/theme/colors";
import CertificationViewEditForm from "./CertificationViewEditForm";
import {
  useDeleteCertification,
  useGetCertificationById,
  useUpdateCertification,
} from "hooks/queries/useCertifications";
import PropTypes from "prop-types";
import CertificationHistoryOverview from "pages/certificationHistory/Overview/CertificationHistoryOverview";
import { useDeleteMultipleFiles, useUploadFiles } from "hooks/queries/useFile";

const CertificationViewEdit = ({ facilityType = null }) => {
  const { certificationId } = useParams();
  const { selectedAsset, selectedEquipment } = useOutletContext();
  const { showError, showSuccess, showWarning } = useAlert();
  const navigate = useNavigate();
  const [isEditing, setIsEditing] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const {
    data: certificationTypes,
    isLoading: isLoadingCertificationTypes,
    isError: isErrorCertificationTypes,
  } = useGetAllCertificationTypes({
    facilityType,
  });
  const {
    data: companies,
    isLoading: isLoadingCompanies,
    isError: isErrorCompanies,
  } = useGetAllCompanies(
    {},
    {
      select: (data) => data?.result?.items,
    }
  );
  const {
    data: selectedCertification,
    isLoading,
    isError,
    error,
  } = useGetCertificationById(certificationId, {
    enabled: !!certificationId && !isDeleted,
  });
  const { mutate: deleteCertification } = useDeleteCertification({
    id: certificationId,
    config: {
      onError: (error) => {
        showError(error.message);
        setIsDeleted(false);
      },
      onSuccess: () => {
        showSuccess("Η πιστοποίηση διαγράφηκε με επιτυχία");
        navigate(-1, { replace: true });
      },
    },
  });
  const { mutate: updateCertification } = useUpdateCertification({
    id: certificationId,
    config: {
      onError: (error) => {
        showError(error.message);
      },
      onSuccess: () => {
        showSuccess("Η πιστοποίηση ενημερώθηκε με επιτυχία");
      },
    },
  });
  const { mutate: uploadFiles } = useUploadFiles({
    fileableId: selectedCertification?.id,
    fileableType: "Certification",
  });
  const { mutate: deleteMultipleFiles } = useDeleteMultipleFiles();

  if (isLoading || isLoadingCertificationTypes || isLoadingCompanies) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          height: "100%",
        }}
      >
        <SpinningCircle />
      </Box>
    );
  }

  if (isError || isErrorCertificationTypes || isErrorCompanies) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          height: "100%",
        }}
      >
        <p>Error: {error.message}</p>
      </Box>
    );
  }

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const extractCompanyName = (input) => {
    const regex = /(.*)\s*\([^)]*\)\s*$/;
    const match = input.match(regex);
    return match ? match[1].trim() : input.trim();
  };

  const handleUpdateWhenDeleteOnly = (payload, deletedFiles) => {
    deleteMultipleFiles(
      deletedFiles.map((file) => file.id),
      {
        onSuccess: () => {
          updateCertification(payload);
        },
        onError: (error) => {
          showWarning(
            "Υπήρξε πρόβλημα με τη διαγραφή των αρχείων. Παρακαλώ δοκιμάστε ξανά την ενημέρωση των αρχείων"
          );
          showError(error.message);
        },
      }
    );
  };

  const handleUpdateWhenUploadOnly = (payload, newFiles) => {
    uploadFiles(
      { files: newFiles },
      {
        onSuccess: () => {
          updateCertification(payload);
        },
        onError: (error) => {
          showWarning(
            "Υπήρξε πρόβλημα με το ανέβασμα των αρχείων. Παρακαλώ δοκιμάστε ξανά την ενημέρωση των αρχείων"
          );
          showError(error.message);
        },
      }
    );
  };

  const handleUpdateWhenDeleteAndUpload = (payload, deletedFiles, newFiles) => {
    deleteMultipleFiles(
      deletedFiles.map((file) => file.id),
      {
        onSuccess: () => {
          uploadFiles(
            { files: newFiles },
            {
              onSuccess: () => {
                updateCertification(payload);
              },
              onError: (error) => {
                showWarning(
                  "Υπήρξε πρόβλημα με το ανέβασμα των αρχείων. Παρακαλώ δοκιμάστε ξανά την ενημέρωση των αρχείων"
                );
                showError(error.message);
              },
            }
          );
        },
        onError: (error) => {
          showWarning(
            "Υπήρξε πρόβλημα με τη διαγραφή των αρχείων. Παρακαλώ δοκιμάστε ξανά την ενημέρωση των αρχείων"
          );
          showError(error.message);
        },
      }
    );
  };

  const handleSaveClick = (data) => {
    setIsEditing(false);
    const formData = {
      ...data,
      certificationTypeId: certificationTypes?.items.find(
        (type) => type.name === data.certificationTypeId
      )?.id,
      externalCompanyId: companies?.find(
        (company) => company.name === extractCompanyName(data.externalCompanyId)
      )?.id,
      assetId: facilityType === "asset" ? selectedAsset?.id : null,
      equipmentId: facilityType === "equipment" ? selectedEquipment?.id : null,
      frequency: data.frequency?.id || null,
    };

    const payload = Object.keys(formData).reduce((acc, key) => {
      if (formData[key] !== undefined && formData[key] !== null) {
        acc[key] = formData[key];
      }
      return acc;
    }, {});

    const newFiles = data.files?.filter((file) =>
      selectedCertification.files.every(
        (attachment) => attachment.id !== file.id
      )
    );

    const deletedFiles = selectedCertification.files?.filter(
      (attachment) => !data.files?.some((file) => file.id === attachment.id)
    );

    if (deletedFiles.length === 0 && newFiles.length === 0) {
      updateCertification(payload);
    } else if (deletedFiles.length > 0 && newFiles.length === 0) {
      handleUpdateWhenDeleteOnly(payload, deletedFiles);
    } else if (deletedFiles.length === 0 && newFiles.length > 0) {
      handleUpdateWhenUploadOnly(payload, newFiles);
    } else {
      handleUpdateWhenDeleteAndUpload(payload, deletedFiles, newFiles);
    }
  };

  const handleCancelClick = () => {
    setIsEditing(false);
  };

  const handleDeleteClick = () => {
    setIsDeleted(true);
    setIsEditing(false);
    deleteCertification();
  };

  return (
    selectedCertification && (
      <>
        <Box
          display="flex"
          alignItems={"center"}
          justifyContent="flex-start"
          gap={1}
        >
          <Typography color={colors.primary} fontWeight={700} fontSize={24}>
            Διαχείριση Πιστοποίησης
          </Typography>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          gap={2}
          sx={{
            height: "100%",
          }}
          mt={4}
        >
          <Box
            mb={2}
            display="flex"
            gap={2}
            justifyContent="space-between"
            alignItems={{
              xs: "flex-start",
              sm: "center",
            }}
            flexWrap={"wrap"}
          >
            <Box
              display="flex"
              gap={2}
              alignItems="center"
              sx={{
                width: {
                  xs: "100%",
                  sm: "auto",
                },
              }}
            >
              <ReturnButton />
              <Typography fontSize={20} fontWeight={600} color={colors.primary}>
                {selectedCertification?.name}
              </Typography>
            </Box>
            <Box
              display="flex"
              gap={2}
              sx={{
                width: {
                  xs: "100%",
                  sm: "auto",
                },
              }}
            >
              <EditButton onClick={handleEditClick} disabled={isEditing} />
            </Box>
          </Box>
          <Box
            display="flex"
            gap={2}
            justifyContent="space-between"
            sx={{
              width: "100%",
            }}
          >
            <CertificationViewEditForm
              selectedCertification={selectedCertification}
              isEditing={isEditing}
              onSave={handleSaveClick}
              onCancel={handleCancelClick}
              onDelete={handleDeleteClick}
              facilityType={facilityType}
            />
            <Box
              sx={{
                borderLeft: "2px solid",
                borderColor: colors.lightGrey,
                borderRadius: 6,
                opacity: "0.2",
                marginLeft: 2,
              }}
            />
            <CertificationHistoryOverview
              certificationId={parseInt(certificationId)}
            />
          </Box>
        </Box>
      </>
    )
  );
};

CertificationViewEdit.propTypes = {
  facilityType: PropTypes.oneOf(["equipment", "asset"]).isRequired,
};

export default CertificationViewEdit;
