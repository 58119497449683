import { useQuery } from "@tanstack/react-query";
import { Agent } from "api/agent";

const baseQueryConfig = {
  staleTime: Infinity,
  enabled: false,
  retry: false,
  refetchOnReconnect: false,
  refetchOnWindowFocus: false,
  refetchOnMount: false,
};

export const useGetReportMaintenanceOrganizations = (
  {
    dateFrom = "",
    dateTo = "",
    pageNumber = -1,
    pageSize = -1,
    organizationId = [],
  },
  options = {}
) => {
  return useQuery({
    queryKey: [
      "getReportMaintenanceOrganizations",
      dateFrom,
      dateTo,
      pageNumber,
      pageSize,
      organizationId?.join(","),
    ],
    queryFn: () =>
      Agent.companyReports.getMaintenanceOrganizations({
        dateFrom: dateFrom,
        dateTo: dateTo,
        pageNumber: pageNumber,
        pageSize: pageSize,
        organizationId: organizationId,
      }),
    staleTime: 1000 * 60 * 60,
    ...options,
  });
};

export const useGetReportMaintenanceOrganizationCosts = (
  { dateFrom = "", dateTo = "", pageNumber = -1, pageSize = -1 },
  options = {}
) => {
  return useQuery({
    queryKey: [
      "getReportMaintenanceOrganizationCosts",
      dateFrom,
      dateTo,
      pageNumber,
      pageSize,
    ],
    queryFn: () =>
      Agent.companyReports.getMaintenanceOrganizationCosts({
        dateFrom: dateFrom,
        dateTo: dateTo,
        pageNumber: pageNumber,
        pageSize: pageSize,
      }),
    staleTime: 1000 * 60 * 60,
    ...options,
  });
};

export const useExportMaintenanceOrganizationsToFile = ({
  exportType = "",
  organizationId = [],
  dateFrom = "",
  dateTo = "",
}) => {
  return useQuery({
    queryKey: [
      "exportMaintenanceOrganizations",
      exportType,
      organizationId?.join(","),
      dateFrom,
      dateTo,
    ],
    queryFn: () =>
      Agent.companyReports.exportMaintenanceOrganizations({
        exportType: exportType,
        organizationId: organizationId,
        dateFrom: dateFrom,
        dateTo: dateTo,
      }),
    ...baseQueryConfig,
  });
};

export const useExportMaintenanceOrganizationCostsToFile = ({
  exportType = "",
  dateFrom = "",
  dateTo = "",
}) => {
  return useQuery({
    queryKey: ["exportMaintenanceOrganizations", exportType, dateFrom, dateTo],
    queryFn: () =>
      Agent.companyReports.exportMaintenanceOrganizationsCostsToFile({
        exportType: exportType,
        dateFrom: dateFrom,
        dateTo: dateTo,
      }),
    ...baseQueryConfig,
  });
};
