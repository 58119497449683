import { Box, CircularProgress } from "@mui/material";
import colors from "config/theme/colors";
import PropTypes from "prop-types";

const SpinningCircle = ({ size = 50, color = colors.primary }) => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      sx={{
        height: "100%",
      }}
    >
      <CircularProgress size={size} style={{ color: color }} thickness={3} />
    </Box>
  );
};

SpinningCircle.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
};

export default SpinningCircle;
