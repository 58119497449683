import { requests } from "api/HttpClient";

const { post, get, put, delete: deleteRequest } = requests;

const equipmentGroup = {
  getAll: ({ pageNumber = -1, pageSize = -1, equipmentTypeId = null }) => {
    let url = "/EquipmentGroup/GetEquipmentGroupList";
    const queryParams = [];

    if (pageNumber && pageSize) {
      queryParams.push(`pageNumber=${pageNumber}`);
      queryParams.push(`pageSize=${pageSize}`);
    }
    if (equipmentTypeId) {
      queryParams.push(`equipmentTypeId=${equipmentTypeId}`);
    }

    if (queryParams.length > 0) {
      url += `?${queryParams.join("&")}`;
    }

    return get(url);
  },
  getById: (id) => get(`/EquipmentGroup/GetEquipmentGroupById/${id}`),
  create: (body) =>
    post("/EquipmentGroup/AddEquipmentGroup", body, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }),
  update: (id, body) =>
    put(`/EquipmentGroup/UpdateEquipmentGroup/${id}`, body, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }),
  delete: (id) => deleteRequest(`/EquipmentGroup/DeleteEquipmentGroup/${id}`),
};

export default equipmentGroup;
