import { useCallback, useEffect } from "react";
import { useRefreshToken } from "hooks/queries/useAuth";
import { observer } from "mobx-react-lite";
import { useAuthStore } from "stores/AuthStore";
import { useAlert } from "hooks/useAlert";
import { jwtDecode } from "jwt-decode";

const TokenExpirationChecker = () => {
  const authStore = useAuthStore();
  const { showError } = useAlert();

  const { mutate: refreshToken } = useRefreshToken();

  const decodeJwtToken = (token) => {
    return jwtDecode(token);
  };

  const onTokenExpiring = useCallback(() => {
    const data = {
      token: authStore.token,
      refreshToken: authStore.refreshToken,
    };

    refreshToken(data, {
      onError: (error) => {
        showError(error.message);
        authStore.logout();
      },
      onSuccess: (data) => {
        sessionStorage.setItem("token", JSON.stringify(data?.result));
        authStore.setIsUserAuthenticated(true);
      },
    });
  }, [authStore, refreshToken, showError]);

  useEffect(() => {
    const checkTokenExpiration = () => {
      const token =
        JSON.parse(sessionStorage.getItem("token"))?.token ||
        JSON.parse(localStorage.getItem("token"))?.token;
      if (!token) {
        authStore.logout();
        return;
      }
      const currentTime = Math.floor(Date.now() / 1000);
      const expTime = decodeJwtToken(token)?.exp;
      const timeLeft = expTime - currentTime;

      if (timeLeft <= 0) {
        onTokenExpiring();
      }
    };

    const intervalId = setInterval(checkTokenExpiration, 1000);

    return () => clearInterval(intervalId);
  }, [authStore, onTokenExpiring]);

  return null;
};

export default observer(TokenExpirationChecker);
