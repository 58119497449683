import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CustomButton from "components/buttons/CustomButton";
import CustomTable from "components/tables/CustomTable";
import { useNavigate } from "react-router";
import colors from "config/theme/colors";
import { ReactComponent as AddIcon } from "assets/icons/add.svg";
import { useGetAllRentsHistory } from "hooks/queries/useRentHistory";
import SpinningCircle from "components/spinners/SpinningCircle";
import { formatDateTime } from "utils/formatDateTime";
import usePagination from "hooks/usePagination";
import PropTypes from "prop-types";

const RentHistoryOverview = ({ rentId }) => {
  const { page, rowsPerPage, handleChangePage, handleChangeRowsPerPage } =
    usePagination();
  const navigate = useNavigate();

  const {
    data: rentsHistory,
    isLoading: isRentsHistoryLoading,
    isError: isErrorRentsHistory,
    error: errorRentsHistory,
  } = useGetAllRentsHistory({
    pageNumber: page,
    pageSize: rowsPerPage,
    rentId,
  });

  if (isRentsHistoryLoading) {
    return <SpinningCircle />;
  }

  if (isErrorRentsHistory) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          height: "100%",
        }}
      >
        <p>Error: {errorRentsHistory?.message}</p>
      </Box>
    );
  }

  const tableHeaders = [
    {
      id: "id",
      label: "Κωδικός",
      isSortable: false,
    },
    {
      id: "paymentDate",
      label: "Ημερομηνία Πληρωμής",
      isSortable: false,
    },
  ];

  const rentsHistoryCount = rentsHistory?.totalCount;
  const rentsHistoryList = rentsHistory?.items?.map((history) => ({
    ...history,
    id: history?.id || "-",
    paymentDate: formatDateTime(history?.paymentDate) || "-",
  }));

  const handleItemClick = (item) => {
    navigate(`history/${item.id}`, { state: { rentId } });
  };

  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginLeft={3}
        gap={13}
      >
        <Typography fontSize={20} fontWeight={600} color={colors.secondary}>
          Ιστορικό Ενοικιάσεων
        </Typography>

        <CustomButton
          icon={<AddIcon fill={colors.primary} width={20} height={20} />}
          title="Προσθήκη"
          orientation="row"
          fontSize={13}
          sx={{
            minHeight: 30,
            maxHeight: 30,
            minWidth: 160,
            maxWidth: 160,
            borderRadius: 3,
            paddingY: 2.3,
          }}
          onClick={() => {
            navigate("history/new", { state: { rentId } });
          }}
        />
      </Box>

      <Box marginLeft={3}>
        {rentsHistoryList?.length > 0 &&
        tableHeaders &&
        rentsHistoryCount > 0 ? (
          <CustomTable
            columns={tableHeaders}
            data={rentsHistoryList}
            fadeEffect
            onItemClick={handleItemClick}
            page={page - 1}
            rowsPerPage={rowsPerPage}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            totalResults={rentsHistoryCount}
          />
        ) : (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{
              height: "100%",
              marginTop: "18%",
            }}
          >
            <Typography variant="h4">Δεν υπάρχουν αποτελέσματα</Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

RentHistoryOverview.propTypes = {
  rentId: PropTypes.string.isRequired,
};

export default RentHistoryOverview;
