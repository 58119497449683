import { useNavigate, useOutletContext } from "react-router";
import { useAlert } from "hooks/useAlert";
import ContractCreateForm from "./ContractCreateForm";
import enums from "config/enums";
import { useCreateContract } from "hooks/queries/useContracts";
import dayjs from "dayjs";
import { Box, Typography } from "@mui/material";
import colors from "config/theme/colors";
import { useUploadFiles } from "hooks/queries/useFile";

const AssetContractCreate = () => {
  const navigate = useNavigate();
  const { selectedAsset } = useOutletContext();
  const { showError, showSuccess } = useAlert();
  const { mutate: uploadFiles } = useUploadFiles({
    fileableType: "Contract",
    config: {
      onError: (error) => {
        showError(error.message);
      },
      onSuccess: () => {
        showSuccess("Η σύμβαση δημιουργήθηκε με επιτυχία");
        navigate("/contracts");
      },
    },
  });
  const { mutate: createContract } = useCreateContract({
    config: {
      onError: (error) => {
        showError(error.message);
      },
    },
  });

  const handleCreateClick = (data) => {
    const payload = {
      name: data.name,
      number: data.number,
      description: data.description,
      validFrom: dayjs(data.validFrom).format("YYYY-MM-DD"),
      validTo: dayjs(data.validTo).format("YYYY-MM-DD"),
      type: enums.ContractTypes?.find((type) => type.label === data.type)?.id,
      status: data.status,
      externalCompanyId: data.externalCompanyId,
    };

    const files = data.files;

    createContract(payload, {
      onSuccess: (contract) => {
        if (files?.length > 0) {
          uploadFiles({ files, entityId: contract?.result?.id });
        } else {
          showSuccess("Η σύμβαση δημιουργήθηκε με επιτυχία");
          navigate(`/assets/${selectedAsset.id}/contracts`);
        }
      },
    });
  };

  return (
    <Box display={"flex"} flexDirection={"column"} width={"100%"} gap={2}>
      <Box
        display="flex"
        alignItems={"center"}
        justifyContent="flex-start"
        gap={1}
      >
        <Typography color={colors.primary} fontWeight={700} fontSize={24}>
          Νέα Σύμβαση
        </Typography>
        <Typography color={colors.text} fontWeight={500} fontSize={18}>
          Δημιουργία νέας σύμβασης
        </Typography>
      </Box>
      <ContractCreateForm onCreate={handleCreateClick} />
    </Box>
  );
};

export default AssetContractCreate;
