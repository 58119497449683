import { Alert, AlertTitle } from "@mui/material";

export const success = (message, onClose = null) => {
  return (
    <Alert severity="success" onClose={onClose} variant="filled">
      <AlertTitle>Επιτυχία</AlertTitle>
      {message}
    </Alert>
  );
};

export const error = (message, onClose = null) => {
  return (
    <Alert severity="error" onClose={onClose} variant="filled">
      <AlertTitle>Σφάλμα</AlertTitle>
      {message}
    </Alert>
  );
};

export const warning = (message, onClose = null) => {
  return (
    <Alert severity="warning" onClose={onClose} variant="filled">
      <AlertTitle>Προειδοποίηση</AlertTitle>
      {message}
    </Alert>
  );
};
