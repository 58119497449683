import React, { useState } from "react";
import { Tooltip, IconButton } from "@mui/material";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import PropTypes from "prop-types";
import colors from "config/theme/colors";

const CopyButton = ({ textToCopy, tooltipSx = {}, buttonSx = {} }) => {
  const [tooltipTitle, setTooltipTitle] = useState("Αντιγραφή");

  const handleCopyClick = (e) => {
    e.stopPropagation();
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        setTooltipTitle("Αντιγράφηκε!");
      })
      .catch((err) => {
        console.error("Σφάλμα κατά την αντιγραφή: ", err);
      });
  };

  return (
    <Tooltip
      title={tooltipTitle}
      arrow
      sx={{ fontSize: 12, ...tooltipSx }}
      onMouseOut={() => setTooltipTitle("Αντιγραφή")}
    >
      <IconButton
        sx={{ color: colors.grey, ...buttonSx }}
        onClick={handleCopyClick}
      >
        <ContentCopyOutlinedIcon sx={{ width: 16, height: 16 }} />
      </IconButton>
    </Tooltip>
  );
};

CopyButton.propTypes = {
  textToCopy: PropTypes.string.isRequired,
};

export default CopyButton;
