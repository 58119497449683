import MainPageLayout from "components/main/MainPageLayout";
import { useNavigate } from "react-router";
import { useAlert } from "hooks/useAlert";
import ContractCreateForm from "./ContractCreateForm";
import enums from "config/enums";
import { useCreateContract } from "hooks/queries/useContracts";
import dayjs from "dayjs";
import { useUploadFiles } from "hooks/queries/useFile";

const ContractCreate = () => {
  const navigate = useNavigate();
  const { showError, showSuccess, showWarning } = useAlert();
  const { mutate: uploadFiles } = useUploadFiles({
    fileableType: "Contract",
    config: {
      onError: (error) => {
        showWarning(
          "Η σύμβαση δημιουργήθηκε, αλλά προέκυψε σφάλμα κατά την αποθήκευση των αρχείων. Δοκιμάστε ξανά."
        );
        navigate("/contracts");
        console.error(error);
      },
      onSuccess: () => {
        showSuccess("Η σύμβαση δημιουργήθηκε με επιτυχία");
        navigate("/contracts");
      },
    },
  });
  const { mutate: createContract } = useCreateContract({
    config: {
      onError: (error) => {
        showError(error.message);
      },
    },
  });

  const handleCreateClick = (data) => {
    const payload = {
      name: data.name,
      number: data.number,
      description: data.description,
      validFrom: dayjs(data.validFrom).format("YYYY-MM-DD"),
      validTo: dayjs(data.validTo).format("YYYY-MM-DD"),
      type: enums.ContractTypes?.find((type) => type.label === data.type)?.id,
      status: data.status?.id,
      externalCompanyId: data.externalCompanyId,
    };

    const files = data.files;

    createContract(payload, {
      onSuccess: (contract) => {
        if (files?.length > 0) {
          uploadFiles({ files, entityId: contract?.result?.id });
        } else {
          showSuccess("Η σύμβαση δημιουργήθηκε με επιτυχία");
          navigate("/contracts");
        }
      },
    });
  };

  return (
    <MainPageLayout
      pageTitle="Νέα Σύμβαση"
      pageSubTitle="Δημιουργία νέας σύμβασης"
    >
      <ContractCreateForm onCreate={handleCreateClick} />
    </MainPageLayout>
  );
};

export default ContractCreate;
