import { emailRegex, passwordRegex, phoneRegex } from "config/regex";

const userRequestFields = [
  {
    label: "Username",
    name: "username",
    type: "text",
    required: true,
  },
  {
    label: "Email",
    name: "email",
    type: "text",
    required: true,
    validation: "custom",
    regex: emailRegex,
    validationMessage: "Παρακαλώ εισάγετε μια έγκυρη διεύθυνση email",
  },
  {
    label: "Κωδικός πρόσβασης",
    name: "password",
    type: "text",
    required: true,
    sensitive: true,
    validation: "custom",
    regex: passwordRegex,
    validationMessage:
      "Ο κωδικός πρόσβασης πρέπει να περιέχει ένα μικρό γράμμα, ένα κεφαλαίο και έναν ειδικό χαρακτήρα",
  },
  {
    label: "Ρόλος",
    name: "role",
    type: "select",
    required: true,
    options: [],
  },
  {
    label: "Όνομα",
    name: "name",
    type: "text",
    required: true,
  },
  {
    label: "Επώνυμο",
    name: "surname",
    type: "text",
    required: true,
  },
  {
    label: "Τηλέφωνο",
    name: "phoneNumber",
    type: "text",
    required: true,
    validation: "custom",
    regex: phoneRegex,
    validationMessage: "Ο αριθμός τηλεφώνου που καταχωρήσατε δεν είναι έγκυρος",
  },
];

export default userRequestFields;
