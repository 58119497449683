import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Agent } from "api/agent";

export const useGetFileById = (id, options = {}) => {
  return useQuery({
    queryKey: ["getFileById", id],
    queryFn: () => Agent.file.getById(id),
    staleTime: 1000 * 60 * 10,
    ...options,
  });
};

export const useUploadFiles = ({ fileableId, fileableType, config = {} }) => {
  const queryClient = useQueryClient();
  const { onSuccess, ...restConfig } = config;

  return useMutation({
    mutationKey: ["uploadFiles"],
    mutationFn: async ({ files, entityId = null }) => {
      const id = entityId ?? fileableId;
      const uploadPromises = files?.map((file) => {
        const groupTag = file.groupTag ?? null;

        return Agent.file.upload({
          fileableId: id,
          fileableType,
          file,
          groupTag,
        });
      });
      return Promise.all(uploadPromises);
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries(["getFileById", fileableId]);
      onSuccess?.(data);
    },
    ...restConfig,
  });
};

export const useDeleteMultipleFiles = ({ config = {} } = {}) => {
  const queryClient = useQueryClient();
  const { onSuccess, ...restConfig } = config;

  return useMutation({
    mutationKey: ["deleteMultipleFiles"],
    mutationFn: async (fileIds) => {
      const deletePromises = fileIds?.map((id) => Agent.file.delete(id));
      return Promise.all(deletePromises);
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries(["getFileById"]);
      onSuccess?.(data);
    },
    ...restConfig,
  });
};

export const useDeleteFile = ({ config = {} }) => {
  const queryClient = useQueryClient();
  const { onSuccess, ...restConfig } = config;

  return useMutation({
    mutationKey: ["deleteFile"],
    mutationFn: async (id) => {
      return Agent.file.delete(id);
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries(["getFileById"]);
      onSuccess?.(data);
    },
    ...restConfig,
  });
};

export const useGetThumbnail = (id, options = {}) => {
  return useQuery({
    queryKey: ["getThumbnail", id],
    queryFn: () => Agent.file.getThumbnail(id),
    staleTime: 1000 * 60 * 10,
    retry: 0,
    refetchOnWindowFocus: false,
    ...options,
  });
};

export const useDownloadFile = ({ config = {} } = {}) => {
  const { onSuccess, ...restConfig } = config;

  return useMutation({
    mutationKey: ["downloadFile"],
    mutationFn: async (id) => {
      return Agent.file.download(id);
    },
    onSuccess: (data) => {
      onSuccess?.(data);
    },
    ...restConfig,
  });
};
