import { requests } from "api/HttpClient";

const { post, get, put, delete: deleteRequest } = requests;

const config = {
  headers: {
    "Content-Type": "multipart/form-data",
  },
};

const rents = {
  getAll: ({ pageNumber = -1, pageSize = -1, assetId = null }) => {
    let url = "/Rent/GetRentList";
    if (pageNumber && pageSize && assetId) {
      url += `?pageNumber=${pageNumber}&pageSize=${pageSize}&assetId=${assetId}`;
    }
    if (pageNumber && pageSize && !assetId) {
      url += `?pageNumber=${pageNumber}&pageSize=${pageSize}`;
    }
    return get(url);
  },
  getById: (id) => get(`/Rent/GetRentById/${id}`),
  create: (data) => post("/Rent/CreateRent", data, config),
  update: (id, data) => put(`/Rent/UpdateRent/${id}`, data, config),
  delete: (id) => deleteRequest(`/Rent/DeleteRent/${id}`),
};

export default rents;
