import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import DataGridTable from "components/tables/DataGridTable";
import { useGetAllEquipment } from "hooks/queries/useEquipment";
import PropTypes from "prop-types";
import SpinningCircle from "components/spinners/SpinningCircle";
import { useMemo, useState } from "react";
import colors from "config/theme/colors";

const EquipmentsSelectionTable = ({
  selectedEquipmentType,
  assetId,
  selectedEquipments,
  setSelectedEquipments,
}) => {
  const [rowSelectionModel, setRowSelectionModel] = useState(
    selectedEquipments?.map((equipment) => equipment?.id)
  );

  const {
    data: equipments,
    isLoading: equipmentsLoading,
    isError: equipmentsError,
  } = useGetAllEquipment(
    {
      equipmentTypeIds: [selectedEquipmentType?.id],
      assetId: assetId,
    },
    {
      select: (data) => data?.result?.items,
    }
  );

  const equipmentList = equipments?.map((equipmentΙtem) => {
    return {
      id: equipmentΙtem?.id || "",
      name: equipmentΙtem?.name || "",
      type: equipmentΙtem?.equipmentClass?.equipmentGroup?.equipmentType?.name,
      group: equipmentΙtem?.equipmentClass?.equipmentGroup?.name,
      code: equipmentΙtem?.id || "",
      class: equipmentΙtem?.equipmentClass?.name || "",
      classId: equipmentΙtem?.equipmentClassId || "",
      typeId: equipmentΙtem?.equipmentClass?.equipmentGroup?.equipmentType?.id,
      floor: equipmentΙtem?.floor ?? "",
    };
  });

  const columns = useMemo(
    () => [
      {
        field: "code",
        headerName: "Κωδικός",
      },
      {
        field: "name",
        headerName: "Όνομα",
      },
      {
        field: "type",
        headerName: "Τύπος",
      },
      {
        field: "group",
        headerName: "Ομάδα",
      },
      {
        field: "class",
        headerName: "Κλάση",
      },
      {
        field: "floor",
        headerName: "Όροφος",
      },
    ],
    []
  );

  const handleRowSelectionModelChange = (newSelection) => {
    const selectedRows = newSelection?.map((id) =>
      equipments?.find((equipment) => equipment?.id === id)
    );
    setSelectedEquipments(selectedRows);
    setRowSelectionModel(newSelection);
  };

  if (equipmentsLoading) {
    return <SpinningCircle />;
  }

  if (equipmentsError) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          height: "100%",
        }}
      >
        <p>Error: {equipmentsError?.message}</p>
      </Box>
    );
  }

  return (
    <Box mt={2}>
      <Typography color={colors.primary} fontWeight={700} fontSize={21} mb={2}>
        Επιλέξτε τον εξοπλισμό
      </Typography>

      {equipmentList.length === 0 ? (
        <Typography fontWeight={500} fontSize={16} mb={2}>
          Δεν υπάρχει διαθέσιμος εξοπλισμός με τα κριτήρια που επιλέξατε.
        </Typography>
      ) : (
        <Box>
          <DataGridTable
            columns={columns}
            rows={equipmentList}
            checkboxSelection
            onRowSelectionModelChange={handleRowSelectionModelChange}
            rowSelectionModel={rowSelectionModel}
            sx={{
              width: "100%",
              marginTop: 3,
              padding: 0.5,
              maxHeight: "30vh",
              height: "100%",
              overflow: "auto",
            }}
          />
        </Box>
      )}
    </Box>
  );
};

EquipmentsSelectionTable.propTypes = {
  selectedEquipmentType: PropTypes.object,
  assetId: PropTypes.number,
  selectedEquipments: PropTypes.array,
  setSelectedEquipments: PropTypes.func,
};

export default EquipmentsSelectionTable;
