import { requests } from "api/HttpClient";

const { post, get, put, delete: deleteRequest } = requests;

const config = {
  headers: {
    "Content-Type": "multipart/form-data",
  },
};

const certifications = {
  getAllCertifications: ({
    pageNumber = -1,
    pageSize = -1,
    assetId,
    equipmentId,
    facilityType,
    certificationTypeIds,
  }) => {
    let url = "/Certification/GetCertificationsList";

    const queryParams = [];

    if (pageNumber && pageSize) {
      queryParams.push(`pageNumber=${pageNumber}`);
      queryParams.push(`pageSize=${pageSize}`);
    }

    if (facilityType) {
      queryParams.push(`facilityType=${facilityType}`);
    }

    if (assetId) {
      queryParams.push(`assetId=${assetId}`);
    }

    if (equipmentId) {
      queryParams.push(`equipmentId=${equipmentId}`);
    }

    if (certificationTypeIds) {
      certificationTypeIds.forEach((type) => {
        queryParams.push(`CertificationTypeIds=${encodeURIComponent(type)}`);
      });
    }

    if (queryParams.length > 0) {
      url += `?${queryParams.join("&")}`;
    }

    return get(url);
  },
  getCertificationById: (id) => {
    let url = `/Certification/GetCertificationById/${id}`;
    return get(url);
  },
  createCertification: (certification) =>
    post("/Certification/CreateCertification", certification, config),
  updateCertification: (id, data) =>
    put(`/Certification/UpdateCertification/${id}`, data, config),
  deleteCertification: (id) =>
    deleteRequest(`/Certification/DeleteCertification/${id}`),
};

export default certifications;
