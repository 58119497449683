import { useForm } from "react-hook-form";
import PropTypes from "prop-types";
import DeleteButton from "components/buttons/DeleteButton";
import { Box, Grid, Typography } from "@mui/material";
import CancelButton from "components/buttons/CancelButton";
import SaveButton from "components/buttons/SaveButton";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import CustomTextField from "components/inputs/CustomTextField";
import colors from "config/theme/colors";
import DropdownField from "components/inputs/DropdownField";
import { useGetAllAssetCategories } from "hooks/queries/useAssetCategories";
import SpinningCircle from "components/spinners/SpinningCircle";
import { useEffect, useState } from "react";
import { useIsMutating } from "@tanstack/react-query";
import DeleteModal from "components/modals/DeleteModal";
import { useGetAllCertificationTypes } from "hooks/queries/useCertificationType";

const EquipmentTypeForm = ({
  isCreateForm = false,
  selectedEquipmentType,
  isEditing = false,
  onSave,
  onCancel,
  onDelete,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const {
    data: assetCategories,
    isLoading: assetCategoriesLoading,
    error: assetCategoriesError,
  } = useGetAllAssetCategories(null, null, {
    select: (data) => data?.result?.items,
  });
  const {
    data: certificationTypes,
    isLoading: certificationTypesLoading,
    isError: certificationTypesError,
  } = useGetAllCertificationTypes({
    facilityType: "Equipment",
  });

  const isCreatingLoading =
    useIsMutating({
      mutationKey: ["createEquipmentType"],
    }) > 0;
  const isUpdateLoading =
    useIsMutating({
      mutationKey: ["updateEquipmentType"],
    }) > 0;
  const isDeleteLoading =
    useIsMutating({
      mutationKey: ["deleteEquipmentType"],
    }) > 0;

  const schema = yup.object({
    name: yup.string().required("Το πεδίο είναι υποχρεωτικό"),
    description: yup.string().nullable(),
    assetCategoryIds: yup
      .array()
      .of(yup.object().shape({ id: yup.number(), label: yup.string() }))
      .min(1, "Το πεδίο είναι υποχρεωτικό"),
    certificationTypeId: yup
      .object()
      .shape({
        id: yup.string(),
        label: yup.string(),
      })
      .nullable(),
  });

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isDirty },
    getValues,
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    const defaultValues = selectedEquipmentType
      ? {
          name: selectedEquipmentType.name || "",
          description: selectedEquipmentType.description || "",
          assetCategoryIds: selectedEquipmentType?.assetCategories || [],
          certificationTypeId: {
            id: selectedEquipmentType.certificationType?.id,
            label: selectedEquipmentType.certificationType?.name,
          },
        }
      : {
          name: "",
          description: "",
          assetCategoryIds: [],
          certificationTypeId: null,
        };
    reset(defaultValues);
  }, [selectedEquipmentType, certificationTypes, reset]);

  if (assetCategoriesLoading || certificationTypesLoading) {
    return <SpinningCircle />;
  }

  if (assetCategoriesError || certificationTypesError) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          height: "100%",
        }}
      >
        <p>
          Error:{" "}
          {assetCategoriesError.message || certificationTypesError.message}
        </p>
      </Box>
    );
  }

  const handleFormSubmit = (data) => {
    onSave(data);
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const disabled = !isEditing && !isCreateForm;

  return (
    <form onSubmit={handleSubmit(() => handleFormSubmit(getValues()))}>
      <Box display="flex" flexDirection="column" gap={4}>
        <Typography fontSize={24} fontWeight={600} color={colors.secondary}>
          {isCreateForm
            ? "Δημιουργία Τύπου"
            : `${selectedEquipmentType?.name} - ${isEditing ? "Επεξεργασία" : "Προβολή"}`}
        </Typography>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <CustomTextField
              name="name"
              label="Όνομα"
              type="text"
              control={control}
              disabled={disabled}
              required
            />
          </Grid>

          <Grid item xs={12}>
            <CustomTextField
              name="description"
              label="Περιγραφή"
              type="textarea"
              control={control}
              disabled={disabled}
            />
          </Grid>

          <Grid item xs={12}>
            <DropdownField
              name="assetCategoryIds"
              label="Κατηγορίες Ακινήτων"
              control={control}
              disabled={disabled}
              editable={isCreateForm}
              multiple
              required
              options={assetCategories?.map((category) => ({
                id: category.id,
                label: category.description,
              }))}
            />
          </Grid>

          <Grid item xs={12}>
            <DropdownField
              name="certificationTypeId"
              label="Τύπος Πιστοποίησης"
              control={control}
              disabled={disabled}
              editable={isCreateForm}
              options={certificationTypes?.items?.map((type) => ({
                id: type.id,
                label: type.name,
              }))}
            />
          </Grid>
        </Grid>

        {Object.keys(errors).length > 0 && (
          <Typography color="error" fontSize={14}>
            Παρακαλώ ελέγξτε ότι όλα τα πεδία έχουν συμπληρωθεί σωστά
          </Typography>
        )}

        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          gap={2}
        >
          <Box display="flex" gap={2} justifyContent={"flex-start"}>
            {isCreateForm && isDirty && (
              <CancelButton
                onClick={() => {
                  reset();
                  onCancel();
                }}
                disabled={disabled}
              />
            )}
            {!isCreateForm && (
              <CancelButton
                onClick={() => {
                  reset();
                  onCancel();
                }}
              />
            )}
            {!isCreateForm && isEditing && (
              <DeleteButton
                onClick={handleOpenModal}
                text="Διαγραφή Τύπου"
                disabled={disabled}
                isLoading={isDeleteLoading}
              />
            )}
          </Box>

          {(isEditing || isCreateForm) && (
            <SaveButton
              disabled={disabled || !isDirty}
              text={isCreateForm ? "Δημιουργία" : "Αποθήκευση"}
              isLoading={isUpdateLoading || isCreatingLoading}
            />
          )}

          {isEditing && (
            <DeleteModal
              isOpen={isModalOpen}
              onClose={handleCloseModal}
              onDelete={onDelete}
              itemTitle={selectedEquipmentType?.name}
              title="Πρόκειται να διαγράψετε τον τύπο"
              description="Σιγουρευτείτε ότι ο συγκεκριμένος τύπος δεν χρησιμοποιείται σε κάποια ομάδα εξοπλισμού. Είστε βέβαιοι ότι θέλετε να προχωρήσετε στη διαγραφή του τύπου;"
            />
          )}
        </Box>
      </Box>
    </form>
  );
};

EquipmentTypeForm.propTypes = {
  isCreateForm: PropTypes.bool,
  selectedEquipmentType: PropTypes.object,
  isEditing: PropTypes.bool,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  onDelete: PropTypes.func,
};

export default EquipmentTypeForm;
