import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {
  useAddMaintenanceInMultipleEquipments,
  useGetAllMaintenances,
} from "hooks/queries/useMaintenances";
import PropTypes from "prop-types";
import SpinningCircle from "components/spinners/SpinningCircle";
import colors from "config/theme/colors";
import { useEffect, useState } from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import CustomButton from "components/buttons/CustomButton";
import CustomTextField from "components/inputs/CustomTextField";
import DropdownField from "components/inputs/DropdownField";
import { Grid } from "@mui/material";
import { useGetAllMaintenanceTypes } from "hooks/queries/useMaintenanceType";
import SaveButton from "components/buttons/SaveButton";
import { useAlert } from "hooks/useAlert";
import { useIsMutating } from "@tanstack/react-query";
import dayjs from "dayjs";

const maintenanceSchema = yup.object().shape({
  name: yup.string().required("Το πεδίο είναι υποχρεωτικό"),
  frequency: yup
    .number()
    .required("Το πεδίο είναι υποχρεωτικό")
    .typeError("Η συχνότητα πρέπει να είναι αριθμός"),
  maintenanceTypeId: yup.string().required("Το πεδίο είναι υποχρεωτικό"),
});

const EquipmentsMaintenancesCheck = ({
  equipmentsIds,
  contractId,
  setDisableNextButton,
  selectedEquipmentType,
}) => {
  const { showError, showSuccess } = useAlert();
  const [showAddMaintenancesForm, setShowAddMaintenancesForm] = useState(false);
  const [allEquipmentsHaveMaintenances, setAllEquipmentsHaveMaintenances] =
    useState(false);
  const [
    equipmentIdsWithActiveMaintenances,
    setEquipmentIdsWithActiveMaintenances,
  ] = useState([]);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [isMutationSuccess, setIsMutationSuccess] = useState(false);
  const [buttonSelected, setButtonSelected] = useState(null);

  const {
    data: maintenances,
    isLoading: isMaintenancesLoading,
    error: errorMaintenances,
    isSuccess,
  } = useGetAllMaintenances(
    {
      equipmentIds: equipmentsIds,
      contractId: contractId,
    },
    {
      select: (data) => data?.result?.items,
    }
  );

  useEffect(() => {
    if (!isSuccess) {
      setDisableNextButton(true);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (maintenances) {
      checkActiveMaintenances();
    }
  }, [maintenances]);

  const {
    data: maintenanceTypes,
    isLoading: isMaintenanceTypesLoading,
    error: errorMaintenanceTypes,
  } = useGetAllMaintenanceTypes(
    {
      equipmentTypeIds: [selectedEquipmentType?.id],
    },
    {
      select: (data) => data.result?.items || [],
    }
  );

  const isMaintenancesCreateLoading =
    useIsMutating({ mutationKey: ["addMaintenanceInMultipleEquipments"] }) > 0;

  const { mutate: addMaintenanceInMultipleEquipments } =
    useAddMaintenanceInMultipleEquipments({
      config: {
        onError: (error) => {
          showError(error.message);
          setIsMutationSuccess(false);
        },
        onSuccess: () => {
          showSuccess("Οι συντηρήσεις προστέθηκαν με επιτυχία");
          setIsMutationSuccess(true);
        },
      },
    });

  const availableTypesOptions = maintenanceTypes?.map((type) => type.name);
  const hasActiveMaintenances = maintenances?.some(
    (maintenance) => maintenance?.status === "Active"
  );

  const checkActiveMaintenances = () => {
    if (maintenances) {
      const equipmentIdsWithActiveMaintenances = maintenances
        ?.filter((maintenance) => maintenance?.status === "Active")
        .sort((a, b) => dayjs(b?.lastDate) - dayjs(a?.lastDate))
        .reduce((acc, maintenance) => {
          if (!acc.includes(maintenance?.equipmentId)) {
            acc.push(maintenance?.equipmentId);
          }
          return acc;
        }, []);
      setEquipmentIdsWithActiveMaintenances(equipmentIdsWithActiveMaintenances);

      const allHaveMaintenances = equipmentsIds?.every((id) =>
        equipmentIdsWithActiveMaintenances?.includes(id)
      );
      setAllEquipmentsHaveMaintenances(allHaveMaintenances);
      setDisableNextButton(!allHaveMaintenances);
    }
  };

  const handleYesClick = () => {
    setButtonSelected("yes");
    setShowAddMaintenancesForm(true);
    setDisableNextButton(true);
    reset();
  };

  const handleNoClick = () => {
    setButtonSelected("no");
    setShowAddMaintenancesForm(false);
    reset();
    if (hasActiveMaintenances) {
      setDisableNextButton(false);
    }
  };

  const onAddMaintenances = (data) => {
    const payload = {
      name: data.name,
      frequency: data.frequency,
      maintenanceTypeId:
        maintenanceTypes?.find((type) => type.name === data.maintenanceTypeId)
          ?.id || null,
      equipmentIds: equipmentsIds?.filter(
        (id) => !equipmentIdsWithActiveMaintenances?.includes(id)
      ),
      contractId: contractId,
    };
    addMaintenanceInMultipleEquipments(payload);
    setShowAddMaintenancesForm(false);
    setFormSubmitted(true);
    setDisableNextButton(false);
  };

  const {
    control,
    handleSubmit,
    formState: { isDirty },
    reset,
  } = useForm({
    resolver: yupResolver(maintenanceSchema),
  });

  if (isMaintenancesLoading || isMaintenanceTypesLoading) {
    return <SpinningCircle />;
  }

  if (errorMaintenances || errorMaintenanceTypes) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          height: "100%",
        }}
      >
        <p>
          Error: {errorMaintenances?.message || errorMaintenanceTypes?.message}
        </p>
      </Box>
    );
  }

  return (
    <Box mt={2}>
      {formSubmitted && isMutationSuccess ? (
        <>
          <Typography
            color={colors.success}
            fontWeight={500}
            fontSize={16}
            mb={2}
          >
            Οι νέες συντηρήσεις προστέθηκαν με επιτυχία!
          </Typography>
          <Typography color={colors.text} fontWeight={500} fontSize={16}>
            Παρακαλώ συνεχίστε στο τελευταίο βήμα για να ολοκληρώσετε την
            καταχώρηση νέου ιστορικού στις συντηρήσεις.
          </Typography>
        </>
      ) : allEquipmentsHaveMaintenances ? (
        <>
          <Typography
            color={colors.success}
            fontWeight={500}
            fontSize={16}
            mb={2}
          >
            Βρέθηκαν ενεργές συντηρήσεις για όλους τους επιλεγμένους
            εξοπλισμούς.
          </Typography>
          <Typography color={colors.text} fontWeight={500} fontSize={16}>
            Παρακαλώ συνεχίστε στο τελευταίο βήμα για να ολοκληρώσετε την
            καταχώρηση νέου ιστορικού στις συντηρήσεις.
          </Typography>
        </>
      ) : (
        <>
          <Typography color={colors.error} fontWeight={700} fontSize={20}>
            Προσοχή!
          </Typography>
          <Typography
            color={colors.error}
            fontWeight={500}
            fontSize={16}
            mb={2}
          >
            Δεν βρέθηκαν ενεργές συντηρήσεις για ορισμένους ή όλους τους
            επιλεγμένους εξοπλισμούς.
          </Typography>
          <Typography color={colors.text} fontWeight={500} fontSize={16}>
            Θέλετε να προσθέσετε νέες συντηρήσεις για όσους εξοπλισμούς δεν
            έχουν;
          </Typography>

          {!hasActiveMaintenances && buttonSelected === "no" && (
            <Typography
              mt={2}
              color={colors.warning}
              fontWeight={500}
              fontSize={16}
            >
              Δεν μπορείτε να προχωρήσετε στο επόμενο βήμα καθώς δεν βρέθηκαν
              ενεργές συντηρήσεις
            </Typography>
          )}
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            gap={2}
            mt={2}
          >
            <CustomButton
              title="Ναι"
              orientation="row"
              fontSize={14}
              fontWeight={600}
              sx={{
                minWidth: 120,
                maxHeight: 20,
                paddingY: 3,
                borderRadius: 3,
                opacity: buttonSelected === "yes" ? 1 : 0.5,
                "&:hover": { opacity: 1 },
              }}
              onClick={handleYesClick}
            />
            <CustomButton
              title="Όχι"
              orientation="row"
              fontSize={14}
              fontWeight={600}
              sx={{
                minWidth: 120,
                maxHeight: 20,
                paddingY: 3,
                borderRadius: 3,
                opacity: buttonSelected === "no" ? 1 : 0.5,
                "&:hover": { opacity: 1 },
              }}
              onClick={handleNoClick}
            />
          </Box>
        </>
      )}

      {showAddMaintenancesForm && (
        <form onSubmit={handleSubmit(onAddMaintenances)}>
          <Box display="flex" flexDirection="column" gap={4}>
            <Grid item xs={12} md={4}>
              <CustomTextField
                label="Όνομα"
                name="name"
                type="text"
                control={control}
                required
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <CustomTextField
                label="Συχνότητα (σε μέρες)"
                name="frequency"
                type="number"
                control={control}
                required
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <DropdownField
                options={availableTypesOptions}
                name="maintenanceTypeId"
                control={control}
                label="Τύπος Συντήρησης"
                required
              />
            </Grid>
          </Box>

          <Box mt={3}>
            <SaveButton
              text="Δημιουργία"
              disabled={!isDirty}
              isLoading={isMaintenancesCreateLoading}
            />
          </Box>
        </form>
      )}
    </Box>
  );
};

EquipmentsMaintenancesCheck.propTypes = {
  equipmentsIds: PropTypes.array,
  contractId: PropTypes.number,
  setDisableNextButton: PropTypes.func,
  selectedEquipmentType: PropTypes.object,
};

export default EquipmentsMaintenancesCheck;
