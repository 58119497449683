import { Box, Typography } from "@mui/material";
import MainPageLayout from "components/main/MainPageLayout";
import UserViewEditForm from "./UserViewEditForm";
import { useNavigate, useParams } from "react-router";
import { useEffect, useState } from "react";
import SpinningCircle from "components/spinners/SpinningCircle";
import EditButton from "components/buttons/EditButton";
import {
  useDeleteUser,
  useGetUserById,
  useUpdateUser,
} from "hooks/queries/useUsers";
import { useAlert } from "hooks/useAlert";
import transformDotFieldsToObject from "utils/transformDotFieldsToJSON";
import ReturnButton from "components/buttons/ReturnButton";
import colors from "config/theme/colors";
import {
  useAddRoleToUser,
  useGetAllRoles,
  useGetUserRoles,
  useRemoveRoleFromUser,
} from "hooks/queries/useAuthSetup";

const UserViewEdit = () => {
  const { id } = useParams();
  const { showError, showSuccess } = useAlert();
  const [isDeleted, setIsDeleted] = useState(false);
  const {
    data: selectedUser,
    isLoading,
    isError,
    error,
  } = useGetUserById(id, {
    select: (data) => data.user,
    enabled: !!id && !isDeleted,
  });
  const {
    data: userRole,
    isLoading: isRoleLoading,
    isError: isRoleError,
  } = useGetUserRoles(id, {
    select: (data) => data?.message || null,
    enabled: !!id && !isDeleted,
  });
  const { data: availableRoles } = useGetAllRoles({
    select: (data) => data?.result,
  });
  const { mutate: deleteUser } = useDeleteUser(id);
  const { mutate: updateUser } = useUpdateUser(id);
  const { mutate: addRoleToUser } = useAddRoleToUser(id);
  const { mutate: removeRoleFromUser } = useRemoveRoleFromUser(id);
  const [isEditing, setIsEditing] = useState(false);
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (selectedUser && userRole) {
      setUser({
        ...selectedUser,
        role: userRole,
      });
    }
  }, [selectedUser, userRole]);

  if (isLoading || isRoleLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          height: "100%",
        }}
      >
        <SpinningCircle />
      </Box>
    );
  }

  if (isError || isRoleError) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          height: "100%",
        }}
      >
        <p>Error: {error.message}</p>
      </Box>
    );
  }

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleSaveClick = (data) => {
    setIsEditing(false);
    const formData = transformDotFieldsToObject(data);

    const { role, ...rest } = formData;

    const userPayload = {
      ...rest,
    };

    if (userRole !== role) {
      removeRoleFromUser(availableRoles.find((r) => r.name === userRole).id, {
        onError: (error) => {
          showError(error.message);
        },
        onSuccess: () => {
          addRoleToUser(availableRoles.find((r) => r.name === role).id, {
            onError: (error) => {
              showError(error.message);
            },
            onSuccess: () => {
              updateUser(userPayload, {
                onError: (error) => {
                  showError(error.message);
                },
                onSuccess: () => {
                  showSuccess("Ο χρήστης ενημερώθηκε με επιτυχία");
                },
              });
            },
          });
        },
      });
    } else {
      updateUser(userPayload, {
        onError: (error) => {
          showError(error.message);
        },
        onSuccess: () => {
          showSuccess("Ο χρήστης ενημερώθηκε με επιτυχία");
        },
      });
    }
  };

  const handleCancelClick = () => {
    setIsEditing(false);
  };

  const handleDeleteClick = () => {
    setIsDeleted(true);
    setIsEditing(false);
    deleteUser(null, {
      onError: (error) => {
        showError(error.message);
        setIsDeleted(false);
      },
      onSuccess: () => {
        showSuccess("Ο χρήστης διαγράφηκε με επιτυχία");
        navigate("/users");
      },
    });
  };

  return (
    user && (
      <MainPageLayout pageTitle={"Διαχείριση Χρήστη"}>
        <Box
          display="flex"
          flexDirection="column"
          gap={2}
          sx={{
            height: "100%",
          }}
        >
          <Box
            mb={2}
            display="flex"
            gap={2}
            justifyContent="space-between"
            alignItems={{
              xs: "flex-start",
              sm: "center",
            }}
            flexWrap={"wrap"}
          >
            <Box
              display="flex"
              gap={2}
              alignItems="center"
              sx={{
                width: {
                  xs: "100%",
                  sm: "auto",
                },
              }}
            >
              <ReturnButton />
              <Typography fontSize={20} fontWeight={600} color={colors.primary}>
                {`${selectedUser.name} ${selectedUser.surname}`}
              </Typography>
            </Box>
            <Box
              display="flex"
              gap={2}
              sx={{
                width: {
                  xs: "100%",
                  sm: "auto",
                },
              }}
            >
              <EditButton onClick={handleEditClick} disabled={isEditing} />
            </Box>
          </Box>
          <Box display="flex" flexDirection="column" gap={2}>
            <UserViewEditForm
              selectedUser={user}
              isEditing={isEditing}
              onSave={handleSaveClick}
              onCancel={handleCancelClick}
              onDelete={handleDeleteClick}
            />
          </Box>
        </Box>
      </MainPageLayout>
    )
  );
};

export default UserViewEdit;
