import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Box, Grid, Typography } from "@mui/material";
import SaveButton from "components/buttons/SaveButton";
import equipmentRequestFields from "config/forms/equipmentRequestFields";
import CustomTextField from "components/inputs/CustomTextField";
import DropdownField from "components/inputs/DropdownField";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useIsMutating } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import DateField from "components/inputs/DateField";
import { useGetAllEquipmentClasses } from "hooks/queries/useEquipmentClass";
import { useGetAllEquipmentTypes } from "hooks/queries/useEquipmentType";
import SpinningCircle from "components/spinners/SpinningCircle";
import CancelButton from "components/buttons/CancelButton";
import { useNavigate, useOutletContext } from "react-router";
import { useGetAllEquipmentGroups } from "hooks/queries/useEquipmentGroup";
import AlfrescoFileUploader from "components/fileUploaders/AlfrescoFileUploader";

const EquipmentCreateForm = ({ onCreate }) => {
  const { selectedAsset } = useOutletContext();
  const [selectedEquipmentType, setSelectedEquipmentType] = useState(null);
  const [selectedEquipmentGroup, setSelectedEquipmentGroup] = useState(null);
  const navigate = useNavigate();
  const { data: equipmentTypes, isLoading: equipmentTypesLoading } =
    useGetAllEquipmentTypes({
      pageNumber: null,
      pageSize: null,
      assetCategoryId: selectedAsset?.assetCategory?.id,
    });
  const { data: equipmentClasses, isLoading: equipmentClassesLoading } =
    useGetAllEquipmentClasses({
      pageNumber: null,
      pageSize: null,
      equipmentGroupId: selectedEquipmentGroup?.id,
    });
  const { data: equipmentGroups, isLoading: equipmentGroupsLoading } =
    useGetAllEquipmentGroups({
      pageNumber: null,
      pageSize: null,
      equipmentTypeId: selectedEquipmentType?.id,
    });
  const isCreatingLoading =
    useIsMutating({ mutationKey: ["createEquipment"] }) +
      useIsMutating({ mutationKey: ["uploadFiles"] }) +
      useIsMutating({ mutationKey: ["deleteMultipleFiles"] }) +
      useIsMutating({ mutationKey: ["deleteFile"] }) >
    0;

  const schema = yup.object(
    equipmentRequestFields.reduce((acc, field) => {
      let fieldSchema;

      if (field.type === "text" || field.type === "textarea") {
        fieldSchema = yup.string();
      } else if (field.type === "select" || field.type === "boolean") {
        if (field.name === "status") {
          fieldSchema = yup.object().shape({
            id: yup.string(),
            label: yup.string(),
          });
        } else fieldSchema = yup.string();
      } else if (field.type === "number") {
        fieldSchema = yup
          .number()
          .transform((value) => (isNaN(value) ? undefined : value));
      } else if (field.type === "date") {
        fieldSchema = yup.date().transform((value, originalValue) => {
          return isNaN(Date.parse(originalValue)) ? undefined : value;
        });
      } else if (field.type === "file") {
        const validTypes = field.validTypes || [];
        const maxSize = field.maxSize || 5000000;

        fieldSchema = yup.mixed().test({
          name: "fileValidation",
          exclusive: true,
          message: "Invalid file",
          test: (value) => {
            if (!value || value.length === 0) return true;

            for (const file of value) {
              if (
                !validTypes.includes(file.type) &&
                !validTypes.includes(file.mimeType)
              ) {
                return new yup.ValidationError(
                  "Μη έγκυρος τύπος αρχείου",
                  null,
                  "file"
                );
              }

              if (file.size > maxSize) {
                return new yup.ValidationError(
                  `Το μέγεθος του αρχείου δεν πρέπει να υπερβαίνει τα ${maxSize / 1000000}MB`,
                  null,
                  "file"
                );
              }
            }

            return true;
          },
        });
      }

      if (field.required) {
        fieldSchema = fieldSchema.required("Το πεδίο είναι υποχρεωτικό");
      } else {
        fieldSchema = fieldSchema.nullable();
      }

      if (field.validation === "custom" && field.regex) {
        fieldSchema = fieldSchema.matches(field.regex, field.validationMessage);
      }

      acc[field.name] = fieldSchema.typeError(
        field.validationMessage || "Μη έγκυρη τιμή"
      );

      return acc;
    }, {})
  );

  const defaultValues = equipmentRequestFields.reduce((acc, field) => {
    acc[field.name] = field.defaultValue;
    return acc;
  }, {});

  const {
    control,
    handleSubmit,
    formState: { errors },
    getValues,
    reset,
    watch,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  });
  const watchEquipmentTypeId = watch("equipmentTypeId");
  const watchEquipmentGroupId = watch("equipmentGroupId");

  useEffect(() => {
    if (watchEquipmentTypeId) {
      setSelectedEquipmentType(
        equipmentTypes?.items.find((type) => type.name === watchEquipmentTypeId)
      );
    } else {
      setSelectedEquipmentType(null);
    }

    if (watchEquipmentGroupId) {
      setSelectedEquipmentGroup(
        equipmentGroups?.items.find(
          (type) => type.name === watchEquipmentGroupId
        )
      );
    } else {
      setSelectedEquipmentGroup(null);
    }
  }, [
    watchEquipmentTypeId,
    watchEquipmentGroupId,
    equipmentTypes,
    equipmentGroups,
  ]);

  if (
    equipmentTypesLoading ||
    equipmentClassesLoading ||
    equipmentGroupsLoading
  ) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          height: "100%",
        }}
      >
        <SpinningCircle />
      </Box>
    );
  }

  const availableTypesOptions = equipmentTypes?.items?.map((item) => item.name);

  const availableGroupsOptions = equipmentGroups?.items?.map(
    (item) => item.name
  );

  const availableClassesOptions = equipmentClasses?.items?.map(
    (item) => item.name
  );

  const getInputElementByFieldType = (field) => {
    if (
      field.type === "text" ||
      field.type === "number" ||
      field.type === "textarea"
    ) {
      return <CustomTextField {...field} control={control} />;
    } else if (field.type === "select" || field.type === "boolean") {
      let availableOptions =
        field.type === "boolean" ? ["Ναι", "Όχι"] : field.options;

      if (!availableOptions) {
        if (field.name === "equipmentTypeId") {
          availableOptions = availableTypesOptions;
        } else if (field.name === "equipmentClassId") {
          availableOptions = availableClassesOptions;
        } else if (field.name === "equipmentGroupId") {
          availableOptions = availableGroupsOptions;
        }
      }

      return (
        <DropdownField
          {...field}
          control={control}
          options={availableOptions}
          boolean={field.type === "boolean"}
        />
      );
    } else if (field.type === "date") {
      return <DateField {...field} control={control} />;
    } else if (field.type === "file") {
      return <AlfrescoFileUploader {...field} control={control} />;
    }
  };

  return (
    <form onSubmit={handleSubmit(() => onCreate(getValues()))}>
      <Box display="flex" flexDirection="column" gap={2}>
        <Grid container spacing={2}>
          {equipmentRequestFields
            ?.filter((field) => field.display !== false)
            .map((field) => {
              const { name } = field;
              const shouldSkip =
                (name === "equipmentGroupId" && !selectedEquipmentType) ||
                (name === "equipmentClassId" &&
                  (!selectedEquipmentGroup || !selectedEquipmentType));

              if (shouldSkip) {
                return null;
              } else if (field.type === "file") {
                return (
                  <Grid item xs={12} key={name}>
                    <Box width={{ xs: "100%", lg: "50%" }}>
                      {getInputElementByFieldType(field)}
                    </Box>
                  </Grid>
                );
              } else
                return (
                  <Grid item xs={12} md={4} key={name}>
                    {getInputElementByFieldType(field)}
                  </Grid>
                );
            })}
        </Grid>

        {Object.keys(errors).length > 0 && (
          <Typography color="error" fontSize={14}>
            Παρακαλούμε ελέγξτε ότι όλα τα πεδία έχουν συμπληρωθεί σωστά
          </Typography>
        )}

        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          gap={2}
          width={"100%"}
        >
          <CancelButton
            onClick={() => {
              reset();
              navigate(-1);
            }}
          />
          <SaveButton text="Δημιουργία" isLoading={isCreatingLoading} />
        </Box>
      </Box>
    </form>
  );
};

EquipmentCreateForm.propTypes = {
  onCreate: PropTypes.func,
};

export default EquipmentCreateForm;
